import NationalInsuranceIcon from '../../../assets/car-insurance/form-5/national.png'
import HDFCIcon from '../../../assets/car-insurance/form-5/hdfc.png'
import IciciIcon from '../../../assets/car-insurance/form-5/icici.png'
import DigitIcon from '../../../assets/car-insurance/form-5/digit.png'
import CholaIcon from '../../../assets/car-insurance/form-5/chola.png'
import RelianceIcon from '../../../assets/car-insurance/form-5/reliance.png'


export const quotesData = [
    {
        id:1,
        name:'National Insurance Company Ltd.',
        coverYear:'1',
        price:'2,839',
        icon:NationalInsuranceIcon
    },
    {
        id:2,
        name:'HDFC Ergo General Insurance Company',
        coverYear:'1',
        price:'2,839',
        icon:HDFCIcon
    },
    {
        id:3,
        name:' ICICI General Insurance',
        coverYear:'1',
        price:'2,839',
        icon:IciciIcon
    },
    {
        id:4,
        name:'Digit General Insurance Ltd.',
        coverYear:'1',
        price:'2,839',
        icon:DigitIcon
    },
    {
        id:5,
        name:'Chola MS General Insurance Company Ltd.',
        coverYear:'1',
        price:'2,839',
        icon:CholaIcon
    },
    {
        id:6,
        name:'Reliance General Insurance Company',
        coverYear:'1',
        price:'2,839',
        icon:RelianceIcon
    },
    {
        id:7,
        name:'Digit General Insurance Ltd.',
        coverYear:'1',
        price:'2,839',
        icon:DigitIcon
    },
    {
        id:8,
        name:'Chola MS General Insurance Company Ltd.',
        coverYear:'1',
        price:'2,839',
        icon:CholaIcon
    },
    {
        id:9,
        name:'Reliance General Insurance Company',
        coverYear:'1',
        price:'2,839',
        icon:RelianceIcon
    },
    
]