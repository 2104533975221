import React from 'react';
import './paymentModeSection.css';
import bankOptions from '../../../utilities/bankOptions';

const PaymentModeSection = ({ formData, handleChange }) => {
  // const handlePaymentModeChange = (e) => {
  //   const { value } = e.target;

  //   // Update the payment mode in the formData
  //   handleChange(e);

  //   // Clear specific fields when the payment mode is changed (except for "Cash")
  //   if (value !== 'Cash') {
  //     handleChange({ target: { name: 'amount', value: '' } });
  //     handleChange({ target: { name: 'bank', value: '' } });
  //     handleChange({ target: { name: 'chequeNumber', value: '' } });
  //     handleChange({ target: { name: 'dated', value: '' } });
  //     handleChange({ target: { name: 'bankBranch', value: '' } });
  //     handleChange({ target: { name: 'payModeRemark', value: '' } });
  //   }
  // };

  return (
    <div className="payment-mode-section">
  <h2>Payment Mode</h2>
  <div className="payment-mode-grid">
    <div className="form-group">
      <label htmlFor="paymentMode" className="paymentModeLabel">Payment Mode</label>
      <select
        id="paymentMode"
        name="paymentMode"
        className="paymentModeSelect"
        value={formData.paymentMode || ''}
        onChange={handleChange}
      >
        <option value="">--Select--</option>
        <option value="Cash">Cash</option>
        <option value="Cheque">Cheque</option>
        <option value="CRS">CRS</option>
        <option value="CustFloat">CustFloat</option>
        <option value="Etransfer">Etransfer</option>
        <option value="Online">Online</option>
        <option value="UPI">UPI</option>
        <option value="None">None</option>
      </select>
    </div>

    <div className="form-group">
      <label htmlFor="amount" className="paymentModeLabel">Amount</label>
      <input
        type="number"
        id="amount"
        name="amount"
        className="paymentModeInput"
        value={formData.amount || ''}
        onChange={handleChange}
      />
    </div>

    {formData.paymentMode !== 'Cash' && formData.paymentMode && (
      <>
        <div className="form-group required">
          <label htmlFor="bank" className="paymentModeLabel">Bank</label>
          <select
            id="bank"
            name="bank"
            className="paymentModeSelect"
            value={formData.bank}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            {bankOptions.map((bank) => (
              <option key={bank.value} value={bank.value}>
                {bank.label}
              </option>
            ))}
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="chequeNumber" className="paymentModeLabel">Chq/DD/Trn No.</label>
          <input
            type="text"
            id="chequeNumber"
            name="chequeNumber"
            className="paymentModeInput"
            value={formData.chequeNumber || ''}
            onChange={handleChange}
            required
          />
        </div>

        <div className="form-group">
          <label htmlFor="dated" className="paymentModeLabel">Dated</label>
          <input
            type="date"
            id="dated"
            name="dated"
            className="paymentModeInput"
            value={formData.dated || ''}
            onChange={handleChange}
          />
        </div>

        <div className="form-group">
          <label htmlFor="bankBranch" className="paymentModeLabel">Bank Branch</label>
          <input
            type="text"
            id="bankBranch"
            name="bankBranch"
            className="paymentModeInput"
            value={formData.bankBranch || ''}
            onChange={handleChange}
          />
        </div>
      </>
    )}

    <div className="form-group">
      <label htmlFor="payModeRemark" className="paymentModeLabel">PayMode Remark</label>
      <input
        type="text"
        id="payModeRemark"
        name="payModeRemark"
        className="paymentModeInput"
        value={formData.payModeRemark || ''}
        onChange={handleChange}
      />
    </div>
  </div>
</div>

  );
};

export default PaymentModeSection;
