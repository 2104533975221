import React from "react";
import MenuItems from "./MenuItem";

const Dropdown = ({ submenus, dropdown, depthLevel }) => {
  const updatedDepthLevel = depthLevel + 1;
  const dropdownClass = updatedDepthLevel > 1 ? "dropdown-submenu" : "";

  return (
    <ul className={`dropdown ${dropdownClass} ${dropdown ? "show" : ""}`}>
      {submenus.map((submenu, index) => (
        <MenuItems
          items={submenu}
          key={`${submenu.title}-${index}`} // Ensure a more unique key if possible
          depthLevel={updatedDepthLevel}
        />
      ))}
    </ul>
  );
};

export default Dropdown;
