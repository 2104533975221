import Input from "antd/lib/input";
import styled from "styled-components";


export const LeftArrowIcon = styled.img`
    transform: rotate(180deg);
    height:13px;
    width:13px
`
export const RightArrowIcon = styled.img`
height:13px;
    width:13px
`

export const styles = {
    layout: {
        border: '.5px solid #B7B7B7',
        margin: '2rem',
        backgroundColor: '#fff',
        padding: '1rem',
        display: 'flex',
        alignItems: 'center'
    },
    searchBox: {
        // padding: '1rem',
        borderRadius: '.6rem',
        marginTop: '1rem',
    },
    step: {
        fontSize: '.8rem',
        marginBottom: 0,
        marginTop: '.3rem'
    },
    midCol: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
    },
    topBox: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        paddingLeft: '1.6rem',
        paddingRight: '1.6rem',
        borderRadius: '.6rem',
        border: '1px solid #f36',
        width: '100%'
        
    },
    
    brand: {
        marginBottom: '.3rem'
    },
    outerRow: {

    },

    brandBox: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #B7B7B7',
        width: '125px',
        borderRadius: '.5rem',
        padding: '.6rem',
        paddingBottom: 0,
        textAlign: 'center',
        marginTop: '.4rem',
        marginBottom: '.4rem',
        cursor: 'pointer',
        background:"none",
        alignItems:"center"
    },
    activeBrandBox: {
        display: 'flex',
        flexDirection: 'column',
        border: '1px solid #F6931E',
        width: '130px',
        borderRadius: '.5rem',
        padding: '.6rem',
        paddingBottom: 0,
        textAlign: 'center',
        marginTop: '.4rem',
        marginBottom: '.4rem',
        cursor: 'pointer'
    },
    brandLogo: {
        width: 70, height: 70, margin: 'auto'
    },
    brandName: {
        marginTop: '.2rem'
    },
    rtoCode: {
        marginTop: '.2rem',fontWeight:700
    },
    gridContainer: {
        display: 'flex', width: '100%', flexDirection: 'row',justifyContent:"space-between", marginTop: '1rem'
    }

}

export const SearchInput = styled(Input.Search)`
    span  {
        /* width: 100px !important; */
        border-radius: 1rem !important;
    }
`

export const InactiveTab = styled.button`
    background-color: #fff;
    border: 1px solid #B7B7B7;
    color:#000 !important;
    opacity: .25;
    border-radius: .4rem;
    width: 128px;
    padding:.6rem;
`
export const Tab = styled.button`
    background-color: #fff;
    /* border: 1px solid #F6931E; */
    border: 1px solid #B7B7B7;
    border-radius: 0.4rem;
    width: 128px;
    padding:.6rem;
    color: #000 !important;
    font-weight: 800;
    margin: .2rem .2rem;
`



export const ErrorContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    // left: 33rem;
    right: 1.9rem;
    // top: 0.2rem;
    // top: -28px;
    bottom: 1rem;
    z-index:1;
    // font-size: .8rem;
    align-items: flex-end;

`


export const SelectDropdown = styled.select`
    width: 100%;
    padding-top: .35rem;
    padding-bottom: .35rem;
    // outline: dotted;
    // padding: 0.3rem;
    // border-radius: .6rem;
    font-size: 1.1rem;
    // border: 1px solid #B7B7B7;
    // text-shadow: 0 0 0 #000;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
`

export const InputC = styled(Input)`
    width: 100%;
    // padding-top: 1rem;
    // border-radius: .6rem;
    // font-size: 1.1rem;
    // border: 1px solid #B7B7B7;
    color: #605F5E;
    border: none;
    ${'' /* background-color: transparent; */}
    resize: none;
    outline: none;
`

export const ErrArrow = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid #F6931E; 
    border-left: 10px solid transparent;
    margin-right: .5rem;
`

export const ErrorIcon = styled.img`
    width: 20px;
    margin-top: .4rem;
`

export const ErrorMsgContainer = styled.div`
    padding: .5rem 1rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    // border-bottom-right-radius: 0;
    color: #fff;
    font-weight: 900;
`

export const HDiv = styled.div`
    display: flex;
    align-self:center;
    align-items: center;
    margin-top: 1.6rem;
`

export const FloatingTitle = styled.p`
    position: absolute;
    top: -8px;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #fff;
    font-weight: 800;
`

export const InputContainer = styled.div`
    position: relative;
    padding: .6rem;
    margin: 1rem auto;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    input {
        width: 100%;
         ${'' /* padding-top: .2rem; */}
         ${'' /* padding-bottom: .2rem; */}
        outline: none;
        border: none;
        font-size: 1rem;
        margin-left: .2rem;
        background:none;
        margin-top:.3rem;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .m-0 {
        margin-top: 0;
        h4 {
            margin: auto;
        }
    }
    .m-0-0 {
        margin: 0;
    }

    .horizontal {
        display: flex;
        align-items: center;
        input {
            width: auto;
            accent-color: #F6931E;
        }
        label {
            margin-left: .5rem;
        }

        .apart+.apart {
            margin-left: 1rem;
        }

    }
    
`


export const RtoModalContainer = styled.div`
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    padding: 1rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    position: absolute;
    background-color: #fff;
    padding-top: 2rem;
    bottom: 2rem;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: 1px 3px 10px ;
    width: 400px;
    height: 100px;
    top: 200px;

    @media screen and (max-width: 700px) {
        grid-template-columns: repeat(3,1fr);
        width:100%;
    }
    
    @media screen and (max-width: 550px) {
        grid-template-columns: repeat(2,1fr);
    }

    @media screen and (max-width: 400px) {
        grid-template-columns: repeat(1,1fr);
    }

`
export const CloseBtn = styled.img`
    position: absolute;
    right: 12px;
    cursor: pointer;
    top: 12px;
`
export const TopBox = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    border-radius: .6rem;
    border: 1px solid #f36;
    width: 100%;

    ${'' /* @media screen and (max-width: 410px) { */}
        .ant-typography {
            font-size: 1rem;
        ${'' /* } */}
    }
    @media screen and (max-width:900px) {
       padding-left:0;
       padding-right:0;
    }

`

export const OuterRow = styled.div`
    width: 600px;
    margin-top: 1rem;
    position:relative;
    margin-bottom: 1rem;
    .ant-row {
        margin: 0 !important;
    }

    @media screen and (max-width:900px) {
        width: 90%;
    }

`
