import ReactElasticCarousel from "react-elastic-carousel"
import { useNavigate, Link } from "react-router-dom"
import { MainContainer } from "../../assets/common-styles/common-styles"
import { SkeletonComponent } from "../../components/skeleton-component/SkeletonComponent"
import { Btns, CardContainer, Circle, CircleInactive, HDiv, InputContainer, Opacity, ReactElasticCarouselComp, SkeletonContainer } from "./styles"
import { updateCombinedModel, updateFirstPageData } from '../../Redux/LifeRedux';
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./intro.css"
import { Modal } from 'antd';
import ErrorIconImg from '../../assets/brand-new-car/exclamation.svg'

const confirm = Modal.confirm;

const ErrorPopups = error => {
    return (
        <div className='error-containerA'>
            <div className="error-msg-containerA">{error.error}</div>
            <div className="errArrowA" />
            <img src={ErrorIconImg} className="error-iconA" />
        </div>
    )
}

export const LifeIntroScreen = () => {

    // for responsive screen 
      const [screenWidth, setScreenWidth] = useState(window.innerWidth);
        useEffect(() => {
            const handleResize = () => {
            setScreenWidth(window.innerWidth);
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => {
            window.removeEventListener('resize', handleResize);
            };
        }, []);


    const [itemId, setItemId] = useState(0);
    // const combinedRedux = useSelector((state) => state.life.combinedRedux);
    const combinedRedux1 = useSelector((state) => state.life.combinedRedux);
    // const combinedReduxInsure = useSelector((state) => state.life.combinedRedux.life);
    // console.log(combinedRedux1, 'kdsk');
    //const [combinedReduxState, setCombinedReduxState] = useState(combinedReduxInsure);
    const [income, setIncome] = useState('');
    const [error, setError] = useState('')
    const data = [
        {
            id: 1,
            ques: "Please choose your Occupation type",
            options: [
                {
                    id: 1,
                    value: "Salaried",
                    selected: false
                },
                {
                    id: 2,
                    value: "Self Employed",
                    selected: false
                },
            ]
        },
        {
            id: 2,
            ques: "Do you Smoke or Chew tobacco?",
            options: [
                {
                    id: 1,
                    value: "Yes",
                    selected: false
                },
                {
                    id: 2,
                    value: "No",
                    selected: false
                }
            ]
        },
        {
            id: 3,
            ques: "Your annual income",
            value: income,
        }
    ]
    const carousel = React.useRef(null);
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const PaginationCircle = ({ onClick, key, active }) => {
        if (active === true) {
            return (
                <Circle
                    key={key}
                    onClick={onClick}
                />
            )
        } else if (active === false) {
            return (
                <CircleInactive
                    key={key}
                    onClick={onClick}
                />
            )
        }
    }
    const onClickFun = (id, activeSlide, state, e) => {
        setItemId(id);
        const enquiryId = combinedRedux1?.enquiryId;
        if (id === 1 && state === 1) {
            const obj = {
                enquiryId: enquiryId,
                userDetail: {
                    occupationType: 'Salaried',
                }
            }
            dispatch(updateFirstPageData(obj));
            carousel.current.goTo(activeSlide + 1)
        } else if (id === 1 && state === 2) {
            const obj = {
                enquiryId: enquiryId,
                userDetail: {
                    occupationType: 'Self Employed',
                }
            }
            dispatch(updateFirstPageData(obj));
            carousel.current.goTo(activeSlide + 1)
        }
        if (id === 2 && state === 1) {
            const obj = {
                enquiryId: enquiryId,
                userDetail: {
                    isTobbaco: true,
                }
            }
            dispatch(updateFirstPageData(obj));
            carousel.current.goTo(activeSlide + 1)
        } else if (id === 2 && state === 2) {
            const obj = {
                enquiryId: enquiryId,
                userDetail: {
                    isTobbaco: false,
                }
            }
            dispatch(updateFirstPageData(obj));
            carousel.current.goTo(activeSlide + 1)
        }
        if (id === 3) {

            // else{
            // //  handleRangeSelect(income)
            // }
            const obj = {
                enquiryId: enquiryId,
                userDetail: {
                    annualIncome: income,
                }
            }
            dispatch(updateFirstPageData(obj));
            navigate('/life-quotes?enquiryId=' + enquiryId);
        }
    }
    function showConfirm(title, income) {
        confirm({
            title,

            onOk() {
                console.log('OK');
                handleRevisedSum(income)
            },
            okButtonProps: {
                style: {
                    background: '#F6931E',
                    borderRadius: '5px',
                    border: 'none',
                },
            },
            onCancel() {
                console.log('Cancel');
                // return "Cancel"
            },
            cancelButtonProps: {
                style: {
                    background: '#F6931E',
                    borderRadius: '5px',
                    border: 'none',
                },
            },
        });
    }

    const handleRevisedSum = maxValue => {
        const obj = {
            enquiryId: combinedRedux1?.enquiryId,
            sumAssured: parseInt((maxValue) * 20),
            userDetail: {
                annualIncome: maxValue
            },
        };
        console.log("updatedSumAssured", obj)
        dispatch(updateFirstPageData(obj));
        navigate('/life-quotes?enquiryId=' + combinedRedux1?.enquiryId);
    }
    const handleRangeSelect = (maxValue) => {
        console.log("maxVal1", maxValue)
        if (maxValue < 200000) {
            // alert('Annual Income Must be Greater 2 Lakh')
            setError({ income: "Annual Income Must be Greater 2 Lakh" })
            return;
        }
        else if (combinedRedux1.data?.sumAssured > (parseInt(maxValue) * 20)) {
            // console.log("maxVal1",maxValue)
            showConfirm(`On This Annual Income Maximum Sum Assured can be provided Rs${parseInt(maxValue) * 20}. Do you want to revise your Sum Assured?`, parseInt(maxValue))
            //    showConfirm("sum assured is not valid for this annual income!. Do you want to revise your sum assured",parseInt(maxValue))
            //    if(reviseSumAssured=="Cancel")
            // console.log(reviseSumAssured)
            //    return;
            // const obj = {
            //     enquiryId: combinedRedux1?.data?.enquiryId,
            //     sumAssured:parseInt((maxValue)*20),
            //     userDetail: {
            //         annualIncome: maxValue
            //     },
            //     };
            //   console.log("updatedSumAssured",obj)
            // dispatch(updateFirstPageData(obj));
            // navigate('/life-quotes?enquiryId='+combinedRedux1?.data?.enquiryId);
        }

        // setIncome(maxValue);
        else {
            const obj = {
                enquiryId: combinedRedux1?.enquiryId,
                userDetail: {
                    annualIncome: maxValue
                },
            };
            //   console.log("updatedSumAssured",obj)
            dispatch(updateFirstPageData(obj));
            navigate('/life-quotes?enquiryId=' + combinedRedux1?.enquiryId);
        }
    };
    const css = itemId == 2
        ? `
        .sc-bRAYea {
        height: 470px !important;
        }
    `
        : `
        .sc-bRAYea{
        height: 231px !important;
        }
    `

    const a = ['', 'one ', 'two ', 'three ', 'four ', 'five ', 'six ', 'seven ', 'eight ', 'nine ', 'ten ', 'eleven ', 'twelve ', 'thirteen ', 'fourteen ', 'fifteen ', 'sixteen ', 'seventeen ', 'eighteen ', 'nineteen ']
    const b = ['', '', 'twenty', 'thirty', 'forty', 'fifty', 'sixty', 'seventy', 'eighty', 'ninety']

    const regex = /^(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/

    const getLT20 = (n) => a[Number(n)]
    const getGT20 = (n) => b[n[0]] + ' ' + a[n[1]]

    function toTitleCase(str) {
        return str.toLowerCase().split(' ').map(function (word) {
            return (word.charAt(0).toUpperCase() + word.slice(1));
        }).join(' ');
    }


    function numWords(input) {
        const num = Number(input)
        if (isNaN(num)) return ''
        if (num === 0) return null

        const numStr = num.toString()
        if (numStr.length > 9) {
            throw new Error('overflow') // Does not support converting more than 9 digits yet
        }

        const [, n1, n2, n3, n4, n5] = ('000000000' + numStr).substr(-9).match(regex) // left pad zeros

        let str = ''
        str += n1 != 0 ? (getLT20(n1) || getGT20(n1)) + 'crore ' : ''
        str += n2 != 0 ? (getLT20(n2) || getGT20(n2)) + 'lakh ' : ''
        str += n3 != 0 ? (getLT20(n3) || getGT20(n3)) + 'thousand ' : ''
        str += n4 != 0 ? getLT20(n4) + 'hundred ' : ''
        str += n5 != 0 && str != '' ? 'and ' : ''
        str += n5 != 0 ? (getLT20(n5) || getGT20(n5)) : ''
        const titleCase = toTitleCase(str.trim() + " rupees only")
        return titleCase
    }

    //   income handler 
    const handleIncome = e => {
        console.log("income", e.target.value)
        if(e.target.value<1){
            setIncome('')
            return;
         }
        if (parseInt(e.target.value) < 999999999)
            setIncome(e.target.value)
        else if (isNaN(e.target.value) || !e.target.value)
            setIncome('')
    }

    const removeError = () => {
        console.log("called")
        if (error.income)
            setError(error.income = "")

    }
    return (
        <>
            <MainContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <SkeletonContainer>
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                </SkeletonContainer>
                <Opacity />
                <CardContainer style={{ height: 'auto' }}>
                    <ReactElasticCarouselComp
                        ref={carousel}
                        showArrows={false}
                        enableSwipe={false}
                        renderPagination={({ pages, activePage, onClick }) => {
                            return (
                                <HDiv>
                                    {pages.map((item) => {
                                        const isActivePage = activePage === item;
                                        return (
                                            <PaginationCircle
                                                key={item}
                                                onClick={() => {
                                                    onClick(item)
                                                }}
                                                active={isActivePage}
                                            />
                                        )
                                    })}
                                </HDiv>
                            )
                        }}
                    >
                        {data.map(item => (
                            <>
                                <div className="carousel-container" key={item.id}>
                                    <p className= {screenWidth <= 1024 ? "carousel-text-mobile" : "carousel-text"}>Just answer {data.length - item.id + 1} more questions to get more accurate quotes.</p>
                                    <div className="line"></div>
                                    <p className= {screenWidth <= 1024 ? "line-text-mobile" : "line-text"}>{item.ques}</p>
                                    {item.options &&
                                        item.options.map((option) => (
                                            <Btns
                                                key={option.id}
                                                onClick={() =>
                                                    onClickFun(item.id, carousel?.current?.state?.activeIndex, option.id)
                                                }
                                            >
                                                {option.value}
                                            </Btns>
                                        ))}
                                    {
                                        item.id === 3 && (
                                            <div>
                                                <div className="annual-income">
                                                    <div style={{ position: 'relative', display: 'inline-block' }} onClick={removeError}>
                                                        <input
                                                            type="number"
                                                            placeholder="Enter Your annual Income"
                                                            value={income}
                                                            className= {screenWidth <= 1024 ? "introInput-mobile no-spinner" : "introInput no-spinner"}
                                                            onChange={handleIncome}
                                                        />
                                                        {error.hasOwnProperty('income') ? (
                                                            <ErrorPopups error={error.income} />
                                                        ) : null}
                                                        <button className="introBtn"
                                                            // onClick={() => onClickFun(item.id, carousel?.current?.state?.activeIndex)}
                                                            onClick={e => handleRangeSelect(income)}
                                                        >
                                                            <span className="introSpan">&#10140;</span>
                                                        </button>

                                                    </div>
                                                    <p className="annualText">{numWords(income)}</p>
                                                </div>
                                                <div className= {screenWidth <= 1024 ? "range-mobile" : "range"}>
                                                    <button type="button" onClick={() => handleRangeSelect(3000000)}>25 Lac to 30 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(2500000)}>15 Lac to 24.9 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(1500000)}>10 Lac to 14.9 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(1000000)}>8 Lac to 9.9 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(800000)}>5 Lac to 7.9 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(500000)}>3 Lac to 4.9 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(300000)}>2 Lac to 2.9 Lac</button>
                                                    <button type="button" onClick={() => handleRangeSelect(200000)}>Less than 2 Lac</button>
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                            </>
                        ))}
                    </ReactElasticCarouselComp>
                </CardContainer>
            </MainContainer>
            <style>{css}</style>
        </>
    )
}