import styled from "styled-components";

export const MainContainer = styled.div`
    padding: 150px 15px 25px 15px;
`

export const GradientBg = styled.div`
    width: 100%;
    height: 50vh;
    position: absolute;
    z-index: -1;
    background: linear-gradient(#41025f, #ffe1bb);
`
export const FormContainer = styled.div`
    background-color: #fff;
    border-radius: 20px;
    padding: 20px;
    padding-top: 35px;
`
export const HDiv = styled.div`
    display: flex;
    align-items: center;
`
export const BackIcon = styled.img`
    margin-left: 1rem;
`
export const Title = styled.h2`
    margin: auto 1rem;
    font-weight: 500;
`
export const Line = styled.div`
    width: 10%;
    height: 1.5px;
    background-color: #ff4800;
`
export const MainForm = styled.form`
    margin-top: 40px;
    padding: 50px;
    background-color: #f5f5f5;
    border-radius: 15px 15px 0 0;
`
export const FormTitle = styled.h4`
    font-size: 21px;
    font-weight: 800;
`
export const HDivApart = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width:900px) {
        display: block;
    }

`
export const Input = styled.input`
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #707070;
    width:75%;
    @media screen and (max-width:500px) {
        width: 100%;
    }
`
export const RadioBtn = styled.input`
    margin: auto 10px auto 0;
    width: 20px;
    height: 20px;
`
export const InputSet = styled.div`
    margin: 30px auto;
    position: relative;
    p{
        margin: 10px auto;
        font-size: 14px;
        color: #333333;
    }

    @media screen and (max-width: 500px) {
        .i-btn {
            position: absolute;
            top: 30px;
        }
    }

`
export const FormLeft = styled.div`
    flex: 1;
    
`
export const FormRight = styled.div`
    flex: 1;
    .dob {
        position: relative;
        img {
            margin-bottom: -8px;
        }
    }
    `
export const InfoIcon = styled.img`
    margin-left: 5px;
    cursor: pointer;
`
export const CautionIcon = styled.img`

`

export const CautionContainer = styled.div`
    background-color: #e8e8e8;
    padding: 28px 50px;
    border-radius: 0 0 15px 15px;
` 
export const CautionIconDiv = styled.div`
    position: relative;
`

export const RedText = styled.p`
    color: #fc4c1e;
    font-size: 24px;
    font-weight: 900;
    position: absolute;
    top: 15px;
    left: 21.5px;
`
export const CautionText = styled.p`
    color: #333333;
    font-size: 0.75rem;
    width: 60%;
    margin-left: 16px;
    line-height: 1.5;
`
export const CalculateBtn = styled.button`
    background: linear-gradient(#ed4168, #ffb25a);
    width: 200px;
    padding: 16px;
    border: none;
    border-radius: 1.5rem;
    font-weight: 900;
    color: #fff;
    font-size: 1.1rem;
    margin: auto;
    margin-top: 2rem;
    display: block;
    cursor: pointer;
`