import { NotificationContainer, NotificationContainerLight } from "./styles"
import ArrowRight from '../../../../assets/car-insurance/dashboard-icons/right-theme-arrow.svg'

export const NotificationComp = ({ light, item }) => {
    if (light) {
        return (
            <NotificationContainer>
                <p>{item.title}</p>

                <div className="flex">
                    {item.new ?
                        <div className="new">NEW</div>
                        : null}
                    <img
                        src={ArrowRight}
                        className='arrow'
                    />
                </div>
            </NotificationContainer>
        )
    } else {
        return (
            <NotificationContainerLight>
                <p>{item.title}</p>

                <div className="flex">
                    {item.new ?
                        <div className="new">NEW</div>
                        : null}
                    <img
                        src={ArrowRight}
                        className='arrow'
                    />
                </div>
            </NotificationContainerLight>
        )
    }
}