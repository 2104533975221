import React from 'react';
import './partnerBrokerSection.css';

const PartnerBrokerSection = ({
  formData,
  handleChange,
  handleCheckboxChange,
  isChecked,
}) => {
  return (
    <div className="partner-broker-section">
      <div className="checkbox-container">
        <label htmlFor="partnerBrokerCheckbox" className="partnerBrokerLabel">
          <input
            type="checkbox"
            id="partnerBrokerCheckbox"
            className="partnerBrokerInput"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          Partner Broker
        </label>
      </div>

      {isChecked && (
        <div className="partner-broker-form">
          <div className="partner-broker-grid">
            <div className="form-group">
              <label htmlFor="partnerBrokerName" className="partnerBrokerLabel">
                Partner Broker Name
              </label>
              <select
                id="partnerBrokerName"
                name="partnerBrokerName"
                className="partnerBrokerSelect"
                value={formData.partnerBrokerName}
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                <option value="Broker1">Broker 1</option>
                <option value="Broker2">Broker 2</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="partnerBrokerCode" className="partnerBrokerLabel">
                Partner Broker Code
              </label>
              <input
                type="text"
                id="partnerBrokerCode"
                name="partnerBrokerCode"
                className="partnerBrokerInput"
                value={formData.partnerBrokerCode}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <label htmlFor="network" className="partnerBrokerLabel">
                Network
              </label>
              <select
                id="network"
                name="network"
                className="partnerBrokerSelect"
                value={formData.network}
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                <option value="Network1">Network 1</option>
                <option value="Network2">Network 2</option>
              </select>
            </div>

            <div className="form-group">
              <label htmlFor="crossReferral" className="partnerBrokerLabel">
                Cross/Referral
              </label>
              <select
                id="crossReferral"
                name="crossReferral"
                className="partnerBrokerSelect"
                value={formData.crossReferral}
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                <option value="Cross">Cross</option>
                <option value="Referral">Referral</option>
              </select>
            </div>
          </div>

          <div className="partner-broker-table-container">
            <table className="partnerBrokerTable">
              <thead>
                <tr>
                  <th className="partnerBrokerTableHeader">Name</th>
                  <th className="partnerBrokerTableHeader">Address</th>
                  <th className="partnerBrokerTableHeader">Relation</th>
                  <th className="partnerBrokerTableHeader">Phone</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td className="partnerBrokerTableData">John Doe</td>
                  <td className="partnerBrokerTableData">123 Main St</td>
                  <td className="partnerBrokerTableData">Partner</td>
                  <td className="partnerBrokerTableData">555-1234</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default PartnerBrokerSection;
