import { BackIcon, CarIcon, HDiv, HDivApart, Line } from "./styles"
import BackIconImg from '../../assets/commons/back-icon.png'
import CarIconImg from '../../assets/car.png'

export const CardHeader = ({title}) => {
    return(
        <HDivApart>
            <HDiv>
                <BackIcon src={BackIconImg} />
                <h2>{title}</h2>
                <Line />
            </HDiv>
            <div>
                <CarIcon src={CarIconImg} />
            </div>
        </HDivApart>
    )
}