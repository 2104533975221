import React, { useEffect, useRef } from 'react';
import './PolicyModel.css'; // Ensure you have styles for the modal

const Modal = ({ isOpen, onClose, children }) => {
  const modalRef = useRef();

  // Close modal if clicked outside of modal content
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose(); // Call the onClose function to close the modal
      }
    };

    // Add event listener when the modal is open
    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    // Clean up the event listener when the modal is closed or unmounted
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, onClose]);

  if (!isOpen) return null;

  return (
    <div className="policy-modal-overlay">
      <div className="policy-modal-content" ref={modalRef}>
        {/* Close Button */}
        <button className="policy-modal-close-btn" onClick={onClose}>
          &times;
        </button>
        {/* Modal Content */}
        {children}
      </div>
    </div>
  );
};

export default Modal;
