import Footer from "../../../components/Footer/FooterNew"
import { BackIcon, BgDiv, CardMainContainer, CarIcon, CarType, ConfirmBtn, DetailsMainContainer, EditBtn, FloatingP, HDiv, HDivApart, LeftDiv, Line, MainContainer, RightDiv } from "./styles"
import CarIconImg from '../../../assets/car-insurance/car-simple.png'
import BackIconImg from '../../../assets/commons/back-icon.png'
import { useEffect, useState } from "react"
import { CardHeader } from "../../../components/card-header/CardHeader"
import { Link } from "react-router-dom"


export const CarForm2 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [detailsTrue, setDetailsTrue] = useState(false)

    return(
        <>
            <MainContainer>
                <BgDiv/>

                <CardMainContainer>
                    <CardHeader title='Vehicle details' />
                    <DetailsMainContainer>
                        <HDivApart>
                            <CarType>Selected private car</CarType>
                            <EditBtn>Edit</EditBtn>
                        </HDivApart>
                        <HDivApart className="main-details-container">
                            <LeftDiv>
                                <FloatingP>Your vehicle details : Chevrolette Impala</FloatingP>
                                <h2>Registration Number : <span>MP04-OP-4200</span></h2>
                                <p>Engine Number : <span>CB21F69420</span></p>
                                <p>Chassis : <span>119324324242</span></p>
                                <p>Model : <span>2015</span></p>
                                <p>Cubic cap : <span>2000cc</span></p>
                                <p>Variant : <span>Red</span></p>
                                <p>Registration Date : <span>20/4/2015</span></p>
                            </LeftDiv>
                            <RightDiv>
                                <p>Manufacturer : <span>Chevrolette</span></p>
                                <p>Make : <span>Jamaica</span></p>
                                <p>Colour : <span>Red</span></p>
                                <p>Fuel Type : <span>Petrol</span></p>
                                <p>Manufacturer Year : <span>2015</span></p>
                                <p>City : <span>Bhopal</span></p>
                                <h3> </h3>

                            </RightDiv>
                        </HDivApart>
                        <HDiv className="authenticity">
                            <input checked={detailsTrue} onChange={() => setDetailsTrue(prev => !prev)}  type={'checkbox'} />
                            <p onClick={() => setDetailsTrue(prev => !prev)}>All above information displayed are of my vehicle</p>
                        </HDiv>
                        <Link to='/car/form-3'>
                            <ConfirmBtn>Confirm and Continue</ConfirmBtn>
                        </Link>
                    </DetailsMainContainer>
                </CardMainContainer>

            </MainContainer>
            <Footer />
        </>
    )
}