import styled from "styled-components"

export const MainContainer = styled.div`
    padding-top: 3rem;
    position: relative;
    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

    a {
        text-decoration-line: none;
    }
`
export const styles = {
    layout: {
        border: '.5px solid #B7B7B7',
        margin: '2rem',
        backgroundColor: '#fff',
        padding: '1rem',
        display: 'flex',
        alignItems: 'center',
        themeColor: '#F6931E',
        BACKGROUND_DARK: '#000'
    }
}