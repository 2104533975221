import React, { useState, useEffect, useRef } from 'react';
import './faq.css';
import Quick from '../../components/new_quick_links/quick';
import Hero from '../../components/Hero_Banner/hero';
import BlogCard from '../../components/blog-card/blog';
import { FcFaq } from "react-icons/fc";
import faqBanner from '../../assets/faq-banner.png';
import FooterNew from '../../components/Footer/FooterNew';
import faqsData from './faqData';

const FAQPage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [selectedCategory, setSelectedCategory] = useState('General');
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const answerRefs = useRef({}); // Create a ref object to store answer refs

  const toggleCategory = (category) => {
    setSelectedCategory(selectedCategory === category ? null : category);
    setSelectedQuestion(null); // Close the selected question when changing the category
  };

  const toggleQuestion = (category, faqIndex) => {
    const questionId = `${category}-${faqIndex}`;
    setSelectedQuestion((prevSelectedQuestion) =>
      prevSelectedQuestion === questionId ? null : questionId
    );

    // Scroll to the answer when a question is clicked
    setTimeout(() => {
      if (answerRefs.current[questionId]) {
        answerRefs.current[questionId].scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 300); // Adjust the delay if needed
  };

  useEffect(() => {
    // Set default category to 'General'
    setSelectedCategory('General');
  }, []);

  return (
    <div>
      <Hero icon={<FcFaq size={100} />} heading={"Empowering SUSTAINABLE AND Secure Choices"} image={faqBanner} subHeading={"Stay Informed. Empower Your Insurance Decisions"} btn={true} />
      <Quick heading={'Frequently Asked Questions (FAQ)'} />
      <div className="faq-page">
        <div className="faq-buttons">
          {faqsData.map((section, index) => (
            <button
              key={index}
              className={`faq-button ${selectedCategory === section.category ? 'active' : ''}`}
              onClick={() => toggleCategory(section.category)}
            >
              {section.category}
            </button>
          ))}
        </div>
        <div className="faq-list">
          {faqsData.map((section, index) => (
            <div key={index} className={`faq ${selectedCategory === section.category ? 'active' : 'inactive'}`}>
              {section.category === selectedCategory &&
                section.faqs.map((faq, faqIndex) => (
                  <div key={faqIndex}>
                    <div
                      className="question"
                      onClick={() => toggleQuestion(section.category, faqIndex)}
                    >
                      {faq.question}
                    </div>
                    <div
                      className={`answer ${selectedQuestion === `${section.category}-${faqIndex}` ? 'active' : ''}`}
                      ref={(el) => (answerRefs.current[`${section.category}-${faqIndex}`] = el)} // Assign the ref
                    >
                      {faq.answer}
                    </div>
                  </div>
                ))}
            </div>
          ))}
        </div>
      </div>
      {/* Blog Section */}
      <div className='blog_section'>
        <h2 className='learnMore'>LEARN MORE</h2>
        <div className="blog_container">
          <BlogCard />
        </div>
      </div>
      <div style={{ padding: "40px" }}></div>
      <FooterNew />
    </div>
  );
};

export default FAQPage;
