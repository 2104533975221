import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCall, apiPostCall } from '../utilities/site-apis';

const initialState = {
  isFetching: false,
  insurance: {},
  success: false,
  error: false,
}

export const getCarInfo = createAsyncThunk(
  'insurance/getCarInfo',
  async (params, { rejectWithValue }) => {
    const response = await apiGetCall(`/vehicle/details`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)
export const resetRedux = createAsyncThunk(
  'insurance/resetRedux',
  async ( params,{ rejectWithValue }) => {
    return 0;
    // const response = await apiGetCall(`/vehicle/details`, params)
    // if (response.data.status === 'error') {
    //   return rejectWithValue(response.data)
    // }
    // return response.data
  }
)



export const counterSlice = createSlice({
  name: 'insurance',
  initialState,
//   reducers: {
//     setLoginRedirectUrl: (state, action) => {
//       state.loginRedirectUrl = action.payload
//     },
//     setIsSignUp: (state, action) => {
//       state.isSignUp = action.payload
//     },
//     logout: (state, action) => {
//       state.user = null
//       state.token = null
//     },
//   },
  extraReducers: {

    [getCarInfo.pending]: (state, action) => {
      state.isFetching = true
    },
    [getCarInfo.rejected]: (state, action) => {
      state.isFetching = false
      state.error = true
    },
    [getCarInfo.fulfilled]: (state, action) => {
      state.isFetching = false
      state.insurance = action.payload.data
      state.success = true
    },
    [resetRedux.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = false
    },
    [resetRedux.fulfilled]: (state, action) => {
      state.isFetching = false
      state.error = false
      state.success = false
    },
  }

})

export const { logout, setLoginRedirectUrl, setIsSignUp } = counterSlice.actions
export default counterSlice.reducer