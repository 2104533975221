import styled from "styled-components";

export const MainContainer = styled.div`
    padding-top: 3rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

    a {
        text-decoration-line: none;
    }
`
export const styles = {
    layout: {
        border: '.5px solid #B7B7B7',
        margin: '2rem',
        backgroundColor: '#fff',
        padding: '1rem',
        display: 'flex',
        alignItems: 'center'
    }
}

export const Layout = styled.div`
    border: .5px solid #B7B7B7;
    margin: 2rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
`