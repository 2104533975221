import React from "react";
import SearchBox from "./Searchbox";

const Search = ({ onSearchChange, placeholder }) => {
  return (
    <div className="search-area">
      <SearchBox  searchChange={onSearchChange} placeholder={placeholder} />
    </div>
  );
};

export default Search;
