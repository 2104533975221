function calculatePayouts(sumAssured, modeOfPayment, LumpsumPercentage) {
  if (LumpsumPercentage < 0 || LumpsumPercentage > 100) {
    throw new Error("LumpsumPercentage must be between 0 and 100.");
  }

  // Calculate lump sum and income based on LumpsumPercentage
  let lumpsum = (LumpsumPercentage / 100) * sumAssured;
  let income = ((100 - LumpsumPercentage) / 100) * ((sumAssured / 10) / 12);

  let lumpsumValue, incomeValue;

  if (modeOfPayment) {
    lumpsumValue = lumpsum.toLocaleString('en-IN', { maximumFractionDigits: 2 });
    incomeValue = income.toLocaleString('en-IN', { maximumFractionDigits: 2 });
  } else {
    lumpsumValue = '';
    incomeValue = '';
  }

  return {
    lumpsumPercentage: LumpsumPercentage,
    incomePercentage: 100 - LumpsumPercentage,
    lumpsumValue,
    incomeValue,
  };
}

export default calculatePayouts;
