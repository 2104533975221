import { useEffect } from "react"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import Footer from "../../../components/Footer/FooterNew"
import { Content, Heading, Heading2 } from "./styles"

export const Refunds = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <>
            <MainContainer>
                <Content>
                    <Heading>Refunds/Cancellations Policy</Heading>

                    {/* <Heading2>Return Policy</Heading2> */}

                    <p className="caps">RETURN POLICY</p>
                    <p>Last updated August 20, 2022</p>
                    <br />
                    <p className="caps">REFUNDS</p>
                    <p>All sales are final, and no refund will be issued.</p>
                    <br />
                    <p className="caps">QUESTIONS</p>
                    <p>If you have any questions concerning our return policy, please contact us at:</p>
                    <br />
                    <p>9980850054</p>
                    <p>contact@simpliinsure.com</p>

                </Content>
            </MainContainer>
            <Footer />
        </>
    )
}