import styled from "styled-components";

export const HDivApart = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

`
export const HDiv = styled.div`
    display: flex;
    align-items: center;

    h2 {
        margin: auto 1rem;
    }

`
export const BackIcon = styled.img`
    /* margin-left: 1.5rem; */
`
export const Line = styled.div`
    width: 5rem;
    height: 2.5px;
    background-color: #ff4800;

    @media screen and (max-width: 800px) {
        display: none;
    }

`
export const CarIcon = styled.img`
    transform: scale(.8);
`