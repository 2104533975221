import Skeleton from "react-loading-skeleton"
import { HDiv } from "./styles"

export const SkeletonComponent = () => {
    return(
        <HDiv>
            <div>
            <Skeleton height={50} />
            </div>
            {/* <div>
                <Skeleton height={60} />
                <Skeleton />
            </div> */}
            {/* <hr />
            <div><Skeleton count={2} /></div>
            <hr/>
            <div><Skeleton count={2} /></div>
            <hr />
            <div><Skeleton count={3} /></div>
            <hr />
            <div><Skeleton count={3} /></div>
            <hr />
            <div><Skeleton count={3} /></div>
            <hr />
            <div><Skeleton height={40} count={2} /></div> */}
        </HDiv>
    )
}