import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { CardHeader } from "../../../components/card-header/CardHeader"
import Footer from "../../../components/Footer/FooterNew"
import { BgDiv, BottomDiv, CardMainContainer, ConfirmBtn, Div, DivBorderRight, DivPadding, HDiv, HDivApart, InputData, InputDiv, InputThemeDiv, InputThemeTitle, InputTitle, InsideCardContainer, LeftDiv, Logo, MainContainer, PrivateCarBtn, RightDiv } from "./styles"

export const CarForm6 = ({}) => {

    const location = useLocation()
    const item = location.state.from;

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const InputDivComp = ({ title, val }) => {
        return (
            <InputDiv className="padding-more">
                <InputTitle>{title}</InputTitle>
                <InputData>{val}</InputData>
            </InputDiv>
        )
    }

    return(
        <>
            <MainContainer>
                <BgDiv />

                <CardMainContainer>
                    <CardHeader title='Premium Breakup' />

                    <InsideCardContainer>
                        <DivPadding>
                            <HDivApart>
                                <DivBorderRight>
                                    <Logo src={item.icon} />
                                </DivBorderRight>
                                <Div>
                                    <h4>{item.name}</h4>
                                    <p>Chevrolette Impala (Kesari, 2000cc) - Petrol </p> <span>Private Car - 2015 MP-04 </span>
                                    <p>Quotation No. - CVIMPL6989698989</p>
                                </Div>
                                <Div>
                                    <PrivateCarBtn>Private Car Package Policy</PrivateCarBtn>
                                </Div>
                            </HDivApart>
                        </DivPadding>
                    </InsideCardContainer>
                    <BottomDiv>

                        <LeftDiv>
                            <InputDivComp title='Register Year' val={'04-Nov-2016'} />  
                            <InputDivComp title='RTO' val={'MP-04-NZ-1234'} />
                            <InputDivComp title='IDV' val={'Rs. 12141'} />
                            <InputDivComp title='Ex-Showroom' val={'Rs. 0'} />
                            <InputDivComp title='Min. IDV' val={'Rs. 0'} />
                            <InputDivComp title='Max. IDV' val={'Rs.124213'} />
                            <InputDivComp title='Vehicle Age' val={'10'} />
                            <InputDivComp title='Fuel Type' val={'Uranium'} />
                            <InputDivComp title='Cubic Capacity' val={'2000'} />
                            <InputDivComp title='Seating Capacity' val={'2'} />
                        </LeftDiv>

                        <RightDiv>
                            <InputDiv className="marb-1">
                                <InputTitle>Basic OD Premium(+)</InputTitle>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData>Basic OD Premium :</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                                <HDivApart className="mt-1 mb-1 no-col">
                                    <InputData>(A) Total OD Premium :</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                            </InputDiv>
                            <InputDiv className="marb-1">
                                <InputTitle>Discount(-)</InputTitle>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData>NCB(50%)</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                                <HDivApart className="mt-1 mb-1 no-col">
                                    <InputData>(B) Total Discount</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                            </InputDiv>
                            <InputDiv className="marb-1">
                                <InputTitle>TP Premium Calculations(+)</InputTitle>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData>Basic Third Party Liability</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                                <HDivApart className="mt-1 mb-1 no-col">
                                    <InputData>PA Cover to Owned Driver</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                                <HDivApart className="mt-1 mb-1 no-col">
                                    <InputData>(C) Total TP Premium</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                            </InputDiv>

                            <InputDiv className="marb-1">
                                <InputTitle>Addon Premium(+)</InputTitle>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData>Total Addon Premium</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                            </InputDiv>

                            <InputThemeDiv className="marb-1">
                                <InputThemeTitle>Net Premium(+)</InputThemeTitle>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData>(A-B)+C+D</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData>GST 18%</InputData>
                                    <InputData> ₹  2,580.96</InputData>
                                </HDivApart>
                                <HDivApart className="mb-1 mt-1 no-col">
                                    <InputData className="fw-b">Final Premium Payable</InputData>
                                    <InputData className="fw-b"> ₹  2,580.96</InputData>
                                </HDivApart>
                            </InputThemeDiv>

                        </RightDiv>

                    </BottomDiv>

                    <Link to='/car/form-7' state={{from:item}}>
                        <ConfirmBtn>Confirm and Continue</ConfirmBtn>
                    </Link>

                </CardMainContainer>

            </MainContainer>
            <Footer/>
        </>
    )
}