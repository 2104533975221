
import React from 'react';
import './benifits.css'; 

import {
//   FaMoneyBillAlt,
//   FaHospital,
//   FaUserMd,
//   FaBriefcaseMedical,
//   FaHandsHelping,
//   FaUserFriends,
//   FaAmbulance,
//   FaRegHospital,
//   FaStar,
  FaShieldAlt,
} from 'react-icons/fa';

// const benefitsData = [
//   {
//     id: 1,
//     icon: <FaShieldAlt />,
//     heading: 'HEALTHCARE ACCESS Immediate and Extensive Medical Assistance',
//     paragraph:
//       'Access an expansive network of healthcare providers for prompt medical attention and specialized treatments, ensuring your health remains a priority.',
//   },
//   {
//     id: 2,
//     icon: <FaShieldAlt />,
//     heading: 'RISK MANAGEMENT Personalized Risk Mitigation Solutions',
//     paragraph:
//       'Tailored insurance plans mitigate risks for your business and personal needs, providing a safety net for your assets and ventures.',
//   },
//   {
//     id: 3,
//     icon: <FaShieldAlt />,
//     heading: 'HIGH SETTLEMENT CLAIM RATIO Swift and Fair Claims Resolution',
//     paragraph:
//       'Count on us for rapid and equitable settlements, reflecting our commitment to your security with a consistently high claim settlement ratio.',
//   },
//   {
//     id: 4,
//     icon: <FaShieldAlt />,
//     heading: 'ONLINE AND OFFLINE CUSTOMER SERVICE Seamless and Expert Support',
//     paragraph:
//       'Experience hassle-free service both online and offline. Our dedicated team ensures you receive expert guidance and support whenever you need it.',
//   },
//   {
//     id: 5,
//     icon: <FaShieldAlt />,
//     heading: 'FINANCIAL PROTECTION Shielding You From Life Unpredictability',
//     paragraph:
//       'Prepare for unforeseen circumstances with our comprehensive insurance policies, providing financial security and peace of mind for you and your loved ones.',
//   },
// ];

const BenefitsCard = ({benefitsData}) => {
  return (
    <div className="benefits-card">
    <h1 className='policy_benefits_heading'>BENEFITS</h1>
    {benefitsData?.map((data) => (
      <div>
      <div className="content-container">
            <div className="icon-container">
            {data?.icon || <FaShieldAlt />}
          </div>
          <div className='text-container'>
            <h2 className="heading">{data?.heading || ""}</h2>
            <p className="brand-paragraph">{data?.paragraph || ""}</p>
        </div>
      </div>
      </div>
      ))}
    </div>
  );
};

export default BenefitsCard;
