import React from 'react'
import ErrorIconImg from '../../../assets/brand-new-car/exclamation.svg'

const ErrorPopups = (error, className = "") => {
  return (
    <div className={`error-container-pos ${className}`}>
      <div className="error-msg-container-pos">{error.error}</div>
      <div className="errArrow-pos" />
      <img src={ErrorIconImg} className="error-icon-pos" alt="error-icon-pos" />
    </div>
  );
}

export default ErrorPopups