import styled from "styled-components";


export const Opacity = styled.div`
    width: 100vw;
    height: ${document.body.scrollHeight}px;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: .6;
    z-index: 200;
`
export const MainContainer = styled.div`
    /* position: relative; */
`

export const PopupContainer = styled.div`
    border-radius: 10px;
    padding: 1rem 2rem;
    border: 1px solid #B7B7B7;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 220;
    top: 200px;
    background-color: #fff;
    padding-top: 1.7rem;
    padding-bottom: 1.8rem;

    .prompt {
        font-weight: 800;
        font-size: 20px;
        color:#605F5E;
    }
    .flex {
        display: flex;
    }
    .apart {
        justify-content: space-between;
    }
    button {
        width: 45%;
        padding: 0.6rem 1rem;
        border-radius: 25px;
        border: 1px solid #B7B7B7;
        background-color: #fff;
        color:#605F5E !important;
        text-transform: uppercase;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            border-color: #F6931E;
        }
    }

`

export const CloseIcon = styled.img`
    position: absolute;
    top: 10px;
    right:10px;
    cursor: pointer;
`