import styled from "styled-components"

export const MainContainer = styled.div`
    padding-top: 3rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

    a {
        text-decoration-line: none;
    }

`
export const BgDiv = styled.div`
    width: 100%;
    height: 35vh;
    position: absolute;
    background: linear-gradient(to left, #41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }
`
export const CardMainContainer = styled.div`
    border-radius: 1rem;
    padding: 1rem 2.5rem;
    background-color: #fff;
    width: 95%;
    margin: 1rem auto;
    margin-bottom: 3rem;

    a {
        text-decoration-line: none;
    }

    @media screen and (max-width:800px) {
        padding: 1rem;
    }

`
export const InsideCardContainer = styled.div`
    border: 1px solid #b7b7b7;
    border-radius: .6rem .6rem 0 0 ;
    border-bottom: 0;
`

export const HDivApart = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: 910px) {
        flex-direction: column;
        text-align: center;
        
    }

`
export const Logo = styled.img`

`
export const DivBorderRight = styled.div`
    padding: 1rem;
    padding-right: 4rem;
    border-right: 1px solid #b7b7b7;

    @media screen and (max-width: 910px) {
        padding:1rem;
        border: none;
    }

`
export const Div = styled.div`
    h4 {
        margin-bottom: .5rem;
    }
    p {
        font-size: .8rem;
    }
    span {
        font-size: .8rem;
        color:#ff4c00;
    }

    @media screen and (max-width: 910px) {
        margin:1rem;
    }

`
export const PrivateCarBtn = styled.div`
    padding: 1rem;
    font-size: .82rem;
    border: 1px solid #ff4c00;
    color:#ff4c00;
    border-radius: .4rem;
`
export const DivPadding = styled.div`
    padding: 1rem !important;
    border-bottom: 1px solid #b7b7b7;
`
export const MidDiv = styled.div`
    background-color: #f6f6f6;
    padding: 1rem;

    display: grid;
    grid-template-columns: repeat(3,1fr);

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1,1fr);
    }

`
export const MidSubDiv = styled.div`
    padding: 1rem;
    font-size: .85rem;

    .colored {
        color:#ff4c00;
        font-weight: 900;
    }
    
    .padding-more {
        padding: 1rem;
    }

`
export const InputDiv = styled.div`
    position: relative;
    padding: .5rem 1rem;
    border: 1px solid #b7b7b7;
    border-radius: .6rem;
    margin: 1.5rem auto;
    position: relative;

    .bg-white, select, input {
        background-color: #fff;
    }

`
export const InputTitle = styled.p`
    position: absolute;
    padding: 0.4rem .6rem;
    background-color: #f6f6f6;
    top: -.9rem;
`
export const InputData = styled.p`
    color:#605f5e;
`
export const BottomDiv = styled.div`
    border: 1px solid #b7b7b7;
    border-top: 0;
    border-radius: .5rem;
    display: grid;
    grid-template-columns: repeat(2,1fr);
    padding-bottom: 3rem;
    position: relative;

    .bottom-radio {
        position: absolute;
        bottom: 1.5rem;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    .reg-addr-prompt {
        margin-right: 1rem;
        color: #605f5e;
    }
    
    @media screen and (max-width: 740px) {
        grid-template-columns: repeat(1,1fr);
        padding-bottom: 4rem;

        .bottom-radio {
            bottom: 2rem;
            width: 100%;
            justify-content: center;
            padding-left: 1rem;
        }

    }
    @media screen and (max-width: 525px) {
        .bottom-radio {
            bottom: 1rem;
        }
    }

`
export const BottomSubDiv = styled.div`
    padding: 1rem;
`
export const Input = styled.input`
    width: 100%;
    padding: 1rem;
    padding-left: .6rem;
    border: none;
    outline: none;
    &::placeholder {
        color: #c4c4c4;
    }
`
export const InputSelect = styled.select`
    width: 100%;
    padding: 1rem;
    padding-left: .5rem;
    border: none;
    outline: none;
`
export const DatePlaceholder = styled.p`
    position: absolute;
    top: 1.6rem;
    left: 1.7rem;
    background-color: #fff;
    color: #c4c4c4;
`
export const HDiv = styled.div`
    display: flex;
    align-items: center;

    input[type=radio] {
        margin-right: .5rem;
        accent-color: #ff4c00;
    }
    p {
        margin-right: .7rem;
    }

`
export const ConfirmBtn = styled.button`
    border: none;
    border-radius: 1.5rem;
    padding: .8rem 2.8rem;
    color: #fff;
    font-size: 1.2rem;
    background: linear-gradient(to left, #ed4168, #ffb25a);
    display: flex;
    margin: auto;
    margin-bottom: 2.7rem;
    cursor: pointer;
    margin-top: 2rem;
`