import React from 'react';
import { useLocation } from 'react-router-dom';
import './app.css';
import Address from './components/Address';


export function POS() {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const id = queryParams.get('id');

  return (
    <>
      <Address id={id} />
    </>
  );
}
