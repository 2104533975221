import React from 'react';
import { FiArrowRightCircle } from "react-icons/fi";
import './quick.css';
import { useNavigate } from 'react-router-dom';

const Quick = ({heading}) => {
  const navigate = useNavigate();
  return (
    <>
    <div className='quick-title'>
      <div className='title_body'>
        <div className='title_content'>
          <p onClick={() => navigate(-1)} className='titleNavigation'><span style={{marginRight:"10px"}}>Quick links </span><FiArrowRightCircle size={15} /> <span style={{marginLeft:"10px"}}>{heading}</span> </p>
          <h1 className='title_heading'>{heading}</h1>
          </div>
    </div>
    </div>
    </>
  );
}

export default Quick;
