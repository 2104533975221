import React, { useEffect, useRef } from 'react';
import mapboxgl from 'mapbox-gl';

function Map() {
  const mapContainerRef = useRef(null);

  useEffect(() => {
    mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [77.60615, 12.98061],
      zoom: 3,
    });

    // Create markers
    const marker1 = new mapboxgl.Marker().setLngLat([77.60615, 12.98061]).addTo(map);
    const marker2 = new mapboxgl.Marker().setLngLat([79.05797, 21.11532]).addTo(map);

    // Create popups
    const popup1 = new mapboxgl.Popup({ offset: 25 }).setHTML("<h3>SimpliInsure Bangalore Branch</h3><p>Click here to go to SimpliInsure</p>");
    const popup2 = new mapboxgl.Popup({ offset: 25 }).setHTML("<h3>SimpliInsure Nagpur Branch</h3><p>Click here to go to SimpliInsure</p>");

    // Add popups to markers
    marker1.setPopup(popup1);
    marker2.setPopup(popup2);

    // Add event listeners for click
    marker1.getElement().addEventListener('click', () => {
      window.open("https://maps.app.goo.gl/xHdMVN7wd2YaxeVn6", '_blank');
    });

    marker2.getElement().addEventListener('click', () => {
      window.open("https://www.google.com/maps/place/Income+Tax+Colony,+Kotwal+Nagar,+Pratap+Nagar,+Nagpur,+Maharashtra+440020/@21.1151953,79.0580677,17z/data=!3m1!4b1!4m6!3m5!1s0x3bd4bf86c7e6d39b:0x6fbb099bf0e38b5!8m2!3d21.1153214!4d79.0579732!16s%2Fg%2F11g6njk_np?entry=ttu", '_blank');
    });


    return () => map.remove();
  }, []);

  return <div ref={mapContainerRef} style={{ width: '100%', height: '400px' }} />;
}

export default Map;
