import styled from "styled-components";

export const Content = styled.div`
    width: 62%;
    margin: auto;
    color: #7A7A7A;
    padding-bottom: 1rem;
    letter-spacing: .05ch;
    h1, h2, h3, p {
        color: #7A7A7A;
    }

    p {
        margin: 0;
    }

    .mt-1 {
        margin-top: 1rem;
    }

    @media screen and (max-width: 800px) {
        margin-top: 3rem;
    }
    @media screen and (max-width: 550px) {
        width:80%;
    }

`

export const Heading = styled.h1`
    font-weight: 900;
    color: #F6931E !important;

`
export const Heading2 = styled.h2`
    font-size: 1.9rem;
`
export const SubHead = styled.h3`
    font-size: 1.3rem;
    font-weight: 900;
    margin-top: 1.25rem;
`
export const ListContainer = styled.ul`
    margin-left: 2rem;
`