import { useNavigate } from "react-router-dom"
import { styles, MainContainer } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { ContinueBtn, Layout } from "./styles"
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState} from "react";
import { updateCombinedModel,modelVariant } from '../../../Redux/CarsRedux';
const moment = require('moment');
export const CarDetailsForm2 = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fuelType = useSelector((state) => state.carsRedux.fuelType);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux)
    const [fuelValue, setFuelValue] = useState('');
    const [Manufacture, setManufacture] = useState();
    const [registration, setRegistration] = useState();
    const [error, setError] = useState({});
    
    useEffect(() => {

        console.log("combinedRedux0", combinedRedux);
        if(combinedRedux.modelId){
            console.log("combinedRedux", combinedRedux);
            dispatch(modelVariant({uniqueFuel:true,modelId:combinedRedux.modelId, uniqueFuelData: true, uniqueSubNameData: true,brand:combinedRedux.brand, model: combinedRedux.model}))
            console.log("combinedRedux2", combinedRedux);
    
        }
        
        else{
            var carInsuranceDetails =  (localStorage.getItem('canInsuranceDetails'));
            if(carInsuranceDetails){
                console.log("carInsuranceDetails is ", carInsuranceDetails);
                carInsuranceDetails = JSON.parse(carInsuranceDetails);
                dispatch(modelVariant({uniqueFuel:true,modelId:carInsuranceDetails.modelId, uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true,brand:carInsuranceDetails.brand,model:carInsuranceDetails.model}))
            }
        }
    }, [combinedRedux.modelId])
    
    useEffect(() => {

        // alert(combinedRedux.modelId+'suresh')

        if(combinedRedux.hasOwnProperty('engineNo')){

            if(combinedRedux.hasOwnProperty('fuelType')){
                setFuelValue(combinedRedux.fuelType);
            }

            if(combinedRedux.hasOwnProperty('mfgYear')){
                setManufacture(moment(combinedRedux.mfgYear).format('YYYY-MM-DD'));
            }
            if(combinedRedux.hasOwnProperty('regDate')){
                setRegistration(moment(combinedRedux.regDate).format('YYYY-MM-DD'));
            }
        }
        else{
            setTimeout(() => {
                getLocalStorage()
            }, 1);
        }
    },[combinedRedux])


    const getLocalStorage = async() => {
        var carInsuranceDetails =  await (localStorage.getItem('canInsuranceDetails'));
        if(carInsuranceDetails){
            carInsuranceDetails = JSON.parse(carInsuranceDetails);
            if(carInsuranceDetails.hasOwnProperty('fuelType')){
                setFuelValue(carInsuranceDetails.fuelType);
            }

            if(carInsuranceDetails.hasOwnProperty('mfgYear')){
                setManufacture(carInsuranceDetails.mfgYear);
            }
            if(carInsuranceDetails.hasOwnProperty('regDate')){
                setRegistration(carInsuranceDetails.regDate);
            }
            dispatch(updateCombinedModel(carInsuranceDetails));
        }
    };

 
    
    const submitFunction = () =>{
        if(!fuelValue){
            // alert('Please select a fuel value.')
            setError({fuelType:'Please select a fuel value.'})
        }
        else if(!Manufacture){
            setError({manufacture:'Please enter manufacture date.'})
            // alert('Please enter manufacture year.')
        }
        else if(!registration){
            setError({registration:'Please enter registration year.'})
            // alert('Please enter registration year.')
        }
        else{
            dispatch(updateCombinedModel({fuelType:fuelValue,mfgYear:Manufacture,regDate:registration}));
            navigate('/brand-new-model/car-details/1')
        }
    }

    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent step={4}
                        fuelType={fuelType} manufacture={Manufacture}
                        setFuelValue={setFuelValue}
                        setManufacture={setManufacture}
                        setRegistration={setRegistration}
                        noSearch={true} title='Fuel Type, Manufacture & Registration Date'
                        registration={registration}
                        max={1}
                        dropDownDefault={fuelValue}
                        error={error}
                        setError={setError}
                    />


                </Layout>
                {/* <ContinueBtn onClick={() => navigate('/brand-new-model/car-details/3')}> */}
                <ContinueBtn onClick={submitFunction}>
                    Continue
                </ContinueBtn>
            </MainContainer>
        </>
    )
}