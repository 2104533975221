import React, { useEffect,useState } from 'react'
import './HomePage.css'
// import useHistory
import { Link, useNavigate } from "react-router-dom";
import { updateAuthorization } from '../../Redux/AuthRedux';
import { resetRedux } from '../../Redux/InsuranceRedux';
import { resetRedux as resetReduxes } from '../../Redux/CarsRedux';
import {Modal} from 'antd'
import tag from '../../assets/tag.png'
import medal from '../../assets/medal.png'
import book from '../../assets/book.png'
import car from '../../assets/car.png'
import bike from '../../assets/bike.png'
import people1 from '../../assets/people1.png'
import people2 from '../../assets/people2.png';
import { useSelector, useDispatch } from "react-redux";
import shadows from '@material-ui/core/styles/shadows';
import { Contact } from '../insurance-contact/Contact';
const HomePage = () => {
  
  // let history = useHistory();
  const[show,setShow]=useState(false)
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleModal=()=>{
    setShow(!show)
  }

    const navigateToHealth = (url) => {
    window.location.href = url;
  };

  useEffect(() => {
    window.history.replaceState({}, '/')
    window.scrollTo(0, 0)
    var Authorization = localStorage.getItem('Authorization');
    const carData = localStorage.getItem('canInsuranceDetails');
    console.log("carData ==>",carData)

    if(carData){
      localStorage.removeItem('canInsuranceDetails')
      window.location.reload()
    }
    if(Authorization){
      dispatch(updateAuthorization(true))
    }
     dispatch(resetRedux({}))
     dispatch(resetReduxes({}))
  },[])
  

  return (
    <div className="homePageWrapper">
      <div className="imgWrapper">
        <div className="insWrapper">
          <h1 className="home_heading">Simple Insurance, Instant Protection</h1>
        </div>
        <p className="home_para">
          Instantly protect what matters most with our straightforward insurance
          options.
        </p>
      </div>

      <div className="cardWrapper">
        <div
          onClick={() => navigate('/car/car-insurance')}
          className="card car "
        >
          <img src={car} alt="Car Insurance" />
          <button>Car Insurance</button>
        </div>
        <div onClick={() => navigate('/term')} className="card people2">
          <img src={people2} alt="Life Insurance" />
          <button>Life Insurance</button>
        </div>
        <div
          onClick={() => navigateToHealth(process.env.REACT_APP_HEALTH_URL)}
          className="card people1"
        >
          <img src={people1} alt="Health Insurance" />
          <button>Health Insurance</button>
        </div>
        <div onClick={handleModal} className="card bike">
          <img src={bike} alt="2 Wheeler Insurance" />
          <button>2 Wheeler Insurance</button>
        </div>

        <Modal
          className="modal"
          title="Contact Us"
          open={show}
          onOk={handleModal}
          onCancel={handleModal}
          width={800}
        >
          <Contact handleModal={handleModal} />
        </Modal>
      </div>
      <div className="imgCard">
        <div className="img-p-container">
          <img src={tag} alt="Instant Policy" />
          <p>Instant Policy</p>
        </div>
        <div className="img-p-container">
          <img src={medal} alt="Best Prices" />
          <p>Best Prices</p>
        </div>
        <div className="img-p-container">
          <img src={book} alt="No Paperwork" />
          <p>No Paperwork</p>
        </div>
      </div>
    </div>
  );
}

export default HomePage