import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { verifyUser } from "../../Redux/AuthRedux";
import './reset.css';

const OTPPage = () => {
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleOtpChange = (e) => {
    const newOtp = e.target.value;

    if (/^\d*$/.test(newOtp) && newOtp.length <= 4) {
      setOtp(newOtp);
      setOtpError('');
    } else {
      setOtpError('OTP must be a numeric value and 4 digits long.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const { phone, countryCode } = JSON.parse(localStorage.getItem('resetPasswordUserData'));

    if (otp.length !== 4) {
      setOtpError('OTP must be 4 digits long.');
      return;
    }

    const obj = {
      phone: phone,
      countryCode: countryCode,
      code: otp,
      mode: "otp"
    };

    const response = await dispatch(verifyUser(obj));

    if (response.payload.status === "Success") {
      localStorage.setItem('resetPasswordUserId', JSON.stringify(response.payload.data.userId ? response.payload.data.userId : response.payload.data._id));

      // Remove the user data from local storage
      localStorage.removeItem('userDetails');
      localStorage.removeItem('Authorization');

      navigate("/reset-password/new-password");
    } else {
      alert(response.payload.message);
    }
  };

  return (
    <div className="reset-app-container">
      <div className="reset-page-container">
        <h2 className="page-title">Reset Password</h2>
        <h3 className="page-subtitle">Enter OTP</h3>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="input-group">
            <input
              type="text"
             className={`input-field ${otpError ? '' : 'input_margin'}`}
              placeholder="OTP"
              required
              value={otp}
              onChange={handleOtpChange}
            />
            {otpError && <p className="error-message">{otpError}</p>}
          </div>
          <div className="button-group">
            <button type="button" className="btn-secondary" onClick={() => navigate(-1)}>Back</button>
            <button type="submit" className="btn-primary">Next</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default OTPPage;
