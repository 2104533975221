import { InputComponentDash } from "../input-component-dash/InputComponentDash"
import { FormContainer, MainContainer, SaveBtn } from "./styles"
import CloseImg from '../../../../assets/brand-new-car/close-btn.svg'

export const EditModal = ({onClose}) => {
    return(
        <MainContainer>
            <FormContainer onSubmit={e => e.preventDefault()}>
                <img src={CloseImg} onClick={() => onClose()} className='close-btn' />
                <p className="title">Edit Password</p>
                <p className="info">Please enter current password and new password</p>
                <InputComponentDash title={'current password'} type='password' />
                <InputComponentDash title={'new password'} type='password' />
                <InputComponentDash title={'confirm password'} type='password' />

                <SaveBtn type='submit'>Save</SaveBtn>
            </FormContainer>
        </MainContainer>
    )
}