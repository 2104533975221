import styled from "styled-components";

export const Content = styled.div`
    width: 62%;
    margin: auto;
    color: #7A7A7A;
    letter-spacing: .05ch;
    h1, h2, h3, p {
        color: #7A7A7A;
    }

    p {
        margin: 0;
    }

    .mt-2 {
        margin-top: 2rem;
    }
    .mb-3 {
        margin-bottom: 3rem ;
    }
    .text-center {
        text-align: center;

        p {
            text-align: justify;
        }
    }
    .p-1 {
        padding: 1rem;
    }

    @media screen and (max-width: 800px) {
        margin-top: 3rem;
    }
    @media screen and (max-width: 550px) {
        width:80%;
    }

`

export const Heading = styled.h1`
    font-weight: 900;
    color: #212121 !important;

`
export const CenteredHead = styled.h1`
    font-weight: 600;
    font-family: 'Inter';
    font-size: 30px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 2rem;
    color: #f6931E !important;
`
export const SubHead = styled.h3`
    font-size: 1.5rem;
    font-weight: 900;
    margin-top: 1.25rem;
    margin-bottom: .5rem;
`
export const ListContainer = styled.ul`
    margin-left: 1rem;
`
export const CompanyOwnersContainer = styled.div`
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: 550px) {
        ${'' /* width:80%; */}
        flex-direction:column;
    }
`
export const OwnerImg = styled.img`
    border-radius: 50%;
    width: 200px;
`