import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Container, Title, Label, Input, TextArea, Button, Dropdown } from './mailstyle.js';
import { axiosPOSTCall1, axiosGETCall } from "../../../utilities/utils/apiPost"
import SelectedUser from "./communication/SelectedUser.js"
import { useSearchParams } from 'react-router-dom';

const EmailCommunication = (item) => {
  const [selectedCustomer, setSelectedCustomer] = useState([]);
  const [subject, setSubject] = useState('');
  const [email, setEmail] = useState('');
  const [posName, setPosName] = useState('');
  const [message, setMessage] = useState('');
  const [custom, setCustom] = useState(false);
  const [type, setType] = useState("");
  const [nonSelectedUser, setNonSelectedUser] = useState(item?.item || []);
  const [selectedUser, setSelectedUser] = useState([]);
  const [customerName, setCustomerName] = useState("");

  // Params from URL
  const [searchParams] = useSearchParams();
  const searchPosId = searchParams.get('id');

  const handleCustomerChange = (e) => {
    console.log("eee",e,customerName);
    // return
    setCustomerName(e.label)
    setSelectedCustomer([e.value?.toLowerCase()]);
    const newArr = item?.item;
    const newData = newArr?.filter(val=>{console.log("valueee",val?.motorData?.ownerDetails?.email); return val?.motorData?.ownerDetails?.email == e.value});
    // console.log("new array",newData);
    // console.log("new array1",selectedUser);
    setSelectedUser([...selectedUser,...newData]);
    setNonSelectedUser(nonSelectedUser?.filter(val=>val?.motorData?.ownerDetails?.email.toLowerCase() != e.value.toLowerCase()));
  };

  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleMessageChange = (e) => {
    setMessage(e.target.value);
  };

  const getValue = (data) => {
    if (data?.insuranceType == "motor") {
      return data?.motorData?.ownerDetails?.email || ""
    }
    else return data.healthData?.proposal_details?.Email || ""
  }

  const filterMail = (item) => {
    const filterData = item?.item?.filter(val => getValue(val) != "");
    console.log("🚀 ~ filterMail ~ filterData:", filterData)

    const data = filterData.map(val => { return ({ value: getValue(val), label: getLabel(val) }) })
    return data
  }
  const getLabel = (val) => {
    if (val?.insuranceType == "motor") {
      return `${val?.motorData?.ownerDetails?.fullName}  (${val?.customerID})`
    }
    else return `${val?.policyHolderName || val?.healthData?.proposal_details?.fullName}  (${val?.customerID})` || ""
  }

  // fetch POSP details
  useEffect(() => {
    if (searchPosId) {
    axiosGETCall(`/pospInfo/${searchPosId}`, (callBack) => {
    if (callBack.status === "Success") {
      setEmail(callBack?.data?.email);
      setPosName(callBack?.data?.fullName);
      }
    })
  }
  }, [searchPosId]);

    const handleSendEmail = () => {
    if (!searchPosId) {
      if (!type || (type === "custom" && selectedUser.length < 1)) {
        alert("please select customer");
        return;
      }
    }

    if(type==="custom"){
      var usersEmail = item?.item.map(val=>val?.motorData?.ownerDetails?.email?.toLowerCase())
    }

    
    const obj = {
      email:{
        addresses: type == "all" ? [] : searchPosId ? email : usersEmail,
        subject: subject,
        body: message,
      },
      type: type
    }
    axiosPOSTCall1(`/pospInfo/sendMail`, obj, (callBack => {
      if (callBack.status === "Success") {
        alert(callBack?.status)
      }
    }));

    setSubject('');
    setEmail('');
    setMessage('');
    setSelectedCustomer([]);
  };



  const handleFilter = e => {
    if (e.target.value == "custom") {
      setType(e.target.value)
      setCustom(true)
    } else {
      setType(e.target.value);
      setCustom(false);
      setNonSelectedUser(item);
      setSelectedUser([]);
    }
  }

  const addUnSelectedUser = (item)=>{
    console.log("hsgf",item, nonSelectedUser);
    // setNonSelectedUser(nonSelectedUser.push(item));
    setSelectedUser(selectedUser.filter(val=>val?.motorData?.ownerDetails?.email != item?.motorData?.ownerDetails?.email))
    
  }

  return (
    <Container>
      {
        searchPosId ? ( <Title>Send Email to {posName}</Title> ) : ( <Title>Send Email</Title> )
      }
    {
      !searchPosId && (
         <div style={{ display: "flex" }}>
        <Label>
          Select Customer:
          <Dropdown value={type} onChange={handleFilter}>
            <option value="" disabled>select</option>
            <option value="all">All</option>
            {/* <option value="lead">Payment pending</option>
            <option value="customer">Policy issued</option> */}
            <option value="custom">Custom</option>

          </Dropdown>
        </Label>
        {
          custom && 
          <Select className="dropdown-select-com pk custom" placeholder={customerName ? customerName : "select customer"} onChange={handleCustomerChange} options={item?.item?.length>0 && filterMail(item)} value={customerName} />
        }
      </div>
      )
    }
      <Label>
        Email:
      </Label>
      <Input type="text" value={type === 'custom' ? selectedCustomer : email} onChange={ handleEmailChange}/>
      <Label>
        Subject:
      </Label>
      <Input type="text" value={subject} onChange={handleSubjectChange} />
      <Label>
        Message:
      </Label>
      <div>
        <TextArea value={message} onChange={handleMessageChange} rows={4} />
      </div>
      <Button onClick={handleSendEmail}>Send Email</Button>
     { selectedUser.length>0 && <SelectedUser data = {selectedUser} pushItem = {addUnSelectedUser}/> }
    </Container>
  );
};

export default EmailCommunication;
