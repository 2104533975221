import React, { useState } from 'react';
import './new-contact.css';
import { useDispatch } from 'react-redux';
import { ContactLead } from '../../Redux/AuthRedux';

const ContactForm = ({ title, titleSize }) => {
  const dispatch = useDispatch();

  const initialFormData = {
    name: '',
    phone: '',
    email: '',
    city: '',
    enquiryType: 'general', // Set default value
    message: '',
  };

  const [formData, setFormData] = useState(initialFormData);
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Perform validation based on the field name
    let updatedValue = value;
    if (name === 'name') {
      updatedValue = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === 'phone') {
      updatedValue = value.replace(/\D/g, '').slice(0, 10);
    } else if (name === 'email') {
      if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        // Invalid email format
        setError('Please enter a valid email address');
      }
    } else if (name === 'city') {
      updatedValue = value.replace(/[^A-Za-z\s]/g, '');
    } else if (name === 'message') {
      // No specific validation for message field
    }

    setFormData((prevData) => ({
      ...prevData,
      [name]: updatedValue,
    }));
    setError('');
    setSubmitted(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple form validation
    if (!formData.name || !formData.phone || !formData.email || !formData.city || !formData.message) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);

    try {
      // Simulating form submission delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      
      // Dispatching the contact lead action
      dispatch(ContactLead(formData));
      setSubmitted(true);
      
      // Clear form data after successful submission
      setFormData(initialFormData);
    } catch (error) {
      setError('Failed to submit the form. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="contact-container">
      <h2 style={{fontSize: titleSize}}>{title}</h2>
      <form onSubmit={handleSubmit}>
        <div className="contact-form-group">
          <label htmlFor="name">Name:<span className="required-asterisk">*</span></label>
          <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} className={error && !formData.name ? 'contact-error' : ''} />
        </div>
        <div className="contact-form-group">
          <label htmlFor="phone">Phone:<span className="required-asterisk">*</span></label>
          <input type="tel" id="phone" name="phone" value={formData.phone} onChange={handleChange} className={error && !formData.phone ? 'contact-error' : ''} />
        </div>
        <div className="contact-form-group">
          <label htmlFor="email">Email:<span className="required-asterisk">*</span></label>
          <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} className={error && !formData.email ? 'contact-error' : ''} />
        </div>
        <div className="contact-form-group">
          <label htmlFor="city">City:<span className="required-asterisk">*</span></label>
          <input type="text" id="city" name="city" value={formData.city} onChange={handleChange} className={error && !formData.city ? 'contact-error' : ''} />
        </div>
        <div className="contact-form-group">
          <label htmlFor="enquiry-type">Enquiry Type:<span className="required-asterisk">*</span></label>
          <select id="enquiry-type" name="enquiryType" value={formData.enquiryType} onChange={handleChange} className={error && !formData.enquiryType ? 'contact-error' : ''}>
            <option value="general">General Enquiry</option>
            <option value="customer-support">Customer Support</option>
          </select>
        </div>
        <div className="contact-form-group">
          <label htmlFor="message">Message:</label>
          <textarea id="message" name="message" value={formData.message} onChange={handleChange} className={error && !formData.message ? 'contact-error' : ''} />
        </div>
        <button className='contact-form-button' type="submit" disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
      </form>
      {submitted && <div className="contact-success-alert">Form submitted successfully!</div>}
      {error && <div className="contact-error-alert">{error}</div>}
    </div>
  );
};

export default ContactForm;
