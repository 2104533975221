import styled from "styled-components"

export const CardContainer = styled.div`
    align-items: center;
    border: 1px solid #b7b7b7;
    border-radius: 6px;
    padding: 20px;
    text-align: center;
    max-width: 350px;
    height: 380px;
    width: 320px;
    margin: auto;

    @media screen and (max-width: 470px) {
        width: 80%;
    }
    @media screen and (max-width: 410px) {
        min-height: 420px;
    }
`
export const CarCardContainer = styled.div`
    border: 1px solid #b7b7b7;
    border-radius: .4rem;
    padding: .8rem;
    align-items: center;
    display: flex;
    flex-direction: column;

    p {
        color: #707070;
        font-size: .85rem;
    }

    .colored-btn {
        background-color: #ff4800;
        color: #fff;
    }

`

export const CompanyLogo = styled.img`
    margin-top: .6rem;
    height: 3rem;
    
`
export const BrandName = styled.p`
    font-family: Arial, Helvetica, sans-serif;
    font-size: 13px;
    font-weight: 600;
    margin-top: 18px;
    margin-bottom: 10px;
    text-align: center;
`
export const HDiv = styled.div`
    display: flex;
    align-items: center;
    .btns {
        margin: auto;
    }
`
export const Divider = styled.div`
    width: 90%;
    height: 0.5px;
    margin: 20px auto;
    background-color: #b7b7b7;
`
export const Feature = styled.p`
    color: #707070;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 0.75rem;
    line-height: 1.55;
`
export const BuyButton = styled.button`
    background-color: #fff;
    font-size: 1.25rem;
    font-weight: 600;
    color: #ff4800;
    border: 2px solid #ff4800;
    padding: .5rem;
    border-radius: 6px;
    margin: 1.2rem auto .6rem auto;
    cursor: pointer;
`
export const Button = styled.button`
    background-color: #fff;
    font-size: .65rem;
    padding: 6px;
    border-radius: 5px;
    color: #707070;
    border: 1px solid #707070;
    margin: auto 2px ;
`
export const SelectedBtn = styled.button`
    background-color: #fff;
    font-size: 0.65rem;
    padding: 6px;
    border-radius: 5px;
    border: 1px solid #ff4800;
    margin: auto 2px ;
    color: #ff4800;
`