import { Modal } from 'antd';
import 'antd/dist/antd.css';
import './label.css'; // Import your custom CSS file

const { confirm } = Modal;

const handleAlert = (title, content) => {
  const modal = confirm({
    title,
    content,
    centered: true,
    okButtonProps: {
      style: {
        background: '#F6931E',
        borderRadius: '5px',
        border: 'none', // Remove button border
        outline: 'none', // Remove button outline
      },
    },
    cancelButtonProps: {
      style: {
        border: 'none', // Remove button border
        outline: 'none', // Remove button outline
      },
    },
    maskClosable: true,
    style: {
      boxShadow: 'none', // Remove shadow
      border: 'none',   // Remove border
    },
  });

  // Automatically close the modal after 5 seconds (5000 milliseconds)
  setTimeout(() => {
    modal.destroy();
  }, 3000);
};

export default handleAlert;
