import styled from "styled-components";

export const MainContainer = styled.div`
    height: 100vh;
    overflow-y: hidden;
`
export const OpacityDiv = styled.div`
    display: flex;
    width: 100%;
    height: 110%;
    background-color: rgb(0,0,0);
    position: absolute;
    top: 0;
    z-index: 999;
    opacity: .8;
    `
export const OtpContainer = styled.div`
    padding: 2rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: .5rem;

    p {
        color:#707070;
    }

`
export const Button = styled.button`
    background-color: #ff4800;
    padding: .5rem 1.5rem;
    color: #fff;
    border: none;
    border-radius: .4rem;
    cursor: pointer;
    font-size: 1rem;
`
export const inputStyles = {
    margin:'1rem', 
    fontSize:'1.5rem', 
    width:'3rem', 
    border:'1px solid #b1d2ef', 
    borderRadius:'.3rem', 
    padding:'.6rem 1rem'
}