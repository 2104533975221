// bankOptions.js

const bankOptions = [
  { label: 'AB Bank', value: 'ABBank' },
  {
    label: 'Abhyudaya Co-operative Bank Ltd',
    value: 'AbhyudayaCooperativeBankLtd',
  },
  { label: 'Abu Dhabi Commercial Bank', value: 'AbuDhabiCommercialBank' },
  { label: 'Access Bank', value: 'AccessBank' },
  {
    label: 'Ahmedabad Mercantile Co-Op Bank Limited',
    value: 'AhmedabadMercantileCoOpBankLimited',
  },
  {
    label: 'Amanath Co-operative Bank Limited',
    value: 'AmanathCooperativeBankLimited',
  },
  { label: 'American Express', value: 'AmericanExpress' },
  {
    label: 'Andhra Pradesh Grameena Vikas Bank',
    value: 'AndhraPradeshGrameenaVikasBank',
  },
  {
    label: 'Andhra Pragathi Grameena Bank',
    value: 'AndhraPragathiGrameenaBank',
  },
  { label: 'Apna Sahakari Bank Limited', value: 'ApnaSahakariBankLimited' },
  { label: 'Arunachal Pradesh Rural Bank', value: 'ArunachalPradeshRuralBank' },
  { label: 'Aryavart Bank', value: 'AryavartBank' },
  { label: 'Assam Gramin Vikash Bank', value: 'AssamGraminVikashBank' },
  { label: 'AU Small Finance Bank', value: 'AUSmallFinanceBank' },
  {
    label: 'Australia and New Zealand Banking Group',
    value: 'AustraliaAndNewZealandBankingGroup',
  },
  { label: 'Axis Bank', value: 'AxisBank' },
  {
    label: 'Banco Bilbao Vizcaya Argentaria',
    value: 'BancoBilbaoVizcayaArgentaria',
  },
  { label: 'Banco BPM', value: 'BancoBPM' },
  { label: 'Banco de Sabadell', value: 'BancoDeSabadell' },
  { label: 'Bandhan Bank', value: 'BandhanBank' },
  { label: 'Bank of America', value: 'BankOfAmerica' },
  { label: 'Bank of Bahrain and Kuwait', value: 'BankOfBahrainAndKuwait' },
  { label: 'Bank of Baroda', value: 'BankOfBaroda' },
  { label: 'Bank of Ceylon', value: 'BankOfCeylon' },
  { label: 'Bank of China', value: 'BankOfChina' },
  { label: 'Bank of India', value: 'BankOfIndia' },
  { label: 'Bank of Maharashtra', value: 'BankOfMaharashtra' },
  { label: 'Bank of Montreal', value: 'BankOfMontreal' },
  { label: 'Bank of Nova Scotia', value: 'BankOfNovaScotia' },
  { label: 'Bank of Taiwan', value: 'BankOfTaiwan' },
  { label: 'Barclays Bank Plc', value: 'BarclaysBankPlc' },
  {
    label: 'Baroda Rajasthan Kshetriya Gramin Bank',
    value: 'BarodaRajasthanKshetriyaGraminBank',
  },
  { label: 'Baroda UP Bank', value: 'BarodaUPBank' },
  {
    label: 'Bassein Catholic Co-operative Bank Limited',
    value: 'BasseinCatholicCooperativeBankLimited',
  },
  {
    label: 'Bharat Co-operative Bank (Mumbai) Limited',
    value: 'BharatCooperativeBankMumbaiLimited',
  },
  { label: 'BNP Paribas', value: 'BNPParibas' },
  {
    label: 'Bombay Mercantile Co-operative Bank Limited',
    value: 'BombayMercantileCooperativeBankLimited',
  },
  { label: 'Busan Bank', value: 'BusanBank' },
  { label: 'Caixa Geral de Depositos', value: 'CaixaGeralDeDepositos' },
  { label: 'Canara Bank', value: 'CanaraBank' },
  { label: 'Capital Small Finance Bank', value: 'CapitalSmallFinanceBank' },
  { label: 'Central Bank of India', value: 'CentralBankOfIndia' },
  {
    label: 'Chaitanya Godavari Gramin Bank',
    value: 'ChaitanyaGodavariGraminBank',
  },
  { label: 'Citibank India', value: 'CitibankIndia' },
  {
    label: 'Citizen Credit Co-operative Bank Limited',
    value: 'CitizenCreditCooperativeBankLimited',
  },
  { label: 'City Union Bank', value: 'CityUnionBank' },
  { label: 'Coastal Local Area Bank Ltd', value: 'CoastalLocalAreaBankLtd' },
  { label: 'Cosmos Bank', value: 'CosmosBank' },
  {
    label: 'Credit Agricole Corporate and Investment Bank',
    value: 'CreditAgricoleCorporateAndInvestmentBank',
  },
  {
    label: 'Credit Industriel et Commercial',
    value: 'CreditIndustrielEtCommercial',
  },
  { label: 'Credit Suisse', value: 'CreditSuisse' },
  { label: 'CSB Bank', value: 'CSBBank' },
  { label: 'CTBC Bank', value: 'CTBCBank' },
  { label: 'Dakshin Bihar Gramin Bank', value: 'DakshinBiharGraminBank' },
  { label: 'DBS Bank', value: 'DBSBank' },
  { label: 'DCB Bank', value: 'DCBBank' },
  { label: 'Deutsche Bank', value: 'DeutscheBank' },
  { label: 'Dhanlaxmi Bank', value: 'DhanlaxmiBank' },
  { label: 'DNB Bank', value: 'DNBBank' },
  {
    label: 'Dombivli Nagari Sahakari Bank Ltd',
    value: 'DombivliNagariSahakariBankLtd',
  },
  { label: 'DZ Bank', value: 'DZBank' },
  { label: 'Ellaquai Dehati Bank', value: 'EllaquaiDehatiBank' },
  { label: 'Emirates NBD', value: 'EmiratesNBD' },
  { label: 'Equitas Small Finance Bank', value: 'EquitasSmallFinanceBank' },
  { label: 'ESAF Small Finance Bank', value: 'ESAFSmallFinanceBank' },
  { label: 'Federal Bank', value: 'FederalBank' },
  { label: 'Fincare Small Finance Bank', value: 'FincareSmallFinanceBank' },
  {
    label: 'Fingrowth Co-operative Bank Limited',
    value: 'FingrowthCooperativeBankLimited',
  },
  { label: 'First Abu Dhabi Bank', value: 'FirstAbuDhabiBank' },
  { label: 'FirstRand Bank', value: 'FirstRandBank' },
  { label: 'Gazprombank', value: 'Gazprombank' },
  {
    label: 'Goa Urban Co-operative Bank Limited',
    value: 'GoaUrbanCooperativeBankLimited',
  },
  {
    label: 'Gopinath Patil Parsik Janata Sahakari Bank Limited',
    value: 'GopinathPatilParsikJanataSahakariBankLimited',
  },
  {
    label: 'Greater Bombay Co-operative Bank Limited',
    value: 'GreaterBombayCooperativeBankLimited',
  },
  {
    label: 'GS Mahanagar Co-operative Bank Limited',
    value: 'GSMahanagarCooperativeBankLimited',
  },
  { label: 'HDFC Bank', value: 'HDFCBank' },
  { label: 'Himachal Pradesh Gramin Bank', value: 'HimachalPradeshGraminBank' },
  { label: 'HSBC Bank India', value: 'HSBCBankIndia' },
  { label: 'ICICI Bank', value: 'ICICIBank' },
  { label: 'IDBI Bank', value: 'IDBIBank' },
  { label: 'IDFC First Bank', value: 'IDFCFirstBank' },
  { label: 'Indian Bank', value: 'IndianBank' },
  {
    label: 'Indian Mercantile Co-operative Bank Limited',
    value: 'IndianMercantileCooperativeBankLimited',
  },
  { label: 'Indian Overseas Bank', value: 'IndianOverseasBank' },
  { label: 'IndusInd Bank', value: 'IndusIndBank' },
  {
    label: 'Industrial & Commercial Bank of China',
    value: 'IndustrialAndCommercialBankOfChina',
  },
  { label: 'Industrial Bank of Korea', value: 'IndustrialBankOfKorea' },
  { label: 'Intesa Sanpaolo', value: 'IntesaSanpaolo' },
  {
    label: 'Jalgaon Janata Sahakari Bank Limited',
    value: 'JalgaonJanataSahakariBankLimited',
  },
  {
    label: 'Jalgaon Peoples Co-operative Bank Limited',
    value: 'JalgaonPeoplesCooperativeBankLimited',
  },
  { label: 'Jammu & Kashmir Bank', value: 'JammuAndKashmirBank' },
  {
    label: 'Jammu And Kashmir Grameen Bank',
    value: 'JammuAndKashmirGrameenBank',
  },
  { label: 'Jana Small Finance Bank', value: 'JanaSmallFinanceBank' },
  {
    label: 'Janakalyan Sahakari Bank Limited',
    value: 'JanakalyanSahakariBankLimited',
  },
  {
    label: 'Janalaxmi Co-operative Bank Limited',
    value: 'JanalaxmiCooperativeBankLimited',
  },
  { label: 'Janata Sahakari Bank Limited', value: 'JanataSahakariBankLimited' },
  { label: 'Jharkhand Rajya Gramin Bank', value: 'JharkhandRajyaGraminBank' },
  { label: 'JPMorgan Chase', value: 'JPMorganChase' },
  {
    label: 'Kallappanna Awade Ichalkaranji Janata Sahakari Bank Limited',
    value: 'KallappannaAwadeIchalkaranjiJanataSahakariBankLimited',
  },
];

export default bankOptions;
