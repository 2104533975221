import styled from "styled-components";

export const CardContainer = styled.div`
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    width: 85%;
    margin: 1rem auto;
    margin-bottom: 2rem;

    p {
        margin: 0;
    }

    .flex {
        display: flex;
    }
    .flex-1 {
        flex: 1;
    }
    .align-center {
        align-items: center;
    }
    .arrow {
    }
    .w-100 {
        width: 100%;
    }
    .back-to-quotes {
        color: #2B003F;
        font-weight: 700;
    }
    .apart {
        justify-content: space-between;
    }
    .bold {
        color: #605F5E;
        font-weight: 700;
        font-size: 14px;
    }
    .simpli {
        color: #FD9620;
        font-weight: 700;
    }
    .insure {
        color: #321046;
        font-weight: 700;
    }
    .tnc {
        text-decoration-line: underline;
    }

    .continue {
        border-radius: 40px;
        padding: .6rem;
        background-color: #F6931E;
        color: #fff;
        border: none;
        cursor: pointer;
        width: 175px;
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .cards {
        padding: 1rem;
        box-shadow: 0px 0px 27px -11px rgba(0,0,0,0.25);
        border-radius: 10px;
    }
    .column {
        flex-direction: column;
    }
    .brand-img {
        border-radius: 50%;
        width: 55px;
        height: 55px;
    }
    .brand-name {
        font-weight: 700;
        color:#605F5E;
        font-size: 16px;
    }
    .mt-half {
        margin-top: .5rem;
    }
    .mb-1 {
        margin-bottom: 1rem;
    }
    .basics {
        color: #F6931E;
        /* margin-bottom: 1rem; */
        margin-top: .5rem;
    }
    .mr-half {
        margin-right: .5rem;
    }
    .ml-half {
        margin-left: .5rem;
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .total {
        color: #000000;
        font-weight: 900;
        font-size: 16px;
    }
    .amount {
        font-weight: 900;
        color:#000;
        font-size: 16px;
    }
    .head {
        color: rgba(51,51,51,0.5);
        font-weight: 700;
        text-transform: uppercase;
        font-size: .85rem;
    }
    .data {
        color: #333333;
        font-size: .85rem;
        font-weight: 700;
    }
    .video-icon-container {
        background-color: rgba(246,147,30,0.21);
        padding: 1rem;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: .5rem;
    }
    .orange-dot {
        background-color:rgba(246,147,30,0.21);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: .3rem;
    }
    .vid-tips {
        font-size: .7rem;
        font-weight: 700;
        color: rgba(51,51,51,0.5);
    }

    .vid-inspection {
        font-weight: 700;
        font-size: 15px;
        color: #333333;
    }

    .cards-container {
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
    }
    .res-apart {
        @media screen and (max-width:650px) {
            flex-direction: column;
        }
    }
    .res-center {
        @media screen and (max-width:650px) {
            text-align: center;
            align-items: center;
        }
    }
    .res-margin {
        @media screen and (max-width:650px) {
            margin-bottom: .35rem;
        }
    }
    .change-no {
        font-weight: 700;
        color: #321046;
        text-decoration-line: underline;
    }
    .resend {
        font-weight: 700;
        color: #FD9620;
        text-decoration-line: underline;
    }
`
export const Separator = styled.hr`
    opacity: 1;
    width: 100%;
    background-color: #B7B7B7;
    margin: .5rem auto  ;
    opacity: .5;
`