import React, { useEffect, useState } from 'react'
import { CloseIcon, MainContainer, Opacity, PopupContainer, InputContainer, FloatingTitle, SelectDropdown } from "./styles"
import CloseImg from '../../../assets/brand-new-car/close-btn.svg'
import ErrorPopups from '../../../components/POS/components/ErrorPopups'
import { apiGetCall, apiPutCall } from '../../../utilities/site-apis'

const NewPopup = ({ someData, changePopupState }) => {
    const [error, setError] = useState('')
    const [data, setData] = useState('')
    const [showOptions, setShowOptions] = useState([])
    const [showData, setShowData] = useState()

    const [showKey, setShowKey] = useState('Brand')

    const [formData, setFormData] = useState({
        project: '',
        makeName: '',
        modelName: '',
        cubicCap: '',
        fuelType: '',
        variantName: ''
    })


    const FetchSomeData = async () => {
        if (!showData) return null
        const response = await apiGetCall('/vehicle/details', { regNo: showData.number })
        if (response.status === 200) {
            setShowOptions(response.data?.data)
        }
    }
    const FetchOptions = async () => {
        const response = await apiGetCall('/masterData/', { ...formData })
        if (response.status === 200) {
            setShowOptions(response.data?.data)
        }
    }

    const handleContinue = async () => {
        let body = {}
        if (showKey === 'Brand' && data) {
            body = {
                brand: data
            }
        } else if (showKey === 'Model' && data) {
            body = {
                model: data
            }
        } else if (showKey === 'CC' && data) {
            body = {
                cubicCap: data
            }
        } else if (showKey === 'Variant' && data) {
            body = {
                variant: data
            }
        } else {
            setError(error.internal = "Invalid Key !!")
        }

        const response = await apiPutCall(`/vehicle/details/${showData?.number}`, { ...body })
        let reponseData = response.data?.data
        if (response.status === 201) {
            if (reponseData.next) {
                if (reponseData.model) {
                    setFormData(prev => ({ ...prev, makeName: reponseData.vehicle?.brand, project: "modelName" }))
                    setShowKey("Model")
                } else if (reponseData.cubicCap) {
                    setFormData(prev => ({ ...prev, makeName: reponseData.vehicle?.brand, modelName: reponseData.vehicle?.model, project: "cubicCap" }))
                    setShowKey("CC")
                } else if (reponseData.variant) {
                    setFormData(prev => ({ ...prev, cubicCap: reponseData.vehicle?.cubicCap, makeName: reponseData.vehicle?.brand, modelName: reponseData.vehicle?.model, project: "variantName" }))
                    setShowKey("Variant")
                }
                setData("")
            }
            setData("")
            setShowData(reponseData.vehicle)
        } else if (response.status === 200) {
            setData("")
            localStorage.setItem("canInsuranceDetails", JSON.stringify(reponseData.vehicle))
            changePopupState(false)
        }
    }


    const removeError = (key) => {
        switch (key) {
            case showKey:
                if (error[showKey]) return setError(error[showKey] = "")
                break
            case 'internal':
                if (error.internal) return setError(error.internal = "")
                break
            default:
                break;
        }
    }
    const PopupConatiner = () => {

        return (
            <PopupContainer>
                <CloseIcon src={CloseImg} />
                <p className="prompt">Please Confirm your {showKey} ^_^</p>
                <InputContainer onClick={() => error.internal ? removeError('internal') : removeError(showKey)}>
                    <FloatingTitle>{showKey}</FloatingTitle>
                    <SelectDropdown contentEditable
                        value={data}
                        onChange={(e) => {
                            setData(e.target.value);
                        }}
                    >
                        <option value="" disabled>
                            Select {showKey}
                        </option>
                        {showOptions.length && showOptions.sort().map((item, index) => (
                            <option key={index} value={item} disabled={item === "#N/A" ? true : false} >{item}</option>
                        ))}
                    </SelectDropdown>
                    {error.hasOwnProperty(showKey) ? (
                        <ErrorPopups error={error[showKey]} />
                    ) : error.hasOwnProperty('internal') ?
                        (<ErrorPopups error={error.internal} />) : null}
                </InputContainer>
                <button style={{
                    width: "100%",
                    padding: "5px",
                    backgroundColor: "orange",
                    color: "black",
                }} onClick={handleContinue}> Save Check </button>
            </PopupContainer>
        )
    }

    useEffect(() => {
        FetchOptions();
    }, [formData]);

    useEffect(() => {
        setShowData(someData)
        FetchSomeData();
    }, [formData]);

    return (
        <MainContainer>
            <Opacity />
            <PopupConatiner />
        </MainContainer>
    )
}

export default NewPopup