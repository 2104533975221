// our partners page
import React, { useState, useEffect } from 'react';
import './ourPartners.css';
import CareerImage from '../../../assets/banner22.png';
import FooterNew from '../../../components/Footer/FooterNew';
import Quick from '../../../components/new_quick_links/quick';
import BlogCard from '../../../components/blog-card/blog';
import Hero from '../../../components/Hero_Banner/hero';
import { AiOutlineFileProtect } from "react-icons/ai";
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Partners = () => {
  const [partnerImages, setPartnerImages] = useState([]);
  const navigate = useNavigate();

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


  const imageNameData =  [
    "1-nationalInsurance",
    "2-hdfcLife",
    "3-BajajAllianzGeneralInsurance",
    "4-bhartiAXA",
    "5-canaraHSBC",
    "6-careHealth",
    "7-cholaMS",
    "8-digit",
    "9-edelweiss",
    "10-edelweissLifeTokio",
    "11-exideLifeInsurance",
    "12-futureGenerali",
    "13-hdfcERGO",
    // "14-bajajAllianz",
    "15-iciciLombard",
    "16-iciciPrudential",
    "17-iffcoTOKIO",
    "18-kotakGeneralInsuance",
    "19-kotakLifeInsurance",
    "20-liberty",
    "21-LIC",
    "22-magmaHDI",
    "23-manipalCigna",
    "24-maxLife",
    "25-adityaBirla",
    "26-niva",
    "27-orientalInsurance",
    "28-pnbMetLife",
    "29-rahejaQBE",
    "30-relianceGeneralInsurance",
    "31-relianceNipponLifeInsurance",
    "32-royalSundaram",
    "33-sbiGeneral",
    "34-sbiLife",
    "35-shriramGeneralInsurance",
    "36-shriramlifeInsurance",
    "37-starHealthInsurance",
    "38-tataAIG",
    "39-theNewIndiaAssurance",
    "40-unitedIndia",
    "41-universalSompoGeneralInsurance",
    "42-tataLife",
    "43-bajajLife"
  ];

  useEffect(() => {
    const importImages = async () => {
      const images = await Promise.all(
        imageNameData.map(async (imageName) => {
          const image = await import(`../../../assets/new_brand/${imageName}.png`);
          return image.default;
        })
      );
      setPartnerImages(images);
    };
    
    importImages();
  }, []);

  // const lifeInsuranceIndices = [2, 5, 10, 11, 16,  19, 21, 24, 28, 31, 34, 36];
 const lifeInsuranceIndices = [  "hdfcLife", "tataLife",  "edelweissLifeTokio",  "exideLifeInsurance",  "iciciPrudential",  "kotakLifeInsurance",  "LIC",  "maxLife",  "pnbMetLife",  "relianceNipponLifeInsurance",  "sbiLife",  "shriramlifeInsurance", "bajajLife"]
 const generalInsuranceIndices = [  "nationalInsurance",  "BajajAllianzGeneralInsurance",  "cholaMS",  "digit",  "edelweiss",  "futureGenerali",  "hdfcERGO",  "iciciLombard",  "iffcoTOKIO",  "kotakGeneralInsuance",  "liberty",  "magmaHDI",  "manipalCigna",  "adityaBirla",  "niva",  "orientalInsurance",  "rahejaQBE",  "relianceGeneralInsurance",  "royalSundaram",  "sbiGeneral",  "shriramGeneralInsurance",  "starHealthInsurance",  "tataAIG",  "theNewIndiaAssurance",  "unitedIndia",  "universalSompoGeneralInsurance",   "careHealth",]

  // const generalInsuranceIndices = [1, 3, 4, 6, 7, 8, 9, 12, 13, 14, 15, 17, 18, 20, 22, 23, 25, 26, 27, 29, 30, 32, 33, 35, 37, 38, 39, 40, 41];

  
  return (
    <div>
      <Hero image={CareerImage} icon={<AiOutlineFileProtect size={80} color='#fff' />} heading="Trusted Insurance Partners" subHeading="Discover Our Network of Insurance Companies" btn={true}/>
      <Quick heading="Insurance Companies" />
      <div className="partners-container">
        <h1 className='media-heading'>PARTNERS</h1>
        <div className='diversity'>
          <h2 className='partner_heading_small'>At Simpliinsure.com, we cherish our robust affiliations with top-tier insurance companies across India.</h2>
          <p>Our esteemed partners operate in diverse sectors, encompassing automobile insurance, health insurance, life insurance, corporate insurance, travel insurance, and loans. Leveraging these strategic partnerships, we aim to make life simple for all by presenting comprehensive policy details and competitive pricing options offered by our trusted insurers.</p>
        </div>

        <h2 className='insurance-heading'>General Insurance</h2>
        <div className="our-partners-grid">
        {generalInsuranceIndices.map((imageName, index) => {
          const matchingImage = imageNameData.find(name => name.includes(imageName));
          if (matchingImage) {
              const imageIndex = imageNameData.indexOf(matchingImage);
              return (
                  <img
                      key={index}
                      src={partnerImages[imageIndex]}
                      alt={`Partner ${index}`}
                      className="partner-image"
                      onClick={() => navigate(`/brand/${imageName}`)}
                  />
              );
          } else {
              // Handle case when imageName is not found in imageNameData
              return null; // Or any other fallback UI
          }
      })}

        </div>

        <h2 className='insurance-heading'>Life Insurance</h2>
        <div className="our-partners-grid">
         {lifeInsuranceIndices.map((imageName, index) => {
            const matchingImage = imageNameData.find(name => name.includes(imageName));
            if (matchingImage) {
                const imageIndex = imageNameData.indexOf(matchingImage);
                return (
                    <img
                        key={index}
                        src={partnerImages[imageIndex]}
                        alt={`Partner ${index}`}
                        className="partner-image"
                        onClick={() => navigate(`/brand/${imageName}`)}
                    />
                );
            } else {
                // Handle case when imageName is not found in imageNameData
                return null; // Or any other fallback UI
            }
        })}

        </div>
      </div>
      
      <FooterNew />
    </div>
  );
};

export default Partners;
