import React, { useRef, useState, useEffect } from 'react';
import { MainContainer } from "../../../../assets/common-styles/common-styles"

import { CardContainer, Separator, ContinueBtn } from "../preview-after-payment/styles"
import Footer from "../../../../components/Footer/FooterNew"

import '../preview-after-payment/preview'

export const Instructions = () => {
    
    return (
        <div>
          <MainContainer>
                <CardContainer>
                    

                    <div className="flex apart cards-container">

                        <div className="flex-1 mr-half">
                            <div className="cards mb-1">
                                <h2>How does Self Inspection works?</h2>
                            </div>
                            <div className="cards mb-1">
                                <div className="flex column">
                                <p style={{marginBottom:"1rem"}} className="brand-name">To Self Inspect your car, just follow these three simple steps : </p>
                                <div>
                                
                                   
                                </div>
                                </div>
                            </div>
                            <div className=" mb-1">
                                <div className="flex column">
                                <p>1. Click on the link sent to you via SMS or E-mail.</p>
                                <p>2. Capture the images of your car as per the instructions.</p>
                                <p>3. Once the inspection is approved the payment link will be emailed to you.</p>
                               

                                
                                
                                </div>
                            </div>
                        </div>

                       
                    </div>
                </CardContainer>
            </MainContainer>
          <Footer/>
        </div>
    )
}