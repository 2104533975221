import { useLocation, useNavigate } from "react-router-dom"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Button, ButtonInactive, CloseBtn, ContinueBtn, HDiv, HdivApart, HelpIcon, InputContainer, Layout } from "./styles"
import CloseImg from '../../../assets/brand-new-car/close-btn.svg'
import HelpImg from '../../../assets/car-policy-details/help.svg'

export const StandaloneTp = () => {

    const navigate = useNavigate()
    const location = useLocation()



    return(
        <>
            <MainContainer>
                <Layout>

                    <CarDetailsComponent 
                        title='Type of Policy'
                        step={2}
                        totalSteps={2}
                        noSearch={true}
                    />
                    <ButtonInactive>Comprehensive Policy</ButtonInactive>
                    <ButtonInactive>Standalone OD Policy</ButtonInactive>
                    <Button>{location?.state?.policy}</Button>

                    <InputContainer>

                        <CloseBtn src={CloseImg} onClick={()=>navigate(-1)}/>
                        <p>Cover Details</p>

                        <HdivApart>
                            <HDiv className="left">
                                <input
                                    type={'checkbox'}
                                    id='enter-idv'
                                />
                                <label for='enter-idv'>Additional TPPD Cover</label>
                                <HelpIcon src={HelpImg} />
                            </HDiv>
                            <HDiv className="right">
                                <input
                                    type={'checkbox'}
                                    id='lowest-idv'
                                />
                                <label for='lowest-idv'>Additional TPA Cover</label>
                                <HelpIcon src={HelpImg} />
                            </HDiv>
                        </HdivApart>


                        
                        <ContinueBtn>Continue</ContinueBtn>
                    </InputContainer>

                </Layout>
            </MainContainer>
        </>
    )
}