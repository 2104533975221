import styled from "styled-components";

export const Layout = styled.div`
    border: .5px solid #B7B7B7;
    margin: 2rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
    flex-direction: column;

    .selected {
        border: 1px solid #F6931E;
    }

`
export const Button = styled.button`
    width: 600px;
    border-radius: .6rem;
    border: 1px solid #B7B7B7;
    background-color: #fff;
    padding: .8rem;
    color:#000 !important; 
    font-weight: 800;
    margin-bottom: .9rem;
    user-select: none;
    
    &:hover {
        cursor: pointer;
        /* border: 1px solid #000; */
    }

    @media screen and (max-width: 900px) {
        width: 80%;
    }

    @media screen and (max-width: 600px) {
        width: 95%;
    }

`
export const InputContainer = styled.div`
    width: 600px;
    border-radius: .6rem;
    border: 1px solid #B7B7B7;
    padding: .8rem;
    position: relative;
    text-align: center;

    p{ 
        font-weight: 800;
    }
    label {
        user-select: none;
    }

    .input-description {
        color: rgba(51,51,51,0.5);
        font-size: 12px;
        margin-top: 1.25rem;
    }

    @media screen and (max-width: 900px) {
        width: 80%;
    }

    @media screen and (max-width: 600px) {
        width: 95%;
    }

`
export const CloseBtn = styled.img`
    position: absolute;
    right: 15px;
    cursor: pointer;
`
export const Btns = styled.button`

    text-transform: uppercase;
    padding: .75rem;
    border-radius: 25px;
    width: 100px;
    background-color: #fff;
    color:#605F5E !important;
    font-size: 17px;
    border:1px solid #B7B7B7;
    cursor: pointer;
    margin: 2rem 1rem;
    &:hover {
        border: 1px solid #F6931E;
    }

`