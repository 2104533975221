import { useState } from 'react';
import EditUserSvg from '../../../../assets/dashboard/edit-img.svg';
import { uploadFile } from "../../../../components/POS/uploadFile"
import { axiosPUTCall1 } from "../../../../utilities/utils/apiPost"
import "./pos.css"
const moment = require('moment');

const PosModelData = ({ posData, modalCloses, placeholder }) => {
  const [posInfo, setPosInfo] = useState(posData)
  const uploadDocs = async (e, docName) => {
    // console.log(e.target.files[0]);

    if (e.target.files[0].size < 10485760) {
      const url = await uploadFile(e.target.files[0])
      console.log("posInfo", posInfo)
      const obj = {
        [`documents.${docName}`]: url.data[0].url
      }
      axiosPUTCall1(`/pospInfo/profile/${posInfo._id}`, obj, callback => {
        if (callback.status == "Success") {
          setPosInfo(callback.data)
        }
      })
    }
    else {
      alert("Please, Reduce the size of image (below 10 MB) ")
    }
  };

  return (
    <div>
      <div id="myModal" className="modalPos">
        <div className="modal-content main-model-data">
          <div className="close-pos-model" onClick={() => modalCloses({})}>
            &times;
          </div>
          <div className="inside_box">
            <div className="profile_div">
              <img
                src={posInfo?.documents?.profile || placeholder}
                className="profile_photo"
                alt="profile"
              />
              <div className="edit_div prof-icon">
                <img className="edit_docs" src={EditUserSvg} alt="profile" />
                <input type="file" onChange={e => uploadDocs(e, "profile")} />
              </div>
            </div>
            <div className="content_popup">
              <div className="popup_head">
                <h3 className="heading">POS Details :</h3>
              </div>
              <div className="rm_basic">
                <div className="rm_right">
                  <div>
                    <h3>
                      Member Since : <span>20/01/2020</span>
                    </h3>
                    <h3>
                      POS ID :<span>{posInfo?._id}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      POS Name : <span>{posInfo?.fullName}</span>
                    </h3>
                    <h3>
                      POS Email :<span>{posInfo?.email}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      POS Mobile No. : <span>+91 - {posInfo?.phone}</span>
                    </h3>
                    <h3>
                      Sr. RM/No. :
                      <span>
                        {posInfo?.informationRM?.name}/+91 -{' '}
                        {posInfo?.informationRM?.phone}
                      </span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      House No. : <span>{posInfo?.houseNo}</span>
                    </h3>
                    <h3>
                      Street :<span>{posInfo?.street}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      Town : <span>{posInfo?.town}</span>
                    </h3>
                    <h3>
                      District :<span>{posInfo?.district} (Urban)</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      State : <span>{posInfo?.state}</span>
                    </h3>
                    <h3>
                      Pincode :<span>{posInfo?.pincode}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      Area : <span>{posInfo?.area}</span>
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="popUp_line" />

              <h3 className="heading">Other Details :</h3>
              <div className="rm_basic">
                <div className="rm_right">
                  <div>
                    <h3>
                      Gender :<span>{posInfo?.gender}</span>
                    </h3>
                    <h3>
                      D.O.B :
                      <span>
                        {posInfo?.dob
                          ? moment(posInfo.dob).format('DD/MM/YYYY')
                          : 'Not available'}
                      </span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      Pan Card :<span>{posInfo?.panNo}</span>
                    </h3>
                    <h3>
                      Basic Quilification :
                      <span>{posInfo?.qualification}</span>
                    </h3>
                  </div>
                  <div>
                    <h3>
                      IRDA Number :
                      <span>
                        {posInfo?.irdaNumber
                          ? posInfo?.irdaNumber
                          : 'Not Available'}
                      </span>
                    </h3>
                    <h3 style={{ cursor: 'pointer' }}>
                      IRDA Document :{' '}
                      {posInfo?.irdaImg ? (
                        <a href={posInfo.irdaImg} download>
                          Download
                        </a>
                      ) : (
                        <span>No document available</span>
                      )}
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="popUp_line" />

              <h3 className="heading">KYC Status :</h3>
              <div className="rm_basic">
                <div className="rm_right">
                  <div>
                    <h3>
                      KYC Status:
                      <span>
                        {posInfo?.kycStatus
                          ? posInfo?.kycStatus
                          : 'Pending'}
                      </span>
                    </h3>
                  </div>
                </div>
              </div>
              <hr className="popUp_line" />

              <h3 className="heading">Document Details :</h3>
              <div className="rm_basic">
                <div className="rm_details">
                  <div className="details_front">
                    <div>
                      <h3>Aadhar card Front :
                        <div className="edit_div">
                          <img className="edit_docs" src={EditUserSvg} alt="aadhaar-front" />
                          <input type="file" onChange={e => uploadDocs(e, "aadharFront")} />
                        </div>
                      </h3>
                      <img
                        src={
                          posInfo?.documents?.aadharFront ||
                          `https://placehold.co/600x400?text=AADHAR FRONT`
                        }
                      />
                    </div>
                    <div>
                      <h3>Pan card Front :
                        <div className="edit_div">
                          <img className="edit_docs" src={EditUserSvg} alt="pan-front" />
                          <input type="file" onChange={e => uploadDocs(e, "pancard")} />
                        </div>
                      </h3>
                      <img
                        src={
                          posInfo?.documents?.pancard ||
                          `https://placehold.co/600x400?text=PAN FRONT`
                        }
                      />
                    </div>
                    <div>
                      <h3>Education Qualification Proof :
                        <div className="edit_div">
                          <img className="edit_docs" src={EditUserSvg} alt="education" />
                          <input type="file" onChange={e => uploadDocs(e, "education")} />
                        </div>
                      </h3>
                      <img
                        src={
                          posInfo?.documents?.education ||
                          `https://placehold.co/600x400?text=EDUCATION`
                        }
                      />
                    </div>
                  </div>

                  <div className="details_back">
                    <div>
                      <h3>Aadhar card back :
                        <div className="edit_div">
                          <img className="edit_docs" src={EditUserSvg} alt="aadhaar-back" />
                          <input type="file" onChange={e => uploadDocs(e, "aadharBack")} />
                        </div>
                      </h3>
                      <img
                        src={
                          posInfo?.documents?.aadharBack ||
                          `https://placehold.co/600x400?text=AADHAR BACK`
                        }
                      />
                    </div>
                    <div>
                      <h3> </h3>
                    </div>
                    <div>
                      <h3>Bank Statement :
                        <div className="edit_div">
                          <img className="edit_docs" src={EditUserSvg} alt="bank-stat" />
                          <input type="file" onChange={e => uploadDocs(e, "bankDoc")} />
                        </div>
                      </h3>
                      <img
                        src={
                          posInfo?.documents?.bankDoc ||
                          `https://placehold.co/600x400?text=BANK STATEMENT`
                        }
                      />
                    </div>
                    <div>
                      <h3> </h3>
                    </div>
                    <div>
                      <h3>Signature Proof :
                        <div className="edit_div">
                          <img className="edit_docs" src={EditUserSvg} alt="signature" />
                          <input type="file" onChange={e => uploadDocs(e, "signature")} />
                        </div>
                      </h3>
                      <img
                        src={
                          posInfo?.documents?.signature ||
                          `https://placehold.co/600x400?text=SIGNATURE`
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PosModelData;