import styled from "styled-components";

export const MainDiv = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 1rem .6rem;
    border-radius: 0px 0px 10px 10px;
    border: 1px solid #B7B7B7;
    margin:0 auto;
    align-items: center;
    margin-bottom: .5rem;
    text-align: center;


    hr {
        height: 75px;
        background-color: rgb(151,151,151);
        opacity: .3;
    }

    .logo-container {
        /* width: 250px; */
        img {
            width: 130px;
            height: 60px;
        }
        p {
            color:#605F5E;
        }
    }


    .head {
        color:rgb(32,32,32,.6);
    }

    .uppercase {
        text-transform: uppercase;
    }
    .u-l {
        text-decoration-line: underline;
    }
    .bold {
        font-weight: 800;
    }

    .flex {
        display: flex;
        align-items: center;
    }
    .text-center {
        text-align: center;
    }

    .no-flex {
        display: flex;
        flex-direction: column !important;
    }

    .btn-container {
        height: 100%;
    }

    .plan-details {
        background-color: #fff;
        border: 1px solid #F6931E;
        cursor: pointer;
        border-radius: 10px;
        padding: .4rem;
        width: 100px;
        color:#F6931E !important;
        margin-bottom: .6rem;
    }
    .price-btn {
        background-color: #F6931E;
        cursor: pointer;
        border: 1px solid #F6931E;
        border-radius: 10px;
        padding: .6rem 1rem;
        width: 100px;
        color:#fff !important;
    }

    .mr-1 {
        margin-right: .5rem;
    }
    .mb-1 {
        margin-bottom: .2rem;
    }

`
export const TickIcon = styled.img`

`
export const CardContainer = styled.div`
    padding: 0.75rem;
    border: 1px solid #B7B7B7;
    p {
        margin: 0;
        color: #605F5E;
    }
    img {
        width: 100px;
        height: 36px;
    }
    .flex {
        display: flex;

        img {
            width: 12px;
            height: auto;
            margin-right: .15rem;
        }
        .mr-half {
            margin-right: .5rem;
        }
        .mt-half {
            margin-top: .2rem;
        }

        .plan-details {
           padding :.65rem ;
           width: 120px;
           border-radius: 10px;
           border: 1px solid #F6931E;
           background-color: #fff;
           color: #F6931E !important;
           cursor: pointer;
        }
        .price-btn {
           padding :.65rem ;
           width: 120px;
           border-radius: 10px;
           border: 1px solid #F6931E;
           background-color: #F6931E;
           color: #fff !important;
           cursor: pointer;
        }

    }
    .apart {
        justify-content: space-between;
    }
    .brand {
        color: #605F5E;
        font-weight: 700;
        margin: .35rem auto;
        margin-bottom: 0;
    }
    .fs-13 {
        font-size: 13px;
    }
    .fs-12 {
        font-size: 12px;
    }
    .fs-11 {
        font-size: 11px;
    }
    .bold {
        font-weight: 700;
    }
    .ml-2 {
        margin-left: 2rem;
    }
    .mt-half {
        margin-top: .5rem;
    }

    .left, .right {
        .flex, .mt-half, .mr-half {
            margin: .5rem auto;
        }
    }

    @media screen and (max-width: 800px) {
        padding-right:2rem;
    }
    @media screen and (max-width: 500px) {
        width:85vw;
        padding-right: .4rem;
      
    }

`

export const Opacity = styled.div`
    width: 100vw;
    height: ${localStorage.getItem('iHeight')}px;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: .6;
    z-index: 200;
`
export const CrossIcon = styled.img`

`
export const ArrowPriceIcon = styled.img``