import styled from "styled-components";

export const CardContainer = styled.div`
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    width: 85%;
    margin: 1rem auto;
    margin-bottom: 2rem;

    p {
        margin: 0;
    }
    .prop{
    p{
    width:25%
    }
    }
    .flex {
        display: flex;
    }
    .flex-1 {
        flex: 1;
    }
    .align-center {
        align-items: center;
    }
    .arrow {
        margin-right: .35rem;
        cursor:pointer;
    }
    .w-100 {
        width: 100%;
    }
    .back-to-quotes {
        color: #2B003F;
        font-weight: 700;
    }
    .apart {
        justify-content: space-between;
    }
    .bold {
        color: #605F5E;
        font-weight: 700;
        font-size: 14px;
    }
    .simpli {
        color: #FD9620;
        font-weight: 700;
    }
    .insure {
        color: #321046;
        font-weight: 700;
    }
    .tnc {
        text-decoration-line: underline;
    }

    .continue {
        border-radius: 40px;
        padding: .6rem;
        background-color: #F6931E;
        color: #fff;
        border: none;
        cursor: pointer;
        width: 175px;
        font-weight: 700;
        font-size: 1.2rem;
        margin-top: 1rem;
        margin-bottom: 2rem;
    }

    .cards {
        padding: 1rem;
        box-shadow: 0px 0px 27px -11px rgba(0,0,0,0.25);
        border-radius: 10px;
    }
    .column {
        flex-direction: column;
    }
    .brand-img {
        border-radius: 50%;
        width: 55px;
        height: 55px;
    }
    .brand-name {
        font-weight: 700;
        color:#605F5E;
        font-size: 16px;
    }
    .mt-half {
        margin-top: .5rem;
    }
    .mb-1 {
        margin-bottom: 1rem;
    }
    .basics {
        color: #F6931E;
        /* margin-bottom: 1rem; */
        margin-top: .5rem;
    }
    .mr-half {
        margin-right: .5rem;
    }
    .ml-half {
        margin-left: .5rem;
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .total {
        color: #000000;
        font-weight: 900;
        font-size: 16px;
    }
    .amount {
        font-weight: 900;
        color:#000;
        font-size: 16px;
    }
    .head {
        color: rgba(51,51,51,0.5);
        font-weight: 700;
        text-transform: uppercase;
        font-size: .85rem;
    }
    .data {
        color: #333333;
        font-size: .85rem;
        font-weight: 700;
    }
    .video-icon-container {
        background-color: rgba(246,147,30,0.21);
        padding: 1rem;
        width: 60px;
        height: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: .5rem;
    }
    .orange-dot {
        background-color:rgba(246,147,30,0.21);
        width: 6px;
        height: 6px;
        border-radius: 50%;
        margin-right: .3rem;
    }
    .vid-tips {
        font-size: .7rem;
        font-weight: 700;
        color: rgba(51,51,51,0.5);
    }

    .vid-inspection {
        font-weight: 700;
        font-size: 15px;
        color: #333333;
    }

    .cards-container {
        @media screen and (max-width: 1200px) {
            flex-direction: column;
        }
    }
    .res-apart {
        @media screen and (max-width:650px) {
            flex-direction: column;
        }
    }
    .res-center {
        @media screen and (max-width:650px) {
            text-align: center;
            align-items: center;
        }
    }
    .res-margin {
        @media screen and (max-width:650px) {
            margin-bottom: .35rem;
        }
    }
    .change-no {
        font-weight: 700;
        color: #321046;
        text-decoration-line: underline;
    }
    .resend {
        font-weight: 700;
        color: #FD9620;
        text-decoration-line: underline;
    }
    .ant-row {
        margin: 0 !important;
    }
`
export const Separator = styled.hr`
    opacity: 1;
    width: 100%;
    background-color: #B7B7B7;
    margin: .5rem auto  ;
    opacity: .5;
`



export const ErrArrow = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid #F6931E; 
    border-left: 10px solid transparent;
    margin-right: .5rem;
`

export const ErrorIcon = styled.img`
    width: 20px;
    margin-top: .4rem;
`

export const ErrorMsgContainer = styled.div`
    padding: .5rem 1rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    // border-bottom-right-radius: 0;
    color: #fff;
    font-weight: 900;
`

export const HDiv = styled.div`
    display: flex;
    align-self:center;
    align-items: center;
    margin-top: 1.6rem;
`

export const FloatingTitle = styled.p`
    position: absolute;
    top: -8px;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #fff;
    font-weight: 800;
`

export const InputContainer = styled.div`
    position: relative;
    padding: .6rem;
    margin: 1rem auto;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    input {
        width: 100%;
        padding:.3rem;
        outline: none;
        border: none;
        font-size: 1rem;
        margin-left: .2rem;
        background:none;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .m-0 {
        margin-top: 0;
        h4 {
            margin: auto;
        }
    }
    .m-0-0 {
        margin: 0;
    }

    .horizontal {
        display: flex;
        align-items: center;
        input {
            width: auto;
            accent-color: #F6931E;
        }
        label {
            margin-left: .5rem;
        }

        .apart+.apart {
            margin-left: 1rem;
        }

    }
    
`

export const ErrorContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    // left: 33rem;
    right: 1.9rem;
    // top: 0.2rem;
    // top: -28px;
    bottom: 1rem;
    z-index:1;
    // font-size: .8rem;
    align-items: flex-end;

`

export const ContinueBtn = styled.button`
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    padding: 0.7rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 4rem;
    border: none;
    color: #fff;
    font-weight: 900;
    cursor: pointer;

    &:hover {
        box-shadow: 1px 1px 6px gold;
    }

`
export const SelectDropdown = styled.select`
    width: 100%;
    padding-top: .35rem;
    padding-bottom: .35rem;
    // outline: dotted;
    // padding: 0.3rem;
    // border-radius: .6rem;
    font-size: 1.1rem;
    // border: 1px solid #B7B7B7;
    // text-shadow: 0 0 0 #000;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
`
