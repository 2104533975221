import styled from "styled-components";

export const SkeletonContainer = styled.div`
    position: absolute;
    /* top: 300px; */
    width: 80%;
    margin: auto;
    /* left: 50%; */
    /* transform: translate(-50%, -50%); */
`
export const Opacity = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: #000;
    z-index: 1;
    opacity: .35;
`