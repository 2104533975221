import { CarForm9 } from "../form-9/Form9"
import VerifiedIconImg from '../../../assets/car-insurance/form-11/verified-icon.png'
import { BottomContainer, HDiv, MainContainer, OpacityDiv, OtpContainer, VerifiedIcon } from "./styles"

export const CarForm11 = () => {
    return(
        <>
            <MainContainer>
                <CarForm9 />
                <OpacityDiv />
                <OtpContainer>
                    <VerifiedIcon src={VerifiedIconImg} />
                    <p className="heading">Payment Successful</p>
                    <p className="desc">Your payment has been processed! Details of the transaction has been included below</p>                    
                    <BottomContainer>
                        <HDiv className="gap-1">
                            <p>Amount</p>
                            <HDiv>
                                <p className="mr-1">:</p>
                                <p className="right-p">₹ 3394/-</p>
                            </HDiv>
                        </HDiv>
                        <HDiv className="gap-1">
                            <p>Transaction ID</p>
                            <HDiv>
                                <p className="mr-1">:</p>
                                <p className="right-p">5662892</p>
                            </HDiv>
                        </HDiv>
                        <HDiv className="gap-1">
                            <p>Payment Type</p>
                            <HDiv>
                                <p className="mr-1 ml-1">:</p>
                                <p className="right-p">Credit Card</p>
                            </HDiv>
                        </HDiv>
                    </BottomContainer>
                </OtpContainer>
            </MainContainer>
        </>
    )
}