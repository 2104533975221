import { useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Btns, Button, CloseBtn, InputContainer, Layout } from "./styles"
import CloseImg from '../../../assets/brand-new-car/close-btn.svg'
import { useSelector, useDispatch } from "react-redux";
import { motorEnquiryAPI } from '../../../Redux/CarsRedux';

export const TypeOfPolicy3 = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [policy, setPolicy] = useState(location?.state?.policy);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);


    const NoClick = () =>{
        var {enquiryId} = JSON.parse(localStorage.getItem('enquiryId'));
        dispatch(motorEnquiryAPI({enquiryId:enquiryId,isPreviousPolicy:"No"})).then((res)=>{
            navigate('/car-insurance/main?enquiryId='+enquiryId)
        })
    };

    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent 
                        title='Type of Policy'
                        step={2}
                        totalSteps={2}
                        noSearch={true}
                    />

                    <InputContainer>
                        <CloseBtn src={CloseImg} onClick={()=>navigate(-1)}/>

                        <p>Do you have a previous policy?</p>

                        <Btns onClick={() => navigate('/policy-details/prev-insurance')}>Yes</Btns>
                        <Btns onClick={NoClick}>No</Btns>

                    </InputContainer>

                </Layout>
            </MainContainer>
        </>
    )
}