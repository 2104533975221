import styled from "styled-components";

export const PaginationContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 1rem auto 3rem auto;
`
export const PrevIcon = styled.img`
    margin:auto .5rem;
`
export const NextIcon = styled.img`
    margin:auto .5rem;
    cursor: pointer;
`
export const PageCircle = styled.div`
    width: 30px;
    height: 30px;
    background-color: #FF4800;
    color: #fff;
    border-radius:100px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin: auto .2rem;
`
export const InactivePage = styled.div`
    width: 30px;
    height: 30px;
    border: 1px solid #FF4800;
    color: #FF4800;
    border-radius:100px;
    display:flex;
    align-items:center;
    justify-content:center;
    margin: auto .2rem;
    cursor: pointer;
`
export const Dot = styled.div`
    width: 4px;
    height: 4px;
    background-color: #FF4800;
    border-radius: 20px;
    opacity: .2;
    margin: auto .08rem 0 .08rem;
`