import { useState } from "react";
import { MainContainer } from "../../../../assets/common-styles/common-styles"
import { CardContainer, InputContainer, SelectDropdown, Button } from "./styles";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { axiosPOSTCall1 } from "../../../../utilities/utils/apiPost";
import { useNavigate, useLocation} from "react-router-dom"

export const CarPayment5 = () => {

  const [poitype, setPOIType] = useState("");  
  const [poaType, setPOAType] = useState("");
  const [poiImage, setPOIImage] = useState("");
  const [poaImage, setPOAImage] = useState("");
  const combinedReduxInsure = useSelector((state) => state.carsRedux.combinedRedux);
  const navigate = useNavigate();
  const { state } = useLocation();
   console.log("combinedReduxInsure is ", combinedReduxInsure);
  const carsRedux = useSelector((state) => state.carsRedux);

  const convertToBase64POI = (e) => {
    const reader = new FileReader()

    reader.readAsDataURL(e.target.files[0]);
    var base64 = ""
    reader.onload = () => {
      console.log('called: ', reader)
      setPOIImage(reader.result)
    }
    return base64
  }

  const convertToBase64POA = (e) => {
    const reader = new FileReader()

    reader.readAsDataURL(e.target.files[0]);
    var base64 = ""
    reader.onload = () => {
      console.log('called: ', reader)
      setPOAImage(reader.result)
    }
    
  }


  const handleSubmit = async () => {
    const userData = JSON.parse(localStorage.getItem('userDetails'))
    console.log("userData ", userData.phone);
    const obj = {
        phone: combinedReduxInsure?.ownerDetails?.mobileNo ? combinedReduxInsure?.ownerDetails?.mobileNo : userData.phone,
        email: combinedReduxInsure?.ownerDetails?.email ? combinedReduxInsure?.ownerDetails?.email : userData.email,
        type1: poitype,
        type2: poaType,
        base64Poa: poaImage.replace("data:image/jpeg;base64,", ""),
        base64Poi: poiImage.replace("data:image/jpeg;base64,", ""),
        enquiryId: combinedReduxInsure?.enquiryId
    }
    console.log("obj are", obj);
    axiosPOSTCall1('/vehicleInsurance/motor/validateKyc/motor-icici', obj, (callBack => {
        if(callBack && callBack.status == "success") {
            navigate('/car-insurance/payment/4',{ state:state})
        }
     })) 
  }
    return (
        <>
            <MainContainer>
                <CardContainer>
                <div className="flex align-center j-center column heading">Upload E-KYC</div>
                <div className="border margin">
                    <div className="flex align-center j-center column">
                    <p className="subHeading"> Acceptable types for Proof of Identity are:</p>
                   <InputContainer>
                        <SelectDropdown onChange={(e) => {
                                    setPOIType(e.target.value == 'Driving License' ? "DL" : e.target.value);
                                }}>
                                    <option value={-1} disabled selected>{'Please select any ID'}</option>
                                    {
                                        ['PAN', 'AADHAAR', 'VOTERID', 'Driving License', 'PASSPORT'].map(item => (
                                            <>
                                                
                                            {
                                                poitype == item ?
                                                <option selected value={item}>{item}</option>
                                                :
                                                <option value={item}>{item}</option>
                                            }
                                            </>
                                        ))
                                    }
                                </SelectDropdown>
                        </InputContainer>
                        <input type="file" className="inputBox" onChange={convertToBase64POI}/>
                    </div>
                </div>
                <div className="border">
                    <div className="flex align-center j-center column">
                    <p className="subHeading"> Acceptable types for Proof of Adress are:</p>
                    <InputContainer>
                        <SelectDropdown onChange={(e) => {
                                    setPOAType(e.target.value == 'Driving License' ? "DL" : e.target.value);
                                    // setError({});
                                }}>
                                    <option value={-1} disabled selected>{'Please select any ID'}</option>
                                    {
                                        ['AADHAAR', 'VOTERID', 'Driving License', 'PASSPORT'].map(item => (
                                            <>
                                                
                                            {
                                                poaType == item ?
                                                <option selected value={item}>{item}</option>
                                                :
                                                <option value={item}>{item}</option>
                                            }
                                            </>
                                        ))
                                    }
                                </SelectDropdown>
                        </InputContainer>
                        <input type="file" className="inputBox" onChange={convertToBase64POA}/>
                    </div>
                </div>

                </CardContainer>

                <Button onClick={handleSubmit}>Submit</Button>

            </MainContainer>
        </>
    )
}