import { GoArrowUp } from "react-icons/go";
import { GoArrowDown } from "react-icons/go";
import { PiEquals } from 'react-icons/pi';
import './track-card.css'

export const TrackerCard = ({ title, month, fYear, monthPercent, fyPercent, icon, profit, miniTitle, viewDetails }) => {

    const formattedPercent = parseFloat(fyPercent);
    const formattedMonthPercent = parseFloat(monthPercent);

const getStyleClass = (value) => {
    if (Object.is(value, -0) || value === 0) {
        return 'equal';
    }
    return value >= 0 ? 'positive' : 'negative';
};

  
    return (
        <div className="tracker-card">
            <div className="flex align-center">
                <img
                    src={icon}
                    className='tracker-icon'
                    alt="icon"
                />
                {title ?
                    <p className="card-title">{title}</p>
                    : null
                }
            </div>
            <p className="mini-title">{miniTitle}</p>

            <div className="flex apart mt-half">
            <p className="sub-head">Total</p>
                {/* <p className="sub-head">This Month</p>
                <p className="sub-head">Fiscal Year</p> */}
            </div>

            <div className="flex apart">
                <p className="data">{month}</p>
                {/* <p className="data">{fYear}</p> */}
            </div>

            {/* <div className="flex apart">
                <div className="flex items-center">
                    {!isNaN(formattedMonthPercent) && (
                        <>
                            {formattedMonthPercent > 0 ? (
                                <GoArrowUp className="arrow-icon arrow-up" size={16} />
                            ) : formattedMonthPercent < 0 ? (
                                <GoArrowDown className="arrow-icon arrow-down" size={16} />
                            ) : null}
                            <p className={`percent ${getStyleClass(formattedMonthPercent)}`}>
                                {monthPercent}
                            </p>
                        </>
                    )}
                </div> */}
           {/* <div className="flex items-center">
                {!isNaN(formattedPercent) && (
                    <>
                        {formattedPercent > 0 ? (
                            <GoArrowUp className="arrow-icon arrow-up" size={16} />
                        ) : formattedPercent < 0 ? (
                            <GoArrowDown className="arrow-icon arrow-down" size={16} />
                        ) : null}
                        <p className={`percent ${profit ? `profit-txt` : ''} ${getStyleClass(formattedPercent)}`}>
                            {fyPercent}
                        </p>
                    </>
                )}
            </div> */}
            {/* </div> */}
            {/* {viewDetails ? <p className="view-details">View Details</p> : null} */}
        </div>
    )
}