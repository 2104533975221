import Input from "antd/lib/input";
import styled from "styled-components";

export const InputContainer = styled.div`
    position: relative;
    padding: .6rem;
    margin: 1rem auto;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    input {
        width: 100%;
         ${'' /* padding-top: .2rem; */}
         ${'' /* padding-bottom: .2rem; */}
        outline: none;
        border: none;
        font-size: 1rem;
        margin-left: .2rem;
        background:none;
        margin-top:.3rem;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .m-0 {
        margin-top: 0;
        h4 {
            margin: auto;
        }
    }
    .m-0-0 {
        margin: 0;
    }

    .horizontal {
        display: flex;
        align-items: center;
        input {
            width: auto;
            accent-color: #F6931E;
        }
        label {
            margin-left: .5rem;
        }

        .apart+.apart {
            margin-left: 1rem;
        }

    }
    
`

export const FloatingTitle = styled.p`
    position: absolute;
    top: -8px;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #fff;
    font-weight: 800;
`
export const SelectDropdown = styled.select`
    width: 100%;
    padding-top: .35rem;
    padding-bottom: .35rem;
    // outline: dotted;
    // padding: 0.3rem;
    // border-radius: .6rem;
    font-size: 1rem;
    // border: 1px solid #B7B7B7;
    // text-shadow: 0 0 0 #000;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
`
// export const SelectInput=styled.input`
//  width: 100%;
//     padding-top: .35rem;
//     padding-bottom: .35rem;
   
   
//     font-size: 1rem;
   
//     border: none;
//     background-color: transparent;
//     resize: none;
//     outline: none;
//  `
// export const SelectSumAssured=styled.datalist`
// width: 100%;
//     padding-top: .35rem;
//     padding-bottom: .35rem;
//     // outline: dotted;
//     // padding: 0.3rem;
//     // border-radius: .6rem;
//     font-size: 1rem;
//     // border: 1px solid #B7B7B7;
//     // text-shadow: 0 0 0 #000;
//     border: none;
//     background-color: transparent;
//     resize: none;
//     outline: none;
// `
// export const ErrorContainer = styled.div`
//     position: absolute;
//     display: flex;
//     flex-direction: column;
//     // left: 33rem;
//     right: 1.9rem;
//     // top: 0.2rem;
//     // top: -28px;
//     bottom: 1rem;
//     z-index:1;
//     // font-size: .8rem;
//     align-items: flex-end;

// `
// export const ErrorMsgContainer = styled.div`
//     padding: .5rem 1rem;
//     /* background: linear-gradient(to left, #ED4168, #FFB25A); */
//     background-color: #F6931E;
//     border-radius: .6rem;
//     // border-bottom-right-radius: 0;
//     color: #fff;
//     font-weight: 900;
// `
// export const ErrArrow = styled.div`
//     width: 0; 
//     height: 0; 
//     border-top: 10px solid #F6931E; 
//     border-left: 10px solid transparent;
//     margin-right: .5rem;
// `

// export const ErrorIcons = styled.img`
//     width: 20px;
//     margin-top: .4rem;
// `