// HealthInsuranceTable.js
import React from 'react';
import './table.css';

const HealthInsuranceTable = ({ brandPageData, selectedInsurance }) => {
  return (
    <div>
     <table className="insurance-table">
  <thead>
    <tr>
      <th>Category</th>
      <th>Insurance</th>
      <th>Entry Age</th>
      <th>Maximum Maturity Age</th>
      <th>Policy Term</th>
      <th>Starting From</th>
      <th>Application</th>
    </tr>
  </thead>
  <tbody>
    {selectedInsurance === 'health' && brandPageData?.attributes?.healthInsuranceTableCategories && brandPageData?.attributes?.healthInsuranceTableCategories?.map(category => (
     category?.policies && category?.policies.map(policy => (
        <tr key={policy?.id}>
          <td>{category?.name || "-"}</td>
          <td>{policy?.name}</td>
          <td>{policy?.entryAge || "-"}</td>
          <td>{policy?.maxMaturityAge ||"-"}</td>
          <td>{policy?.policyTerm || "-"}</td>
          <td>{policy?.startingFrom || "-"}</td>
          <td>
            <button className="buy-now-button">Buy Now</button>
          </td>
        </tr>
      ))
    ))}
    {selectedInsurance === 'life' && brandPageData?.attributes?.lifeInsuranceTableCategories && brandPageData?.attributes?.lifeInsuranceTableCategories.map(category => (
     category?.policies && category?.policies?.map(policy => (
        <tr key={policy.id}>
      <td>{category?.name || "-"}</td>
          <td>{policy?.name}</td>
          <td>{policy?.entryAge || "-"}</td>
          <td>{policy?.maxMaturityAge ||"-"}</td>
          <td>{policy?.policyTerm || "-"}</td>
          <td>{policy?.startingFrom || "-"}</td>
          <td>
            <button className="buy-now-button">Buy Now</button>
          </td>
        </tr>
      ))
    ))}
    {selectedInsurance === 'motor' && brandPageData?.attributes?.motorInsuranceTableCategories && brandPageData?.attributes?.motorInsuranceTableCategories?.map(category => (
     category?.policies && category?.policies.map(policy => (
        <tr key={policy.id}>
          <td>{category?.name || "-"}</td>
          <td>{policy?.name}</td>
          <td>{policy?.entryAge || "-"}</td>
          <td>{policy?.maxMaturityAge ||"-"}</td>
          <td>{policy?.policyTerm || "-"}</td>
          <td>{policy?.startingFrom || "-"}</td>
          <td>
            <button className="buy-now-button">Buy Now</button>
          </td>
        </tr>
      ))
    ))}
    {selectedInsurance === 'bike' && brandPageData?.attributes?.businessInsuranceTableCategories && brandPageData?.attributes?.businessInsuranceTableCategories?.map(category => (
     category?.policies && category?.policies.map(policy => (
        <tr key={policy?.id}>
          <td>{category?.name || ""}</td>
          <td>{policy?.name}</td>
          <td>{policy?.entryAge || "-"}</td>
          <td>{policy?.maxMaturityAge ||"-"}</td>
          <td>{policy?.policyTerm || "-"}</td>
          <td>{policy?.startingFrom || "-"}</td>
          <td>
            <button className="buy-now-button">Buy Now</button>
          </td>
        </tr>
      ))
    ))}
  </tbody>
</table>

    </div>
  );
};

export default HealthInsuranceTable;
