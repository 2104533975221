import { useEffect, useState } from "react"
import { Layout } from "antd"
import { MainContainer, styles } from "../../../assets/common-styles/common-styles"
import EditIcon from '../../../assets/brand-new-car/pencil.svg'
import { ContinueBtn, EditBtn, HDiv, Row, RowAndDivider, SlotContainer, Terms, ContinueBtnDisable } from "./styles"
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from 'moment';
import { v4 as uuid } from 'uuid';
import { updateCombinedModel, motorEnquiryAPI, getCarInfo } from "../../../Redux/CarsRedux";
import { resetRedux } from '../../../Redux/InsuranceRedux';
import { resetRedux as resetReduxes } from '../../../Redux/CarsRedux';
import { isEmpty } from "../../../utilities/helper"
import NewPopup from "./NewPopup"

export const CarFormPreview = () => {
    const dispatch = useDispatch();
    const { state } = useLocation();
    const carStates = useSelector((states) => state?.fromForm ? states.carsRedux.combinedRedux : states.insurance.insurance);
    const carStates1 = useSelector((states) => states.carsRedux.combinedRedux);
    const [checkBox, setCheckBox] = useState(false);
    const [popupOpen, setPopupOpen] = useState(false);
    const navigate = useNavigate();
    const [data, setData] = useState({});

    useEffect(() => {
        var carInsuranceDetails = (localStorage.getItem('canInsuranceDetails'));
        if (carInsuranceDetails) {
            setData(JSON.parse(carInsuranceDetails))
            createUUID();
        }
        else {
            dispatch(resetRedux({}))
            dispatch(resetReduxes({}))
            setTimeout(() => {
                navigate('/');
            }, 1);
            return
        }
        if (!state?.fromForm) {
            if (carStates.success == false) {
                navigate('/')
                return
            }
        }
    }, [popupOpen])

    const changePopup = (value) => {
        setPopupOpen(value);
    };

    useEffect(() => {

        if (Object.keys(data).length && !data.brand) {
            setPopupOpen(true)
        }
    }, [data])

    useEffect(() => {
        if (!isEmpty(data)) {

            let date1 = moment();
            let date2 = moment(data.regDate);
            var diff = moment(date1).diff(date2, 'days');
            var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));


            setTimeout(() => {
                if (diff > 30) {
                    dispatch(motorEnquiryAPI({ enquiryId: enquiryId, vehicleIDV: "0", vehicleType: "individual", type: "old", addOns: [], ncb: 0 }));
                }
                else {
                    dispatch(motorEnquiryAPI({ enquiryId: enquiryId, vehicleIDV: "0", vehicleType: "individual", type: "new", addOns: [], ncb: 0 }));
                }
            }, 1000);

        }
    }, [data])


    const createUUID = () => {
        var canInsuranceDetails = (localStorage.getItem('canInsuranceDetails'));

        canInsuranceDetails = JSON.parse(canInsuranceDetails);
        if (canInsuranceDetails.number) {
            canInsuranceDetails = removeKey('_id', canInsuranceDetails)
            let enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
            if (enquiryId) {
                if (enquiryId.number != carStates.number) {
                    let uuidd = uuid();
                    dispatch(updateCombinedModel({ enquiryId: uuidd }));
                    localStorage.setItem('enquiryId', JSON.stringify({ enquiryId: uuidd, number: carStates.number }));
                    canInsuranceDetails = Object.assign(canInsuranceDetails, { enquiryId: uuidd });
                }
                else {
                    dispatch(updateCombinedModel({ enquiryId: enquiryId.enquiryId }));
                    canInsuranceDetails = Object.assign(canInsuranceDetails, { enquiryId: enquiryId.enquiryId });
                }
            }
            else {
                let uuidd = uuid();
                dispatch(updateCombinedModel({ enquiryId: uuidd }));
                localStorage.setItem('enquiryId', JSON.stringify({ enquiryId: uuidd, number: carStates.number }));
                let obj = { enquiryId: uuidd, number: carStates.number }
                canInsuranceDetails = Object.assign(canInsuranceDetails, obj);
            }
        }
        else {
            canInsuranceDetails = removeKey('_id', canInsuranceDetails)
                let uuidd = uuid();
                dispatch(updateCombinedModel({ enquiryId: uuidd }));
                localStorage.setItem('enquiryId', JSON.stringify({ enquiryId: uuidd, number: '' }));
                let obj = { enquiryId: uuidd, number: '' }
                canInsuranceDetails = Object.assign(canInsuranceDetails, obj);
           
        }
        dispatch(motorEnquiryAPI(canInsuranceDetails));
    }


    const removeKey = (key, object) => {
        const { [key]: gone, ...rest } = object;
        return rest;
    };


    const continueFun = () => {
        navigate('/policy-details/type-of-policy')
    }

    const editFun = (form) => {
        if (form == 1) {
            navigate('/brand-new-model/brand-model')
        }
        else if (form == 2) {
            navigate('/brand-new-model/select-model')
        }
        else if (form == 3) {
            navigate('/brand-new-model/car-details/2')
        }
        else if (form == 4) {
            navigate('/brand-new-model/car-details/4')
        }
        else if (form == 5) {
            navigate('/brand-new-model/car-details/3')
        }
        else if (form == 6) {
            navigate('/brand-new-model/car-details/1')
        }
        else if (form == 7) {
            navigate('/brand-new-model/car-details/5')
        }
        else if (form == 8) {
            navigate('/car/car-insurance', { state: { item: data.carNo } })
        }

    }

    return (
        <>
            <MainContainer>
                {popupOpen ? <NewPopup someData={data} changePopupState={changePopup} /> : null}
                <Layout style={styles.layout}>
                    <h4 style={{ fontWeight: '700' }}>Preview Policy Details</h4>

                    <SlotContainer>
                        <RowAndDivider>
                            <h5>Registration No.</h5>
                            <HDiv>
                                <p>{data.hasOwnProperty('carNo') ? data.carNo : data.number ? data.number : 'N/A'}</p>
                                {/* <p>{carStates.number?carStates.number:'N/A'}</p> */}
                                <EditBtn onClick={() => editFun(8)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>


                        <RowAndDivider>
                            <h5>Brand</h5>
                            <HDiv>
                                <p>{data.brand}</p>
                                <EditBtn onClick={() => editFun(1)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>Model</h5>
                            <HDiv>
                                <p>{data.model}</p>
                                <EditBtn onClick={() => editFun(2)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>Variant</h5>
                            <HDiv>
                                <p>{data.modelVariant}</p>
                                <EditBtn onClick={() => editFun(4)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <Row>
                            <h5>Fuel Type</h5>
                            <HDiv>
                                <p>{data.fuelType}</p>
                                <EditBtn onClick={() => editFun(3)} src={EditIcon} />
                            </HDiv>
                        </Row>

                    </SlotContainer>

                    <SlotContainer>
                        <RowAndDivider>
                            <h5>Cubic Capacity</h5>
                            <HDiv>
                                <p>{data.cubicCap}</p>
                                <EditBtn onClick={() => editFun(5)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>Colour</h5>
                            <HDiv>
                                <p>{data.color}</p>
                                <EditBtn onClick={() => editFun(6)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>No. of Seats</h5>
                            <HDiv>
                                <p>{data.noOfSeats}</p>
                                {/* {state?.fromForm&&<EditBtn src={EditIcon} />} */}
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>City</h5>
                            <HDiv>
                                <p>{data.city}</p>
                                <EditBtn onClick={() => editFun(7)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <Row>
                            <h5>RTO</h5>
                            <HDiv>
                                <p>{data.rto}</p>
                                <EditBtn src={EditIcon} onClick={() => editFun(7)} />
                            </HDiv>
                        </Row>

                    </SlotContainer>

                    <SlotContainer>
                        <RowAndDivider>
                            <h5>Chassis Number</h5>
                            <HDiv>
                                <p>{data.chasisNo}</p>
                                <EditBtn onClick={() => editFun(6)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>Engine Number</h5>
                            <HDiv>
                                <p>{data.engineNo}</p>
                                <EditBtn onClick={() => editFun(6)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>Manufacture Year</h5>
                            <HDiv>
                                <p>{moment(data.mfgYear).format('DD/MM/YYYY')}</p>
                                <EditBtn onClick={() => editFun(3)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>

                        <RowAndDivider>
                            <h5>Date of Registration</h5>
                            <HDiv>
                                <p>{moment(data.regDate).format('DD/MM/YYYY')}</p>
                                <EditBtn onClick={() => editFun(3)} src={EditIcon} />
                            </HDiv>
                        </RowAndDivider>


                        <Row>
                            <h5>Insurance Expiry Date</h5>
                            <HDiv>
                                {data?.insurance?.insurancePolicyExpiryDate ? <p>{moment(data.insurance.insurancePolicyExpiryDate).format('DD/MM/YYYY')}</p> : 'N/A'}
                                {/* {data?.insurance?.insurancePolicyExpiryDate&&<EditBtn src={EditIcon} />} */}
                            </HDiv>
                        </Row>


                    </SlotContainer>


                    <HDiv style={{ marginTop: '1rem' }}>

                        <input
                            id='term'
                            type={'checkbox'}
                            onChange={e => setCheckBox(!checkBox)}
                            checked={checkBox}
                        />
                        <Terms for='term'>By clicking on the checkbox, you agree the above details are correct</Terms>
                    </HDiv>

                    {
                        checkBox ?
                            <ContinueBtn onClick={() => {
                                continueFun()
                            }}>
                                Continue
                            </ContinueBtn>
                            :
                            <ContinueBtnDisable>
                                Continue
                            </ContinueBtnDisable>
                    }


                </Layout>
            </MainContainer>
        </>
    )
}