    import ErrorIconImg from "../../../assets/brand-new-car/exclamation.svg"
    import "./skeleton.css"
    
    export const ErrorPop = (error) => {
    return (
        <div className='error-container'>
            <div className="error-msg-container">{error.error}</div>
            <div className="errArrow" />
            <img src={ErrorIconImg} className="error-icon" alt="error-img" />
        </div>
    )
}


