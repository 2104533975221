import React, {useEffect} from 'react';
import './sitemap.css';
import Footer from '../../../components/Footer/FooterNew';
import banner from '../../../assets/banner/1.png'
import Quick from '../../../components/new_quick_links/quick';
import BlogCard from '../../../components/blog-card/blog';

const Sitemap = () => {
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <div>
    <img src={banner} className='image'/>
    {/* Quick Links */}
    <Quick heading = "Sitemap" />
    <div className="sitemap-container">
    <div className='partOne'>
      <div className="section">
        <h2 className='sitemap_heading'>COMPANY</h2>
        <ul>
          <li>About Simpliinsure.com</li>
          <li>Our Partners</li>
          <li>Products</li>
          {/* <li>Media Corner</li> */}
          <li>Work With Us</li>
          <li>Contact Us</li>
        </ul>
      </div>
      <div className="section">
        <h2 className='sitemap_heading'>KNOWLEDGE CENTER</h2>
        <ul>
          <li>Learn</li>
          <li>Newsletter</li>
          <li>Top Insurance Policies</li>
          <li>Careers</li>
          <li>FAQs</li>
        </ul>
      </div>
      <div className="section">
        <h2 className='sitemap_heading'>QUICK LINKS</h2>
        <ul>
          <li>Testimonials</li>
          <li>Customer Feedback</li>
          <li>Grievances</li>
          <li>Privacy Policy</li>
          <li>Terms & Conditions</li>
          <li>Sitemap</li>
        </ul>
      </div>

      </div>

    <div className='partTwo'>
          <div className="section">
        <h2 className='sitemap_heading'>PRODUCTS</h2>
        <div className="product-category">
          <h3>INDIVIDUAL</h3>
          <ul>
            <li>Health Insurance</li>
            <li>Individual Health Insurance</li>
            <li>Family Floater Health Insurance</li>
            <li>Life Insurance</li>
            <li>Term Life Insurance</li>
            <li>Life Investment-Related Cover</li>
            <li>Motor Insurance</li>
            <li>Bike Insurance Policy</li>
            <li>Car Insurance Policy</li>
            <li>Property Insurance</li>
            <li>Home Insurance</li>
          </ul>
        </div>
          <div className="product-category">
          <h3>CORPORATE</h3>
            <h3>Employee and Group Welfare</h3>
          <ul>
            <li>Group Term Insurance</li>
            <li>Mediclaim Policy</li>
            <li>Group Mediclaim Policy (GMC)</li>
            <li>Group Personal Accident Insurance (GPA)</li>
            <li>Workers Compensation Insurance</li>
            <li>Directors and Officers Liability Insurance</li>
            <li>Product Liability Insurance</li>
            <li>Commercial General Liability Insurance</li>
            <li>Professional Indemnity Insurance</li>
            <li>Public Liability Insurance</li>
            <li>Small Industries Insurance</li>
          </ul>
        </div>
    </div>

        <div className="product-category">
          <h3>Data and Financial Security Insurance</h3>
          <ul>
            <li>Cyber Security Insurance</li>
            <li>Bankers Indemnity Insurance</li>
            <li>Fidelity Guarantee Insurance (F.G.I)</li>
            <li>Money Insurance Policy</li>
          </ul>
        </div>

        <div className="product-category">
          <h3>Asset Insurance</h3>
          <ul>
            <li>Burglary Insurance</li>
            <li>Standard Fire and Special Perils Insurance</li>
            <li>Marine Cargo Insurance</li>
            <li>Contractors' All Risks Insurance (C.A.R)</li>
            <li>Engineering All-Risk Insurance (E.A.R)</li>
            <li>Machinery Breakdown Insurance</li>
            <li>Electronic Equipment Insurance</li>
            <li>Contractor's Plant & Machinery Insurance</li>
          </ul>
        </div>
        </div>

        <div className='partThree'>
          <div className="section">
        <h2 className='sitemap_heading'>INSURANCE COMPANIES - PARTNERS</h2>
        <ul>
          <li>Royal Sundaram</li>
          <li>SBI General Insurance</li>
          <li>Cholamandalam MS General Insurance</li>
          <li>Future Generali India Insurance Company</li>
          <li>Raheja QBE</li>
          <li>Shriram General Insurance</li>
          <li>Liberty General Insurance</li>
          <li>Edelweiss Insurance</li>
          <li>Universal Sompo Insurance</li>
          <li>Magma</li>
          <li>Max Bupa Health Insurance</li>
          <li>HDFC ERGO General Insurance</li>
          <li>The New India Assurance</li>
          <li>United India Insurance</li>
          <li>Care Health Insurance</li>
          <li>Tata AIG General Insurance</li>
          <li>Go Digit</li>
          <li>Kotak Mahindra General Insurance</li>
          <li>ICICI Lombard</li>
          <li>Bajaj Allianz General Insurance</li>
          <li>Cigna Health Insurance</li>
          <li>IFFCO Tokio General Insurance</li>
          <li>Oriental Insurance</li>
          <li>Star Health</li>
          <li>Reliance Insurance</li>
          <li>Aditya Birla Health Insurance Co. Limited (ABHICL)</li>
        </ul>
      </div>

      <div className="section">
        <h2 className='sitemap_heading'>BEST BUY</h2>
        <ul>
          <li>Insurance Buying Guide</li>
          <li>Best Individual Health Insurance Policies</li>
          <li>Best Individual Life Insurance Policies</li>
          <li>Best Individual Bike Insurance Policies</li>
          <li>Best Individual Car Insurance Policies</li>
          <li>Best Corporate Insurance Policies</li>
        </ul>
      </div>
      </div>
    </div>
    {/* for blog section */}
    <div className='blog_section'>
    <h2 className='learnMore'>LEARN MORE</h2>
    <div className="blog_container">
    <BlogCard />
    </div>
    </div>
    <div style={{padding:"40px"}}>

    </div>
    <Footer />
    </div>
  );
};

export default Sitemap;

       
