  import './FAQ.css';
  
  // const faqData = [
  //   {
  //     question: 'What types of insurance does National Insurance Company offer?',
  //     answer: 'National Insurance Company offers various insurance types, including health insurance, motor insurance, home insurance, travel insurance, and commercial insurance for businesses.',
  //   },
  //   {
  //     question: 'How can I renew my insurance policy with National Insurance Company?',
  //     answer: 'Policy renewal with National Insurance Company can be done online through their website or by visiting their nearest branch office. You can renew by providing policy details and making the premium payment.',
  //   },
  //   {
  //     question: 'What is the claim settlement process for policies with National Insurance Company?',
  //     answer: 'The claim settlement process involves notifying the insurer about the claim, filling out the claim form, submitting necessary documents, and allowing the company to assess the claim. Once approved, the claim amount is disbursed.',
  //   },
  //   {
  //     question: 'Are there any specific requirements or documents needed to file a claim?',
  //     answer: 'Claim requirements typically include a filled claim form, policy details, incident details, bills, receipts, and any other relevant documents requested by the insurer.',
  //   },
  //   {
  //     question: 'Does National Insurance Company offer any discounts or bonuses for long-term policyholders?',
  //     answer: 'National Insurance Company may offer discounts or bonuses for long-term policyholders as part of their loyalty programs. Contact them directly for specific details.',
  //   },
  //   {
  //     question: 'Can I customize my insurance policy to suit my specific needs?',
  //     answer: 'National Insurance policies may offer some level of customization or add-on covers that can be tailored to your specific needs, providing additional coverage as required.',
  //   },
  //   {
  //     question: 'What is the process to avail cashless hospitalization facilities under National Insurance policies?',
  //     answer: 'National Insurance Company typically has tie-ups with network hospitals where you can avail cashless hospitalization by showing your insurance card. Check the list of network hospitals for details.',
  //   },
  //   {
  //     question: 'Does the company provide 24/7 customer support for emergencies or claim assistance?',
  //     answer: 'National Insurance Company usually offers round-the-clock customer support for emergencies and claim assistance through their helpline numbers or online platforms.',
  //   },
  //   {
  //     question: 'Are there any add-on covers available with National Insurance policies, and what do they include?',
  //     answer: 'National Insurance policies may offer add-on covers like zero depreciation for vehicle insurance, critical illness riders for health insurance, and more. Check their policy documents for available add-ons.',
  //   },
  //   {
  //     question: 'What is the procedure to cancel an insurance policy with National Insurance Company, and are there any penalties or charges?',
  //     answer: 'To cancel a policy, contact National Insurance Company directly. There might be cancellation charges or penalties depending on the policy terms and the duration for which it has been active.',
  //   },
  // ];

    const FAQ = ({faqData}) => {
     return (
    <div className="brand-faq-container">
    <h1 className='policy_benefits_heading'>FAQ</h1>
      {faqData && faqData?.map((faq, index) => (
        <div className="brand-faq-container" key={index}>
          <div className="brand-faq-question">{faq?.question || ""}</div>
          <div className="brand-faq-answer">{faq?.answer || ""}</div>
        </div>
      ))}
    </div>
  );
    };

export default FAQ;


