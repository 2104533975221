import React from 'react'
import { FcOk } from 'react-icons/fc'
import { BsFillRecordCircleFill } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import './Sidebar.css'
import { useState } from 'react'

const Sidebar = (props) => {
  const { items1, items2, items3, items4, items5 } = props
  // const { items1, setItem1 } = props
  // const { items2, setItems2 } = props
  // const { items3, setItems3 } = props
  // const { items4, setItems4 } = props
  // const { items5, setItems5 } = props
  return (
    <div className="side-div">
      <p>ADD POS</p>
      <div className='form'>
        <Link to="/partner"  >
          {
            items1 ?
              <BsFillRecordCircleFill className='icons1' />
              :
              <FcOk className='icons1' />

          }

          <h3>Address & Name</h3>
        </Link>
        <span></span>
        <Link to="/details"> {items2 ?
          <FcOk className='icons1' />
          :
          <BsFillRecordCircleFill className='icons1' />

        }
          <h3>Basic details</h3>
        </Link>
        <span></span>

        <Link to='/lang' > {items3 ?
          <FcOk className='icons1' />
          :
          <BsFillRecordCircleFill className='icons1' />
        }
        <h3>Language</h3>

        </Link>
        <span></span>

        <Link to="/sengment"> {items4 ?
          <FcOk className='icons1' />
          :
          <BsFillRecordCircleFill className='icons1' />
        }
        <h3>Segment</h3>

        </Link>
        <span></span>
        <Link to='/documents'
        > {items5 ?
          <FcOk className='icons1' />
          :
          <BsFillRecordCircleFill className='icons1' />
          }
          <h3>Upload Documents</h3>

        </Link>
      </div>



    </div>
  )
}

export default Sidebar