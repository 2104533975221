import React, {useEffect} from 'react';
import './brand.css';
// import BrandBenefits from './benifits-buy/benifits';
// import FAQ from './FAQ/FaqPage';
import { TfiHandPointRight } from "react-icons/tfi";
import Health from '../../components/insurancePolicy/insurancePolicy';
import InsuranceProcessCards from './process-details/process';


const BrandPage = ({ brandPageData }) => {

      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
  return (
    <div>
    {/* <Hero heading={'National Insurance'} image={healthImage} subHeading={'National insurance is a financial protection policy that provides a designated'} btn={true}/>
    <Quick heading = {'National Insurance'} /> */}
    <div className='brand-container'>
      <div className='health-part-one'>
        <div className='health-column'>
      <h2 className='brand-header-heading'>{brandPageData?.attributes?.title || ""}</h2>
        <p className='brand-header-para'>{brandPageData?.attributes?.subTitle || ""}</p>
          <p style={{ fontSize: "14px", maxWidth: "100%" }}>
          {brandPageData?.attributes?.details || ""}
        </p>

        </div>
        <div className='brand-sec-two'>
        <div className='brand_sec_two_card'>
          <p className='sec_two_para_1'><TfiHandPointRight /> Holistic Coverage Solutions</p>
          <p className='sec_two_para_1'><TfiHandPointRight /> Exclusive Online Discount</p>
          <p className='sec_two_para_1'><TfiHandPointRight /> Expert Guidance by Certified Professionals</p>
          <p className='sec_two_para_1'><TfiHandPointRight /> Family-Focused Claims Support</p>
          <p className='sec_two_para_1'><TfiHandPointRight /> Secure Communication</p>
        </div>
        </div>
      </div>

      <div className='brand-part-two'>
          <Health brandPageData = { brandPageData } />         
      </div>

      {/* <div className='brand-part-four'>
      <p>BENEFITS</p>
      <BrandBenefits />
      </div> */}

      {/* <div className='health-part-five'>
      <p>FAQ</p>
      <FAQ />
      </div> */}

      <div className='health-part-six'>
      <p>PROCESS</p>
      <InsuranceProcessCards />
      </div>

    </div>
        {/* <div className='blog_section'>
    <h2 className='learnMore'>LEARN MORE</h2>
    <div className="blog_container">
    <BlogCard image = {'https://placehold.co/600x400.png'} category={'All texts'} heading = {'All texts'} subheading = {'All texts in white background area H2 Headings Black'} />
    <BlogCard image = {'https://placehold.co/600x400.png'} category={'All texts'} heading = {'All texts'} subheading = {'All texts in white background area H2 Headings Black'} />
    <BlogCard image = {'https://placehold.co/600x400.png'} category={'All texts'} heading = {'All texts'} subheading = {'All texts in white background area H2 Headings Black'} />
    <BlogCard image = {'https://placehold.co/600x400.png'} category={'All texts'} heading = {'All texts'} subheading = {'All texts in white background area H2 Headings Black'} />
    </div>
    </div>
    <div style={{padding:"40px"}}>

    </div>
    <FooterNew /> */}
    </div>
  );
};

export default BrandPage;
