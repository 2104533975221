import styled from "styled-components";

export const MainContainer = styled.div`
    height: 100vh;
    overflow-y: hidden;
`
export const OpacityDiv = styled.div`
    display: flex;
    width: 100%;
    height: 110%;
    background-color: rgb(0,0,0);
    position: absolute;
    top: 0;
    z-index: 999;
    opacity: .8;
    `
export const OtpContainer = styled.div`
    padding: 2rem;
    background-color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: .5rem;

    p {
        color:#707070;
    }

    .heading {
        color:#000;
        font-weight: 800;
        margin-bottom: 1rem;
    }

`

export const VerifiedIcon = styled.img`

`
export const HDiv = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mr-1 {
        margin-right: 1rem;
    }
    .ml-1 {
        margin-left: 1rem;
    }

`
export const BottomContainer = styled.div`
    padding: 1rem;
    margin-top: 1rem;

    .gap-1 {
        margin:.5rem auto;
    }

`