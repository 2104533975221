const options = [
  { value: '1000000', label: '(10 lac)' },
  { value: '1500000', label: '(15 lac)' },
  { value: '2000000', label: '(20 lac)' },
  { value: '2500000', label: '(25 lac)' },
  { value: '3000000', label: '(30 lac)' },
  { value: '3500000', label: '(35 lac)' },
  { value: '4000000', label: '(40 lac)' },
  { value: '4500000', label: '(45 lac)' },
  { value: '5000000', label: '(50 lac)' },
  { value: '5500000', label: '(55 lac)' },
  { value: '6000000', label: '(60 lac)' },
  { value: '6500000', label: '(65 lac)' },
  { value: '7000000', label: '(70 lac)' },
  { value: '7500000', label: '(75 lac)' },
  { value: '8000000', label: '(80 lac)' },
  { value: '8500000', label: '(85 lac)' },
  { value: '9000000', label: '(90 lac)' },
  { value: '9500000', label: '(95 lac)' },
  { value: '10000000', label: '(1 Cr)' },
  { value: '15000000', label: '(1.5 Cr)' },
  { value: '20000000', label: '(2 Cr)' },
  { value: '25000000', label: '(2.5 Cr)' },
  { value: '30000000', label: '(3 Cr)' },
  { value: '35000000', label: '(3.5 Cr)' },
  { value: '40000000', label: '(4 Cr)' },
  { value: '45000000', label: '(4.5 Cr)' },
  { value: '50000000', label: '(5 Cr)' },
  { value: '55000000', label: '(5.5 Cr)' },
  { value: '60000000', label: '(6 Cr)' },
  { value: '65000000', label: '(6.5 Cr)' },
  { value: '70000000', label: '(7 Cr)' },
  { value: '75000000', label: '(7.5 Cr)' },
  { value: '80000000', label: '(8 Cr)' },
  { value: '85000000', label: '(8.5 Cr)' },
  { value: '90000000', label: '(9 Cr)' },
  { value: '95000000', label: '(9.5 Cr)' },
  { value: '100000000', label: '(10 Cr)' },
];

module.exports = options;