import axios from 'axios';

export const pincodeDataHandler = (e, setPincode, setState, setCityDropdown, setCity) => {
    setPincode(e);
    if (e.length === 6) {
        axios.get(`https://api.postalpincode.in/pincode/${e}`).then((res) => {
            if (res?.data[0]?.Status === 'Success') {
                const stateData = res?.data[0]?.PostOffice[0]?.State;
                const uniqueCities = res?.data[0]?.PostOffice?.map((item) => item?.Block);
                const city = [...new Set(uniqueCities)].filter((item) => item != 'NA');
                setState(stateData);
                setCity(city[0]);
                setCityDropdown(city);
            }
        });
    }
};
