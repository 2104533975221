import { Dot, InactivePage, MainContainer, NextIcon, PageCircle, PaginationContainer, PrevIcon } from "./styles"
import PrevImg from '../../assets/car-insurance/main-page/prev-inactive.svg'
import NextImg from '../../assets/car-insurance/main-page/next-dark.svg'
import { useEffect, useState } from "react";
export const Pagination = ({total, currentPage,setCurrentPage,pageSize,setPageSize,getData}) => {
    const [totalPages, setTotalPages] = useState(0);
    const [paginationDots, setPaginationDots] = useState(5);
    useEffect(() => {
        var totalPages1 = total/pageSize
        totalPages1 = Math.ceil(totalPages1)
        setTotalPages(totalPages1)
        // console.log("🚀 ~ file: Pagination.js ~ line 9 ~ useEffect ~ totalPages", totalPages)
    }, [total])
    const PageIndicator = ({pageNo, currentPage, onClick}) => {
        
        if(currentPage) {
            return(
                <PageCircle>{pageNo}</PageCircle>
            )
        } else {
            return(
                <InactivePage onClick={onClick}>{pageNo}</InactivePage>
            )
        }
    }

    const changePage = (page) => {
        setCurrentPage(page)
        getData(page);
    };

    useEffect(() => {

        if(currentPage > 1){
            
           
            // if(paginationDots > currentPage){
            //     setPaginationDots(paginationDots-6)
            // }
            if ((currentPage % 6) == 0){
                if(paginationDots > currentPage){
                    setPaginationDots(paginationDots-6)
                }
                else{
                    setPaginationDots(paginationDots+6)
                }
            }
            // else{
            //     if ((currentPage % 5) == 0){
            //         setPaginationDots(paginationDots-6)
            //     }
            // }
        }
        else{
            setPaginationDots(paginationDots)
            return
            if(paginationDots > currentPage){
                setPaginationDots(paginationDots-6)
            }
            if ((currentPage % 6) == 0){
                if(paginationDots > currentPage){
                    setPaginationDots(paginationDots-6)
                }
                else{
                    setPaginationDots(paginationDots+6)
                }
            }

        }
    }, [currentPage])
    

    return(
        <PaginationContainer>
            <PrevIcon src={PrevImg} onClick={()=>currentPage && currentPage > 1 && changePage(currentPage-1)}/>
            {(totalPages && totalPages > 0) ? [...Array(totalPages)].map((x, i) =>
                <>
                    {
                        i >= paginationDots-6  && i <= paginationDots
                        ? <PageIndicator pageNo={i+1} currentPage={currentPage == i+1} onClick={()=>
                            changePage(i+1)
                        }/>
                        :
                        i >= paginationDots+5 && i<= paginationDots+10 &&
                        <Dot />
                    }
                </>
            ): 
                <PageIndicator pageNo={1} currentPage={1}/>
            }
            {/* {
                currentPage != totalPages && 
            <PageIndicator pageNo={totalPages} currentPage={currentPage == totalPages} onClick={()=>
                changePage(totalPages)
            }/>
            } */}
{/*             
            <PageIndicator pageNo={2} onClick={()=>setCurrentPage(2)}/>
            <PageIndicator pageNo={3} />
            <PageIndicator pageNo={4} />
            <Dot />
            <Dot />
            <Dot />
            <Dot />
            <PageIndicator pageNo={12} /> */}
            <NextIcon src={NextImg} onClick={()=>currentPage < totalPages && changePage(currentPage+1)}/>
        </PaginationContainer>
    )
}