import styled from "styled-components";

export const CardContainer = styled.div`
    position: absolute;
    /* top: 400px; */
    margin-top: 4rem;
    z-index: 1;

    @media screen and (max-width: 900px) {
        width: 100%;
    }
    
`

export const CardMainDiv = styled.div`
    position: relative;
    width: 800px;
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #B7B7B7;
    background-color: #fff;
    .grid-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;

        @media screen and (max-width: 900px) {
            grid-template-columns: repeat(1,1fr);
        }
        
    }
    
    @media screen and (max-width: 900px) {
        margin: auto;
        width: 95%;
        margin-bottom: 2rem;
    }

`
export const CloseBtn = styled.img`
    position: absolute;
    top: 12px;
    right: 12px;
    cursor: pointer;
`
export const Title = styled.p`
    font-size: 1.1rem;
    font-weight: 800;
    text-align: center;
`

export const ContinueBtn = styled.button`
    background-color: #F6931E;
    padding: .6rem;
    width: 150px;
    border: none;
    border-radius: 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: auto;
    margin-top: 1rem;
`