const posData = [
  {
    "title": "🚀 Join Our Team as a Point of Sale (POS) Person!",
    "description": "Begin your journey with us by registering as a Point of Sale (POS) person. Fill out the form below to get started.",
    "stage": "NA"
  },
  {
    "title": "🔍 Pick up Where You Left Off!",
    "description": "You've started your registration process but haven't completed it yet. Continue from where you left off to become a valuable member of our POS team.",
    "stage": "draft"
  },
  {
    "title": "⏳ Hang Tight! Your Application is Under Review",
    "description": "Hang tight! Your application is under review. Thank you for your interest in joining us as a POS person. We'll notify you as soon as a decision is made.",
    "stage": "pending"
  },
  {
    "title": "❌ Unfortunately, Your Application Has Been Rejected",
    "description": "We regret to inform you that your application has been rejected. Feel free to contact us for further clarification or to discuss your application.",
    "stage": "rejected"
  },
  {
    "title": "🎉 Congratulations! You're Now a Part of Our POS Team!",
    "description": "Your application has been approved. Welcome aboard! Access your POS dashboard and start selling insurance products right away.",
    "stage": "approved"
  }
]

export default posData