import { LeftArrowIcon, RightArrowIcon, SearchInput } from "../../../components/car-details-component/styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent";
import { Layout, MainContainer, styles } from "./styles";
import Maruti from '../../../assets/brand-new-car/maruti.svg'
import Tesla from '../../../assets/brand-new-car/tesla.svg'
import Hyundai from '../../../assets/brand-new-car/hyundai.svg'
import Kia from '../../../assets/brand-new-car/kia.svg'
import { useNavigate } from "react-router-dom";
import { useEffect , useState } from 'react';
import { vehicleInsuranceList,SelectCarModalList,updateCombinedModel } from '../../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";

export const BrandModel = () => {

    const dispatch = useDispatch();
    const carsList = useSelector((state) => state.carsRedux);
    const [carsListState, setCarsListState] = useState([]);
    useEffect(() => {
        if(carsList.vehicleList.length > 0) {
            setCarsListState(carsList.vehicleList)
        }
    }, [carsList])
    
    useEffect(() => {
        dispatch(vehicleInsuranceList({}));
    }, [])
    
    
    function searchModal(e){
        var obj = {
            name:e.target.value
        };
        dispatch(vehicleInsuranceList(obj));
        // alert(e.target.value)
    }
    
    const navigate = useNavigate()

    const handleSelect = val => {
        dispatch(updateCombinedModel({brandId:val._id,brand:val.name}));
        setTimeout(() => {
            navigate('/brand-new-model/select-model')
        }, 1);
    }

    return (
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent step={1} data={carsListState} title='Select the brand' onSelect={handleSelect} searchModal={searchModal}/>
                </Layout>

            </MainContainer>
        </>
    )
}