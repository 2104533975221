import { BrandName, Button, BuyButton, CarCardContainer, CardContainer, CompanyLogo, Divider, Feature, HDiv, SelectedBtn } from "./styles"

export const QuoteCard = ({item, variant, handleBuy, selectedQuote}) => {

    if(variant==='car') {

        return(
            <CarCardContainer>
                <CompanyLogo alt='hi' src={`${item.icon}`} />
                <BrandName>{item.name}</BrandName>
                <HDiv className="btns">
                    <div className="btns">
                        <SelectedBtn>Max limit : {item.maxLimit}</SelectedBtn>
                        <Button>Premium Breakup</Button>
                    </div>
                </HDiv>
                <Divider />
                <p>Private Car Package Policy</p>
                <BuyButton className={selectedQuote?.id===item.id?'colored-btn':null} onClick={() => handleBuy(item.id)}>Buy Now - {item.price}</BuyButton>
    
            </CarCardContainer>
        )
    }
    else if(variant==='life') {
        return (
            <CardContainer>
                <CompanyLogo alt='hi' src={`${item.logo}`} />
                <BrandName>{item.companyName}</BrandName>
                <HDiv className="btns">
                    <div className="btns">
                        <SelectedBtn>Max limit : {item.maxLimit}</SelectedBtn>
                        <Button>Premium Breakup</Button>
                    </div>
                </HDiv>
                <Divider />
                <Feature>{item.features.a}</Feature>
                <Feature>{item.features.b}</Feature>
                <Feature>{item.features.c}</Feature>
    
                <BuyButton>Buy Now - {item.price}</BuyButton>
    
            </CardContainer>
        )
    }
}