import Config from "./Config";
import axios from 'axios';

const headers = {
  'Content-Type': 'application/json',
  'Strict-Transport-Security': 'max-age=31536000',
  'X-XSS-Protection': '1; mode=block',
  'X-Frame-Options': 'SAMEORIGIN',
  'X-Content-Type-Options': 'nosniff',
  'Cache-Control': 'max-age=2628000, public',
  'Referrer-Policy': 'no-referrer-when-downgrade',
  'Content-Security-Policy': 'upgrade-insecure-requests',
  'Permissions-Policy': 'geolocation=(); midi=(); notifications=();'
}

const axiosAPI = axios.create({
  baseURL: Config.apiBaseUrl,
  headers: {
    'Content-Type': 'application/json',

  }
});

export function apiPostCall(path, params) {
  let headers = {}
  return axiosAPI.post(path, params, { headers: headers })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        return error.message
      }
    });
}

export function apiPostCall1(path, params) {
  let headers = {}
  return axiosAPI.post(path, params, { headers: { 'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}` } })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        return error.message
      }
    });
}
export function apiPutCall(path, params) {
  let headers = {}
  return axiosAPI.put(path, params, { headers: { 'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}` } })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        return error.message
      }
    });
}
export function apiGETCall1(path, params) {
  let headers = {}
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => searchParams.append(key, params[key]));
  let newUrl = `${path}?` + searchParams.toString();
  console.log("newUrl are", localStorage.getItem('Authorization'));
  return axiosAPI.get(newUrl, { headers: { 'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}` } })
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response) {
        return error.response
      } else if (error.request) {
        return error.request
      } else {
        return error.message
      }
    });
}

export function apiGetCall(path, params) {
  let headers = {}
  const searchParams = new URLSearchParams();
  Object.keys(params).forEach(key => searchParams.append(key, params[key]));
  let newUrl = `${path}?` + searchParams.toString();
  // for (const key of Object.keys(params)) {
  //   if (params[key]) {
  //     newUrl += `${key}=${params[key]}`
  //   }
  // }
  return axiosAPI.get(newUrl)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.data) {
        return error.response
      } else if (error.request.data) {
        return error.request
      } else {
        alert('Network error, Please try again later.')
        return error.message
      }
    });
}

export function apiGetCallVariants(path, slug, params) {
  // console.log("🚀 ~ file: site-apis.jsx:108 ~ apiGetCallVariants ~ params:", params)
  let headers = {}
  const searchParams = new URLSearchParams({ ...params });
  // console.log("🚀 ~ file: site-apis.jsx:111 ~ apiGetCallVariants ~ searchParams:", searchParams)
  // Object.keys(params).forEach(key => searchParams.append(key, params[key]));
  let newUrl = `${path}/${slug}?` + searchParams;
  // for (const key of Object.keys(params)) {
  //   if (params[key]) {
  //     newUrl += `${key}=${params[key]}`
  //   }
  // }
  return axiosAPI.get(newUrl)
    .then((response) => {
      return response
    })
    .catch((error) => {
      if (error.response.data) {
        return error.response
      } else if (error.request.data) {
        return error.request
      } else {
        alert('Network error, Please try again later.')
        return error.message
      }
    });
}
