export const validationHandler = ({
    firstName,
    middleName,
    lastName,
    email,
    mobile,
    state,
    city,
    pincode,
    setError
}) => {

      // regex for validation
    const nameRegex = /^[A-Za-z]*$/;
    const emailRegex = /^[A-Za-z0-9]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
    const pincodeRegex = /^[0-9]{6}$/;
    const mobileRegex = /^[0-9]{10}$/;
    const cityRegex = /^[A-Za-z\s]+$/;




    if (!firstName || !nameRegex.test(firstName)) {
        setError({ error: 'Please enter a valid first name' });
        return 'Please enter a valid first name';
    }

    if (middleName && !nameRegex.test(middleName)) {
        setError({ error: 'Please enter a valid middle name' });
        return 'Please enter a valid middle name';
    }

    if (!lastName || !nameRegex.test(lastName)) {
        setError({ error: 'Please enter a valid last name' });
        return 'Please enter a valid last name';
    }

    if (!email || !emailRegex.test(email)) {
        setError({ error: 'Please enter a valid email' });
        return 'Please enter a valid email';
    }

    if (!mobile || !mobileRegex.test(mobile)) {
        setError({ error: 'Please enter a valid mobile number' });
        return 'Please enter a valid mobile number';
    }

    if (!state) {
        setError({ error: 'Please enter a valid state' });
        return 'Please enter a valid state';
    }

    if (!city || !cityRegex.test(city)) {
        setError({ error: 'Please enter a valid city' });
        return 'Please enter a valid city';
    }

    if (!pincode || !pincodeRegex.test(pincode)) {
        setError({ error: 'Please enter a valid pincode' });
        return 'Please enter a valid pincode';
    }

    if (!firstName || !lastName || !email || !mobile || !state || !city || !pincode) {
        setError({ error: 'Please fill all the fields' });
        return 'Please fill all the fields';
    }

    return null;
};
