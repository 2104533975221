import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './reset.css';

const SuccessPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const redirectTimeout = setTimeout(() => {
      navigate('/signin-signup?sourcePage=header');
    }, 3000);

    return () => clearTimeout(redirectTimeout); // Cleanup timeout on component unmount
  }, [navigate]);

  return (
    <div className="reset-app-container">
    <div className="reset-page-container">
      <img src="https://i.gifer.com/7efs.gif" alt="Success Icon" style={{ width: '250px'}} />
      <h2>Success</h2>
      <p>Your password has been reset successfully!</p>
      <p>Redirecting to login page...</p>
    </div>
    </div>

  );
};

export default SuccessPage;
