import React from 'react';
import { CiImageOn } from 'react-icons/ci';
import './Done.css';
import submtmittedGif from '../components/submitted.gif';
import { useNavigate } from 'react-router-dom';
import { useState, useEffect } from 'react';
// import SubmittedGif from '../components/submitted.gif'

const Done = () => {
  const userDetails = JSON.parse(localStorage.getItem('userDetails'));

  const navigate = useNavigate();
  const clickHandle = () => {
    navigate('/');
  };

  return (
    <>
      <div className="main8">
        <div className="Done">
          <div className="Done1">
            <img src={submtmittedGif} alt="submtmittedGif" />
          </div>
          <div className="Done2">
            <div>
              {userDetails?.roles[0] === 'admin' ? (
                <p>POS has been successfully added</p>
              ) : (
                <p>
                  Congratulations! You Have Successfully Submitted Your
                  Application
                </p>
              )}
            </div>
          </div>
          <div className="btn9" onClick={clickHandle}>
            <button>DONE</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Done;
