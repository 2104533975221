import Footer from "../../../../components/Footer/FooterNew"
import Header from "../../../../components/Header/Header.jsx"
import { BackIcon, CalculateBtn, CautionContainer, CautionIcon, CautionIconDiv, CautionText, FormContainer, FormLeft, FormMainContainer, FormRight, FormTitle, GradientBg, HDiv, HDivApart, InfoIcon, Input, InputSet, Line, MainContainer, MainForm, RadioBtn, RedText, Title } from "./styles"
import BackIconImg from '../../../../assets/commons/back-icon.png'
import InfoIconImg from '../../../../assets/form-2/i-icon.png'
import CautionIconImg from '../../../../assets/form-2/caution.png'
import { Link } from "react-router-dom"
import { useEffect } from "react"

export const Form2 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return(
        <>
            {/* <Header/> */}
                <GradientBg />
                <MainContainer>
                    <FormContainer>
                        <HDiv>
                            <BackIcon src={BackIconImg} />
                            <Title>Provide some more details to calculate premium</Title>
                            <Line />
                        </HDiv>
                        <MainForm>
                            <FormTitle>It shouldn’t take more than few minutes!</FormTitle>
                            <HDivApart>
                                <FormLeft>
                                    <InputSet>
                                        <p>What's your name?</p>
                                        <Input placeholder="Enter your name" />
                                    </InputSet>
                                    <InputSet>
                                        <p>What's your gender?</p>
                                        <HDiv>
                                            <RadioBtn type={'radio'} />Male
                                            <RadioBtn style={{marginLeft:10}} type={'radio'} />Female
                                        </HDiv>
                                    </InputSet>
                                    <InputSet>
                                        <p>Are you an NRI?</p>
                                        <HDiv>
                                            <RadioBtn type={'radio'} />Yes
                                            <RadioBtn style={{marginLeft:10}} type={'radio'} />No
                                        </HDiv>
                                    </InputSet>
                                    <InputSet>
                                        <p>What's your mobile number?</p>
                                        <Input placeholder="Enter mobile number" />
                                    </InputSet>
                                </FormLeft>

                                <FormRight>
                                    <InputSet>
                                        <p>What's your last name?</p>
                                        <Input placeholder="Enter your last name" />
                                    </InputSet>
                                    <InputSet className="dob">
                                        <p>What's your date of birth?</p>
                                        <Input type={'date'} />
                                        <InfoIcon className="i-btn" src={InfoIconImg} />
                                    </InputSet>
                                    <InputSet>
                                        <p>Do you consume tobacco?</p>
                                        <HDiv>
                                            <RadioBtn type={'radio'} />Yes
                                            <RadioBtn style={{marginLeft:10}} type={'radio'} />No
                                            <InfoIcon src={InfoIconImg} />
                                            {/* <CautionIcon src={CautionIconImg} /> */}
                                        </HDiv>
                                    </InputSet>
                                    <InputSet>
                                        <p>Add your email id</p>
                                        <Input type={'email'} placeholder="Enter your email id" />
                                    </InputSet>
                                </FormRight>
                            </HDivApart>
                        </MainForm>
                        <CautionContainer>
                            <HDiv>
                                <CautionIconDiv>
                                    <CautionIcon src={CautionIconImg} />
                                    <RedText>!</RedText>
                                </CautionIconDiv>
                                <CautionText>Your mobile number and email id are needed so that we can get in touch with you in case of any concerns. We do not spam & respect our customer’s privacy.</CautionText>
                            </HDiv>
                        </CautionContainer>

                        <CalculateBtn>Calculate Now</CalculateBtn>

                    </FormContainer>
                </MainContainer>
            <Footer/>
        </>
    )
}