import PencilSvg from '../../../../assets/dashboard/pencil.svg'

export const InputComponentDash = ({ style = {}, onClick, title, name, type, editable, onEdit, value, onChange, disable, maxLength=100, error = null }) => {
    // console.log("🚀 ~ file: InputComponentDash.js ~ line 4 ~ InputComponentDash ~ maxlength", maxLength)
    return (
        <div className="input-container mr-1 ml-1" style={style} onClick={onClick}>
            <p className="float-p">{title}</p>
            <div>
                <input type={type} className="input-box" value={value} onChange={onChange} disabled={disable} maxLength={maxLength } name={name} />
            </div>
            {error}
            {editable ?
                <img onClick={() => onEdit()} src={PencilSvg} className='pencil-icon' />
                : null}
        </div>
    )
}