import { useState ,useEffect} from 'react';
import { styles } from "../../../assets/common-styles/common-styles"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { ContinueBtn, Layout } from "./styles"
import { useNavigate } from 'react-router-dom'
import { updateCombinedModel } from '../../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";
import moment from "moment"

export const CarDetailsForm1 = () => {

    const [engineNoState, setEngineNoState] = useState('');
    const [ChassisState, setChassisState] = useState('');
    const [colorState, setColorState] = useState('');
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    useEffect(() => {
        console.log("combinedRedux is --->", combinedRedux);
    }, [combinedRedux])
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [error, setError] = useState({})
    const [type, setType] = useState("")
    
    setTimeout(() => {
            let date1 = moment();
            let date2 = moment(combinedRedux.regDate);
            var diff = moment(date1).diff(date2, 'days');
            console.log("date diff", diff);   
                if(diff > 30){
                    setType("old")
                }
                else {
                    setType("new")
                }
    }, [combinedRedux.regDate])

    const submitFunction = () =>{

        if(!engineNoState){
            setError({engineNo:'Please enter engine number.'})
            // alert('Please enter engine number.');
        }
        else if(engineNoState.length < 5){
            setError({engineNo: 'Please Enter atleast 5 digits'})
        }
        else if(!ChassisState){
            setError({chassisNo:'Please enter chassis number.'})
            // alert('Please enter chassis number.');
        }
        else if(type == "old" && ChassisState.length < 5){
            setError({chassisNo: 'Please Enter atleast 5 digits'})
        }
        else if(type == "new" && ChassisState.length < 17) {
            // if(ChassisState.length < 17){
                setError({chassisNo: 'Please Enter 17 digits Chasis Number'})
            // }
        }
        else{
            dispatch(updateCombinedModel({engineNo:engineNoState,chasisNo:ChassisState,color:colorState}));
            navigate('/brand-new-model/car-details/3')
        }
    }
    
    useEffect(() => {
        if(combinedRedux.hasOwnProperty('modelId')){
            if(combinedRedux.hasOwnProperty('engineNo')){
                setEngineNoState(combinedRedux.engineNo);
            }
            if(combinedRedux.hasOwnProperty('chasisNo')){
                setChassisState(combinedRedux.chasisNo);
            }
            if(combinedRedux.hasOwnProperty('color')){
                setColorState(combinedRedux.color);
            }
        }
        else{
            setTimeout(() => {
                getLocalStorage()
            }, 1);
        }
    },[combinedRedux])


    const getLocalStorage = async() => {
        var carInsuranceDetails =  await (localStorage.getItem('canInsuranceDetails'));
        if(carInsuranceDetails){
            carInsuranceDetails = JSON.parse(carInsuranceDetails);
            if(carInsuranceDetails.hasOwnProperty('engineNo')){
                setEngineNoState(carInsuranceDetails.engineNo);
            }
            if(carInsuranceDetails.hasOwnProperty('chasisNo')){
                setChassisState(carInsuranceDetails.chasisNo);
            }
            if(carInsuranceDetails.hasOwnProperty('color')){
                setColorState(carInsuranceDetails.color);
            }
            dispatch(updateCombinedModel(carInsuranceDetails));
        }
    };

    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent step={3}
                        engineNo={engineNoState} setEngineNoState={setEngineNoState} setChassisState={setChassisState} setColorState={setColorState} chassisNo={ChassisState}
                        noSearch={true} title='Colour, Chassis & Engine' colour={colorState}
                        error={error} setError={setError}
                    />

                </Layout>
                <ContinueBtn onClick={submitFunction}>
                    
                    Continue
                </ContinueBtn>
            </MainContainer>
        </>
    )
}