import Carousel from "react-elastic-carousel";
import styled from "styled-components";

export const SkeletonContainer = styled.div`
    position: absolute;
    width: 80%;
    margin: auto;
`
export const Circle = styled.div`
    background-color: #F6931E;
    border-radius: 10rem;
    width: 10px;
    height: 10px;
    margin: auto .325rem;
    cursor:pointer;

`
export const CircleInactive = styled.div`
    background-color: #F6931E;
    border-radius: 10rem;
    width: 8px;
    height: 8px;
    opacity: .25;
    margin:auto .325rem;
    cursor:pointer;
`
export const HDiv = styled.div`
    display: flex;
    align-self:center;
    align-items: center;
    margin-top: 1.6rem;
`
export const CardContainer = styled.div`
    padding: 1rem;
    border-radius: 10px;
    background-color: #fff;
    height: 200px;
    margin-top: 5rem;
    width: 700px;
    z-index: 9;
    text-align: center;

    @media screen and (max-width: 800px) {
        width: 95%;
    }

    @media screen and (max-width:435px) {
        height: 300px;
    }


`
export const ReactElasticCarouselComp = styled(Carousel)`
    margin-top: 1rem;

    p {
        font-weight: 800;
        margin-bottom: 1rem;
    }

    .carousel-container {
        margin-bottom: .5rem;
    }


`
export const Opacity = styled.div`
    width: 100vw;
    height: 100vh;
    position: absolute;
    top: 0;
    background-color: #000;
    z-index: 1;
    opacity: .35;
`
export const Btns = styled.button`
    padding: .6rem;
    width: 140px;
    border-radius:25px;
    border: 1px solid #B7B7B7;
    margin: auto 1rem;
    background-color: #fff;
    color: #000 !important;
    text-transform: uppercase;

    &:hover {
        border-color: #F6931E;
        cursor: pointer;
    }

    @media screen and (max-width: 435px) {
        margin: .5rem;
    }

`