import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CardHeader } from "../../../components/card-header/CardHeader"
import Footer from "../../../components/Footer/FooterNew"
import { QuoteCard } from "../../../components/quote-card/QuoteCard"
import { quotesData } from "./data"
import { BgDiv, CardMainContainer, ConfirmBtn, GridDiv, MainContainer, MutedConfirmBtn } from "./styles"

export const CarForm5 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [selectedQuote, setSelectedQuote] = useState(null)

    const renderCards = (item) => {

        const handleBuy = (val) => {
            setSelectedQuote(quotesData[val-1])
        }
        
        return(
            <QuoteCard handleBuy={handleBuy} selectedQuote={selectedQuote} key={item.id} variant={'car'} item={item} />
            )
        }

    return(
        <>
            <MainContainer>

                <BgDiv />

                <CardMainContainer>
                    <CardHeader title={'Quotes from various companies'} />
                    
                    <GridDiv>
                        {quotesData.map((item,index) => renderCards(item))}
                    </GridDiv>

                </CardMainContainer>
                
                {selectedQuote?(
                    <Link to='/car/form-6' state={{ from: selectedQuote}}>
                        <ConfirmBtn>Confirm and Continue</ConfirmBtn>
                    </Link>
                ):(
                    <MutedConfirmBtn>
                        Confirm and Continue
                        <span>Please select a quote</span>
                    </MutedConfirmBtn>
                )}

            </MainContainer>
            <Footer />
        </>
    )
}