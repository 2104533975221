import { useState, useEffect, useRef } from "react";
import './workwithus.css';
import CareerImage from '../../../assets/career.png';
import Footer from '../../../components/Footer/FooterNew';
import Hero from '../../../components/Hero_Banner/hero';
import { RiCommunityLine } from "react-icons/ri";
// import { useNavigate } from 'react-router-dom';
// import { Link } from "react-router-dom";
// import { axiosGETCall1 } from "../../../utilities/utils/apiPost";
import { useSelector } from "react-redux";
import PosCard from "./posCard";
import { getUserProfile } from "../../../Redux/AuthRedux";
import { useDispatch } from "react-redux";


const NewsLatter = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
 
  // for the pos status
  // const posStatus = userData?.posStatus || "NA";
  const dispatch = useDispatch();
  const posRedux = useSelector((state) => state.posRedux.posData);
  const { userProfileData } = useSelector((state) => state.auth);
  const dataUser = localStorage.getItem('userDetails');
  const finalParseData = JSON.parse(dataUser);

  useEffect(() => {
    dispatch(getUserProfile({ userId: finalParseData?._id || finalParseData?.userId}));
  }, []);


  // const handlePOSSection = () => {
  //   navigate('/signin-signup');
  //   localStorage.setItem('posLogin', 'true');
  // }

  // const handlePos = () => {
  //   setIsPos(true);
  //   navigate("/signin-signup?isPos=" + isPos);
  // };


  const data = {
    values: [
      {
        title: "Innovative Solutions",
        description: "Access cutting-edge technology and inventive approaches to transform insurance experiences."
      },
      {
        title: "Client-Centric Approach",
        description: "Collaborate on tailored solutions to meet specific insurance needs, ensuring satisfaction and loyalty."
      },
      {
        title: "Mutual Growth",
        description: "Explore mutually beneficial partnerships that foster growth, expand market reach, and create new opportunities."
      },
      {
        title: "Collaborative Engagement",
        description: "Engage in a partnership that values collaboration, transparency, and mutual success."
      },
    ]
  };

    const Card = ({ title, description }) => {
    return (
      <div className="card">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    );
  };

  return (
  <div>
        {/* <div className='work-media-data'>
        <img src={CareerImage} alt="careers" className='career_banner' />
        <button className='work-media-button'>REQUEST A CALLBACK</button>
      </div> */}
      <Hero heading={'WORK WITH US'} image={CareerImage} subHeading={'Your Journey to Success Begins Here. Apply Now!'} btn={true}  icon={<RiCommunityLine size={100} />}/>
    <div className='careerContainer'>
      <h1 className='career_heading '>WORK WITH US</h1>

    <PosCard rejectReasons = {posRedux?.irdaRejectionComments} finalUserData = { userProfileData?.data } />
    
    <div className='whyJoin'>
      <h2 className='career_heading_small'>Why Partner with Simpli Insure</h2>
      <p>If you're seeking dynamic collaboration opportunities to enhance your insurance capabilities or drive innovation in solutions, whether as an individual agent or an enterprise, welcome to a realm of possibilities.
      </p>
      <div className="cards-container">
      {data.values.map((item, index) => (
        <Card key={index} title={item.title} description={item.description} />
      ))}
    </div>
    </div>
                {/* {
            isLogIn ? (
              isLogIn && !isVerifiedPos && status.role !== 'admin' && (
                (status.isUserPos && posStatus === "NA") ? (
                  <Link to="/partner" className="pos-wwu-button pos">
                    Register as POS
                  </Link>
                ) : (
                  status.isUser && (posStatus === "draft" || posStatus === "NA") ? (
                    <Link to="/partner" className="pos-wwu-button pos">
                      Continue as POS
                    </Link>
                  ) : (
                    status.isUserPos && posStatus === "pending" ? (
                      <div className="header-menu pos" style={{ cursor: 'default' }}>
                        POS Pending
                      </div>
                    ) : (
                      status.isUserPos && posStatus === "approved" ? (
                        <Link to="/dashboard/agent" className="pos-wwu-button pos">
                          POS
                        </Link>
                      ) : (
                        status.isUserPos && posStatus === "rejected" ? (
                          <Link to="/partner" className="pos-wwu-button pos">
                            Rejected as POS
                          </Link>
                        ) : (
                          <div className="pos-wwu-button pos" style={{ cursor: 'default' }}>
                            Not eligible for POS X_X
                          </div>
                        )
                      )
                    )
                  )
                )
              )
            ) : (
              <div className="pos-wwu-button" onClick={() => navigate('/signin-signup')}>
                Login/Register as POS
              </div>
            )
            } */}

      {/* <div className='joinTeam'>
        <p>If you're exploring dynamic partnerships to fortify your organization's insurance
          capabilities or drive innovation in insurance solutions, welcome to a realm of
          possibilities.</p>
      </div> */}

      {/* <div className='howToApply'>
        <h2 className='career_heading_small'>Collaboration Opportunities</h2>
        <p>To discuss partnership opportunities or propose collaborations, please reach out to our partnership team at <span><a href='mailto:media@simpliinsure.com'>media@simpliinsure.com</a></span></p>
      </div> */}

      {/* <div className='workwithus-footer'>
          <h2 className='career_heading_small'>Simpli Insure : Because Life Should Be Simple</h2>
      </div> */}


      {/* <div className='work-with-us-contact'>
      <ContactForm />
      </div> */}
    </div>
    <Footer />
  </div>
  );
}

export default NewsLatter;
