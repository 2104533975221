import React from 'react'
import { CiImageOn } from 'react-icons/ci'
import { useNavigate } from 'react-router-dom'
import './Review.css'
import { useState, useEffect, useRef } from 'react'
import { uploadFile } from "../uploadFile"
import { useDispatch, useSelector } from "react-redux"
import { updateProfile } from "../../../../src/Redux/AuthRedux";
import { updateIrda, posSaveData } from "../../../../src/Redux/posRedux"
import { axiosPOSTCall, axiosGETCall, axiosPUTCall1, axiosGETCall1 } from "../../../utilities/utils/apiPost"
import { updateAddress, updateFetchedPosInfo } from "../../../../src/Redux/posRedux";
import ErrorPopups from './ErrorPopups'


const Review = () => {
  const el = useRef();
  const dispatch = useDispatch();
  const [file, setFile] = useState("");
const [error, setError] = useState('')
  const [upload, setUpload] = useState()
  const aBackRef = useRef();
  const [irda, setIrda] = useState("")
  const posRedux = useSelector((state) => state.posRedux.posData);
  var userDetails = JSON.parse(localStorage.getItem("userDetails"));
  
  useEffect(() => {
    // console.log(" posRedux---> ", userDetails);
    axiosGETCall1(`/pospInfo/getPosInfo`, {}, (callBack => {
      if (callBack.status === "Success") {
        // console.log("🚀 ~ file: Address.jsx:64 ~ useEffect ~ callBack.data:", callBack.data)
        dispatch(
          updateProfile({
            userId: userDetails.userId,
            posId: callBack.data._id,
          })
        );
        dispatch(updateFetchedPosInfo(callBack.data));
      }
    }))
  }, [])

  async function handlechange(e) {
    // console.log(e.target.files[0]);
    setFile(e.target.files[0]);
    // console.log("files firest is ", file);
    const rl = await uploadFile(e.target.files[0])
    // console.log("rl", rl)
    setUpload(rl.data[0].url)
    aBackRef.current.style.display = "block"
  }

  const navigate = useNavigate()
  const clickHandle = () => {
    let obj = { irdaNumber: irda, irdaImg: upload }
    if (!upload) {
      alert("Please Upload Image")
    } else if (!irda) {
      setError({ irda: "Enter IRDA Number" })
    }
    else {
      // console.log("🚀 ~ file: Review.jsx:44 ~ clickHandle ~ posRedux:", posRedux)
      axiosPUTCall1('pospInfo/' + posRedux._id, obj, (callBack => {
        if (callBack.status === "Success") {
          dispatch(updateIrda(callBack.data));
          // console.log("callback are bewfnlk. ,", callBack);
           const source = localStorage.getItem('sourcePage');
           if (source === 'adminDash') {
             navigate('/dashboard/agent');
             localStorage.removeItem('sourcePage');
           } else {
              navigate('/done');
            }
        }
      }));
    }

  }
  const removeError = (key) => {
    switch (key) {
      case 'irda':
        setError({ irda: "" })
        break
      default:
        break
    }
  }
  return (
    <>
      <div className="main6">
        <div className="review">
          <div className="review1">
            <h1>IRDA REVIEW</h1>
          </div>
          <div
            className="input_box"
            onClick={() => removeError('irda')}
            style={{ width: '60%' }}
          >
            <p className="reviewCertInputNum">
              IRDA CERTIFACATE NUMBER&nbsp;
              <span
                style={{
                  color: 'red',
                  fontSize: '16px',
                  position: 'absolute',
                  top: '2px',
                }}
              >
                *
              </span>
            </p>
            <input
              type="text"
              id="irda"
              name="irda "
              placeholder="Please Enter Your IRDA Number"
              style={{ display: 'block' }}
              value={irda}
              onChange={(e) => {
                const inputValue = e.target.value;
                const isAlphaNumeric = /^[a-zA-Z0-9]*$/.test(inputValue); // Regular expression to allow only alphanumeric characters
                if (isAlphaNumeric) {
                  setIrda(inputValue.toUpperCase());
                } else {
                  setError({ irda: 'Enter valid AlphaNum character' });
                }
              }}
            />
            {error.irda ? <ErrorPopups error={error.irda} /> : null}
          </div>
          {/* <div className="review2">
            <h5>IRDA CERTIFACATE NUMBER</h5>
            <h3>UIO987U7</h3>
          </div> */}
          <br />
          {/* <div className="review3"> */}
          <h2>Upload IRDA Certificate</h2>
          <div className="imageViewer" style={{ width: '60%', height: '40%' }}>
            <div
              className="upload_image"
              style={{ marginTop: '20%', display: upload ? 'none' : '' }}
            >
              {' '}
              <CiImageOn />{' '}
            </div>
            <input type="file" id="id2" onChange={handlechange} />
            <label
              htmlFor="id2"
              className="logo"
              style={{
                width: '100%',
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
              }}
            >
              <p>Click here</p>
              <img
                src={upload}
                alt="review_document_image"
                className="review_document_image"
                ref={aBackRef}
              />
            </label>
            <p style={{ display: upload ? 'none' : '' }}>
              To upload IRDA Certificate
            </p>
          </div>
          {/* <div className='card7' style={{ cursor: 'pointer' }}>
              <input type="file" id='id' ref={el} onChange={handlechange} />
              <label htmlFor="id" style={{ display: upload ? "none" : "block", cursor: 'pointer' }}>
                <span>
                  <CiImageOn />
                </span>
                Click here <br /> To upload IRDA Certificate
                <img src={upload} style={{ display: upload ? "" : 'none' }} alt="click here" />
              </label>
            </div> */}
          <br />
          <h4>
            By continuing, you agree to the Term of Use and Privacy policy of{' '}
            <span>Simpli</span>Insure
          </h4>
          {/* </div> */}
          <div className="btn_register">
            <button onClick={clickHandle}>NEXT</button>
          </div>
        </div>
      </div>
    </>
  );
}

export default Review