// import React, { useState } from 'react';
// import './campaignPolicyStatusSection.css';

// const CampaignPolicyStatusSection = () => {
//   const [formData, setFormData] = useState({
//     campaignName: '',
//     policyStatus: 'Logged in',
//     policyStatusRemarks: '',
//   });

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleSave = () => {
//     // Save logic here
//     console.log('Saved data: ', formData);
//   };



//   return (
//     <div className="campaign-policy-status-section">
//       <h2>Campaign : Policy Status</h2>
//       <div className="campaign-policy-grid">
//         <div className="form-group required">
//           <label htmlFor="campaignName">Campaign Name</label>
//           <select
//             id="campaignName"
//             name="campaignName"
//             value={formData.campaignName}
//             onChange={handleChange}
//             required
//           >
//             <option value="">--Select--</option>
//             <option value="Campaign1">Campaign 1</option>
//             <option value="Campaign2">Campaign 2</option>
//           </select>
//         </div>

//         <div className="form-group required">
//           <label htmlFor="policyStatus">Policy Status</label>
//           <select
//             id="policyStatus"
//             name="policyStatus"
//             value={formData.policyStatus}
//             onChange={handleChange}
//             required
//           >
//             <option value="Logged in">Logged in</option>
//             <option value="In Progress">In Progress</option>
//             <option value="Completed">Completed</option>
//           </select>
//         </div>

//         <div className="form-group">
//           <label htmlFor="policyStatusRemarks">Policy Status Remarks</label>
//           <input
//             type="text"
//             id="policyStatusRemarks"
//             name="policyStatusRemarks"
//             value={formData.policyStatusRemarks}
//             onChange={handleChange}
//           />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default CampaignPolicyStatusSection;

import React from 'react';
import './campaignPolicyStatusSection.css';

const CampaignPolicyStatusSection = ({ formData, handleChange }) => {
  return (
    <div className="campaign-policy-status-section">
      <h2>Campaign : Policy Status</h2>
      <div className="campaign-policy-grid">
        <div className="form-group required">
          <label htmlFor="campaignName" className="campaignPolicyLabel">
            Campaign Name
          </label>
          <select
            id="campaignName"
            name="campaignName"
            className="campaignPolicySelect"
            value={formData.campaignName}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="noCampaign">No Campaign</option>
          </select>
        </div>

        <div className="form-group required">
          <label htmlFor="policyStatus" className="campaignPolicyLabel">
            Policy Status
          </label>
          <select
            id="policyStatus"
            name="policyStatus"
            className="campaignPolicySelect"
            value={formData.policyStatus}
            onChange={handleChange}
            required
          >
            <option value="">--Select--</option>
            <option value="Approved">Approved</option>
            <option value="delivered">Delivered</option>
            <option value="Issued">Issued</option>
            <option value="LoggedIn">Logged in</option>
            <option value="SendForApproval">Send for Approval</option>
          </select>
        </div>

        <div className="form-group">
          <label htmlFor="policyStatusRemarks" className="campaignPolicyLabel">
            Policy Status Remarks
          </label>
          <input
            type="text"
            id="policyStatusRemarks"
            name="policyStatusRemarks"
            className="campaignPolicyInput"
            value={formData.policyStatusRemarks}
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
};

export default CampaignPolicyStatusSection;
