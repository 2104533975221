import React, {useState} from 'react';
import formatSumAssured from "../../utilities/calculateSum";
import './gridCss.css';
import { InfoCircleOutlined } from '@ant-design/icons';
import { SkeletonCardMobile } from "../../pages/life-insurance/skeleton-card/SkeletonCard-mobile";
import {Modal} from 'antd'

const PlainCardMobile = (props) => {

  const { 
item, handleDetails, viewMoreDetails, ciValue, adhbValue, handleKeyPress, handleCiInputChange, handleAdhbInputChange, applyAddOns, errorTooltip, payout, onlyForPayout,
inad, c4ci, pprPac,
hprCc,
hprCci,
pprAdc,
hdfcRider,
hprPlusCc,
inadPlus,
c4ciPlus,
hdfcPlusRider,
isTATA_adb,
isTATA_tpd,
isTATA_cbp,
isTATA_hcb,
tataRider,
handleInad,
handleC4ci,
handlePprPac,
handleHprCc,
handleHprCci,
handlePprAdc,
handleInadPlus,
handleC4ciPlus,
handleHprPlusCc,
handleTataAdb,
handleTataTpd,
handleTataCbp,
handleTataHcb,
isTATA_MRS_adb,
isTATA_MRS_tpd,
isTATA_MRS_hcb,
tataMrsRider,
riderHandlerTataMrs,
handleTataMrsAdb,
handleTataMrsTpd,
handleTataMrsHcb,
isRop,
handleRop,
isWopCI,
handleWopCI,
isRopPlus,
isWopCIPlus,
handleRopPlus,
handleWopCIPlus
} = props;
  

  const excludeFromHBAndCi = ['life-icici-sjb']
  const[show,setShow]=useState(false)
     let handleModal=()=>{
    setShow(!show)
  }

  return (
    <div className="mainCard">
                <div className="details_card">
                      <div className="logo-container3">
                          <img className="mobile_head-img" src={item?.sluginfo?.logo} alt='logo'/>
                          <div>
                             <p className="sec" >
                            {
                              item?.sluginfo?.name.split(' ').map((word, index, arr) => {
                                const isLife = word.toLowerCase() === 'life';
                                const isPlus = word.toLowerCase() === 'plus';
                                return (
                                  <React.Fragment key={index}>
                                    {(isLife || isPlus) ? <span style={{ color: '#ff3300', fontWeight:'bold' }}>{word}</span> : word}
                                    {index !== arr.length - 1 ? ' ' : null}
                                  </React.Fragment>
                                );
                              })
                            }
                       </p>
                          </div>
                      </div>
                      <div className="quoteDetails">
                        <div>
                          <p className="quoteData">SA</p>
                          <p>₹{formatSumAssured(item?.sumAssured ? item?.sumAssured : "-")}</p>
                        </div>
                          <div>
                          <p className="quoteData">CTA</p>
                          <p>{item?.coverTillAge ? item?.coverTillAge : "-"} Yrs</p>
                        </div>
                        <div>
                          <p className="quoteData">PT</p>
                          <p>{item?.policyTerm} Yrs</p>
                        </div>
                        <div>
                          <p className="quoteData">PPT</p>
                          <p>{item?.premiumPaymentTerm} Yrs</p>
                        </div>
                      </div>
                      <div className="footer_btn">
                      {
                        !excludeFromHBAndCi.includes(item.slug) ? 
                         <p className="twoMoreBtn" onClick={handleDetails}>{!viewMoreDetails ? "+2 More" : "Show less"}</p>
                         : null
                    }  
                      <div>
                        <button onClick={handleModal} className="cardBuyBtn">Buy On ₹{parseInt(item?.totalPriceWithoutTax).toLocaleString('en-IN', { maximumFractionDigits: 2 })}</button>
                        <label className="newLabelForMobile">₹{parseInt(item.serviceTax).toLocaleString('en-IN', { maximumFractionDigits: 2 })} Tax on Pay {item.mode}</label> 
                      </div>


                <Modal className='modal'
                    open={show}
                    onOk={handleModal}
                    onCancel={handleModal}
                    width={800}
                    radius={20}
                >
                {}
                <SkeletonCardMobile  continueBtn={true} item = {item}  title={'Save Details'} prevPolicy={true}/>
                </Modal>
              </div>

              </div>
                {
                viewMoreDetails ? (
                  !excludeFromHBAndCi.includes(item.slug) ? (
                  <div className = "addonsCard">
                       <div className="addons_para">
                          {
                item.slug === "life-hdfc" ? (
                  <div>
                    <div>
                      <input type="checkbox" id="isRop" className="check" value="isRop" checked={isRop} onChange={handleRop} />
                      <label className="checkText">
                        Return of Premium{' '}
                      </label>
                    </div>
                  </div>
                ) : item.slug === "life-hdfc-plus" ? (
                  <div>
                    <div>
                      <input type="checkbox" id="isRopPlus" className="check" value="isRopPlus" checked={isRopPlus} onChange={handleRopPlus} />
                      <label className="checkText">
                        Return of Premium{' '}
                      </label>
                    </div>

                  </div>
                ) : !excludeFromHBAndCi.includes(item.slug) ? (
                  <p>
                    ✓ Waiver of Premium Cover <span style={{ color: "#41025F", fontWeight: "bolder" }}>free</span>
                  </p>
                ) : null
              }
              {/* for wopCI waiver of premium */}
                {
                item.slug === "life-hdfc" ? (
                  <div>
                    <div>
                      <input type="checkbox" id="isWopCI" className="check" value="isWopCI" checked={isWopCI} onChange={handleWopCI} />
                      <label className="checkText">
                        Waiver of Premium {' '}
                      </label>
                    </div>
                  </div>
                ) : item.slug === "life-hdfc-plus" ? (
                  <div style={{display:"flex", flexDirection:"column"}}>
                    <div>
                      <input type="checkbox" id="isWopCIPlus" className="check" value="isWopCIPlus" checked={ isWopCIPlus } onChange={ handleWopCIPlus} />
                      <label className="checkText">
                        Waiver of Premium{' '}
                      </label>
                    </div>
                  </div>
                ) : !excludeFromHBAndCi.includes(item.slug) ? (
                  <p>✓ 100% Payout on Terminal illness <span style={{color:"#41025F", fontWeight:"bolder",}}>free</span></p>
                ) : null
              }
                       </div>
                    <div className="addons_input_box">
                    {
                      item.slug === "life-hdfc-plus" ? (
                        <div style={{display:"flex", flexDirection:"column"}}>
                        <div>
                        <input
                          type="checkbox"
                          id="inadPlus"
                          className="check"
                          value="inadPlus"
                          checked={inadPlus}
                          onChange={handleInadPlus}
                          />
                        <label className="checkText">
                          Income benefit on accidental disability{' '}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {hdfcPlusRider?.INAD ? `₹${(parseFloat(hdfcPlusRider['INAD'].price) + parseFloat(hdfcPlusRider['INAD'].tax))}` : null}
                          </span>
                          
                        </label>
                          </div>

                        <div>
                        <input
                          type="checkbox"
                          id="c4ciPlus"
                          className="check"
                          value="c4ciPlus"
                          checked={c4ciPlus}
                          onChange={handleC4ciPlus}
                          />
                          <label className="checkText">Extra payout On Critical illness plus rider {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {hdfcPlusRider?.C4CI ? `₹${(parseFloat(hdfcPlusRider['C4CI'].price) + parseFloat(hdfcPlusRider['C4CI'].tax))}` : null}
                          </span>
                          </label>
                          </div>
                           <div>
                        <input
                        type="checkbox"
                        id="hprPlusCc"
                        className="check"
                        value="hprPlusCc"
                        checked={hprPlusCc}
                        onChange={handleHprPlusCc}
                        />
                          <label className="checkText">Extra payout on health plus rider {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                            {hdfcPlusRider?.HPR_CC ? `₹${(parseFloat(hdfcPlusRider['HPR_CC'].price) + parseFloat(hdfcPlusRider['HPR_CC'].tax))}` : null}
                            </span>
                            </label>
                        </div>
                        </div> 
                          ) : item.slug === "tata-mrss" ? (
                            <div style={{display:"flex", flexDirection:"column"}}>
                        <div>
                        <input
                          type="checkbox"
                          id="isTATA_MRS_adb"
                          className="check"
                          value="isTATA_MRS_adb"
                          checked={isTATA_MRS_adb}
                          onChange={handleTataMrsAdb}
                          />
                        <label className="checkText">
                         Extra Payout for Accidental Death Benefit{' '}

                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                           {tataMrsRider?.map((rider) => rider.RiderCode === "ADBLIR1NW1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>

                        </label>
                        </div>

                        <div>
                        <input
                          type="checkbox"
                          id="isTATA_MRS_tpd"
                          className="check"
                          value="isTATA_MRS_tpd"
                          checked={isTATA_MRS_tpd}
                          onChange={handleTataMrsTpd}
                          />
                          <label className="checkText">Extra Payout for Accidental Disability Benefit {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                           {tataMrsRider?.map((rider) => rider.RiderCode === "TPDLIR1NW1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>
                          </label>
                          </div>
                        <div>
                        <input
                          type="checkbox"
                          id="isTATA_MRS_hcb"
                          className="check"
                          value="isTATA_MRS_hcb"
                          checked={isTATA_MRS_hcb}
                          onChange={handleTataMrsHcb}
                          />
                          <label className="checkText">Extra Payout Hospital Care Benefit {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {tataMrsRider?.map((rider) => rider.RiderCode === "HCBLIR1NW1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>
                          </label>
                          </div>
                      </div>
                          ) : item.slug === "tata-life-srp" ? (
                        <div style={{display:"flex", flexDirection:"column"}}>
                        <div>
                        <input
                          type="checkbox"
                          id="tata_adb"
                          className="check"
                          value="tata_adb"
                          checked={isTATA_adb}
                          onChange={handleTataAdb}
                          />
                        <label className="checkText">
                         Extra Payout for Accidental Death Benefit{' '}

                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                           {tataRider?.map((rider) => rider.RiderCode === "ADBLIR1NV1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>

                        </label>
                        </div>

                        <div>
                        <input
                          type="checkbox"
                          id="tata_tpd"
                          className="check"
                          value="tata_tpd"
                          checked={isTATA_tpd}
                          onChange={handleTataTpd}
                          />
                          <label className="checkText">Extra Payout for Accidental Disability Benefit {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                           {tataRider?.map((rider) => rider.RiderCode === "TPDLIR1NV1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>
                          </label>
                          </div>
                          <div>
                        <input
                          type="checkbox"
                          id="tata_cbp"
                          className="check"
                          value="tata_cbp"
                          checked={isTATA_cbp}
                          onChange={handleTataCbp}
                          />
                        <label className="checkText">
                          Extra Payout for Criticare Plus Benefit{' '}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {tataRider?.map((rider) => rider.RiderCode === "CBPLIR1NV1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>
                        </label>
                        </div>

                        <div>
                        <input
                          type="checkbox"
                          id="tata_hcb"
                          className="check"
                          value="tata_hcb"
                          checked={isTATA_hcb}
                          onChange={handleTataHcb}
                          />
                          <label className="checkText">Extra Payout Hospital Care Benefit {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {tataRider?.map((rider) => rider.RiderCode === "HCBLIR1NV1" ? `₹${(parseFloat(rider.RiderPremium) + parseFloat(rider.RiderST))}` : null )}
                          </span>
                          </label>
                          </div>
                      </div>
                      ) : item.slug === "life-hdfc" ? (
                        <div style={{display:"flex", flexDirection:"column"}}>
                        <div>
                        <input
                          type="checkbox"
                          id="inad"
                          className="check"
                          value="inad"
                          checked={inad}
                          onChange={handleInad}
                          />
                        <label className="checkText">
                          Income benefit on accidental disability{' '}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {hdfcRider?.INAD ? `₹${(parseFloat(hdfcRider['INAD'].price) + parseFloat(hdfcRider['INAD'].tax))}` : null}
                          </span>
                          
                        </label>
                          </div>

                        <div>
                        <input
                          type="checkbox"
                          id="c4ci"
                          className="check"
                          value="c4ci"
                          checked={c4ci}
                          onChange={handleC4ci}
                          />
                          <label className="checkText">Extra payout On Critical illness plus rider {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {hdfcRider?.C4CI ? `₹${(parseFloat(hdfcRider['C4CI'].price) + parseFloat(hdfcRider['C4CI'].tax))}` : null}
                          </span>
                          </label>
                          </div>
                        <div>
                        <input
                          type="checkbox"
                          id="pprAdc"
                          className="check"
                          value="pprAdc"
                          checked={pprAdc}
                          onChange={handlePprAdc}
                          />
                          <label className="checkText">Extra payout On Accidental death cover {" "}
                          <span style={{color:"#41025F", fontWeight:"bolder"}}>
                          {hdfcRider?.PPR_ADC ? `₹${(parseFloat(hdfcRider['PPR_ADC'].price) + parseFloat(hdfcRider['PPR_ADC'].tax))}` : null}
                          </span>
                          </label>
                          </div>
                           <div>
                        <input
                        type="checkbox"
                        id="pprPac"
                        className="check"
                        value="pprPac"
                        checked={pprPac}
                        onChange={handlePprPac}
                        />
                        <label className="checkText">Extra payout on Personal Accident cover {" "}
                        <span style={{color:"#41025F", fontWeight:"bolder"}}>
                         {hdfcRider?.PPR_PAC?.price ? `₹${parseFloat(hdfcRider['PPR_PAC'].price) + parseFloat(hdfcRider['PPR_PAC'].tax)}` : null}
                        </span>
                        </label>
                        </div>
                        <div>
                        <input
                        type="checkbox"
                        id="hprCc"
                        className="check"
                        value="hprCc"
                        checked={hprCc}
                        onChange={handleHprCc}
                        />
                        <label className="checkText">Extra payout on health plus rider {" "}
                        <span style={{color:"#41025F", fontWeight:"bolder"}}>
                        {hdfcRider?.HPR_CC ? `₹${(parseFloat(hdfcRider['HPR_CC'].price) + parseFloat(hdfcRider['HPR_CC'].tax))}` : null}
                        </span>
                        </label>
                        </div>
                        <div>
                        <input
                        type="checkbox"
                        id="hprCci"
                        className="check"
                        value="hprCci"
                        checked={hprCci}
                        onChange={handleHprCci}
                        />
                        <label className="checkText">Extra payout on Comprehensive Critical Illness {" "}
                        <span style={{color:"#41025F", fontWeight:"bolder"}}>
                         {hdfcRider?.HPR_CCI ? `₹${(parseFloat(hdfcRider['HPR_CCI'].price) + parseFloat(hdfcRider['HPR_CCI'].tax))}` : null}
                         </span>
                        </label>
                        </div>
                          </div>
                      ) : (
                      <div>
                      <label htmlFor={`${item.slug}+2`} className="checkText-mobile">
                        Extra For CI:
                        {item.CIRPremium > 0 ? (
                        <span style={{ color: "#41025F", fontWeight: "bolder" }}>
                            ₹{item.CIRPremium + item.ServiceTax_CIR}
                        </span>
                        ) : null}
                      </label>
                    <input
                      type="text"
                      id="ciInput"
                      className="addons_input"
                      placeholder="Enter CI"
                      value={ciValue}
                      onChange={handleCiInputChange}
                      onKeyPress={handleKeyPress}
                    />
                   <label className="newLabel">
                        {formatSumAssured(ciValue) < 100
                          ? formatSumAssured(ciValue) // Show value without ₹ symbol and "only"
                          : `₹${formatSumAssured(ciValue)} only`} {/* Show value with ₹ symbol and "only" */}
                    </label>

                      <label htmlFor={`${item.slug}+1`} className="checkText-mobile">
                          Extra for ADHB: 
                          {item.ADBRPremium > 0 ? (
                          <span style={{ color: "#41025F", fontWeight: "bolder" }}>
                              ₹{item.ADBRPremium + item.ServiceTax_ADB}
                          </span>
                          ) : null}
                      </label>
                    <input
                    type="text"
                    id="adhbInput"
                    value={adhbValue}
                    placeholder="Enter ADHB"
                    className="addons_input"
                    onChange={handleAdhbInputChange}
                    onKeyPress={handleKeyPress}
                  />
                  <label className="newLabel">
                        {formatSumAssured(adhbValue) < 100
                          ? formatSumAssured(adhbValue) // Show value without ₹ symbol and "only"
                          : `₹${formatSumAssured(adhbValue)} only`} {/* Show value with ₹ symbol and "only" */}
                      </label>
                        <button className="addonsCardBtn" onClick={applyAddOns}>Get Addons</button>
                        </div>
                      )}
                        </div>
                        <div className='errorAddons'>                           
                        {errorTooltip && (
                            <p className="paraAdd"><InfoCircleOutlined /> {"  "}{errorTooltip}</p>
                        )}
                        </div>

                      <div className='noteForLumpsumIncome'>
                      {
                      onlyForPayout.includes(item.slug) ?
                      item.DeathBenefitOption === "Lump-Sum-Income" ?
                      <p> <span style={{ color: "#560756", fontWeight: "bolder" }}>Note:</span> {payout.incomePercentage}% of your sum assured is your monthly income, which is <span style={{ color: "#41025F", fontWeight: "bolder" }} id="monthly-income">₹{payout.incomeValue}</span> per month, and {payout.lumpsumPercentage}% is your lump-sum payout, which is <span style={{ color: "#41025F", fontWeight: "bolder" }} id="lump-sum-payout">₹{payout.lumpsumValue}</span>.</p>
                        :null
                        : null
                      }
                    </div>

                      </div> 
                       ) :null
                      ) : null
                      }
            </div>
  );
}

export default PlainCardMobile;