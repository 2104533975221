import { useState, useEffect } from 'react';
import './nimgade.css';
import { FaLinkedin, FaInstagram  } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import Img3 from '../../../assets/abous-us/director2.png'
import FooterNew from '../../../components/Footer/FooterNew';
import { IoMdMail } from "react-icons/io";

const PandePage = () => {

      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div className="prashant-page">
      <div className="profile">
        <img
          src={Img3}
          alt="Prashant N Nimgade"
          className="director-img"
        />
        <p className="director-name">SACHIN PANDE</p>
        <p className="director-designation">Director</p>
        <p className='director-designation-title'>Tech Pioneer | Visionary IT Leader | Sustainable Growth |Seasoned Serial Entrepreneur | Driving Business Excellence in Fintech and BFSI Domains with a focus on innovative solutions and transformative strategies |Driving Innovation in Banking, Finance & IT Solutions.s</p>
      </div>

        <div className="social-links">
          {/* <a href="https://www.linkedin.com/in/sachin-pande-77348922/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={'2.3rem'} color="#000" />
          </a>
            <a href="mailto:prashant@sipfund.com " target="_blank" rel="noopener noreferrer">
            <IoMdMail size={'2.3rem'} color="#000" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaFacebookF size={'2.1rem'} color="#000" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={'2.3rem'} color="#000" />
          </a> */}
        </div>

      <div className="director-info">
        <p>
        <b>LEADING SUSTAINABLE GROWTH THROUGH INNOVATIVE BANKING, FINANCE, & IT SOLUTIONS</b><br />
        Mr. Sachin Pande, a serial entrepreneur, serves as the Director of SIP Fund Private Limited and holds the position of Promoter – Director in Virtual Galaxy Infotech Pvt. Ltd., PayNext Pvt. Ltd., Quintet Corp Pvt. Ltd., and Sampada Infosolutions Pvt. Ltd. With over 25 years of robust business experience in the IT industry, Mr. Pande possesses extensive expertise in System Management, Database Design, Web & Cloud applications, Digital Payments, and architectural frameworks like three-tier and N-tier and with a BE in Computer Technology/Engineering from Nagpur University and an MBA in IT, his professional journey reflects a successful trajectory as a 1st generation entrepreneur, operator, and investor, accentuated by his proficiency in sales, marketing, and strategic mergers and acquisitions. 
        </p>
        <p>
       Mr. Pande's skill set encompasses a deep understanding of System Management, RDBMS, Web-Based, and Client-Server applications, along with expertise in Object-Oriented Programs (OOPs) and emerging technologies such as Artificial Intelligence, Machine Learning, Block-Chain, and Digital Payments. He brings leadership, strategic planning, operational excellence, team-building, and sales & marketing acumen to the table, making him a valuable asset in driving business growth and fostering a culture of innovation. 
        </p>
      </div>
      <FooterNew />
    </div>
  );
};

export default PandePage;


