import styled from "styled-components";


export const Opacity = styled.div`
    width: 100vw;
    height: ${localStorage.getItem('iHeight')}px;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: .6;
    z-index: 200;
`
export const MainContainer = styled.div`
    /* position: relative; */
`
