import React from "react";

const Searchbox = ({  searchChange, placeholder }) => {
  return (
    <form className="search-form">
      <div className="search-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="search-icon"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"
          />
        </svg>
        <input
          type="text"
          placeholder={placeholder}
          onChange={searchChange}
          aria-label="Search for developers by name, role or location"
          className="search-input"
        />
      </div>
    </form>
  );
};
export default Searchbox;
