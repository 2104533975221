import styled from "styled-components";

export const MainDiv = styled.div`
    padding-top: 3rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

`
export const BgDiv = styled.div`
    width: 100%;
    height: 35vh;
    position: absolute;
    background: linear-gradient(to left, #41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }
`

export const CardMainContainer = styled.div`
    border-radius: 1rem;
    padding: 1rem 2.5rem;
    background-color: #fff;
    width: 95%;
    margin: 1rem auto;
    margin-bottom: 3rem;

    a {
        text-decoration-line: none;
    }

`
export const BottomDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap:2rem;

    @media screen and (max-width: 1040px) {
        grid-template-columns: repeat(2,1fr);
    }

    @media screen and (max-width: 800px) {
        grid-template-columns: repeat(1,1fr);
    }

`
export const BottomTitle = styled.h4`
    color: #ff4c00;
    margin: 1.25rem auto;
    margin-top: 0;

    @media screen and (max-width: 800px) {
        text-align: center;
        margin: auto auto 2.5rem auto;
    }

`
export const InputDiv = styled.div`
    padding: 1.5rem;
    border: 1px solid #b7b7b7;
    border-radius:.4rem;
    position: relative;

    .radios-container {
        margin-top: .6rem;
    }

    .date-placeholder {
        position: absolute;
        top: 2.1rem;
        background-color: #fff; 
        padding: 0.2rem .5rem .2rem .5rem;
        left: 2rem;
        color:#b7b7b7;

        @media screen and (max-width: 800px) {
            font-size: .9rem;
        }
    }

`

export const HDiv = styled.div`
    display: flex;
    align-items: center;

    .yes-container {
        margin-right: 1rem;
        p {
            color: #707070;
        }
    }

    .no-container {
        p {
            color: #707070;
        }
    }

    input[type=radio] {
        margin-right: .4rem;
        accent-color: #ff4c00;
    }

`
export const Title= styled.p`
    position: absolute;
    padding: .2rem .6rem;
    background-color: #fff;
    top: -.7rem;
    left: .8rem;

`
export const DateInput = styled.input`
    width: 100%;
    border: 1px solid #b7b7b7;
    padding: .6rem;
    border-radius: .2rem;

    @media screen and (max-width: 800px) {
        background-color: #fff;
    }

`
export const InputSelect = styled.select`
    width: 100%;
    padding: .6rem;
    outline: none;
    border-radius:.2rem;
    border: 1px solid #b7b7b7;

    @media screen and (max-width: 800px) {
        background-color: #fff;
    }

`
export const ConfirmBtn = styled.button`
    background: linear-gradient(to left, #ed4168, #ffb25a);
    color: #fff;
    font-size: 1.12rem;
    display: flex;
    margin: auto;
    padding: .8rem 2.5rem ;
    border: 0;
    border-radius: 1.5rem;
    cursor: pointer;
    margin-top: 5rem;
`