import styled from "styled-components";

export const NotificationContainer = styled.div`
    padding: 1rem;

    p {
        color: #605F5E;
    }
    display:flex;
    justify-content:space-between;
    align-items:center;

    .flex {
        display:flex;
    }
    .align-center {
        align-items:center;
    }
    .new {
        border-radius:8px;
        border:1px solid #41015F;
        padding:.4rem 1rem;
        margin-right:1rem;
    }
    .arrow {
        cursor:pointer;
    }

`
export const NotificationContainerLight = styled.div`
    padding: 1rem;
    background-color: #fff !important;

    p {
        color: #605F5E;
    }
    display:flex;
    justify-content:space-between;
    align-items:center;

    .flex {
        display:flex;
    }
    .align-center {
        align-items:center;
    }
    .new {
        border-radius:8px;
        border:1px solid #41015F;
        padding:.4rem 1rem;
        margin-right:1rem;
    }
    .arrow {
        cursor:pointer;
    }

`