import React, { useState } from 'react';
import './career.css';
// import CareerImage from '../../assets/banner/9.png';
import { FaXTwitter, FaInstagram, FaLinkedin, FaYoutube, FaArrowRight, FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdMail } from "react-icons/io";
import FooterNew from './../../components/Footer/FooterNew';
import { uploadFile } from "../../components/POS/uploadFile";
import { axiosPUTCall, axiosPUTCall1, axiosPOSTCall1 } from "../../utilities/utils/apiPost";
import { careerApply } from "../../Redux/AuthRedux";
import { useDispatch } from 'react-redux';
// import contactBanner from '../../assets/contact-banner.png';
import Hero from '../../components/Hero_Banner/hero';
import Quick from '../../components/new_quick_links/quick';
import { GiTeamIdea } from "react-icons/gi";
import contactBanner from '../../assets/career.png';

const Career = () => {
  const [resume, setResume] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const userDetails = JSON.parse(localStorage.getItem("userDetails")) || {};
  const [name, setName] = useState(userDetails.fullName || "");
  const [email, setEmail] = useState(userDetails.email || "");
  const [phone, setPhone] = useState(userDetails.phone || "");
  const [position, setPosition] = useState("");

  const dispatch = useDispatch();

  const data = {
    values: [
      {
        title: "Innovative Environment",
        description: "Embrace cutting-edge technology and inventive solutions to transform the insurance experience."
      },
      {
        title: "Customer-Centric Approach",
        description: "Focus on delivering simplified, personalized insurance solutions that meet our clients' needs."
      },
      {
        title: "Collaborative Culture",
        description: "Work alongside talented individuals who value teamwork, diversity, and continuous learning."
      },
      {
        title: "Career Growth",
        description: "Access opportunities for professional development and advancement within the company."
      },
      {
        title: "Work-Life Balance",
        description: "Strive for a healthy work-life balance through flexible work arrangements and comprehensive benefits."
      }
    ]
  };

  const uploadProfile = async (e) => {
    console.log(e.target.files[0]);
    if (e.target.files[0].size < 10485760) {
      const rl = await uploadFile(e.target?.files[0])
      setResume(prev => ({ ...prev, resume: rl?.data[0]?.url }))
    }
    else {
      alert("Please, Reduce the size of image (below 10 MB) ")
    }
  }

  const submitApplication =(e) =>{

    e.preventDefault();

    if(!name || !email || !phone || !position){
      alert("All the details required");
      return
    }

    const obj = {
      userId:userDetails.userId,
      name,
      email,
      phone,
      position,
      resume: resume.resume
    }

    try {
      dispatch(careerApply(obj));
      setSubmitted(true);
      alert("Application submitted successfully");
    } catch (error) {
      console.log(error);
    }
  }

    const Card = ({ title, description }) => {
    return (
      <div className="card">
        <h2>{title}</h2>
        <p>{description}</p>
      </div>
    );
  };

  return (
    <div>
    <Hero data = {'#'} image={contactBanner} heading={'JOIN US AND BE PART OF A DYNAMIC WORKFORCE'} subHeading={'Your Journey to Success Begins Here. Apply Now'} btn={true} icon={<GiTeamIdea size={100} />}/>
    <Quick heading = {'Career'} />
    <div className='careerContainer'>
      <h1 className='career_heading '>Careers</h1>
      <div className='joinTeam'>
        <h2 className='career_heading_small'>STAY UPDATED WITH SIMPLI INSURE'S NEWSLETTER
</h2>
        <p className='para_join_team'>At Simpli Insure, we believe in fostering a culture of innovation, collaboration, and excellence. We are dedicated to simplifying insurance for our clients both individuals and enterprises, while nurturing a dynamic and inclusive work environment for our team members. </p>

        <p>If you're passionate about making a difference We'd love to hear from you.</p>
      </div>

      <div className='whyJoin'>
      <h2 className='career_heading_small'>Why join Simpli Insure?</h2>
      <div className="cards-container">
      {data.values.map((item, index) => (
        <Card key={index} title={item.title} description={item.description} />
      ))}
    </div>
    </div>

      <div className='jobOpenings'>
        <h2 className='career_heading_small'>Current Openings</h2>
        <p>We are currently looking for talented individuals to join our team. Please see below for our current job openings.</p>
        </div>

      <div className='howToApply'>
        <h2 className='career_heading_small'>How to Apply</h2>
        <p>To apply for any of the positions listed above or to express your interest in joining our team, please send your resume and cover letter to <span><a href='mailto:info@simpliinsure.com'>info@simpliinsure.com</a></span> or please upload your resume here. </p>
      </div>

      <div className='submitResumeForm'>
        <form onSubmit={submitApplication}>
          <input type='text' name='name' placeholder='Enter your name'   onChange={e=>setName(e.target.value)}/>
          <input type='email' name='email' placeholder='Enter your email'  onChange={e=>setEmail(e.target.value)}/>
          <input type='tel' name='phone' maxLength={10} placeholder='Enter your phone number' onChange={e=>setPhone(e.target.value)}/>
          <select className='position' value={position} onChange={e=>setPosition(e.target.value)}>
          <option value="" disabled selected>Select Position</option>
            <option name= "Developer" value={"Developer"}>Developer</option>
            <option name= "Designer" value={"Designer"}>Designer</option>
            <option name= "Manager" value={"Manager"}>Manager</option>
            <option name= "Other" value={"Other"}>Others</option>
          </select> 
          <input type='file' name='resume' placeholder='Upload your resume' onChange={uploadProfile}/>
          <button type='submit'>Submit</button>
        </form>
      </div>

      <div className='socialMedia'>
        <h2 className='career_heading_small'>Follow us on social media</h2>
        <div className='socialMediaIcons'>
          <a href="https://www.facebook.com/SimpliInsureOfficial" target="_blank" rel="noreferrer">
              <FaFacebook size='1.3rem' color='#000'/>
            </a>
              <a href="http://www.instagram.com/simpliinsureofficial/" target="_blank" rel="noreferrer">
              <FaInstagram size='1.3rem' className='iconSpace' color='#000'/>
              </a>
              <a href="https://wa.me/+918317419118?text=Hi%20SimpliInsure,%20I%20have%20a%20query%20regarding%20my%20insurance%20policy." target="_blank" rel="noreferrer">
              <IoLogoWhatsapp size='1.3rem' className='iconSpace' color='#000'/>
              </a>
              <a href="http://www.twitter.com/SimpliInsure" target="_blank" rel="noreferrer">
              <FaXTwitter size='1.3rem' className='iconSpace' color='#000'/>
              </a>
              <a href="http://www.linkedin.com/company/simpliinsure/" target="_blank" rel="noreferrer">
              <FaLinkedin size='1.3rem' className='iconSpace' color='#000'/>
              </a>
              <a href="http://www.youtube.com/@SimpliInsureOfficial" target="_blank" rel="noreferrer">
              <FaYoutube size='1.3rem' className='iconSpace' color='#000'/>
              </a>
        </div>
      </div>

      <div className='diversity'>
        <h2 className='career_heading_small'>Diversity and Inclusion</h2>
        <p>We are committed to creating an inclusive workplace that celebrates diversity in all its forms. We welcome individuals from diverse backgrounds, experiences, and perspectives to join our team.</p>
      </div>

      <p className='joinUsLast'>Join us in simplifying insurance and shaping the future of the industry!</p>

    </div>
    <FooterNew />
    </div>
  );
}

export default Career;
