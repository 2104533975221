import styled from "styled-components";

export const MainDiv = styled.div`
    padding-top:50px;
`

export const BgDiv = styled.div`
    width: 100%;
    height: 80vh;
    position: absolute;
    background: linear-gradient(#41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }

`


export const HDiv = styled.div`
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 1040px) {
        flex-direction: column;
        align-items: center;
    }

`

export const ErrorContainer = styled.div`
    // position: absolute;
    display: flex;
    flex-direction: column;
    right: 24rem;
    // top: 0.1px;
    // top: -28px;
    bottom: 135px;
    z-index:1;
    font-size: .8rem;
    align-items: flex-end;

`

export const ErrArrow = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid #F6931E; 
    border-left: 10px solid transparent;
    margin-right: .5rem;
`

export const ErrorIcon = styled.img`
    width: 20px;
    margin-top: .4rem;
`

export const ErrorMsgContainer = styled.div`
    padding: .5rem 1rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    // border-bottom-right-radius: 0;
    color: #fff;
    font-weight: 900;
`

export const CarIcon = styled.img`
    width: 370px;

    @media screen and (max-width: 800px) {
        width: 290px;
        margin-top: 1.2rem;
    }

`
export const LeftDiv = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: 1;
    /* padding-top: 100px; */
`
export const RightDiv = styled.div`
    padding: 40px;
    display: flex;
    flex-direction: column;
    flex: 1;
    align-self:center;

    @media screen and (max-width: 1040px) {
        text-align: center;
        align-items: center;
    }

`

export const TitleText = styled.h1`
    color: #fff;
    font-size: 3rem;
    /* width: 60%; */
`
export const ImgCard = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    border-top: 2px solid rgba(255, 255, 255, 0.288);
    border-bottom: 2px solid rgba(255, 255, 255, 0.288);
    margin-top: 2.5rem;
    padding: 0.6rem 0;
    width: 38vw;

    @media screen and (max-width: 1040px) {
        flex-direction: column;
    }

`
export const ImgPContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: auto 1rem;

    p {
        color: #fff;
        margin-left: .3rem;
    }

    @media screen and (max-width: 1040px) {
        flex-direction: column;
        margin: .5rem auto;
    }

`
export const CarDetailsCard = styled.div`
    width: 80%;
    margin: auto;
    padding: 2rem;
    border-radius: 1rem;
    background-color: #fff;
    box-shadow: 0px 1px 10px -4px rgba(0,0,0,0.75);
    margin-bottom: 2rem;
`
export const DetailsInputContainer = styled.div`
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #f6f6f6;
    border:1px solid #c3c2c2;
    border-radius:5px;
    
    @media screen and (max-width: 800px) {
        flex-direction: column;
    }
    
    `
export const CarTypeIcon = styled.img`
    padding-right: 2rem;
    border-right-width: 1px;
    border-right-style: solid;
    border-color:#c3c2c2;

    @media screen and (max-width: 800px) {
        padding: 0;
        padding-bottom: 1rem;
        border: none;
        border-bottom: 1px solid #c3c2c2; 
        width: 80px;
        margin-top: .5rem;
    }

`
export const CarInput = styled.input`
    padding: 1rem;
    background:transparent;
    border: none;
    flex: 1;
    outline: none;
    font-size: 1rem;
    
    @media screen and (max-width: 800px) {
        text-align: center;
        margin: 1rem auto;
    }

`
export const InsureBtn = styled.button`
    /* background: linear-gradient(to left, #ed4168, #ffb25a); */
    background-color: #F6931E;
    padding: .9rem 2.8rem;
    color: #fff !important;
    border: none;
    border-radius: 2rem;
    font-size: 1.2rem;
    font-weight: 800;
    cursor: pointer;
`
export const BottomDiv = styled.div`
    padding: 1rem;
    padding-bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

`
export const Left = styled.div`
    padding: 2rem;
    display: flex;
    align-items: center;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

`
export const Right = styled.div`
    padding: 2rem;
    display: flex;
    align-items: center;

    a {
        color: #ff4800;
        margin-left: 1px;
        cursor: pointer;
        &:hover {
            font-weight: 800;
        }
    }

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

`
export const Divider = styled.div`
    height: 4rem;
    width: 1px;
    opacity: 0.3;
    background-color: #709abf;

    @media screen and (max-width: 800px) {
        height: 1px;
        width: 4rem;
        opacity: 0.3;
    }

`

export const ForwardIcon = styled.img`
    margin-right: 1rem;

    @media screen and (max-width: 800px) {
        margin-bottom: .5rem;
    }

`
export const CarGiftIcon = styled.img`
    margin-right: .8rem;

    @media screen and (max-width: 800px) {
        margin-bottom: -.5rem;
    }

`
