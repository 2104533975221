import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getUserProfile } from '../../../../Redux/AuthRedux';
import moment from 'moment';
import './customerDetail.css';

const CustomerDetail = ({ userId, closeModal }) => {
  const dispatch = useDispatch();
  const { userProfileData } = useSelector((state) => state.auth);
  const posInfo = userProfileData?.data;

  const [activeTab, setActiveTab] = useState('Details');

  useEffect(() => {
    if (userId) {
      dispatch(getUserProfile({ userId }));
    }
  }, [dispatch, userId]);

  const tabs = ['Details', 'Policy', 'Document'];

  const renderContent = () => {
    switch (activeTab) {
      case 'Details':
        return <DetailsTab posInfo={posInfo} />;
      case 'Policy':
        return <PolicyTab />;
      case 'Document':
        return <DocumentTab />;
      default:
        return null;
    }
  };

  return (
    <div className="customer-detail-page">
      <div className="menu-tabs">
        {tabs.map((tab) => (
          <button
            key={tab}
            className={activeTab === tab ? 'active' : ''}
            onClick={() => setActiveTab(tab)}
          >
            {tab}
          </button>
        ))}
        <button onClick={closeModal} className="close-button">
          &times;
        </button>
      </div>
      {renderContent()}
    </div>
  );
};

const DetailsTab = ({ posInfo }) => (
  <div className="customer-card">
    <ProfileSection posInfo={posInfo} />
    <POSDetails posInfo={posInfo} />
    <OtherDetails posInfo={posInfo} />
    <KYCStatus kycStatus={posInfo?.kycStatus} />
  </div>
);

const ProfileSection = ({ posInfo }) => (
  <div className="profile-section">
    <img
      src="https://via.placeholder.com/150"
      className="profile-photo"
      alt="profile"
    />
    <div className="profile-info">
      <h2>{posInfo?.fullName}</h2>
      <p>
        <strong>Name:</strong> {posInfo?.profile?.name}
      </p>
      <p>
        <strong>Email:</strong> {posInfo?.email}
      </p>
      <p>
        <strong>Phone:</strong> +91 - {posInfo?.phone}
      </p>
      <p>
        <strong>Member Since:</strong> {posInfo?.joiningDate || '20/01/2020'}
      </p>
    </div>
  </div>
);

const POSDetails = ({ posInfo }) => (
  <div className="details-section">
    <h3>POS Details</h3>
    <DetailRow label="POS ID" value={posInfo?._id} />
    <DetailRow label="House No." value={posInfo?.houseNo} />
    <DetailRow label="Street" value={posInfo?.street} />
    <DetailRow label="Town" value={posInfo?.town} />
    <DetailRow label="District" value={`${posInfo?.district} (Urban)`} />
    <DetailRow label="State" value={posInfo?.state} />
    <DetailRow label="Area" value={posInfo?.area} />
    <DetailRow label="Pincode" value={posInfo?.pincode} />
  </div>
);

const OtherDetails = ({ posInfo }) => (
  <div className="details-section">
    <h3>Other Details</h3>
    <DetailRow
      label="Gender"
      value={posInfo?.profile?.gender === 'm' ? 'Male' : 'Female'}
    />
    <DetailRow
      label="D.O.B"
      value={
        posInfo?.profile?.dob
          ? moment(posInfo?.profile?.dob).format('DD/MM/YYYY')
          : 'Not available'
      }
    />
    <DetailRow label="Pan Card" value={posInfo?.panNo} />
    <DetailRow label="Basic Qualification" value={posInfo?.qualification} />
    <DetailRow
      label="IRDA Number"
      value={posInfo?.irdaNumber || 'Not Available'}
    />
    <DetailRow
      label="IRDA Document"
      value={
        posInfo?.irdaImg ? (
          <a href={posInfo.irdaImg} download>
            Download
          </a>
        ) : (
          'No document available'
        )
      }
    />
  </div>
);

const KYCStatus = ({ kycStatus }) => (
  <div className="kyc-status">
    <h3>KYC Status</h3>
    <p>
      <strong>Status:</strong> {kycStatus || 'Pending'}
    </p>
  </div>
);

const DetailRow = ({ label, value }) => (
  <div className="detail-row">
    <p>
      <strong>{label}:</strong> {value}
    </p>
  </div>
);

const PolicyTab = () => (
  <div className="policy-table-section">
    <h3>Policy Details</h3>
    <table className="policy-table">
      <thead>
        <tr>
          <th>Customer ID</th>
          <th>Insurer</th>
          <th>Policy Status</th>
          <th>Policy Type</th>
          <th>Created Date</th>
          <th>Expiry Date</th>
          <th>Customer</th>
          <th>Mobile No.</th>
          <th>Email Id</th>
          <th>RM</th>
          <th>Amount</th>
          <th>Policy</th>
        </tr>
      </thead>
      <tbody>{/* Map through policy data and display rows */}</tbody>
    </table>
  </div>
);

const DocumentTab = () => (
  <div className="document-section">
    <h3>Document Details</h3>
    {/* Document details content goes here */}
  </div>
);

export default CustomerDetail;


