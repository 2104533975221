import { useLocation, useNavigate } from "react-router-dom"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Button, CloseBtn, ContinueBtn, ContinueBtnInactive, HDiv, HdivApart, HelpIcon, InputContainer, InputDiv, Layout } from "./styles";
import { updateCombinedModel, motorEnquiryAPI } from '../../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import HelpImg from '../../../assets/car-policy-details/help.svg'
import CloseImg from '../../../assets/brand-new-car/close-btn.svg'
import moment from 'moment';
import { useEffect } from 'react';
export const TypeofPolicy = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    const [enterIdvDropDown, setEnterIdvDropDown] = useState(false);
    const [lowerDropDown, setLowerDropDown] = useState(false);
    const [PriceValue, setPriceValue] = useState('');
    const [callApi, setCallApi] = useState(false);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const [TPAddons, setTPAddons] = useState([{ slug: "personalAccident" }]);
    const [vehicleAge, setVehiclAge] = useState(moment(moment()).diff(combinedRedux.regDate, 'months'))
    const [policy, setPolicy] = useState('')


    const onClickHandler = (navigation, name) => {
        dispatch(updateCombinedModel({ 'typeOfPolicy': name }));
        navigate(navigation, { state: { policy: name } })
    }

    useEffect(() => {

        var obj2 = {
            ...combinedRedux.insurance, ...{
                isClaimMadeinLastYear: false,
                ownerChanged: false,
                isPolicyExpired: false,
                IsPolicyExpiredIn90Days: false
            }
        };
        console.log("type", combinedRedux.type);
    }, [])





    const handelContinueBtn = (type) => {

        let date1 = moment();
        let date2 = moment(combinedRedux.regDate);

        var diff = moment(date1).diff(date2, 'days');
        if (diff > 30) {
            var obj2 = {
                ...combinedRedux.insurance, ...{
                    isClaimMadeinLastYear: false,
                    ownerChanged: false,
                    isPolicyExpired: false,
                    IsPolicyExpiredIn90Days: false
                }
            };
            var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
            dispatch(motorEnquiryAPI({ enquiryId: enquiryId, addOns: type != "privateCarOD" ? TPAddons : [], ncb: 0, newPolicyData: { policyType: type, 'insurance': obj2 } }));
            // if(type == 'privateCarTP'){
            //     var {enquiryId} = JSON.parse(localStorage.getItem('enquiryId'));
            //     navigate('/car-insurance/main?enquiryId='+enquiryId);
            // }
            // else{
            onClickHandler('/policy-details/type-of-policy3')
            // }
        }
        else {

            var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
            dispatch(motorEnquiryAPI({ enquiryId: enquiryId, addOns: type != "privateCarOD" ? TPAddons : [], ncb: 0, type: "new", newPolicyData: { policyType: type } }));
            setCallApi(true);
        }
        return

        if (combinedRedux.number) {
            if (combinedRedux.insurance) {
                var obj2 = {
                    ...combinedRedux.insurance, ...{
                        isClaimMadeinLastYear: false,
                        ownerChanged: false,
                        isPolicyExpired: false,
                        IsPolicyExpiredIn90Days: false
                    }
                };
                var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
                dispatch(motorEnquiryAPI({ enquiryId: enquiryId, addOns: TPAddons, ncb: 0, newPolicyData: { policyType: type, 'insurance': obj2 } }));
                if (type == 'privateCarTP') {
                    var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
                    navigate('/car-insurance/main?enquiryId=' + enquiryId);
                }
                else {
                    onClickHandler('/policy-details/type-of-policy3')
                }
            }
            else {
                var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
                dispatch(motorEnquiryAPI({ enquiryId: enquiryId, addOns: TPAddons, ncb: 0, newPolicyData: { policyType: type, 'insurance': obj2 } })).then(() => {
                    navigate('/car-insurance/main?enquiryId=' + enquiryId);
                })
            }

        }
        else {
            var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
            dispatch(motorEnquiryAPI({ enquiryId: enquiryId, addOns: TPAddons, ncb: 0, newPolicyData: { policyType: type } }));
            setCallApi(true);
        }
    }

    useEffect(() => {
        if (callApi) {
            var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
            navigate('/car-insurance/main?enquiryId=' + enquiryId);
            setCallApi(false);
        }
    }, [combinedRedux])



    const updateCoverAddons = (addons) => {
        var data = TPAddons.find((items) => items.slug === addons);
        if (data) {
            const newArr = TPAddons.filter(object => {
                return object.slug !== addons;
            });
            setTPAddons(newArr)
        }
        else {
            setTPAddons(oldArray => [...oldArray, { slug: addons }])
        }
    };




    return (
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent
                        title='Type of Policy'
                        step={1}
                        totalSteps={1}
                        noSearch={true}
                        yellowBorder={true}
                    />
                    {/* <Button className="selected">{policy ? policy : ''}</Button> */}




                    {policy === 'comprehensive' ?
                        <InputContainer>

                            <CloseBtn onClick={() => setPolicy('')} src={CloseImg} />
                            <p>Enter IDV Value</p>

                            <HdivApart>
                                <HDiv className="left">
                                    <input
                                        type={'checkbox'}
                                        id='enter-idv'
                                        onChange={e => setEnterIdvDropDown(!enterIdvDropDown)}
                                        checked={enterIdvDropDown}
                                    />
                                    <label for='enter-idv'>Enter IDV Value</label>
                                    <HelpIcon src={HelpImg} />
                                </HDiv>
                                <HDiv className="right">
                                    <input
                                        type={'checkbox'}
                                        id='lowest-idv'
                                        onChange={e => setLowerDropDown(!lowerDropDown)}
                                        checked={lowerDropDown}
                                    />
                                    <label for='lowest-idv'>Lowest IDV Value</label>
                                    <HelpIcon src={HelpImg} />
                                </HDiv>
                            </HdivApart>

                            <p className="input-description">Enter a value between min. ₹64,000 to max. ₹6,32,604</p>

                            <InputDiv>
                                <h2>₹</h2>
                                <input
                                    value={PriceValue}
                                    pattern="[0-9]*"
                                    onChange={(e) => setPriceValue(e.target.value.replace(/\D/, ''))}
                                />
                            </InputDiv>
                            {PriceValue ? (
                                <ContinueBtn onClick={() => {
                                    // combinedRedux
                                    handelContinueBtn();
                                    // onClickHandler('/policy-details/type-of-policy3')
                                }
                                }>Continue</ContinueBtn>
                            ) : <ContinueBtnInactive>Continue</ContinueBtnInactive>}
                        </InputContainer>
                        : null}
                    {
                        combinedRedux.type == 'old' ?
                            <>
                                <Button
                                    style={policy === 'comprehensive' ? { borderColor: '#F6931E' } :
                                        policy === 'standalone-tp' ? { opacity: .35, cursor: 'not-allowed' } : null
                                    }
                                    onClick={() => {
                                        if (policy === 'standalone-tp') {
                                            console.log('close standalone tp popup');
                                        } else {
                                            handelContinueBtn('privateCarComprehensive');
                                        }
                                    }
                                    }>
                                    Comprehensive Policy
                                </Button>
                                {policy === 'comprehensive' ? null :  vehicleAge > 30 ? null :
                                    <Button
                                        style={policy === 'standalone-tp' ? { opacity: .35, cursor: 'not-allowed' } : null}
                                        onClick={() => {
                                            handelContinueBtn('privateCarOD');

                                            // onClickHandler('/policy-details/type-of-policy2', 'Standalone OD Policy')
                                        }
                                        }
                                    >
                                        Standalone OD Policy
                                    </Button>}

                                {policy === 'comprehensive' ? null :
                                    <Button
                                        style={policy === 'standalone-tp' ? { borderColor: '#F6931E' } : null}
                                        onClick={() => {
                                            // onClickHandler('/policy-details/standalone-tp', 'Standalone TP Policy')
                                            setPolicy('standalone-tp')
                                            handelContinueBtn('privateCarTP');
                                        }
                                        }>Standalone TP Policy</Button>}

                                {policy === 'standalone-tp' ?

                                    <InputContainer>

                                        <CloseBtn onClick={() => setPolicy('')} src={CloseImg} />
                                        <p>Cover Details</p>

                                        <HdivApart>
                                            <HDiv className="left">
                                                <input
                                                    type={'checkbox'}
                                                    id='enter-idv'
                                                    checked={(TPAddons?.findIndex((items) => items.slug === 'additionalTPPD')) > -1 ? true : false}
                                                    onClick={() => updateCoverAddons('additionalTPPD')}
                                                />
                                                <label for='enter-idv'>Additional TPPD Cover</label>
                                                <HelpIcon src={HelpImg} />
                                            </HDiv>
                                            <HDiv className="right">
                                                <input
                                                    type={'checkbox'}
                                                    id='lowest-idv'
                                                    checked={(TPAddons?.findIndex((items) => items.slug === 'additionalTPA')) > -1 ? true : false}
                                                    onClick={() => updateCoverAddons('additionalTPA')}
                                                />
                                                <label for='lowest-idv'>Additional TPA Cover</label>
                                                <HelpIcon src={HelpImg} />
                                            </HDiv>
                                        </HdivApart>



                                        <ContinueBtn onClick={() => {
                                            handelContinueBtn('privateCarTP');
                                        }}>Continue</ContinueBtn>
                                    </InputContainer>

                                    : null}
                            </>
                            :
                            <>
                                {combinedRedux.type == 'new' &&
                                    <Button onClick={() => {
                                        handelContinueBtn('privateCarODTP');
                                        // onClickHandler('/policy-details/type-of-policy2', 'Standalone TP Policy(3 years) + OD 1 year')
                                    }}>Standalone TP Policy(3 years) + OD 1 year</Button>
                                }
                                {combinedRedux.type == 'new' &&
                                    <Button onClick={() => {
                                        handelContinueBtn('privateCarTP');
                                        // onClickHandler('/policy-details/type-of-policy2', 'Standalone TP Policy(3 years) + OD 1 year')
                                    }}>Standalone TP Policy(3 years) + OD 0 year</Button>
                                }
                            </>
                    }



                </Layout>
            </MainContainer>
        </>
    )
}