import { CarMainPage } from "../main-page/main-page"
import { BrandIcon, CloseIcon, MainContainer, Opacity, PopupContainer, Separator } from "./styles"
import CloseImg from '../../../assets/brand-new-car/close-btn.svg'
import BrandImg from '../../../assets/car-insurance/form-5/national.png'
import TickImg from '../../../assets/car-insurance/view-details/green-tick.svg'
import { useNavigate,useLocation } from "react-router-dom";
import { useEffect, useState } from 'react';
import { motorEnquiryAPI } from "../../../Redux/CarsRedux";
import { useSelector, useDispatch } from "react-redux";
import { ViewDetailsPopup } from "../../../components/view-details-popup/ViewDetailsPopup"

export const ViewDetailsCar1 = () => {

    const { state:{item} } = useLocation();
    const navigate = useNavigate()
    var {enquiryId} = JSON.parse(localStorage.getItem('enquiryId'));
    const [GST, setGST] = useState(0);
    const dispatch = useDispatch();

    // useEffect(() => {
    //     var cal = ((18/ 100) * item?.price)
    //     cal = Math.round(cal)
    //     setGST(cal)
    //     setTotalPrice(parseInt(item?.price) + parseInt(cal))
    // })

    useEffect(() => {
        localStorage.setItem('iHeight',document.body.scrollHeight)
    },[])
    

    

    return(
        <MainContainer>
            <CarMainPage />
            <Opacity />
            <ViewDetailsPopup  />
        </MainContainer>
    )
}