import ReactElasticCarousel from "react-elastic-carousel"
import { useNavigate, Link } from "react-router-dom"
import { MainContainer } from "../../assets/common-styles/common-styles"
import { SkeletonComponent } from "../../components/skeleton-component/SkeletonComponent"
import { Btns, CardContainer, Circle, CircleInactive, HDiv, Opacity, ReactElasticCarouselComp, SkeletonContainer } from "./styles"
import { updateCombinedModel, motorEnquiryAPI } from '../../Redux/CarsRedux';
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';

export const IntroScreen = () => {

    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const combinedReduxInsure = useSelector((state) => state.carsRedux.combinedRedux.insurance);
    const [combinedReduxState, setCombinedReduxState] = useState(combinedReduxInsure);
    const data = [
        {
            id: 1,
            ques: "Any Claim made in the last Year?"
        },
        {
            id: 2,
            ques: "Owner changed in last 12 months?"
        },
        {
            id: 3,
            ques: "Is your Policy expired ?"
        },
    ]
    const carousel = React.useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        var obj2 = {
            ...combinedReduxInsure, ...{
                isClaimMadeinLastYear: false
            }
        };
        dispatch(updateCombinedModel({ 'insurance': obj2 }));
        var obj2 = {
            ...combinedReduxInsure, ...{
                ownerChanged: false
            }
        };
        dispatch(updateCombinedModel({ 'insurance': obj2 }));
        var obj2 = {
            ...combinedReduxInsure, ...{
                isPolicyExpired: false
            }
        };
        dispatch(updateCombinedModel({ 'insurance': obj2 }));
        var obj2 = {
            ...combinedReduxInsure, ...{
                IsPolicyExpiredIn90Days: false
            }
        };
        dispatch(updateCombinedModel({ 'insurance': obj2 }));

    }, [])




    const navigate = useNavigate()

    const PaginationCircle = ({ onClick, key, active }) => {
        if (active === true) {
            return (
                <Circle
                    key={key}
                    onClick={onClick}
                />
            )
        } else if (active === false) {
            return (
                <CircleInactive
                    key={key}
                    onClick={onClick}
                />
            )
        }
    }


    const onClickFun = (id, activeSlide, state) => {
        console.log(id, activeSlide, state, "clicked");
        const ncbArr = ['0', '20', '25', '35', '45', '50']
        var ncbData = 0;
        for (let i = 0; i < ncbArr.length; i++) {
            if (combinedRedux.ncb == ncbArr[i]) {
                ncbData = ncbArr[i];
            }
        }
        console.log("ncbData", ncbData);
        var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
        if (id == 1 && state == 0) {
            var obj2 = {
                ...combinedReduxInsure, ...{
                    isClaimMadeinLastYear: false
                }
            };
            dispatch(updateCombinedModel({ 'insurance': obj2 }));
            carousel.current.goTo(activeSlide + 1)
        }
        else if (id == 1 && state == 1) {
            var obj2 = {
                ...combinedReduxInsure,
                    isClaimMadeinLastYear: true
                
            };
            var obj3 = { ...combinedRedux, ncb:0  }
            dispatch(motorEnquiryAPI({ ...obj3, 'insurance': obj2 }))
            dispatch(updateCombinedModel({ ...obj3, 'insurance': obj2 })).then((data) => {
                navigate('/car-insurance/main?enquiryId=' + enquiryId);
            })

        }
        if (id == 2 && state == 0) {
            var obj2 = {
                ...combinedReduxInsure, ...{
                    ownerChanged: false
                }
            };
            dispatch(updateCombinedModel({ 'insurance': obj2 }));
            carousel.current.goTo(activeSlide + 1)
        }
        else if (id == 2 && state == 1) {
            var obj2 = {
                ...combinedReduxInsure, ...{
                    ownerChanged: true
                }
            };
            var obj3 = { ...combinedRedux, ncb: '0' }
            dispatch(updateCombinedModel({ ...obj3, 'insurance': obj2 })).then((data) => {
                navigate('/car-insurance/main?enquiryId=' + enquiryId);
            })
        }
        if (id == 3 && state == 0) {
            var obj2 = {
                ...combinedReduxInsure, 
                    isPolicyExpired: false
            };
            var obj3 = { ...combinedRedux, ncb: ncbData }
            dispatch(updateCombinedModel({ ...obj3, 'insurance': obj2 })).then((data) => {
                navigate('/car-insurance/main?enquiryId=' + enquiryId);
            })
            // dispatch(updateCombinedModel({'insurance':obj2}));
            // carousel.current.goTo(activeSlide+1)

        }
        else if (id == 3 && state == 1) {
            var obj2 = {
                ...combinedReduxInsure,
                    isPolicyExpired: true
            };
            var obj3 = { ...combinedRedux, ncb: '0' }
            dispatch(updateCombinedModel({ ...obj3, 'insurance': obj2 })).then((data) => {
                navigate('/car-insurance/main?enquiryId=' + enquiryId);
            })

        }
        if (id == 4 && state == 0) {
            var obj2 = {
                ...combinedReduxInsure, ...{
                    IsPolicyExpiredIn90Days: false,

                }
            };
            var obj3 = { ...combinedRedux, ncb: ncbData }
            console.log("obj3 are --------->", obj3);
            dispatch(updateCombinedModel({ ...obj3, 'insurance': obj2 })).then((data) => {
                navigate('/car-insurance/main?enquiryId=' + enquiryId);
            })

        }
        else if (id == 4 && state == 1) {
            var obj2 = {
                ...combinedReduxInsure, ...{
                    IsPolicyExpiredIn90Days: true
                }
            };
            var obj3 = { ...combinedRedux, ncb: '0' }
            dispatch(updateCombinedModel({ ...obj3, 'insurance': obj2 }));
            navigate('/car-insurance/main?enquiryId=' + enquiryId);
        }


    }

    return (
        <>
            <MainContainer style={{ display: 'flex', justifyContent: 'center' }}>
                <SkeletonContainer>
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                </SkeletonContainer>

                <Opacity />

                <CardContainer>
                    <ReactElasticCarouselComp
                        ref={carousel}
                        showArrows={false}
                        enableSwipe={false}
                        renderPagination={({ pages, activePage, onClick }) => {
                            return (
                                <HDiv>
                                    {pages.map((item) => {
                                        const isActivePage = activePage === item;
                                        return (
                                            <PaginationCircle
                                                key={item}
                                                onClick={() => {
                                                    onClick(item)
                                                }}
                                                active={isActivePage}
                                            />
                                        )
                                    })}
                                </HDiv>
                            )
                        }}
                    >
                        {data.map(item => (
                            <>
                                <div className="carousel-container" key={item.id}>
                                    <p>{item.ques}</p>
                                    <Btns onClick={() => onClickFun(item.id, carousel?.current?.state?.activeIndex, 1)}>Yes</Btns>
                                    <Btns onClick={() => onClickFun(item.id, carousel?.current?.state?.activeIndex, 0)}>No</Btns>
                                    {/* <Btns onClick={() => console.log(carousel?.current?.state?.activeIndex) }>Yes</Btns>
                                    <Btns onClick={()=>carousel.current.goTo(3)}>No</Btns> */}

                                </div>
                            </>
                        ))}
                    </ReactElasticCarouselComp>
                </CardContainer>


            </MainContainer>
        </>
    )
}