import { useState, useEffect } from 'react';
import './nimgade.css';
import { FaLinkedin, FaInstagram  } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import Img1 from '../../../assets/abous-us/directorCeo.png'
import FooterNew from '../../../components/Footer/FooterNew';
import { IoMdMail } from "react-icons/io";

const PrashantPage = () => {

      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div className="prashant-page">
      <div className="profile">
        <img
          src={Img1}
          alt="Prashant N Nimgade"
          className="director-img"
        />
        <p className="director-name">Prashant N Nimgade</p>
        <p>B.E., PGDM (IIMA), FCCA, MSc (LBS)</p>
        <p className="director-designation">Founder & CEO</p>
        <p className='director-designation-title'>Financial Visionary Driving Global Empowerment & Sustainable Growth | Expert in Investments, Equity Research, and Strategic Financial Leadership | Leveraging 20+ years' expertise for corporate empowerment and strategic financial enrichment</p>
      </div>

        <div className="social-links">
          <a href="https://www.linkedin.com/in/prashant-nimgade-fcca-b011a84/" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={'2.3rem'} color="#000" />
          </a>
            <a href="mailto:prashant@vgibrokers.com" target="_blank" rel="noopener noreferrer">
            <IoMdMail size={'2.3rem'} color="#000" />
          </a>
          <a href="https://www.facebook.com/prashant.nimgade.5" target="_blank" rel="noopener noreferrer">
            <FaFacebookF size={'2.1rem'} color="#000" />
          </a>
          <a href="https://www.instagram.com/prashant_nm2018/" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={'2.3rem'} color="#000" />
          </a>
        </div>

      <div className="director-info">
        <p>
          <b>FINANCIAL LEADER: DRIVING GLOBAL EMPOWERMENT AND SUSTAINABLE GROWTH</b><br />
With over two decades of expertise in diverse financial domains including Investments, Equity Research, Treasury Management, Deal Structuring, and Project Funding, Prashant Nimgade has showcased adeptness in international financial landscapes across the UK, Europe, Singapore, and the Middle East, accumulating 13 years of invaluable experience.
        </p>
        <p>
         His contributions to esteemed entities such as Duratube Limited (UK), an affiliate of Vedanta Resources, Air Products Plc (UK), and Menzies Aviation Plc attest to his substantial impact. As the former Chief Financial Officer at Mineral Enterprises Limited, Bangalore, Prashant spearheaded financial operations while leading strategic initiatives in HR, IT, and overall business strategy.
        </p>
        <p>An alumnus of Visvesvaraya National College’94, Nagpur, Prashant holds a PGDM (PGP-96) in Business Strategy from IIM Ahmedabad and a Master’s Degree (MiF-2008) in Finance from the London School of Business. His accreditation as a qualified Chartered Certified Accountant from the UK underscores his exceptional financial acumen and global perspective.</p>

        <p>
          Leveraging Prashant's profound financial expertise, we facilitate companies in achieving Employee Empowerment through strategic financial enrichment and investment initiatives, ensuring sustainable growth and empowerment within corporate landscapes.
        </p>
      </div>
      <FooterNew />
    </div>
  );
};

export default PrashantPage;


