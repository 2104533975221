import { useState, useEffect } from 'react';
import './nimgade.css';
import { FaLinkedin, FaInstagram  } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { IoMdMail } from "react-icons/io";
import Img2 from '../../../assets/abous-us/director.png'
import FooterNew from '../../../components/Footer/FooterNew';

const ShandePage = () => {

      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

  return (
    <div className="prashant-page">
      <div className="profile">
        <img
          src={Img2}
          alt="Prashant N Nimgade"
          className="director-img"
        />
        <p className="director-name">AVINASH SHENDE</p>
        <p className="director-designation">Director</p>
        <p className='director-designation-title'>Seasoned Serial Entrepreneur | Driving IT Innovation, Cloud Technology, and Digital Payments | Expertise in System Management, Database Design, and Cutting-Edge Technologies | Driving Business Excellence and Global Technological Advancements</p>
      </div>

        <div className="social-links">
          {/* <a href="https://in.linkedin.com/in/avinash-shende-a09b863" target="_blank" rel="noopener noreferrer">
            <FaLinkedin size={'2.3rem'} color="#000" />
          </a>
            <a href="mailto:avinash@sipfund.com" target="_blank" rel="noopener noreferrer">
            <IoMdMail size={'2.3rem'} color="#000" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaFacebookF size={'2.1rem'} color="#000" />
          </a>
          <a href="#" target="_blank" rel="noopener noreferrer">
            <FaInstagram size={'2.3rem'} color="#000" />
          </a> */}
        </div>

      <div className="director-info">
        <p>
        <b>LEADING SUSTAINABLE GROWTH THROUGH INNOVATIVE BANKING, FINANCE, & IT SOLUTIONS </b><br />
        Avinash Shende serial entrepreneur, Director of Sip Fund Private Limited and the Promoter – Director of Virtual Galaxy Infotech Pvt. Ltd, PayNext Pvt. Ltd., Quintet Corp Pvt. Ltd. and Sampada Infosolutions Pvt. Ltd. possesses strong business experience of more than 25 years of experience in the IT industry. Avinash has in-depth knowledge of the Software Development, System Management, IT Facility Management – Networking and Database Management.
        </p>
        <p>
        He earned his BE in Computer Technology/Engineering from Nagpur University and MBA IT. He has been actively involved in the company from day one and has contributed in the overall design and implementation of the VGIPL products.
        </p>
        <p>His knowledge of IT Technology with core domain expertise in BFSI, Fintech environment has enabled him to understand the needs of the Clients impeccably. Through his techno-commercial approaches, he managed the development of cost effective IT solutions as per requirements of the market and has successfully won quality business for VGIPL while also focusing on customer satisfaction.</p>

        <p>
        Despite being 1st Generation entrepreneur, he has well understood the intricacies of the business and has contributed his best to make it successful.
        </p>
      </div>
      <FooterNew />
    </div>
  );
};

export default ShandePage;


