import { Input } from "antd";
import styled from "styled-components";

export const InpContainer = styled.div`
    position: relative;
    /* padding: 1rem; */
    width: 100%;
    margin:.5rem auto;

    .num-container {
        padding-top: .6rem;
        border-radius: .6rem;
        font-size: 1rem;
        border: 1px solid #B7B7B7;
        color: #605F5E;
        padding-bottom: 0.6rem;
        input {
            border: none;
            padding: 0.2rem;
            outline: none;
        }
        p {
            margin-left: .6rem;
        }
        .align-center {
            align-items: center;
        }

    }
    .flex {
        display: flex;
    }

`
export const FloatingP = styled.p`
    position: absolute;
    top: -10px;
    left: 15px;
    background-color: #fff;
    /* padding-left: .5rem; */
    padding-right: .5rem;
    z-index: 1;
    color: #B7B7B7;
    font-weight: 600;
@media screen and (max-width: 600px) {
       font-size:14px
    }

`
export const InputC = styled(Input)`
    width: 100%;
    padding-top: 1rem;
    border-radius: .6rem;
    font-size: 1rem;
    border: 1px solid #B7B7B7;
    color: #605F5E;
    @media screen and (max-width: 900px) {
        font-size:14px
    }
`
export const SelectDropdown = styled.select`
    width: 100%;
    padding: .6rem;
    outline: none;
    padding-top: 1rem;
    border-radius: .6rem;
    font-size: 1.1rem;
    border: 1px solid #B7B7B7;
    background:none;
    @media screen and (max-width: 900px) {
        font-size:14px
    }
`