import { useNavigate, Link, useLocation } from "react-router-dom"
// import { MainContainer } from "../../assets/common-styles/common-styles"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import { SkeletonComponent } from "../../../components/skeleton-component/SkeletonComponent"
import { CardContainer, Opacity, SkeletonContainer } from "./styles"
import { updateCombinedModel } from '../../../Redux/CarsRedux';
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { FloatingTitle, InputContainer, SelectDropdown } from "../../../components/car-details-component/styles";
import axios from "axios";
const DynamicVariants = () => {
    const { state } = useLocation();
    const dispatch = useDispatch();
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux)
    const [variantValue, setVariantValue] = useState()
    // const variantName = useSelector((state) => state.carsRedux.fuelType);


    useEffect(() => {
        const fetchData = async () => {
            const query = new URLSearchParams({ ...combinedRedux });
            const response = await axios.get(`http://localhost:3001/api/v1/vehicleInsurance/filterVariants/${state.slug}?${query}`)

            console.log("🚀 ~ file: dynamic-variants.js:40 ~ fetchData ~ data:", response.data)
            if (response.data.status === "success") {
                setVariantValue(response.data.data)
            }
        }
        fetchData()
    }, [combinedRedux])
    return (
        <MainContainer style={{ display: 'flex', justifyContent: 'center' }}>
            <SkeletonContainer>
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
                <SkeletonComponent />
            </SkeletonContainer>

            <Opacity />

            <CardContainer>
                Hii, Please cross check your variants with respect to company
                <InputContainer>
                    <FloatingTitle>{'Variants'}</FloatingTitle>
                    <SelectDropdown
                    // onChange={(e) => { setFuelValue(e.target.value); setError({}) }}
                    >
                        <option value={-1} disabled selected>{'Please select Variants'}</option>
                        {
                            // "hoierhoefjeo"
                            variantValue?.map(item => (
                                <>
                                    {/* {
                                        variantName == item ?
                                            <option selected value={item}>{item}</option>
                                            :
                                            <option value={item}>{item}</option>
                                        } */}
                                    <option value={item}>{item}</option>
                                </>
                            ))
                        }
                    </SelectDropdown>
                    {/* {error.hasOwnProperty('fuelType') ? (
                        <ErrorPopup errors={error.fuelType} />
                    )  */}
                    {/* : null} */}
                </InputContainer>
            </CardContainer>
        </MainContainer>
    )
}

export default DynamicVariants