import React, {useState, useEffect }from 'react'
import './Home.css'
import Front from '../../components/Front/Front'
import Insurance from '../../components/Insurance/Insurance'
import Footer from '../../components/Footer/FooterNew'
import HomePage from '../../components/HomePage/HomePage'
import { FaXTwitter, FaInstagram, FaLinkedin, FaYoutube, FaArrowRight, FaPhone } from "react-icons/fa6";
import { FaFacebook } from "react-icons/fa";
import { IoLogoWhatsapp, IoMdInformationCircleOutline } from "react-icons/io";

const Home = () => {
 const [collapsed, setCollapsed] = useState(true);
 const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

 let collapseTimer = null;

 // Function to determine if it's mobile view
 const checkIsMobile = () => {
   setIsMobile(window.innerWidth <= 768);
 };

 useEffect(() => {
   // Collapse timeout durations for mobile vs larger screens
   const collapseTimeout = isMobile ? 2000 : 5000; // 2 seconds on mobile, 5 seconds on larger screens

   collapseTimer = setTimeout(() => {
     setCollapsed(true);
   }, collapseTimeout);

   return () => clearTimeout(collapseTimer); // Clear timer on unmount
 }, [isMobile, collapsed]);

 useEffect(() => {
   // Add event listener to check for window resize
   window.addEventListener('resize', checkIsMobile);

   return () => {
     window.removeEventListener('resize', checkIsMobile);
   };
 }, []);

 // Expand the bar when hovered
 const handleMouseEnter = () => {
   setCollapsed(false); // Expand when hovered
   if (collapseTimer) clearTimeout(collapseTimer); // Clear any existing collapse timers
 };

 // Collapse the bar after a delay when hover ends
 const handleMouseLeave = () => {
   collapseTimer = setTimeout(() => {
     setCollapsed(true); // Collapse after a delay when hover stops
   }, 2000); // Auto-collapse 2 seconds after hover stops on all devices
 };


  return (
    <div className="Home">
      <div
        className={`sticky-social-bar ${collapsed ? 'collapsed' : ''}`}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={handleMouseEnter}
      >
        {/* Social icons */}
        <a
          className="facebook logoPadding"
          href="https://www.facebook.com/SimpliInsureOfficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaFacebook size={30} color="#fff" />
        </a>
        <a
          className="instagram logoPadding"
          href="http://www.instagram.com/simpliinsureofficial/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaInstagram size={30} color="#fff" />
        </a>
        <a
          className="twitter logoPadding"
          href="http://www.twitter.com/SimpliInsure"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaXTwitter size={30} color="#fff" />
        </a>
        <a
          className="linkedin logoPadding"
          href="http://www.linkedin.com/company/simpliinsure/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaLinkedin size={30} color="#fff" />
        </a>
        <a
          className="youtube logoPadding"
          href="http://www.youtube.com/@SimpliInsureOfficial"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaYoutube size={30} color="#fff" />
        </a>
        <a
          className="info logoPadding"
          href="#"
          target="_blank"
          rel="noopener noreferrer"
        >
          <IoMdInformationCircleOutline size={30} color="#fff" />
        </a>
      </div>

      {/* Collapsed state icon */}
      {collapsed && (
        <div className="collapsed-icon" onMouseEnter={handleMouseEnter}>
          <FaArrowRight size={24} color="#fff" />
        </div>
      )}

      {/* <Front />
      <Insurance /> */}
      <HomePage />
      <Footer />
    </div>
  );
}

export default Home