import styled from "styled-components";

export const HDiv = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin: .5rem auto;
    align-self: center;

    div {
        flex:1;
        margin: 0 .5rem;
    }

    hr {
        /* transform: rotate(90deg); */
        height: 50px;
        margin-top: .3rem;
        opacity: .3;
    }

`