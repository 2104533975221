import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { apiGetCall, apiPostCall, apiPostCall1, apiPutCall } from '../utilities/site-apis';
import Config from './../utilities/Config';
import axios from 'axios';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  policyData: {},
  documents: {},
};

// Async thunk to fetch policy data
export const fetchPolicyData = createAsyncThunk(
  'policy-upload/fetchPolicyData',
  async (policyId, { rejectWithValue }) => {
    try {
      // const response = await apiGetCall(`/new-policy/${policyId}`);
      const response = await axios.get(
        `${Config.apiBaseUrl}/new-policy/${policyId}`
      );
      if (response.data.status === 'error') {
        return rejectWithValue(response.data);
      }
      return response.data; // Return policy data
    } catch (error) {
      console.log('Error during API call:', error); // Log the error
      return rejectWithValue(error.response?.data || 'Unknown error occurred');
    }
  }
);

// Async thunk to update policy documents
export const updatePolicy = createAsyncThunk(
  'policy-upload/updateDocuments',
  async (params, { rejectWithValue }) => {
    console.log("🚀 ~ params:", params)
    try {
      const response = await apiPostCall1(
        `/motor/enquiry`,
        params
      );
      if (response.data.status === 'error') {
        return rejectWithValue(response.data);
      }
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);

const policySlice = createSlice({
  name: 'policy-upload',
  initialState,
  reducers: {
    setPolicyData: (state, action) => {
      state.policyData = action.payload; // Set policy data from API
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPolicyData.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(fetchPolicyData.fulfilled, (state, action) => {
        state.isFetching = false;
        state.success = true;
        state.policyData = action.payload; // Store fetched policy data
      })
      .addCase(fetchPolicyData.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload || true;
      })
      .addCase(updatePolicy.pending, (state) => {
        state.isFetching = true;
      })
      .addCase(updatePolicy.fulfilled, (state, action) => {
        state.isFetching = false;
        state.policyData = { ...state.policyData, ...action.payload };
        state.success = true;
      })
      .addCase(updatePolicy.rejected, (state, action) => {
        state.isFetching = false;
        state.error = action.payload || true;
      });
  },
});

export const { setPolicyData } = policySlice.actions;
export default policySlice.reducer;
