import React, { useState } from 'react';
import dropdown from '../../../../utilities/stateData';
import { useDispatch } from 'react-redux';
import { ProductLead } from '../../../../Redux/AuthRedux';
import './form.css';

const InsuranceForm = ({ product }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    state: '',
    productInterested: '',
    isSocialMedia: false,
  });

  const handleChange = (e) => {
      const { name, value, type, checked } = e.target;

      let updatedValue = value;
      let errorMessage = '';

      if (name === 'name') {
        updatedValue = value.replace(/[^A-Za-z\s]/g, '');
      } else if (name === 'phone') {
        updatedValue = value.replace(/\D/g, ''); // Remove all non-digit characters
        if (updatedValue.length !== 10) {
          errorMessage = 'Phone number must be 10 digits';
        }
        updatedValue = updatedValue.slice(0, 10); // Ensure only the first 10 digits are kept
      } else if (name === 'email') {
        // Updated regex for email validation
        if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(value)) {
          errorMessage = 'Please enter a valid email address';
        }
      } else if (name === 'state') {
        updatedValue = value.replace(/[^A-Za-z\s]/g, '');
      }

      setFormData({
        ...formData,
        [name]: type === 'checkbox' ? checked : updatedValue,
      });

      setError(errorMessage);
      setSubmitted(false);
    };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple form validation
    let errorMessage = '';
    if (!formData.name) {
      errorMessage = 'Please enter your name';
    } else if (!formData.phone) {
      errorMessage = 'Please enter your phone number';
    } else if (!formData.email) {
      errorMessage = 'Please enter your email';
    } else if (!formData.state) {
      errorMessage = 'Please enter your state';
    } else if (!formData.productInterested) {
      errorMessage = 'Please specify the product you are interested in';
    } else if (typeof formData.isSocialMedia !== 'boolean') {
      errorMessage = 'Please select whether you use social media';
    } else {
      // Additional validation for phone and email
      if (formData.phone.length !== 10) {
        errorMessage = 'Phone number must be 10 digits';
      } else if (!/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[a-zA-Z]{2,}$/.test(formData.email)) {
        errorMessage = 'Please enter a valid email address';
      }
    }

    if (errorMessage) {
      setError(errorMessage);
      return;
    }

    setLoading(true);

    try {
      // Simulating form submission delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      
      // Dispatching the contact lead action
      dispatch(ProductLead(formData));
      setSubmitted(true);
    } catch (error) {
      setError('Failed to submit the form. Please try again later.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <div className="form-container">
      <h2>Find the Perfect Insurance Plan for You in Minutes - Start Here</h2>
      <form onSubmit={handleSubmit}>
        <label className='form-label'>
          Enter Your Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`form-data ${error && !formData.name ? 'error' : ''}`}
          />
          {/* {error && !formData.name && <span className="error-message">Please enter your name</span>} */}
        </label>
        
        <label className='form-label'>
          Enter Your Phone Number:
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`form-data ${error && !formData.phone ? 'error' : ''}`}
          />
          {/* {error && !formData.phone && <span className="error-message">Please enter your phone number</span>} */}
        </label>
        
        <label className='form-label'>
          Enter Your Email ID:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`form-data ${error && !formData.email ? 'error' : ''}`}
          />
          {/* {error && !formData.email && <span className="error-message">Please enter your email</span>}*/}
        </label> 
        
        <label className='form-label'>
          Select Your State:
          <select name="state" value={formData.state} onChange={handleChange} className={`form-select ${error && !formData.state ? 'error' : ''}`}>
            <option value="">Select</option>
            {Object.keys(dropdown).map((key) => (
              <option key={key} value={key}>{dropdown[key]}</option>
            ))}
          </select>
          {/* {error && !formData.state && <span className="error-message">Please select your state</span>} */}
        </label>
        
        <label className='form-label'>
            Interested In:
            <select
              name="productInterested"
              value={formData.productInterested}
              onChange={handleChange}
              className={`form-select ${error && !formData.productInterested ? 'error' : ''}`}
            >
              <option value="">Select</option>
              <option value={`Buy ${product} Policy`}>Buy {product} Policy</option>
              <option value={`Renew ${product} Policy`}>Renew {product} Policy</option>
            </select>
            {/* {error && !formData.productInterested && <span className="error-message">Please select an insurance type</span>} */}
          </label>

        
        <label className='form-label'>
          <input
            type="checkbox"
            name="isSocialMedia"
            checked={formData.isSocialMedia}
            onChange={handleChange}
          />
          By ticking this box, I acknowledge that I have read and agreed to
          the terms, conditions, and privacy policy. I authorize
          SimpliInsurance.com and its authorized partners to contact me via
          phone call, SMS, WhatsApp, and email.
        </label>
        
        <button type="submit" className='health-form-btn' disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
      </form>
      {error && !formData.isSocialMedia && <span className="error-message">Please agree to the terms and conditions</span>}
      {submitted && <div className="success-message">Form submitted successfully!</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default InsuranceForm;
