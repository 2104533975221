import './process.css';

const processData = [
  {
    "title": "Renewal of Insurance Policy",
    "steps": [
      "Receive renewal reminders before the policy expiration date.",
      "Review your current policy and assess if any changes are needed.",
      "Contact us to renew the policy.",
      "Pay the renewal premium within the stipulated time frame.",
      "Receive the renewed policy documents upon successful payment."
    ]
  },
  {
    "title": "Buying Insurance Policy",
    "steps": [
      "Research and compare policies. Click here to speak with an expert.",
      "Choose the most suitable policy based on coverage and premiums.",
      "Fill out the application form accurately.",
      "Submit required documents for verification.",
      "Once approved, make the payment for the selected policy."
    ]
  },
  {
    "title": "Claim Process of Insurance Policy",
    "steps": [
      "Notify the insurance company about the claim promptly.",
      "Fill and submit the claim form along with necessary documents.",
      "Await the claim assessment and verification process by the insurer.",
      "The insurer will process, i.e., approve or reject the claim based on policy terms."
    ]
  },
  {
    "title": "Reimbursement Process of Insurance Policy",
    "steps": [
      "Seek medical treatment and pay for services or treatments.",
      "Retain all original bills, receipts, and medical records.",
      "Submit a reimbursement claim with the insurer along with all relevant documents.",
      "The insurer reviews the claim and approves the eligible amount for reimbursement.",
      "Receive the approved reimbursement amount after the claim assessment."
    ]
  }
]

const InsuranceProcessCards = () => {
  return (
    <div className="insurance-process-cards-container">
      {processData.map((item, index) => (
        <div key={index} className="process-card-container">
          <h2 className='process-heading'>{item.title}</h2>
          <ul className='process-ul'>
            {item.steps.map((step, stepIndex) => (
              <li className='process-li' key={stepIndex}>{step}</li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default InsuranceProcessCards;

