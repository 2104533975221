import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CardHeader } from "../../../components/card-header/CardHeader"
import Footer from "../../../components/Footer/FooterNew"
import { BgDiv, BottomDiv, BottomTitle, CardMainContainer, ConfirmBtn, DateInput, HDiv, InputDiv, InputSelect, MainDiv, Title } from "./styles"

export const CarForm3 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [data, setData] = useState({
        ownerChanged:1,
        manufactureDate:'',
        owner:0,
        claimMade:1,
        regDate:'',
        prevPolicyType:1,
        ncb:'',
        policyExpiry:'',
        prevInsurernPolicyExp:''
    })

    const InputComponent = ({type, options, title, phText, variant}) => {

        if(type==='radio') {

            
            const handleChange = (e) => {
                // console.log(e.target.value);
                if(variant==='owner-changed') {
                    setData(prev => ({...prev, ownerChanged:e.target.value}))
                }
                if(variant==='owned-by') {
                    setData(prev => ({...prev, owner:e.target.value}))
                }
                if(variant==='claimed') {
                    setData(prev => ({...prev, claimMade:e.target.value}))
                }
                if(variant==='prev-type') {
                    setData(prev => ({...prev, prevPolicyType:e.target.value}))
                }
            }

            return(
                <InputDiv>
                    <Title>{title}</Title>
                    <HDiv className="radios-container">
                        <HDiv className="yes-container">
                            <input 
                                value={variant==='owner-changed'?options[0]:
                                    variant==='owned-by'?options[0]:
                                        variant==='claimed'?options[0]:
                                            variant==='prev-type'?options[0]:null} 
                                type={'radio'} 
                                onChange={handleChange}
                                checked={variant==='owner-changed'?data.ownerChanged=='Yes'?true:false:
                                    variant==='owned-by'?data.owner=='Individual'?true:false:
                                        variant==='claimed'?data.claimMade=='Yes'?true:false:
                                    variant==='prev-type'?data.prevPolicyType=='Comprehensive'?true:false:null}
                            />
                            <p>{options[0]}</p>
                        </HDiv>
                        <HDiv className="no-container">
                            <input 
                                value={variant==='owner-changed'?options[1]:
                                    variant==='owned-by'?options[1]:
                                        variant==='claimed'?options[1]:
                                            variant==='prev-type'?options[1]:null} 
                                type={'radio'} 
                                onChange={handleChange}
                                checked={variant==='owner-changed'?data.ownerChanged=='No'?true:false:
                                    variant==='owned-by'?data.owner=='Organization'?true:false:
                                        variant==='claimed'?data.claimMade=='No'?true:false:
                                    variant==='prev-type'?data.prevPolicyType=='Third Party'?true:false:null}
                            />
                            <p>{options[1]}</p>
                        </HDiv>
                    </HDiv>
                </InputDiv>
            )
        }
        else if(type==='date') {

            const handleChange = (e) => {
                if(variant=='manufacture') {
                    setData(prev => ({...prev, manufactureDate:e.target.value}))
                }
                if(variant=='registration') {
                    setData(prev => ({...prev, regDate:e.target.value}))
                }
                if(variant=='expiry') {
                    setData(prev => ({...prev, policyExpiry:e.target.value}))
                }
            }

            return(
                <InputDiv>
                    <Title>{title}</Title>
                    <DateInput type={'date'} onChange={handleChange} />
                    <p className="date-placeholder">{phText==='MonthYear'?
                        'Month & year DD-MM-YY':phText==='reg'?
                            'Registration Date':
                                phText==='expiry'?'Expiry date':null}</p>
                </InputDiv>
            )
        }
        else if(type==='select') {

            const handleChange = (e) => {
                if(variant==='ncb') {
                    setData(prev => ({...prev, ncb:e.target.value}))
                }
                if(variant==='prev-insurer') {
                    setData(prev => ({...prev, prevInsurernPolicyExp:e.target.value}))
                }
            }
            
            return(
                <InputDiv>
                    <Title>{title}</Title>
                    <InputSelect 
                        value={variant==='ncb'?data.ncb:
                            variant==='prev-insurer'?data.prevInsurernPolicyExp:null} 
                        onChange={handleChange}
                    >
                        <option value={options[0]}>{options[0]}</option>
                        <option value={options[1]}>{options[1]}</option>
                        <option value={options[2]}>{options[2]}</option>
                    </InputSelect>
                </InputDiv>
            )
        }
    }

    return(
        <>
            <MainDiv>
                <BgDiv />
                <CardMainContainer>

                    <CardHeader title={'Previous Insurance Details'} />
                    
                    <BottomTitle>Selected Private Car</BottomTitle>

                    <BottomDiv>
                        <InputComponent variant='owner-changed' type='radio' title={'Owner Changed in last 12 months?'} options={['Yes', 'No']} />

                        <InputComponent variant='manufacture' type='date' phText='MonthYear' title='Manufacturer Month & Year' />

                        <InputComponent variant='owned-by' type='radio' title={'Vehicle Owned By'} options={['Individual', 'Organization']} />
                        <InputComponent variant='claimed' type='radio' title={'Claim made in expiring policy?'} options={['Yes', 'No']} />

                        <InputComponent variant={'registration'} type='date' phText='reg' title='Registration Date' />

                        <InputComponent variant='prev-type' type='radio' title={'Previous Policy Type'} options={['Comprehensive', 'Third Party']} />

                        <InputComponent variant='ncb' type='select' options={['50%', '70%', '90%']} title='Previous No Claim Bonus (NCB)' />

                        <InputComponent variant={'expiry'} type='date' phText='expiry' title='Policy Expiry Date' />

                        <InputComponent variant='prev-insurer' type='select' options={['Insurer & Policy expiry', 'Policy expiry', 'Fraud']} title='Previous Insurer & Policy Expiry' />
                    </BottomDiv>

                    <Link to='/car/form-4'>
                        <ConfirmBtn>Confirm and Continue</ConfirmBtn>
                    </Link>

                </CardMainContainer>
            </MainDiv>
            <Footer />
        </>
    )
}