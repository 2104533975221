 import React, { useRef, useEffect, useState } from 'react';
import '../components/label.css';

function CustomInputBox({ value, onBlur, label, onChange, placeholder, sum, mobile }) {
  const inputRef = useRef();
  const [inputValue, setInputValue] = useState(value);
  const [typingTimeout, setTypingTimeout] = useState(null);

  useEffect(() => {
    const handleInputBlur = () => {
      onBlur(inputRef.current.value);
    };

    const handleEnterKeyPress = (event) => {
      if (event.keyCode === 13) {
        handleInputBlur();
      }
    };

    setInputValue(value);

    inputRef?.current?.addEventListener('blur', handleInputBlur);
    inputRef?.current?.addEventListener('keydown', handleEnterKeyPress);

    return () => {
      inputRef?.current?.removeEventListener('blur', handleInputBlur);
      inputRef?.current?.removeEventListener('keydown', handleEnterKeyPress);
    };
  }, [onBlur, value]);

  const handleKeyPress = (event) => {
    const charCode = event.which ? event.which : event.keyCode;
    const char = String.fromCharCode(charCode);

    // Regular expression to only allow positive integers without any special symbols
    const positiveIntegerRegex = /^[0-9]+$/;

    // If the pressed key is not a numeric character and not Enter key, prevent default behavior
    if (!positiveIntegerRegex.test(char) && charCode !== 13) {
      event.preventDefault();
    }

    // If the input is '0' and the pressed key is not a numeric character, prevent default behavior
    if (inputValue === '0' && !positiveIntegerRegex.test(char)) {
      event.preventDefault();
    }
  };

const handleInputChange = (event) => {
  const newValue = event.target.value;

  // Regular expression to only allow positive integers without any special symbols
  const positiveIntegerRegex = /^[0-9]+$/;

  // If the new value is an empty string or starts with '0', set the input value to an empty string
  if (newValue === '' || newValue.startsWith('0')) {
    setInputValue('');
  }
  // If the new value is a positive integer, update the input value and call the API
  else if (positiveIntegerRegex.test(newValue)) {
    setInputValue(newValue);

    // Clear the previous timeout
    if (typingTimeout) {
      clearTimeout(typingTimeout);
    }

    // Set a new timeout for API call after 500ms of inactivity
    setTypingTimeout(setTimeout(() => {
      onChange(newValue); // Call the API function here
    }, 1200));
  }
};

  return (
    <>
    <div>
      <label className= 'labelUpperMobile'>{label}</label>
      <input
        ref={inputRef}
        type='text'
        className= {sum ? 'inputBoxSum-mobile custom-input-mobile' : 'custom-input-mobile'}
        value={inputValue}
        placeholder= {placeholder}
        onKeyPress={handleKeyPress} // Restrict input to only numeric characters (except Enter)
        onChange={handleInputChange} // Update the inputValue state when the input value changes
      />
    </div>
    </>
  );
}

export default CustomInputBox;