export const only10DigitAndNumber = (e) => {
    const inputValue = e.target.value;

    // Remove non-numeric characters and limit to 10 digits
    const sanitizedValue = inputValue.replace(/[^0-9]/g, '').slice(0, 10);
    console.log("🚀 ~ only10DigitAndNumber ~ sanitizedValue:", sanitizedValue)

    // If the input is not numeric, return an empty string
    if (!/^[0-9]*$/.test(inputValue)) {
        e.preventDefault()
    }

    return sanitizedValue;
};
