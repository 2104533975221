import { ActivePage, BackIcon, ConfirmBtn, Container, GridContainer, HDiv, HDivApart, Heading, LeftArrow, Line, Pages, PaginationContainer, QuoteCardsContainer, RightArrow, SelectedBtn, SelectedView, View } from "./styles"
import BackIconImg from '../../../../assets/commons/back-icon.png'
import HamburgerIcon from '../../../../assets/commons/hamburger.png'
import TilesIcon from '../../../../assets/commons/tiles.png'
import LeftArrowIcon from '../../../../assets/get-quotes/left-arrow.png'
import RightArrowIcon from '../../../../assets/get-quotes/right-arrow.png'
import { cardData } from './data'
import Footer from "../../../../components/Footer/FooterNew"
import { QuoteCard } from "../../../../components/quote-card/QuoteCard"
import { useEffect } from "react"
import { Link } from "react-router-dom"

export const GetQuotes = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    return(
        <>
            
            <Container>
                <QuoteCardsContainer>

                    <HDivApart>
                        <HDiv>
                            <BackIcon src={BackIconImg} />
                            <Heading>Quotes From Various Companies</Heading>
                            <Line />
                        </HDiv>
                        <HDiv>
                            <View src={HamburgerIcon} />
                            <SelectedView src={TilesIcon} />
                        </HDiv>
                    </HDivApart>

                    <GridContainer>
                        {cardData.map((item, index) => {
                            return(
                                <QuoteCard variant='life' key={item.id} item={item}  />
                            )
                        })}
                    </GridContainer>

                    <PaginationContainer>
                        <LeftArrow src={LeftArrowIcon} />
                        <ActivePage>1</ActivePage>
                        <Pages>2</Pages>
                        <Pages>2</Pages>
                        <Pages>3</Pages>
                        <Pages>4</Pages>
                        <RightArrow src={RightArrowIcon} />
                    </PaginationContainer>

                    <Link to='/car-form-preview'>
                        <ConfirmBtn>
                            confirm and continue
                        </ConfirmBtn>
                    </Link>

                </QuoteCardsContainer>
            </Container>
            <Footer />
        </>
    )
}