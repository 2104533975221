import { InputComponent } from "../../../components/input-component/InputComponent"
import {
  updateFirstPageData,
  paymentRedirect,
  paymentRedirectGPP,
  paymentRedirectGift,
  paymentRedirectSJB,
  paymentRedirectROP,
  paymentRedirectTATA,
  paymentRedirectMRS,
  lifeIciciIpsEbiPdf,
  lifeIciciSjbEbiPdf,
  lifeIciciRopEbiPdf,
  lifeTataSISPdf,
  lifeTataMrsSISPdf,
  paymentRedirectHDFC,
  HdfcLifePlusQuote,
  paymentRedirectHDFCPlus,
  lifeBajajBiPdf,
  lifeGoDigitBIPdf,
  paymentRedirectGoDigit,
  GoDigitLifeQuote,
} from '../../../Redux/LifeRedux';
import { useSelector, useDispatch } from "react-redux";
import { useState } from 'react';
import "./skeleton.css"
import moment from "moment";
import handleAlert from "../../../components/hanleAlert";
import { HdfcLifeQuote, lifeEnquiryAPI} from "../../../Redux/LifeRedux";
import { useNavigate } from "react-router-dom";
import { pincodeDataHandler } from "./pincode-handler";
import { validationHandler } from "./handlerValidation";
import { ErrorPop } from "./errorHandler";

// for user data
// const userData = JSON.parse(localStorage.getItem('userDetails'))

export const SkeletonCard = ({ title, item }) => {
  console.log('🚀 ~ SkeletonCard ~ item:', item);

  const dispatch = useDispatch();
  const combinedRedux = useSelector((state) => state.life.combinedRedux);
  const [error, setError] = useState('');
  const navigate = useNavigate();

  // Destructing the repeated field on combineRedux
  const userDetailsRedux = combinedRedux?.userDetail;

  const [firstName, setFirstName] = useState(
    userDetailsRedux?.firstName ? userDetailsRedux?.firstName : ''
  );
  const [middleName, setMiddleName] = useState(
    userDetailsRedux?.middleName ? userDetailsRedux?.middleName : ''
  );
  const [lastName, setLastName] = useState(
    userDetailsRedux?.lastName ? userDetailsRedux?.lastName : ''
  );
  const [gender, setGender] = useState(userDetailsRedux?.gender);
  const [dob, setDob] = useState(
    moment(userDetailsRedux?.dob).format('DD-MM-YYYY')
  );
  const [smoking, setSmoking] = useState(
    userDetailsRedux?.isTobbaco === true ? 'yes' : 'no'
  );
  const [annualIncome, setAnnualIncome] = useState(
    userDetailsRedux?.annualIncome
  );
  const [email, setEmail] = useState(
    userDetailsRedux?.email ? userDetailsRedux?.email : ''
  );
  const [mobile, setMobile] = useState(
    userDetailsRedux?.phone ? userDetailsRedux?.phone : ''
  );
  const [state, setState] = useState(
    userDetailsRedux?.state ? userDetailsRedux?.state : ''
  );
  const [city, setCity] = useState(
    userDetailsRedux?.city ? userDetailsRedux?.city : ''
  );
  const [pincode, setPincode] = useState(
    userDetailsRedux?.pincode ? userDetailsRedux?.pincode : ''
  );
  const [cityDropdown, setCityDropdown] = useState([city]);

  // others useful states and URL
  const [buyNowClicked, setBuyNowClicked] = useState(false);
  const ebiAllowedSlugs = ['life-icici', 'life-icici-sjb', 'life-icici-rop'];
  const queryParameter = new URLSearchParams(window.location.search);
  const enquiryIds = queryParameter.get('enquiryId');

  const pincodeHandler = (e) => {
    pincodeDataHandler(e, setPincode, setState, setCityDropdown, setCity);
  };

  const handleValidation = () => {
    return validationHandler({
      firstName,
      middleName,
      lastName,
      email,
      mobile,
      state,
      city,
      pincode,
      setError,
    });
  };

  // for the error alert
  const ErrorPopups = ErrorPop(error);
  //Error handling End
  const obj = {
    enquiryId: combinedRedux?.enquiryId ? combinedRedux?.enquiryId : enquiryIds,
    planType: combinedRedux?.planType,
    status: 'lead',
    userDetail: {
      firstName: firstName,
      middleName: middleName,
      lastName: lastName,
      gender: gender,
      dob: combinedRedux?.userDetail?.dob,
      isTobbaco: smoking,
      annualIncome: annualIncome,
      email: email,
      phone: mobile,
      state: state,
      city: city,
      pincode: pincode,
    },
    interestedPolicy: {
      slug: item?.slug,
      companySlugName: item?.sluginfo?.name,
      slugImage: item?.sluginfo?.logo,
      policyTerm: item?.policyTerm ? item?.policyTerm : null,
      sumAssured: item?.sumAssured ? item?.sumAssured : null,
      premiumPaymentTerm: item?.premiumPaymentTerm
        ? item?.premiumPaymentTerm
        : null,
      paymentFrequency: item?.mode ? item?.mode : null,
      premium: item?.totalPayableAmount ? item?.totalPayableAmount : null,
      appNumber:
        (item.slug === 'life-hdfc' || item.slug === 'life-hdfc-plus') &&
        item.hdfc_appnum,
    },
  };

  // bajaj pdf
  const handleBajajBiPdf = () => {
    const validationError = handleValidation();
    if (validationError) {
      alert(validationError); // Show alert with the error message
      return;
    }

    const sisPdfMethods = {
      'bajaj-life': lifeBajajBiPdf,
    };

    // Create a reference for the new window/tab
    let pdfWindow = window.open('', '_blank');

    dispatch(updateFirstPageData(obj)).then((res) => {
      if (res?.payload) {
        const selectedEBI = sisPdfMethods[item.slug];
        if (selectedEBI) {
          // based on the slug it dispatch
          dispatch(selectedEBI({ enquiryId: combinedRedux?.enquiryId })).then(
            (res) => {
              // Open the PDF in a new tab or window
              const pdfUrl = res?.payload?.data;
              if (pdfUrl !== undefined) {
                // Set the location of the new window to the PDF URL
                pdfWindow.location.href = pdfUrl;
              }
              window.location.reload();
            }
          );
        }
      }
    });
  };

  // go digit for ebi pdf
  // bajaj pdf
  const handleGoDigitBiPdf = () => {
    const validationError = handleValidation();
    if (validationError) {
      alert(validationError); // Show alert with the error message
      return;
    }

    const sisPdfMethods = {
      'goDigit-life': lifeGoDigitBIPdf,
    };

    // Create a reference for the new window/tab
    let pdfWindow = window.open('', '_blank');

    dispatch(updateFirstPageData(obj)).then((res) => {
      if (res?.payload) {
        const selectedEBI = sisPdfMethods[item.slug];
        if (selectedEBI) {
          // based on the slug it dispatch
          dispatch(selectedEBI({ enquiryId: combinedRedux?.enquiryId })).then(
            (res) => {
              // Open the PDF in a new tab or window
              const pdfUrl = res?.payload?.data;
              if (pdfUrl !== undefined) {
                // Set the location of the new window to the PDF URL
                pdfWindow.location.href = pdfUrl;
              }
              window.location.reload();
            }
          );
        }
      }
    });
  };

  // EBI PDF
  const handleEbiPdf = () => {
    const validationError = handleValidation();
    if (validationError) {
      handleAlert(item?.sluginfo?.name, validationError); // Show alert with the error message
      return;
    }

    const ebiPdfMethods = {
      'life-icici': lifeIciciIpsEbiPdf,
      'life-icici-sjb': lifeIciciSjbEbiPdf,
      'life-icici-rop': lifeIciciRopEbiPdf,
    };
    dispatch(updateFirstPageData(obj)).then((res) => {
      if (res?.payload) {
        const selectedEBI = ebiPdfMethods[item.slug];

        if (selectedEBI) {
          // based on the slug it dispatch
          dispatch(selectedEBI({ enquiryId: combinedRedux?.enquiryId })).then(
            (res) => {
              if (res?.payload?.data?.EBIPdfDigital) {
                window.open(res?.payload?.data?.EBIPdfDigital, '_blank');
                window.location.reload();
                // window.location.href = res?.payload?.data?.EBIPdfDigital;
              }
            }
          );
        }
      }
    });
  };

  // handle pdf for tata product
  const handleTataPdf = () => {
    const validationError = handleValidation();
    if (validationError) {
      handleAlert(item?.sluginfo?.name, validationError); // Show alert with the error message
      return;
    }

    const sisPdfMethods = {
      'tata-life-srp': lifeTataSISPdf,
      'tata-mrss': lifeTataMrsSISPdf,
    };

    dispatch(updateFirstPageData(obj)).then((res) => {
      if (res?.payload) {
        const selectedEBI = sisPdfMethods[item.slug];
        if (selectedEBI) {
          // based on the slug it dispatch
          dispatch(selectedEBI({ enquiryId: combinedRedux?.enquiryId })).then(
            (res) => {
              // Open the PDF in a new tab or window
              window.open(res?.payload?.data, '_blank');
              window.location.reload();
            }
          );
        }
      }
    });
  };

  const handleContinue = (e) => {
    const validationError = handleValidation();
    if (validationError) {
      handleAlert(item?.sluginfo?.name, validationError);
      return;
    }

    localStorage.setItem('redirectAfterLoginURL', window.location.href);
    // for get auth token
    const isAuthTokenExist = localStorage.getItem('Authorization');
    if (!isAuthTokenExist || isAuthTokenExist === 'null') {
      navigate('/signin-signup');
      return;
    }

    setBuyNowClicked(true);

    dispatch(updateFirstPageData(obj)).then((res) => {
      if (res?.payload) {
        // For TATA AIA
        if (item.slug === 'tata-life-srp') {
          dispatch(
            paymentRedirectTATA({ enquiryId: combinedRedux?.enquiryId })
          ).then((res) => {
            const form = document.createElement('form');
            form.method = 'POST';
            form.target = '_top';
            form.action = process.env.REACT_APP_TATA_URL;
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'REQUEST';
            input.value = res.payload.data;
            form.append(input);
            document.body.appendChild(form);
            form.submit();
          });
        }

        // FOR TATA MRS
        if (item.slug === 'tata-mrss') {
          dispatch(
            paymentRedirectMRS({ enquiryId: combinedRedux?.enquiryId })
          ).then((res) => {
            const form = document.createElement('form');
            form.method = 'POST';
            form.target = '_top';
            form.action = process.env.REACT_APP_TATA_URL;
            const input = document.createElement('input');
            input.type = 'hidden';
            input.name = 'REQUEST';
            input.value = res.payload.data;
            form.append(input);
            document.body.appendChild(form);
            form.submit();
          });
        }

                if (item.slug === 'goDigit-life') {
                  dispatch(lifeEnquiryAPI({ enquiryId: enquiryIds })).then(
                    (res) => {
                      dispatch(GoDigitLifeQuote(res.payload.data)).then((res) => {
                        dispatch(
                          paymentRedirectGoDigit({
                            enquiryId: combinedRedux?.enquiryId,
                          })
                        ).then((res) => {
                          if (res?.payload?.data) {
                            window.location.href =
                              res?.payload?.data?.redirectionUrl;
                          }
                        });
                      });
                    }
                  );
                }

        if (item.slug === 'life-hdfc') {
          dispatch(lifeEnquiryAPI({ enquiryId: enquiryIds })).then((res) => {
            dispatch(HdfcLifeQuote(res.payload.data)).then((res) => {
              dispatch(
                paymentRedirectHDFC({ enquiryId: combinedRedux?.enquiryId })
              ).then((res) => {
                if (res?.payload?.data) {
                  window.location.href = res?.payload?.data;
                }
              });
            });
          });
        } else if (item.slug === 'life-hdfc-plus') {
          dispatch(lifeEnquiryAPI({ enquiryId: enquiryIds })).then((res) => {
            dispatch(HdfcLifePlusQuote(res.payload.data)).then((res) => {
              dispatch(
                paymentRedirectHDFCPlus({ enquiryId: combinedRedux?.enquiryId })
              ).then((res) => {
                if (res?.payload?.data) {
                  window.location.href = res?.payload?.data;
                }
              });
            });
          });
        }

        const paymentMethods = {
          'life-icici': paymentRedirect,
          'life-icici-GPP': paymentRedirectGPP,
          'life-icici-gift': paymentRedirectGift,
          'life-icici-sjb': paymentRedirectSJB,
          'life-icici-rop': paymentRedirectROP,
        };
        // payment method is an object which have slug as key and function as value
        const selectedPaymentMethod = paymentMethods[item.slug];
        // if (selectedPaymentMethod)  have "life-icici" in slug then it will go to "paymentRedirect" function in lifeRedux.js file

        if (selectedPaymentMethod) {
          // based on the slug it dispatch
          dispatch(
            selectedPaymentMethod({ enquiryId: combinedRedux?.enquiryId })
          ).then((res) => {
            const mainData = res?.payload?.data;
            dispatch(
              updateFirstPageData({
                enquiryId: combinedRedux?.enquiryId,
                'interestedPolicy.appNumber': mainData?.transID,
              })
            ).then((res) => {
              if (mainData?.URL) {
                window.location.href = mainData?.URL;
              }
            });
          });
        }
      }
    });
  };

  return (
    <div className="modelData">
      {/* <CloseBtn src={CloseImg} onClick={()=>navigate(-1)}/> */}
      <div className="slugData">
        <img src={item?.sluginfo.logo} alt="main" />
        <p className="slugName">{item.sluginfo.name}</p>
      </div>
      <div className="slugLine"></div>
      <p className="title">{title}</p>
      <div className="slugLine"></div>
      <>
        <div className="grid-2">
          <div className="lineDetails">
            <p className="details">Customer Details</p>
          </div>
          <p style={{ fontWeight: 'bold' }}>Basic Information :</p>
          <div style={{ display: 'flex', gap: '20px' }}>
            <InputComponent
              onChange={(e) => {
                setFirstName(e);
              }}
              placeholder={'First Name'}
              value={firstName}
              title={'First Name'}
            />
            {error.hasOwnProperty('education') ? (
              <ErrorPopups error={error.education} />
            ) : null}
            <InputComponent
              onChange={(e) => {
                setMiddleName(e);
              }}
              placeholder={'Middle Name'}
              value={middleName}
              title={'Middle Name'}
            />
            <InputComponent
              onChange={(e) => {
                setLastName(e);
              }}
              placeholder={'Last Name'}
              value={lastName}
              title={'Last Name'}
            />
            <InputComponent
              disabled
              value={gender}
              onChange={(e) => {
                setGender(e);
              }}
              placeholder={'Gender'}
              title={'Gender'}
              dropdown={['male', 'female']}
            />
          </div>
          <div style={{ display: 'flex', gap: '30px' }}>
            <InputComponent
              disabled
              onChange={(e) => {
                setDob(e);
              }}
              placeholder={'DOB'}
              value={dob}
              type={Date}
              title={'DOB'}
            />
            <InputComponent
              disabled
              onChange={(e) => {
                setSmoking(e);
              }}
              placeholder={'Smoking Status'}
              value={smoking}
              title={'Smoking Status'}
              dropdown={['yes', 'no']}
            />
            <InputComponent
              onChange={(e) => {
                setAnnualIncome(e);
              }}
              placeholder={'Annual Income'}
              disabled
              value={annualIncome}
              title={'Annual Income'}
            />
          </div>
          <p style={{ fontWeight: 'bold' }}>Contact Details : </p>
          <div style={{ display: 'flex', gap: '30px' }}>
            <InputComponent
              onChange={(e) => {
                setEmail(e);
              }}
              placeholder={'Email'}
              value={email}
              title={'Email'}
            />
            <InputComponent
              onChange={(e) => {
                setMobile(e);
              }}
              placeholder={'Mobile'}
              value={mobile}
              type={'number'}
              title={'Mobile'}
            />
          </div>

          <p style={{ fontWeight: 'bold' }}>Address :</p>
          <div style={{ display: 'flex', gap: '30px' }}>
            <InputComponent
              onChange={(e) => {
                pincodeHandler(e);
              }}
              placeholder={'Pin Code'}
              value={pincode}
              type={'number'}
              inputType={'pincode'}
              title={'Pincode'}
            />
            <InputComponent
              onChange={(e) => {
                setCity(e);
              }}
              placeholder="City Name"
              value={city}
              dropdown={cityDropdown}
              disabled={pincode.length !== 6}
              title={'City'}
            />
            <InputComponent
              value={state}
              onChange={(e) => {
                setState(e);
              }}
              placeholder={'State'}
              disabled={pincode.length !== 6}
              title={'State'}
            />
          </div>
        </div>
      </>

      {ebiAllowedSlugs.includes(item.slug) ? (
        <div className="main-action">
          <button className="changes" onClick={() => handleContinue()}>
            Buy Now ₹
            {item.totalPayableAmount.toLocaleString('en-IN', {
              maximumFractionDigits: 2,
            })}{' '}
          </button>
          <button className="detailModal__header__btn" onClick={handleEbiPdf}>
            EBI PDF
          </button>
        </div>
      ) : item.slug === 'tata-life-srp' || item.slug === 'tata-mrss' ? (
        <div className="main-action">
          <button className="changes" onClick={() => handleContinue()}>
            Buy Now ₹
            {parseInt(item.totalPayableAmount).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
            })}{' '}
          </button>
          <button
            className="detailModal__header__btn"
            onClick={handleTataPdf}
            disabled={buyNowClicked}
          >
            SIS PDF
          </button>
        </div>
      ) : item.slug === 'bajaj-life' ? (
        <div className="main-action">
          <button className="changes" onClick={() => handleContinue()}>
            Buy Now ₹
            {parseInt(item.totalPayableAmount).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
            })}{' '}
          </button>
          <button
            className="detailModal__header__btn"
            onClick={handleBajajBiPdf}
            disabled={buyNowClicked}
          >
            BI PDF
          </button>
        </div>
      ) : item.slug === 'goDigit-life' ? (
        <div className="main-action">
          <button className="changes" onClick={() => handleContinue()}>
            Buy Now ₹
            {parseInt(item.totalPayableAmount).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
            })}{' '}
          </button>
          <button
            className="detailModal__header__btn"
            onClick={handleGoDigitBiPdf}
            disabled={buyNowClicked}
          >
            BI PDF
          </button>
        </div>
        )
       : (
        <div className="main-action">
          <button className="changes" onClick={() => handleContinue()}>
            Buy Now ₹
            {parseInt(item.totalPayableAmount).toLocaleString('en-IN', {
              maximumFractionDigits: 2,
            })}{' '}
          </button>
        </div>
      )}
    </div>
  );
}