// BlogList.js
import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import './blog.css';
import blogBanner from '../../assets/blog-banner.png';
import Hero from '../../components/Hero_Banner/hero';
import { FaUserShield } from "react-icons/fa6";
import FooterNew from '../../components/Footer/FooterNew';
import axios from 'axios';
import LoadingSpinner from '../../components/loading/loading';
import moment from 'moment';

const BlogList = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true); 
  
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    function truncateText(text, maxLength) {
      if (text && text.length > maxLength) {
        return `${text.slice(0, maxLength)}...`;
      }
      return text;
    }

      useEffect(() => {
      setLoading(true);
      axios.get('https://uat-backend.simpliinsure.in/cms/api/blogs?populate=SubBlogs.img,blogBanner,insurace_type')
        .then(response => {
          // Sort the blogs based on publishedAt date, newest first
          const sortedBlogs = response?.data?.data.sort((a, b) => new Date(b.attributes.publishedAt) - new Date(a.attributes.publishedAt));
          setBlogs(sortedBlogs);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching data:', error);
          setLoading(false);
        });
    }, []);

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div>
    <Hero icon={<FaUserShield size={100} />} heading={"WORLD OF INSURANCE MADE SIMPLE"} image={blogBanner} subHeading={"Empowering Your Financial Understanding and Insurance Intelligence"} btn={true}/>
    <div className='blog_container_section'>
    <div className='blog_para_mid_section'>
      <p style={{fontWeight:'bold', fontSize:'25px', marginTop:'20px'}}>Insurance Insights: Navigating the Future</p>
      <p style={{fontWeight:'bold'}}>Guiding You Through Tomorrow's Landscape</p>
      <p>Explore the evolving landscape of insurance industry trends in business and technology. Delve into essential insights driving innovation and discover today's pulse of insurance advancements. Empower your future decisions with pivotal information in this rapidly transforming sector.</p>
    </div>
    
  <div className='blogList-container'>
   {blogs && blogs.map(blog => (
      <div key={blog?.id} className='blog_page_sec_1'>
      <Link to={`/blog/${blog?.attributes?.slug}`}>
        <div className='blog_img_cat'>
          {blog?.attributes?.blogBanner?.data?.attributes?.url && (
           <img
            src={`${blog?.attributes?.blogBanner?.data?.attributes.url}` || "https://placehold.co/1000x400"}
            alt={blog?.attributes?.BlogTitle || "Placeholder"}
            className='blog_img_sec_1'
          />
          )}
          <p className='blog_para-cat_sec_1'>{blog?.attributes?.category || ''}</p>
        </div>
        <h2 className='blog_h2_sec_1'>{blog?.attributes?.BlogTitle || ''}</h2>
        <p className='blog_timespan'>{moment(blog?.attributes?.publishedAt).format('DD MMMM YYYY, hh:mm A')}</p>
        {/* <h3 className='blog_h3_sec_1'>{blog?.attributes?.SubBlogs[0]?.title || ''}</h3> */}
        <p className='blog_para_sec_1'>{blog.attributes?.SubBlogs[0]?.description1 ? truncateText(blog.attributes?.SubBlogs[0]?.description1, 150)  : ''}</p>
        <div className='blog_link_sec_1'>
        <Link to={`/blog/${blog?.attributes?.slug}`}>Read more..</Link>
        <a className='blog_a_sec_1' target="_blank" rel="noopener noreferrer" href={blog?.attributes?.insurace_type?.redirectionLink || '/'}>Get Quote</a>
        </div>
      </Link>
      </div>
    ))}
    </div>


      </div>
      <FooterNew />
    </div>
  );
};

export default BlogList;
