import styled from "styled-components";

export const CardContainer = styled.div`
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    width: 85%;
    margin: 1rem auto;
    margin-bottom: 2rem;

    @media screen and (max-width:980px) {
        margin-top: 5rem;
    }
    
    .flex {
        display: flex;
    }
    .column {
        flex-direction: column;
    }
    .align-center {
        align-items: center;
    }
    .j-center {
        justify-content: center;
    }
    .margin{
        margin-bottom: 2rem;
    }

    .success {
        width: 30%;
    }
    .success-msg {
        color: #3AD42F;
        font-weight: 700;
        font-size: 1.1rem;
    }
    .info {
        color: #605F5E;
        font-weight: 700;
        text-align: center;
    }
    .heading{
        color: #3AD42F;
        font-size: 2rem;
        font-weight: 700;
    }
    .subHeading{
        color: #605F5E;
        font-size: 1.5rem;
        font-weight: 600;
        margin-bottom: -1rem;
        margin-left:.4rem
    }
    .inputBox{
        border: 1px solid black;
        border-radius: 0.5rem;
    }
    .border{
        border: 1px solid black;
        padding-bottom: 1rem;
        border-radius: 1rem;
    }
`
export const InputContainer = styled.div`
    position: relative;
    padding: .4rem;
    margin: 1rem auto;
    border: 2px solid #B7B7B7;
    border-radius: 10px;
    width: 30%;
    @media screen and (max-width: 600px) {
        width: 60%;
    }
    input {
        width: 100%;
        // padding-top: .2rem;
        // padding-bottom: .2rem;
        outline: none;
        border: none;
        font-size: 1.2rem;
        margin-left: .2rem;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .m-0 {
        margin-top: 0;
        h4 {
            margin: auto;
        }
    }
    .m-0-0 {
        margin: 0;
    }

    .horizontal {
        display: flex;
        align-items: center;
        input {
            width: auto;
            accent-color: #F6931E;
        }
        label {
            margin-left: .5rem;
        }

        .apart+.apart {
            margin-left: 1rem;
        }

    }
    
`
export const SelectDropdown = styled.select`
    width: 100%;
    padding-top: .35rem;
    padding-bottom: .35rem;
    // outline: dotted;
    // padding: 0.3rem;
    // border-radius: .6rem;
    font-size: 1.1rem;
    // border: 1px solid #B7B7B7;
    // text-shadow: 0 0 0 #000;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
 
`


export const Button = styled.button`
    border: none;
    border-radius: 1.5rem;
    padding: .8rem 2.8rem;
    color: #fff;
    font-size: 1.2rem;
    background: #ffb25a;
    display: flex;
    margin: auto;
    margin-bottom: 2.7rem;
    cursor: pointer;
    margin-top: 2rem;
`