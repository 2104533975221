import { CarMainPage } from "../main-page/main-page"
import { CloseIcon, MainContainer, Opacity, PopupContainer } from "./styles"
import CloseImg from '../../../assets/brand-new-car/close-btn.svg'

export const InsurancePopup = () => {

    const Popup = () => {
        return(
            <PopupContainer>
                <CloseIcon src={CloseImg} />
                <p className="prompt">Are you sure that you want to change the policy type?</p>
                <div className="flex apart">
                    <button>Yes</button>
                    <button>No</button>
                </div>
            </PopupContainer>
        )
    }

    return(
        <MainContainer>
            <CarMainPage />
            <Opacity />
            <Popup />
        </MainContainer>
    )
}