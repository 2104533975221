import { useNavigate } from "react-router-dom"
import { MainContainer, styles } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Layout } from "./styles";
import {useEffect , useState} from 'react'; 
import { useSelector, useDispatch } from "react-redux";
import { updateCombinedModel,modelVariant } from '../../../Redux/CarsRedux';

export const CarDetailsForm3 = () => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const cubicType = useSelector((state) => state.carsRedux.cubicCap);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const [cubicCapState, setCubicCapState] = useState([]);
    useEffect(() => {
      
        if(!combinedRedux.hasOwnProperty('regDate')){
            setTimeout(() => {
                getLocalStorage()
            }, 1);
        }
        if(combinedRedux.modelId){
            dispatch(modelVariant({uniqueCubicCap:true,modelId:combinedRedux.modelId, uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true,brand:combinedRedux.brand, model: combinedRedux.model,fuelType:combinedRedux.fuelType}))
        }
        else{
            var carInsuranceDetails =  (localStorage.getItem('canInsuranceDetails'));
            if(carInsuranceDetails){
                carInsuranceDetails = JSON.parse(carInsuranceDetails);
                dispatch(modelVariant({uniqueCubicCap:true,modelId:carInsuranceDetails.modelId,uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true}))
            }
        }
    }, [])

    const getLocalStorage = async() => {
        var carInsuranceDetails =  await (localStorage.getItem('canInsuranceDetails'));
        if(carInsuranceDetails){
            carInsuranceDetails = JSON.parse(carInsuranceDetails);
            dispatch(updateCombinedModel(carInsuranceDetails));
        }
    };


    useEffect(() => {
        if(cubicType.length > 0){
            // console.log("cubic cap state", );
            setCubicCapState(cubicType)
        }
       
    }, [cubicType])


    const handleSelect = val => {
        dispatch(updateCombinedModel({cubicCap:(val)}));
        navigate('/brand-new-model/car-details/4')
    }

    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent
                        step={5} noSearch={true}
                        title='Cubic Capacity'
                        cubicType={cubicCapState}
                        onSelect={handleSelect}
                    />
                </Layout>
            </MainContainer>
        </>
    )
}