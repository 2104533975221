import styled from "styled-components";

export const Layout = styled.div`
    border: .5px solid #B7B7B7;
    margin: 2rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
    flex-direction: column;
`
export const Button = styled.button`
    width: 600px;
    border-radius: .6rem;
    border: 1px solid #B7B7B7;
    background-color: #fff;
    padding: .8rem;
    color:#000 !important; 
    font-weight: 800;
    margin-bottom: .9rem;
    user-select: none;
    
    &:hover {
        cursor: pointer;
        border: 1px solid #000;
    }

    @media screen and (max-width: 900px) {
        width: 80%;
    }

    @media screen and (max-width: 500px) {
        width: 90%;
    }

`
export const InputContainer = styled.div`
    width: 600px;
    border-radius: .6rem;
    border: 1px solid #B7B7B7;
    padding: .8rem;
    position: relative;
    text-align: center;
    margin-bottom:1rem;
    p{ 
        font-weight: 800;
    }
    label {
        user-select: none;
    }

    .input-description {
        color: rgba(51,51,51,0.5);
        font-size: 12px;
        margin-top: 1.25rem;
    }

    @media screen and (max-width: 900px) {
        width: 80%;
    }

    @media screen and (max-width: 600px) {
        width: 95%;
    }

`
export const HdivApart = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    .left {
        margin-left: 2rem;
    }
    .right {
        margin-right: 2rem;
    }

    @media screen and (max-width: 540px) {
        flex-direction:column ;

        .left, .right {
            margin: .5rem auto;
            text-align: left;
        }


    }

`
export const HDiv = styled.div`
    display: flex;

    input[type='checkbox'] {
        accent-color: #F6931E;
        width: 20px;
    }
    label {
        margin: auto .4rem;
    }

`

export const HelpIcon = styled.img`
    cursor: pointer;
`
export const InputDiv = styled.div`
    border: 1px solid #B7B7B7;
    border-radius: 5px;
    padding: 0.6rem;
    display: flex;
    width: 300px;
    justify-content: center;
    margin: auto;
    align-items: center;
    h2 {
        margin: auto;
    }
    input {
        width: 100%;
        border: none;
        outline: none;
        font-size: 1.1rem;
        margin-left: .5rem;
    }

    @media screen and (max-width: 540px) {
        width: auto ;
    }

`
export const ContinueBtn = styled.button`
    background-color: #F6931E;
    padding: .6rem;
    width: 150px;
    border: none;
    border-radius: 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
`
export const ContinueBtnInactive = styled.button`
    background-color:rgba(0,0,0,0.16);
    padding: .6rem;
    width: 150px;
    border: none;
    border-radius: 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: auto;
    margin-top: 1.5rem;
    margin-bottom: 1rem;
`

export const CloseBtn = styled.img`
    position: absolute;
    right: 15px;
    cursor: pointer;
`