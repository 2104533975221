import React, {useEffect, useState} from 'react';
import './main-brand.css';
import BrandPage from '../brandPage/brand';
import healthImage from '../../assets/brand-banner.png';
import Quick from '../../components/new_quick_links/quick';
import Hero from '../../components/Hero_Banner/hero';
import BlogCard from '../../components/blog-card/blog';
import FooterNew from '../../components/Footer/FooterNew';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useParams } from 'react-router';
import ContactForm from './../new-contact-us/new-contact';
import { Helmet } from 'react-helmet';


const companies = [
  { id: 1, name: 'National Insurance', path: "nationalInsurance" },
  { id: 2, name: 'HDFC Life Insurance', path: "hdfcLife" },
  { id: 3, name: 'Bajaj Allianz General Insurance', path: "BajajAllianzGeneralInsurance" }, 
  // { id: 4, name: 'Bharti AXA', path: "bhartiAXA" },
  // { id: 5, name: 'Canara HSBC', path: "canaraHSBC" },
  { id: 6, name: 'Care Health', path: "careHealth" },
  { id: 7, name: 'Chola MS', path: "cholaMS" },
  { id: 8, name: 'Digit', path: "digit" },
  { id: 9, name: 'Edelweiss', path: "edelweiss" },
  { id: 10, name: 'Edelweiss Life Tokio', path: "edelweissLifeTokio" },
  { id: 11, name: 'Exide Life Insurance', path: "exideLifeInsurance" },
  { id: 12, name: 'Future Generali', path: "futureGenerali" },
  { id: 13, name: 'HDFC ERGO', path: "hdfcERGO" },
  // { id: 14, name: 'Bajaj Allianz', path: "bajajAllianz" },
  { id: 15, name: 'ICICI Lombard', path: "iciciLombard" },
  { id: 16, name: 'ICICI Prudential', path: "iciciPrudential" },
  { id: 17, name: 'IFFCO TOKIO', path: "iffcoTOKIO" },
  { id: 18, name: 'Kotak General Insurance', path: "kotakGeneralInsuance" },
  { id: 19, name: 'Kotak Life Insurance', path: "kotakLifeInsurance" },
  { id: 20, name: 'Liberty', path: "liberty" },
  { id: 21, name: 'LIC', path: "LIC" },
  { id: 22, name: 'Magma HDI', path: "magmaHDI" },
  { id: 23, name: 'Manipal Cigna', path: "manipalCigna" },
  { id: 24, name: 'Max Life', path: "maxLife" },
  { id: 25, name: 'Aditya Birla', path: "adityaBirla" },
  { id: 26, name: 'Niva', path: "niva" },
  { id: 27, name: 'Oriental Insurance', path: "orientalInsurance" },
  { id: 28, name: 'PNB MetLife', path: "pnbMetLife" },
  { id: 29, name: 'Raheja QBE', path: "rahejaQBE" },
  { id: 30, name: 'Reliance General Insurance', path: "relianceGeneralInsurance" },
  { id: 31, name: 'Reliance Nippon Life Insurance', path: "relianceNipponLifeInsurance" },
  { id: 32, name: 'Royal Sundaram', path: "royalSundaram" },
  { id: 33, name: 'SBI General', path: "sbiGeneral" },
  { id: 34, name: 'SBI Life', path: "sbiLife" },
  { id: 35, name: 'Shriram General Insurance', path: "shriramGeneralInsurance" },
  { id: 36, name: 'Shriram Life Insurance', path: "shriramLifeInsurance" },
  { id: 37, name: 'Star Health Insurance', path: "starHealthInsurance" },
  { id: 38, name: 'Tata AIG', path: "tataAIG" },
  { id: 39, name: 'The New India Assurance', path: "theNewIndiaAssurance" },
  { id: 40, name: 'United India', path: "unitedIndia" },
  { id: 41, name: 'Universal Sompo General Insurance', path: "universalSompoGeneralInsurance" },
  { id: 42, name: 'Tata Life Insurance', path: "tataLife" },
  { id: 43, name: 'Bajaj Life', path: "bajajLife" }
];


const MainBrandPage = () => {
  const [brandData, setBrandData] = useState([]);
  const { name } = useParams();
  
  useEffect(() => {
    axios.get(`https://uat-backend.simpliinsure.in/cms/api/insurances/?filters[name][$eq]=${name}&populate=life,life.benefitsData,life.faqData,motor,motor.benefitsData,motor.faqData,health,health.benefitsData,health.faqData,bike,bike.benefitsData,bike.faqData,lifeInsuranceTableCategories,lifeInsuranceTableCategories.policies,businessInsuranceTableCategories,businessInsuranceTableCategories.policies,motorInsuranceTableCategories,motorInsuranceTableCategories.policies,healthInsuranceTableCategories,healthInsuranceTableCategories.policies,SEO,SEO.sharedImage,SEO.sharedImage.media`)
      .then(response => {
        setBrandData(response?.data?.data[0]);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  }, [name]);

  
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
  return (
    <>
    {
      brandData?.attributes?.SEO && (
      <Helmet>
        <title> Brand | {brandData?.attributes?.SEO.metaTitle}</title>
        <meta name="title" content={brandData?.attributes?.SEO.metaTitle} />
        <meta name="description" content={brandData?.attributes?.SEO?.metaDescription} />
        <meta name="keywords" content={brandData?.attributes?.SEO?.keywords} />
        <meta property="og:title" content={brandData?.attributes?.SEO.metaTitle} />
        <meta property="og:description" content={brandData?.attributes?.SEO?.metaDescription} />
        <meta property="og:image" content={brandData?.attributes?.SEO?.sharedImage?.media?.data?.attributes?.url} />
      </Helmet>
      )
    }

    <Hero heading={brandData?.attributes?.title} image={healthImage} subHeading={ brandData?.attributes?.subTitle } btn={true}/>
    <Quick heading = {brandData?.attributes?.title} />
    <div className="page-container">
      <div className="left-section"> 
      <h2>Companies</h2>
      <ul>
        {companies.map((company) => (
          <li key={company.id} className="company-item">
            <Link to={`/brand/${company.path}`}>{company.name}</Link>
          </li>
        ))}
      </ul>
      </div>

      <div className="center-section">
      <BrandPage brandPageData = { brandData } />
      </div>

      <div className="right-section"> 
        <ContactForm title = {'APPLY NOW TO GET YOUR POLICY'} titleSize={'15px'}/>
      </div>
    </div>

        <div className='blog_section'>
    <h2 className='learnMore'>LEARN MORE</h2>
    <div className="blog_container">
    <BlogCard />
    </div>
    </div>
    <div style={{padding:"40px"}}>

    </div>
    <FooterNew />
    </>
  );
}

export default MainBrandPage;
