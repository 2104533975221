import styled from "styled-components";

export const MainDiv = styled.div`
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    position: relative;
    text-align: right;
    ul {
        margin: 0;
    }
    padding: 0.5rem;

    @media screen and (max-width: 980px) {
        margin-top: 4rem;
    }

`
export const BarContainer = styled.ul`
    display: flex;
    align-items: center;
    justify-content: space-between;
    list-style-type: none !important;

    li {
        color:#605F5E;
        span {
            text-decoration-line: underline;
            cursor: pointer;
        }
        .no-u {
            text-decoration-line: none;
        }
    }

    .show-more {
        color:#2B003F;
        cursor: pointer;
        user-select: none;
        &:hover {
            font-weight: 700;
        }

    }

    .clear {
        opacity: .5;
        cursor: pointer;
    }
    .flex {
        display: flex;
    }
    .align-center {
        align-items: center;
    }
    .pos-rel {
        position: relative;
    }

    label {
        user-select: none;
    }

    .add-cover {
        top: 200px;
        left: -50%;
        transform: translate(-50%, -50%);
        width: 60vw;

        @media screen and (max-width: 1150px) {
            width: auto;
            left: 50%;
            margin-top: 4rem;
        }
        @media screen and (max-width: 750px) {
            width: 70vw;
        }
        @media screen and (max-width: 600px) {
            width: 85vw;
        }

    }

    @media screen and (max-width: 1150px) {
        flex-direction: column;
        li {
            margin: .2rem auto;
        }
        .more-div {
            margin: .2rem auto;
            width: 25%;
            justify-content: space-between;

            .flex {
                input {
                    margin-right: .75rem;
                }
            }

        }
    }
    @media screen and (max-width: 1020px) {
        .more-div {
            width: 40%;
        }
    }
    @media screen and (max-width: 700px) {
        .more-div {
            width: 60%;
        }
    }
    @media screen and (max-width: 480px) {
        .more-div {
            width: 90%;
        }
    }
   

`
export const DropdownIcon = styled.img`
    width: 12px;
`
export const DiscountIcon = styled.img`
`
export const ConsumablesIcon = styled.img`
`
export const EngineIcon = styled.img`
`
export const InvoiceIcon = styled.img`
`
export const TyreIcon = styled.img`
`
export const BriefcaseIcon = styled.img`
`
export const DropdownContainer = styled.div`
margin-top:20px;
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    padding: 1rem;
    position: absolute;
    z-index: 1;
    background-color: #fff;
    /* input {
        margin-right: .2rem;
        width: 20px;
        accent-color: #F6931E;
    } */

    label {
        color: #605F5E;
    }

    input[type='radio'] {
        -webkit-appearance: none;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        outline: none;
        border: 2px solid gray;
        margin: .35rem auto .35rem 0;
        margin-right: .4rem;
    }

    input[type='radio']:before {
        content: '';
        display: block;
        width: 65%;
        height: 65%;
        margin: 18% auto;
        border-radius: 50%;
    }

    input[type="radio"]:checked:before {
        background: #F6931E;
        
    }
    
    input[type="radio"]:checked {
      border-color:rgb(0,0,0,.2);
    }
    .flex {
        align-items: center;
    }
    .small {
        font-size: 12px;
        color: rgba(51,51,51,0.5);
    }

    input[type='text'] {
        border: none;
        outline: none;
        margin-left: .1rem;
    }

    .input-div {
        border-radius: 10px;
        border: 1px solid #B7B7B7;
        padding: .5rem .35rem;
    }

    button {
        width: 100%;
        padding: .6rem;
        background-color: #F6931E;
        border: none;
        cursor: pointer;
        margin-top: .6rem;
        border-radius: 10px;
    }
    .desc {
        margin: .6rem auto .4rem auto;
    }
    .lasst {
        display: flex;
        .last-radio {
            width: 23px !important;            
        }
    }
   .idv{
    min-width:157px
   }
`
export const HelpIcon = styled.img`
    margin-left: .6rem;
    margin-bottom: .3rem;
    cursor: help;
    &:hover {
        filter: contrast(5);
        transition: filter .1s;
    }
`
export const Tooltip = styled.p`
  position: absolute;
  font-size: 12px;
  width: 150px;
  background-color: #fff;
  padding: 0.5rem;
  border-radius: 10px;
  box-shadow: 0px 0px 13px -7px rgba(0, 0, 0, 0.75);
  z-index: 2;
  text-align: left;
`;
export const CloseIcon = styled.img`
    position: absolute;
    right: 10px;
    cursor: pointer;
`
export const TabsContainer = styled.div`
    display: flex;
    justify-content: space-around;
    margin-top: 1rem;
    width: 80%;
    margin: 1rem auto 0 auto;
    box-shadow: 0px 0px 18px -9px rgba(0,0,0,0.72);
    border-radius: 17.5px;
    padding:.5rem;
`
export const ActiveTab = styled.div`
    border-radius: 17.5px;
    text-align: center;
    padding: 0.3rem auto;
    background-color: #F6931E;
    flex: 1;
    height: 30px;
    width: 170px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-weight: 700;
    letter-spacing: .1ch;
    `
export const InactiveTab = styled.div`
    height: 30px;
    flex: 1;
    width: 170px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
    text-align: center;
    color:#F6931E;
    letter-spacing: .1ch;
    border-radius: 17.5px !important;
`
export const BottomContainer = styled.div`
    padding: 1rem;
    input[type='checkbox'] {
        accent-color:#F6931E;
        width: 16px;
        height: 16px;
        margin-right: .35rem;
    }
    .flex {
        display: flex;
        align-items: center;
        margin-bottom: .25rem;
    }
    .theme-font {
        color: #F6931E;
        font-weight: 700;
    }
    .apart {
        justify-content: space-between;
    }
    .grid {
        display: grid;
        grid-template-columns: repeat(3,1fr);
        .flex {
            margin: .35rem 0;
        }
        input {
            width: 16px !important;
            height: 16px;
            margin-right: .35rem;
        }
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .unnamed {
        margin-right:8rem
    }
    .inputVal {
        background:#F5F5F9;
        width:130px !important;
        height:22px !important;
        margin-left:.6rem
    }
    #inputVal1 {
        background:#F5F5F9;
        width:130px !important;
        height:22px !important;
        margin-left:.6rem
    }
    .inputSI{
        display:flex
    }
    .inputErr {
        border:1px solid red !important;
    }

    @media screen and (max-width: 1150px) {
        .grid, .mt-1 {
            grid-template-columns: repeat(2,1fr);
        }
    }
    @media screen and (max-width: 750px) {
        .grid, .mt-1 {
            grid-template-columns: repeat(1,1fr);
        }
    }

`
export const HamburgerIcon = styled.img`
    cursor: pointer;
    padding: 0.5rem;
    border: 1px solid #fff;

    &:hover {
        border: 1px solid rgba(151,151,151,0.3);
    }
    
`
export const PopupContainer = styled.div`
  ${'' /* position:absolute; */}
  max-width:600px;
  margin-top:5rem;
   background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.7);
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin:auto;
  ${'' /* margin-left:1rem; */}
`;

export const PopupHeader = styled.div`
  color: #e74c3c;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const PopupMessage = styled.div`
  color: #333;
  margin:auto;
  `

export const ButtonContainer = styled.div`
  ${'' /* width:50% !important; */}
  ${'' /* background-color: #e74c3c; */}
  display:flex;
  justify-content:space-between;
  color: #fff;
  border: none;
  padding: 10px 50px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 4px;
`;
export const PopupButton = styled.button`
  width:40% !important;
  ${'' /* background-color: #e74c3c; */}
  color: #fff;
  margin:0 1rem;
  border: none;
  padding: 10px 30px;
  ${'' /* font-size: 16px; */}
  cursor: pointer;
  border-radius: 4px;
`;