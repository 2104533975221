import { useState } from "react"
import { useNavigate } from "react-router-dom"
import { MainContainer, styles } from "../../../assets/common-styles/common-styles"
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Layout } from "./styles"

export const CarDetailsForm6 = () => {

    const data = [
        {
            id:1,
            model:'Delhi'
        },
        {
            id:2,
            model:'Bhopal'
        },
        {
            id:3,
            model:'Mumbai'
        },
        {
            id:4,
            model:'Gurugram'
        },
        {
            id:5,
            model:'Noida'
        },
        {
            id:6,
            model:'Delhi'
        },
        {
            id:7,
            model:'Bhopal'
        },
        {
            id:8,
            model:'Mumbai'
        },
        {
            id:9,
            model:'Gurugram'
        },
        {
            id:10,
            model:'Noida'
        },
        {
            id:11,
            model:'Delhi'
        },
        {
            id:12,
            model:'Bhopal'
        },
        {
            id:13,
            model:'Mumbai'
        },
        {
            id:14,
            model:'Gurugram'
        },
        {
            id:15,
            model:'Noida'
        },
        {
            id:16,
            model:'Delhi'
        },
        {
            id:17,
            model:'Bhopal'
        },
        {
            id:18,
            model:'Mumbai'
        },
        {
            id:19,
            model:'Gurugram'
        },
        {
            id:20,
            model:'Noida'
        },
    ]
    const modalData = [
        {
            id:1,
            code:'KL-01'
        },
        {
            id:2,
            code:'KL-02'
        },
        {
            id:3,
            code:'KL-03'
        },
        {
            id:4,
            code:'KL-04'
        },
        {
            id:5,
            code:'KL-05'
        },
        {
            id:6,
            code:'KL-06'
        },
        {
            id:7,
            code:'KL-07'
        },
        {
            id:8,
            code:'KL-08'
        },

    ]

    const navigate = useNavigate()
    const [triggerModal, setTriggerModal] = useState(false)

    const handleSelect = val => {
        console.log(val);
        setTriggerModal(val.model)
    }
    const onModalSelect = val => {
        console.log(val);
        navigate('/brand-new-model/car-form-preview')
    }

    const onModalClose = () => {
        setTriggerModal(false)
    }

    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent
                        title='RTO'
                        search={'Search for the cities like Delhi, Gurugram, Bhopal, etc'}
                        data={data}
                        modalData={modalData}
                        triggerModal={triggerModal}
                        onSelect={handleSelect}
                        handleModalSelect={onModalSelect}
                        handleModelClose={onModalClose}
                    />
                </Layout>
            </MainContainer>
        </>
    )
}