const formatSumAssured = (value) => {
    if (value >= 10000000000) { // 10 billion or 1000 crore
    return (value / 100000000).toFixed(2) + ' Cr';
    } else if (value >= 10000000) {
    return (value / 10000000).toFixed(2) + ' Cr';
  } else if (value >= 100000) {
    return (value / 100000).toFixed(2) + ' Lac';
  } else if (value >= 1000) {
    return (value / 1000).toFixed(2) + ' K';
  } else if (value >= 100) {
    return (value / 1000).toFixed(2) + ' K'; // Display as 0.10k to 0.99k
  } else {
    return '';
  }
}

export default formatSumAssured;
