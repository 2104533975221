import { useState } from 'react'
import './menu-item.css'

import DashboardArrowImg from '../../../../assets/car-insurance/dashboard-icons/right-arrow-dash.svg'

export const MenuItem = ({ title, img, selected, onSelect, item, menu }) => {

    const [expandSubmenu, setExpandSubmenu] = useState(true)



    return (
        <>
            <div onClick={() => {
                onSelect(item)
            }} className={selected === true ? `flex apart menu-item relative selected` : `flex apart menu-item relative`} >

                <div className="flex align-center">
                    <img
                        src={img}
                        className=''
                    />
                    <p className={selected ? `menu-title selected-text` : `menu-title`}>{title}</p>
                </div>
                <img
                    src={DashboardArrowImg}
                    className={selected ? 'arrow-right' : ' arrow-left-dash'}
                />
                {/* {selected ?
                    <div className="indicator " />
                    : null} */}

            </div>
            {menu==='policies' && expandSubmenu?
                <ul className="p-1">
                    <div className="flex apart selected-text mb-1">
                        <li>Motor Insurance</li>
                        <img src={DashboardArrowImg} className='selected-icon'/>
                    </div>
                    <div className="flex apart mb-1">
                        <li>Life Insurance</li>
                        <img src={DashboardArrowImg}/>
                    </div>
                    <div className="flex apart mb-1">
                        <li>Health Insurance</li>
                        <img src={DashboardArrowImg}/>
                    </div>
                </ul>
            :null}

            
        </>
    )
}

export const SubMenu = ({ subtitle, img, selected, onSelect, item }) => {

    return (
        <>
            <div onClick={() => {
                onSelect(item)
            }} className={selected === true ? `flex apart menu-item relative` : `flex apart menu-item relative`} >

                <div className="flex align-center">
                    <img
                        src={img}
                    />
                    <p className={selected ? `menu-title selected-text` : `menu-title`}>{subtitle}</p>
                </div>
                <img
                    src={DashboardArrowImg}
                    className={selected ? 'arrow-right ' : 'arrow-left-dash'}
                />
                

            </div>
            
        </>
    )
}




