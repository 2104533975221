import React from 'react'
import { useEffect } from 'react'
import Footer from '../../../../components/Footer/FooterNew'
import Front from '../../../../components/Front/Front.jsx'
import Insurance from '../../../../components/Insurance/Insurance.jsx'
import './Term.css'



const Term = () => {

    // useEffect(() => {
    //     window.scrollTo(0, 0)
    //     alert("coming soon")
    //   }, [])

    return (
        <div className='termContainer'>

            <Front />
            <Insurance />
            <Footer />



        </div>
    )
}

export default Term