import { useEffect } from "react"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import Footer from "../../../components/Footer/FooterNew"
import { Content, Heading, Heading2, ListContainer, SubHead } from "./styles"

export const PrivacyPolicy = () => {

    useEffect(() => {
        localStorage.removeItem('no-header')
    }, [])

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <>
            <MainContainer>


                <Content>
                    <Heading>Privacy Policy</Heading>
                    <p>Virtual Galaxy Insurance Brokers Pvt. Ltd. as mentioned in this Privacy policy is
                        very much concerned and committed to maintain confidentiality of data of the
                        users. We intend to ensure the privacy of the data and information of the users
                        those who are availing the online services of company websites, mobile and
                        other modes thereof accessible through various internet enabled smart devices
                        or doing business with the company. The Privacy policy will help customers
                        understand how we collect, use, store, process, transfer, and protect all your
                        information when you visit any of our platforms and avail of our services or even
                        just visit our platform.</p>
                    <br />
                    <p>This privacy policy is an electronic record in the form of electronic contract as
                        per   terms   of   Indian   Information   Technology   Act,   2000   and   rules   made
                        thereunder (As amended from time to time) and does not require any physical
                        signature or seal. </p>

                    <SubHead>Refund Policy</SubHead>
                    <p>In   case   of   a   refund   due   to   an   erroneous   transaction   or   cancellation,   the
                        premium paid for an insurance product will be refunded through direct credit or
                        cheque as per the prevailing policy of the respective Insurance Company that
                        you have chosen to buy the product from.</p>
                    <SubHead>Cancellation Policy</SubHead>
                    <p>In case of a cancellation, the premium paid for an insurance product will be
                        refunded through direct credit or cheque as per the policy of the respective
                        Insurance Company that you have chosen to buy the product from.</p>
                    <SubHead>How we use your information</SubHead>
                    <p>We use the information we collect in various ways, including to:</p>

                    <ListContainer>
                        <li>
                            Provide, operate, and maintain our website
                        </li>

                        <li>
                            Improve, personalize, and expand our website
                        </li>
                        <li>
                            Understand and analyze how you use our website
                        </li>
                        <li>
                            Develop new products, services, features, and functionality
                        </li>
                        <li>
                            Communicate with you, either directly or through one of our partners, including for customer service, to provide you with updates and other information relating to the website, and for marketing and promotional purposes
                        </li>
                        <li>
                            Send you emails
                        </li>

                        <li>
                            Find and prevent fraud
                        </li>
                    </ListContainer>

                    <SubHead>Log Files</SubHead>
                    <p>www.simpliinsure.com follows a standard procedure of using log files. These files log visitors when they visit websites. All hosting companies do this and a part of hosting services’ analytics. The information collected by log files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP), date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked to any information that is personally identifiable. The purpose of the information is for analyzing trends, administering the site, tracking users’ movement on the website, and gathering demographic information.</p>

                    <SubHead>Advertising Partners Privacy Policies</SubHead>
                    <p>You may consult this list to find the Privacy Policy for each of the advertising partners of www.simpliinsure.com.</p>
                    <br />
                    <p>Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons that are used in their respective advertisements and links that appear on www.simpliinsure.com, which are sent directly to users’ browser. They automatically receive your IP address when this occurs. These technologies are used to measure the effectiveness of their advertising campaigns and/or to personalize the advertising content that you see on websites that you visit.</p>
                    <br />
                    <p>Note that www.simpliinsure.com has no access to or control over these cookies that are used by third-party advertisers.</p>

                    <SubHead>Third Party Privacy Policies</SubHead>
                    <p>www.simpliinsure.com’s Privacy Policy does not apply to other advertisers or websites. Thus, we are advising you to consult the respective Privacy Policies of these third-party ad servers for more detailed information. It may include their practices and instructions about how to opt-out of certain options.</p>
                    <br />
                    <p>You can choose to disable cookies through your individual browser options. To know more detailed information about cookie management with specific web browsers, it can be found at the browsers’ respective websites.</p>

                    <SubHead>Children’s Information</SubHead>
                    <p>Another part of our priority is adding protection for children while using the internet. We encourage parents and guardians to observe, participate in, and/or monitor and guide their online activity.</p>
                    <br />
                    <p>www.simpliinsure.com does not knowingly collect any Personal Identifiable Information from children under the age of 13. If you think that your child provided this kind of information on our website, we strongly encourage you to contact us immediately and we will do our best efforts to promptly remove such information from our records.</p>

                </Content>


            </MainContainer>
            <Footer />
        </>
    )
}