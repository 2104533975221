import React, { useState } from 'react';
import HealthInsuranceTable from './policyTable/insuranceTable';
import './insurancePolicy.css';
import BenefitsCard from '../../pages/brandPage/benifits-buy/benifits';
import FAQ from '../../pages/brandPage/FAQ/FaqPage';
import { useParams } from 'react-router-dom';

const Health = ({ brandPageData }) => {
  const [selectedInsurance, setSelectedInsurance] = useState('health');
  const { name } = useParams();

  const handleInsuranceClick = (insuranceType) => {
    setSelectedInsurance(insuranceType);
  };

  const lifeInsuranceIndices = [  "hdfcLife", "tataLife", "edelweissLifeTokio",  "exideLifeInsurance",  "iciciPrudential",  "kotakLifeInsurance",  "LIC",  "maxLife",  "pnbMetLife",  "relianceNipponLifeInsurance",  "sbiLife",  "shriramlifeInsurance", "bajajLife"]

  // Ensure brandPageData and brandPageData.attributes are defined before accessing them
  const attributes = brandPageData?.attributes || {};
  const allowedTypes = ['health', 'life', 'motor', 'bike'];

  const shouldShowLifeContent = lifeInsuranceIndices.includes(name);


  return (
    <div>
      <div className='policy-part-one'>
        <p className='policy_title'>INSURANCE POLICIES</p>
        {
         shouldShowLifeContent && shouldShowLifeContent ? (
              <div className='policy-btn-container'>
            </div>
          ) : (
             <div className='policy-btn-container'>
          {Object.keys(attributes)
            .filter(type => allowedTypes && allowedTypes.includes(type) && type !== 'life')
            .map((type) => (
              <button
                key={type}
                className={selectedInsurance === type ? 'policy-button active-policy' : 'policy-button'}
                onClick={() => handleInsuranceClick(type)}
              >
                {attributes[type]?.title || ""}
              </button>
            ))}
        </div>
          )
        }
      </div>

      <div>
        {shouldShowLifeContent ? (
          <React.Fragment>
            <h1 className='policy_heading'>{attributes['life']?.title?.toUpperCase() || ""}</h1>
            <p className='policy_para'>{attributes['life']?.content || ""}</p>
            <HealthInsuranceTable brandPageData={brandPageData} selectedInsurance={ 'life' } />
            <BenefitsCard benefitsData={attributes['life']?.benefitsData} />
            <FAQ faqData={attributes['life']?.faqData} />
          </React.Fragment>
        ) : (
          <React.Fragment>
            <h1 className='policy_heading'>{attributes[selectedInsurance]?.title?.toUpperCase() || ""}</h1>
            <p className='policy_para'>{attributes[selectedInsurance]?.content || ""}</p>
            <HealthInsuranceTable brandPageData={brandPageData} selectedInsurance={selectedInsurance} />
            <BenefitsCard benefitsData={attributes[selectedInsurance]?.benefitsData} />
            <FAQ faqData={attributes[selectedInsurance]?.faqData} />
          </React.Fragment>
        )}
      </div>
    </div>
  );
}

export default Health;


// import React, { useState } from 'react';
// import HealthInsuranceTable from './policyTable/insuranceTable';
// import './insurancePolicy.css';
// import BenefitsCard from '../../pages/brandPage/benifits-buy/benifits';
// import FAQ from '../../pages/brandPage/FAQ/FaqPage';

// const Health = ({ brandPageData }) => {
//   const [selectedInsurance, setSelectedInsurance] = useState('health');

//   const handleInsuranceClick = (insuranceType) => {
//     setSelectedInsurance(insuranceType);
//   };

//   // Ensure brandPageData and brandPageData.attributes are defined before accessing them
//   const attributes = brandPageData?.attributes || {};
//   const allowedTypes = ['health', 'life', 'motor', 'bike'];

//   return (
//     <div>
//       <div className='policy-part-one'>
//         <p className='policy_title'>INSURANCE POLICIES</p>
//         <div className='policy-btn-container'>
//          {Object.keys(attributes)
//           .filter(type => allowedTypes && allowedTypes.includes(type))
//           .map((type) => (
//             <button
//               key={type}
//               className={selectedInsurance === type ? 'policy-button active-policy' : 'policy-button'}
//               onClick={() => handleInsuranceClick(type)}
//             >
//               {attributes[type]?.title || ""}
//             </button>
//         ))}
//         </div>
//       </div>

//       <div>
//         <h1 className='policy_heading'>{attributes[selectedInsurance]?.title?.toUpperCase() || ""}</h1>
//         <p className='policy_para'>{attributes[selectedInsurance]?.content || ""}</p>
//         <HealthInsuranceTable  brandPageData = { brandPageData } selectedInsurance ={ selectedInsurance }/>
//         <BenefitsCard benefitsData={attributes[selectedInsurance]?.benefitsData} />
//         <FAQ faqData={attributes[selectedInsurance]?.faqData} />
//       </div>
//     </div>
//   );
// }

// export default Health;