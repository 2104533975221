import CarInsuranceImage from '../../assets/car.png';
import LifeInsuranceImage from '../../assets/removeBg.png';
import HealthInsuranceImage from '../../assets/people1.png';
import BikeInsuranceImage from '../../assets/bike.png';

export const data = [
    {
        id:1,
        title:'Best Car Insurance',
        image:CarInsuranceImage,
        description: "Drive with confidence and protect your vehicle with our reliable car insurance, offering comprehensive coverage and peace of mind on the road."
    },
    {
        id:2,
        title:'Best Life Insurance',
        image:LifeInsuranceImage,
        description: "Secure your loved ones' future with our comprehensive life insurance coverage, providing peace of mind and financial protection when it matters most."
    },
    {
        id:3,
        title:'Best Health Insurance',
        image:HealthInsuranceImage,
        description: "Safeguard your well-being and gain peace of mind with our comprehensive health insurance, ensuring quality healthcare coverage and support when you need it most."
    },
    {
        id:3,
        title:'Best Bike Insurance',
        image:BikeInsuranceImage,
        description: "Pedal with confidence and protect your two-wheeled companion with our reliable bike insurance, offering comprehensive coverage and peace of mind on your cycling adventures."
    }
]