// const stateData = [
//   "andamanAndNicobar",
//   "andhraPradesh",
//   "arunachalPradesh",
//   "assam",
//   "bihar",
//   "chandigarh",
//   "chhattisgarh",
//   "dadraAndNagar",
//   "damanAndDiu",
//   "delhi",
//   "goa",
//   "gujarat",
//   "harayana",
//   "himachalPradesh",
//   "jharkhand",
//   "jammuAndKashmir",
//   "karnataka",
//   "kerala",
//   "lakshadweep",
//   "madhyaPradesh",
//   "maharashtra",
//   "manipur",
//   "meghalaya",
//   "mizoram",
//   "nagaland",
//   "orissa",
//   "pondicherry",
//   "punjab",
//   "rajasthan",
//   "sikkim",
//   "tamilNadu",
//   "telangana",
//   "tripura",
//   "uttarPradesh",
//   "uttarakhand",
//   "westBengal",
// ];

const stateData = {
  "andamanAndNicobar": "Andaman And Nicobar",
  "andhraPradesh": "Andhra Pradesh",
  "arunachalPradesh": "Arunachal Pradesh",
  "assam": "Assam",
  "bihar": "Bihar",
  "chandigarh": "Chandigarh",
  "chhattisgarh": "Chhattisgarh",
  "dadraAndNagar": "Dadra And Nagar Haveli",
  "damanAndDiu": "Daman And Diu",
  "delhi": "Delhi",
  "goa": "Goa",
  "gujarat": "Gujarat",
  "harayana": "Haryana",
  "himachalPradesh": "Himachal Pradesh",
  "jharkhand": "Jharkhand",
  "jammuAndKashmir": "Jammu And Kashmir",
  "karnataka": "Karnataka",
  "kerala": "Kerala",
  "lakshadweep": "Lakshadweep",
  "madhyaPradesh": "Madhya Pradesh",
  "maharashtra": "Maharashtra",
  "manipur": "Manipur",
  "meghalaya": "Meghalaya",
  "mizoram": "Mizoram",
  "nagaland": "Nagaland",
  "orissa": "Odisha",
  "pondicherry": "Puducherry",
  "punjab": "Punjab",
  "rajasthan": "Rajasthan",
  "sikkim": "Sikkim",
  "tamilNadu": "Tamil Nadu",
  "telangana": "Telangana",
  "tripura": "Tripura",
  "uttarPradesh": "Uttar Pradesh",
  "uttarakhand": "Uttarakhand",
  "westBengal": "West Bengal",
};

export default stateData;