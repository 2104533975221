import React, { useState, useEffect, useRef } from "react";
import Dropdown from "./Dropdown";
import { Link } from "react-router-dom";

const MenuItems = ({ items, depthLevel }) => {
  const [dropdown, setDropdown] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const handler = (event) => {
      if (dropdown && ref.current && !ref.current.contains(event.target)) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handler);
    document.addEventListener("touchstart", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("touchstart", handler);
    };
  }, [dropdown]);

  const handleMouse = (action) => {
    if (window.innerWidth > 960) {
      action === "enter" ? setDropdown(true) : setDropdown(false);
    }
  };

  const toggleDropdown = () => {
    setDropdown((prev) => !prev);
  };

  return (
    <li className="menu-items" ref={ref} onMouseEnter={() => handleMouse("enter")} onMouseLeave={() => handleMouse("leave")}>
      {items.submenu ? (
        <>
        <Link
          to={items.path}
          aria-expanded={dropdown ? "true" : "false"}
          onClick={toggleDropdown}
          className="menu-link"
        >
          {items.title} {depthLevel > 0 ? <span>&raquo;</span> : <span className="arrow" />}
        </Link>
          {dropdown && <Dropdown depthLevel={depthLevel} submenus={items.submenu} dropdown={dropdown} />}
        </>
      ) : (
        <Link to={items.path} className="menu-link">{items.title}</Link>
      )}
    </li>
  );
};

export default MenuItems;
