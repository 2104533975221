import React from 'react';
import { RiDeleteBin7Fill } from "react-icons/ri";
import styled from 'styled-components';

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
`;

const Th = styled.th`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const Td = styled.td`
  padding: 8px;
  text-align: left;
  border-bottom: 1px solid #ddd;
`;

const TableRow = styled.tr`
  &:nth-child(even) {
    background-color: #f2f2f2;
  }
`;


function SelectedUser({data, pushItem}) {
  console.log("data..",data)
  return (
    <Table>
      <thead>
        <tr> 
          <Th>Name</Th>
          <Th>Email</Th>
          <Th>Phone</Th>
          <Th>Remove</Th>
        </tr>
      </thead>
      <tbody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <Td>{row?.motorData?.ownerDetails?.fullName}</Td>
            <Td>{row?.motorData?.ownerDetails?.email}</Td>
            <Td>{row?.motorData?.ownerDetails?.mobileNo}</Td>
            <Td onClick={()=>pushItem(row)}><RiDeleteBin7Fill /></Td>
          </TableRow>
        ))}
      </tbody>
    </Table>
  );
}

export default SelectedUser;
