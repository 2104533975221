// import { useNavigate } from 'react-router-dom';
// import { uploadFile } from '../../components/POS/uploadFile';
// import './policyUpload.css';

// import React, { useState, useEffect } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { fetchPolicyData, updatePolicy } from '../../Redux/policyRedux';
// import { useParams } from 'react-router-dom';

// // const DocumentUpload = () => {
// //   const [url, setUrl] = useState({
// //     common: '',
// //     ecard: '',
// //     policy: '',
// //   });

// //   const [selectedDocType, setSelectedDocType] = useState('');
// //   const [selectedFile, setSelectedFile] = useState(null);
// //   const [uploading, setUploading] = useState(false);
// //   const [uploadSuccess, setUploadSuccess] = useState('');
// //   const [errorMessage, setErrorMessage] = useState('');

// //   const navigate = useNavigate();
// //   const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

// //   const handleDocTypeChange = (e) => {
// //     setSelectedDocType(e.target.value);
// //   };

// //   const handleFileChange = (e) => {
// //     const file = e.target.files[0];
// //     if (file && file.size > FILE_SIZE_LIMIT) {
// //       setErrorMessage('File size should be less than 10 MB');
// //       setSelectedFile(null);
// //     } else {
// //       setSelectedFile(file);
// //       setErrorMessage('');
// //     }
// //   };

// //   const uploadFileHandler = async () => {
// //     if (!selectedFile || !selectedDocType) {
// //       setErrorMessage('Please select a document type and file to upload');
// //       return;
// //     }

// //     setUploading(true);
// //     setErrorMessage('');
// //     try {
// //       const uploadResult = await uploadFile(selectedFile);
// //       setUploading(false);

// //       if (uploadResult && uploadResult.data && uploadResult.data.length > 0) {
// //         setUploadSuccess(
// //           `File uploaded successfully: ${uploadResult.data[0]?.url}`
// //         );
// //         setUrl((prev) => ({
// //           ...prev,
// //           [selectedDocType.toLowerCase()]: uploadResult.data[0]?.url,
// //         }));
// //       } else {
// //         setErrorMessage('File upload failed: Invalid response structure');
// //       }

// //       setSelectedFile(null);
// //     } catch (error) {
// //       setUploading(false);
// //       setErrorMessage(`Error uploading file: ${error.message}`);
// //     }
// //   };

// //   const clickHandle = async () => {
// //     const obj = {
// //       documents: {
// //         common: url.common,
// //         ecard: url.ecard,
// //         policy: url.policy,
// //       },
// //     };
// //     if (!url.common) {
// //       alert('Please Upload Your Common Document');
// //     } else if (!url.ecard) {
// //       alert('Please Upload Your ECard');
// //     } else if (!url.policy) {
// //       alert('Please Upload Your Policy Document');
// //     } else {
// //       console.log(obj);
// //       navigate('/next-step');
// //     }
// //   };

// //   return (
// //     <div className="upload-container">
// //       <h3>Document Upload</h3>
// //       <div className="doc-type">
// //         <label>Select Document Type:</label>
// //         <div>
// //           <input
// //             type="radio"
// //             name="docType"
// //             value="common"
// //             onChange={handleDocTypeChange}
// //           />{' '}
// //           Common
// //           <input
// //             type="radio"
// //             name="docType"
// //             value="ecard"
// //             onChange={handleDocTypeChange}
// //           />{' '}
// //           ECard
// //           <input
// //             type="radio"
// //             name="docType"
// //             value="policy"
// //             onChange={handleDocTypeChange}
// //           />{' '}
// //           Policy
// //         </div>
// //       </div>
// //       <div className="file-upload">
// //         <label>Select a file (max 10MB):</label>
// //         <input type="file" onChange={handleFileChange} />
// //         {errorMessage && <p className="error-message">{errorMessage}</p>}
// //       </div>
// //       <button onClick={uploadFileHandler} disabled={uploading || !selectedFile}>
// //         {uploading ? 'Uploading...' : 'Upload'}
// //       </button>
// //       {uploadSuccess && <p className="success-message">{uploadSuccess}</p>}
// //       <button
// //         onClick={clickHandle}
// //         disabled={!url.common || !url.ecard || !url.policy}
// //       >
// //         Submit
// //       </button>
// //     </div>
// //   );
// // };

// // export default DocumentUpload;


// const DocumentUpload = () => {
//   const { policyId } = useParams();
//   const [url, setUrl] = useState({
//     common: '',
//     ecard: '',
//     policy: '',
//   });

//   const [selectedDocType, setSelectedDocType] = useState('');
//   const [selectedFile, setSelectedFile] = useState(null);
//   const [uploading, setUploading] = useState(false);
//   const [uploadSuccess, setUploadSuccess] = useState('');
//   const [errorMessage, setErrorMessage] = useState('');
//   console.log("🚀 ~ DocumentUpload ~ urlllll:", url)

//   const navigate = useNavigate();
//   const dispatch = useDispatch(); // Use Redux dispatch
//   const { policyData, isFetching, error } = useSelector(
//     (state) => state.policy
//   ); // Access state
//   console.log("🚀 ~ DocumentUpload ~ policyData:", policyData)

//   const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

//   // Fetch policy data when the component mounts
//   useEffect(() => {
//     if (policyId) {
//       dispatch(fetchPolicyData(policyId));
//     }
//   }, [dispatch, policyId]);

//   const handleDocTypeChange = (e) => {
//     setSelectedDocType(e.target.value);
//   };

//   const handleFileChange = (e) => {
//     const file = e.target.files[0];
//     if (file && file.size > FILE_SIZE_LIMIT) {
//       setErrorMessage('File size should be less than 10 MB');
//       setSelectedFile(null);
//     } else {
//       setSelectedFile(file);
//       setErrorMessage('');
//     }
//   };

//   const uploadFileHandler = async () => {
//     if (!selectedFile || !selectedDocType) {
//       setErrorMessage('Please select a document type and file to upload');
//       return;
//     }

//     setUploading(true);
//     setErrorMessage('');
//     try {
//       const uploadResult = await uploadFile(selectedFile);
//       setUploading(false);

//       if (uploadResult?.data && uploadResult.data.length > 0) {
//         setUploadSuccess(
//           `File uploaded successfully: ${uploadResult.data[0]?.url}`
//         );
//         setUrl((prev) => ({
//           ...prev,
//           [selectedDocType.toLowerCase()]: uploadResult.data[0]?.url,
//         }));
//       } else {
//         setErrorMessage('File upload failed: Invalid response structure');
//       }

//       setSelectedFile(null);
//     } catch (error) {
//       setUploading(false);
//       setErrorMessage(`Error uploading file: ${error.message}`);
//     }
//   };

//   const clickHandle = async () => {
//     const obj = {
//       documents: {
//         common: url.common,
//         ecard: url.ecard,
//         policy: url.policy,
//       },
//     };

//     if (!url.common && !url.ecard && !url.policy) {
//       alert('Please Upload Your Common, ECard, and Policy Documents to Proceed');
//     } else {
//       try {
//         const params = { _id: policyData?.data._id, ...obj }; // Params to send
//         console.log("🚀 ~ clickHandle ~ params:", params)
//         await dispatch(updatePolicy(params)); // Dispatch the Redux action
        
//         if (policyData?.data?.status === 'success') {
//           alert('Policy documents uploaded successfully');
//           setUrl({ common: '', ecard: '', policy: '' }); // Reset URLs
//         } 
//       } catch (error) {
//         console.error('Error updating policy:', error);
//       }
//     }
//   };

//   if (isFetching) return <p>Loading policy data...</p>;
//   if (error) return <p>Error fetching policy data</p>;

//   return (
//     <div className="upload-container">
//       <h3 className="policyUploadHeading">Document Upload</h3>
//       <div className="doc-type">
//         <label>Select Document Type:</label>
//         <div>
//           <input
//             type="radio"
//             name="docType"
//             value="common"
//             onChange={handleDocTypeChange}
//           />{' '}
//           Common
//           <input
//             type="radio"
//             name="docType"
//             value="ecard"
//             onChange={handleDocTypeChange}
//           />{' '}
//           ECard
//           <input
//             type="radio"
//             name="docType"
//             value="policy"
//             onChange={handleDocTypeChange}
//           />{' '}
//           Policy
//         </div>
//       </div>
//       <div className="file-upload">
//         <label>Select a file (max 10MB):</label>
//         <input type="file" onChange={handleFileChange} />
//         {errorMessage && <p className="error-message">{errorMessage}</p>}
//       </div>
//       <button
//         type="button"
//         className="policyUploadBtn"
//         onClick={uploadFileHandler}
//         disabled={uploading || !selectedFile}
//       >
//         {uploading ? 'Uploading...' : 'Upload'}
//       </button>
//       {uploadSuccess && <p className="success-message">{uploadSuccess}</p>}
//       <button
//         type="submit"
//         className="policyUploadBtn"
//         onClick={clickHandle}
//         disabled={!url.common && !url.ecard && !url.policy}
//       >
//         Submit
//       </button>
//     </div>
//   );
// };

// export default DocumentUpload;
import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../../components/POS/uploadFile';
import './policyUpload.css';

import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPolicyData, updatePolicy } from '../../Redux/policyRedux';
import { useParams } from 'react-router-dom';

const DocumentUpload = () => {
  const { policyId } = useParams();
  console.log("🚀 ~ DocumentUpload ~ policyId:", policyId)
  const [url, setUrl] = useState({
    common: '',
    ecard: '',
    policy: '',
  });

  const [uploadedDocuments, setUploadedDocuments] = useState([]); // Track uploaded documents
  const [selectedDocType, setSelectedDocType] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { policyData, isFetching, error } = useSelector(
    (state) => state.policy
  );

  const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

  console.log("🚀 ~ DocumentUpload ~ policyData:", policyData)
  // Fetch policy data when the component mounts
  useEffect(() => {
    if (policyId) {
      dispatch(fetchPolicyData(policyId));
    }
  }, [dispatch, policyId]);

  const handleDocTypeChange = (e) => {
    setSelectedDocType(e.target.value);
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > FILE_SIZE_LIMIT) {
      setErrorMessage('File size should be less than 10 MB');
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  };

  const uploadFileHandler = async () => {
    if (!selectedFile || !selectedDocType) {
      setErrorMessage('Please select a document type and file to upload');
      return;
    }

    setUploading(true);
    setErrorMessage('');
    try {
      const uploadResult = await uploadFile(selectedFile);
      setUploading(false);

      if (uploadResult?.data && uploadResult.data.length > 0) {
        const uploadedFileUrl = uploadResult.data[0]?.url;
        setUploadSuccess(`File uploaded successfully: ${uploadedFileUrl}`);
        setUrl((prev) => ({
          ...prev,
          [selectedDocType.toLowerCase()]: uploadedFileUrl,
        }));

        // Add the uploaded document to the list of uploaded documents
        setUploadedDocuments((prevDocs) => [
          ...prevDocs,
          {
            type: selectedDocType,
            fileName: selectedFile.name,
            url: uploadedFileUrl,
            issuedBy: 'Admin', // Placeholder for issued by
            issueDate: new Date().toLocaleString(), // Current date and time
          },
        ]);
      } else {
        setErrorMessage('File upload failed: Invalid response structure');
      }

      setSelectedFile(null);
    } catch (error) {
      setUploading(false);
      setErrorMessage(`Error uploading file: ${error.message}`);
    }
  };

  // const clickHandle = async () => {
  //   const obj = {
  //     documents: {
  //       quotationURL: url.common,
  //       ecard: url.ecard,
  //       policy: url.policy,
  //     },
  //   };

  //   if (!url.common && !url.ecard && !url.policy) {
  //     alert(
  //       'Please Upload Your Common, ECard, and Policy Documents to Proceed'
  //     );
  //   } else {
  //     try {
  //       const params = { _id: policyData?.data._id, ...obj };
  //       await dispatch(updatePolicy(params));

  //       if (policyData?.data?.status === 'success') {
  //         alert('Policy documents uploaded successfully');
  //         setUrl({ common: '', ecard: '', policy: '' });
  //       }
  //     } catch (error) {
  //       console.error('Error updating policy:', error);
  //     }
  //   }
  // };
  const clickHandle = async () => {
  // Determine which document URL to send as quotationURL
  let quotationURL = '';

  if (url.common) {
    quotationURL = url.common;
  } else if (url.ecard) {
    quotationURL = url.ecard;
  } else if (url.policy) {
    quotationURL = url.policy;
  }

  // Ensure at least one document URL is available
  if (!quotationURL) {
    alert('Please upload a document to proceed.');
    return;
  }

  // Create the payload with just the quotationURL
  const obj = {
    quotationURL, // Send the selected document URL directly
  };

  try {
    const params = { enquiryId: policyId, ...obj };
    await dispatch(updatePolicy(params));
    console.log("🚀 ~ clickHandle ~ policyDataaaaa:", policyData)
    console.log("🚀 ~ clickHandle ~ sssssssssparannss:", params)

    if (policyData?.data?.status === 'success') {
      alert('Policy document uploaded successfully');
      setUrl({ common: '', ecard: '', policy: '' }); // Reset the URL states after success
    }
  } catch (error) {
    console.error('Error updating policy:', error);
  }
};


  if (isFetching) return <p>Loading policy data...</p>;
  if (error) return <p>Error fetching policy data</p>;

  return (
    <>
      <div className="upload-container">
        <h3 className="policyUploadHeading">Document Upload</h3>
        <div className="doc-type">
          <label>Select Document Type:</label>
          <div>
            <input
              type="radio"
              name="docType"
              value="common"
              onChange={handleDocTypeChange}
            />{' '}
            Common
            <input
              type="radio"
              name="docType"
              value="ecard"
              onChange={handleDocTypeChange}
            />{' '}
            ECard
            <input
              type="radio"
              name="docType"
              value="policy"
              onChange={handleDocTypeChange}
            />{' '}
            Policy
          </div>
        </div>
        <div className="file-upload">
          <label>Select a file (max 10MB):</label>
          <input type="file" onChange={handleFileChange} />
          {errorMessage && <p className="error-message">{errorMessage}</p>}
        </div>
        <button
          type="button"
          className="policyUploadBtn"
          onClick={uploadFileHandler}
          disabled={uploading || !selectedFile}
        >
          {uploading ? 'Uploading...' : 'Upload'}
        </button>
        {uploadSuccess && <p className="success-message">{uploadSuccess}</p>}
        <button
          type="submit"
          className="policyUploadBtn"
          onClick={clickHandle}
          disabled={!url.common && !url.ecard && !url.policy}
        >
          Submit
        </button>
      </div>

      {/* Separate section for document cards */}
      <div className="document-cards-section">
        {/* <h3>List of Document Files in this Policy:</h3> */}
        {uploadedDocuments.length > 0 ? (
          <div class="document-table-card">
            <h3>List of Document Files in this Policy</h3>
            <div class="document-table-container">
              <table className="document-table">
                <thead>
                  <tr>
                    <th>Document Name</th>
                    <th>Issue Date</th>
                    <th>Issued By</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {uploadedDocuments && uploadedDocuments.length > 0 ? (
                    uploadedDocuments.map((doc, index) => (
                      <tr key={index}>
                        <td>{doc.fileName}</td>
                        <td>{doc.issueDate}</td>
                        <td>{doc.issuedBy}</td>
                        <td>
                          <a
                            href={doc.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4">No documents available</td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        ) : (
          <div className="document-cards-section">
              <div class="document-table-card">
                <div class="document-table-container">
                  <p>No documents uploaded yet.</p>
                </div>
              </div>
          </div>
        )}
      </div>
    </>
  );
};

export default DocumentUpload;
