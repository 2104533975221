import styled from "styled-components";

export const CardContainer = styled.div`
    padding: 1rem;
    border-radius: 10px;
    border: 1px solid #B7B7B7;
    width: 85%;
    margin: 1rem auto;
    margin-bottom: 2rem;

    @media screen and (max-width:980px) {
        margin-top: 5rem;
    }
    
    .flex {
        display: flex;
    }
    .column {
        flex-direction: column;
    }
    .align-center {
        align-items: center;
    }
    .j-center {
        justify-content: center;
    }

    .success {
        width: 30%;
    }
    .success-msg {
        color: #3AD42F;
        font-weight: 700;
        font-size: 1.1rem;
    }
    .info {
        color: #605F5E;
        font-weight: 700;
        text-align: center;
    }


`