export const copyToClipboard = () => {
    const currentLink = window.location.href;
    navigator.clipboard.writeText(currentLink)
      .then(() => {
        console.log('Link copied to clipboard:', currentLink);
        alert('Link copied..');
      })
      .catch((error) => {
        console.error('Unable to copy link to clipboard', error);
      });
  };
