import {useEffect,useState} from 'react'
import { Row, Col, Input, Typography } from "antd";
import ArrowImg from '../../assets/brand-new-car/arrow-right.svg'
import { FaSearch } from "react-icons/fa";
import { AiOutlineSearch } from "react-icons/ai";
import { brandList } from "./data";
import Maruti from '../../assets/brand-new-car/maruti.svg'
import { 
    CloseBtn, 
    InactiveTab, 
    LeftArrowIcon, 
    OuterRow, 
    RightArrowIcon, 
    RtoModalContainer, 
    styles, 
    Tab, 
    TopBox,
    ErrorContainer,
    ErrorMsgContainer, 
    ErrArrow,
    ErrorIcon, 
    HDiv,
    InputContainer,
    FloatingTitle,
    SelectDropdown,
    InputC
} from "./styles";
import './car-details.css'
import { Button } from "antd";
import { InputComponent } from "../input-component/InputComponent";
import CloseButton from '../../assets/brand-new-car/close-btn.svg'
import { Oval } from  'react-loader-spinner';
import { useNavigate } from "react-router-dom";
import ErrorIconImg from '../../../src/assets/brand-new-car/exclamation.svg';
const moment = require('moment');

const {Title} = Typography;




export const CarDetailsComponent = ({
        title, data, step,
        onSelect, noSearch, 
        engineNo, chassisNo, 
        colour, fuelType, totalSteps,
        manufacture, registration, search, 
        modalData, handleModalSelect, handleModalClose, triggerModal,
        searchModal,
        searchBrandModal,
        searchRto,
        setEngineNoState,
        setChassisState,
        setColorState,
        modals,
        setFuelValue,
        setManufacture,
        setRegistration,
        cubicType,
        variantsList,
        cityList,
        modalLoading,
        yellowBorder,
        widthAuto,
        max,
        dropDownDefault,
        error,
        setError,
        cityPage
    }) => {
        
        const regex = /([A-Z0-9]+)-([^()]+)\(([^)]+)\)/;
        const navigate = useNavigate();

        const ErrorPopup = ({errors}) => {
            return (
                <>
                <ErrorContainer>
                    <ErrorMsgContainer>{(errors)}</ErrorMsgContainer>
                    <ErrArrow />
                    <ErrorIcon src={ErrorIconImg} />
                </ErrorContainer>
                </>
            )
        }


        
    return (
        <OuterRow style={widthAuto?{width:'auto'}:null}>
            <TopBox style={yellowBorder?{borderColor:'#F6931E'}:null}>
                <Col>
                    <Button onClick={()=>navigate(-1)} style={{border:'none'}}>
                        <LeftArrowIcon src={ArrowImg} />
                    </Button>
                </Col>
                <Col style={styles.midCol}>
                    
                    <Row>
                        <Title level={5} style={styles.step} >Step {step}/{totalSteps?totalSteps:7}</Title>
                    </Row>
                    <Row>
                        <Title level={4} style={styles.brand}>{title}</Title>
                    </Row>
                </Col>
                <Col>
                    <Button onClick={()=>navigate(1)} style={{border:'none'}}>
                        <RightArrowIcon src={ArrowImg} />
                    </Button>
                </Col>
            </TopBox>


            {noSearch?null:
                <Input
                    addonBefore={<AiOutlineSearch style={{ marginTop: '5px' }} />}
                    style={styles.searchBox}
                    className='sss'
                    size='large'
                    placeholder={search?search:"Search for the brand like Maruti, Hyundat, Tata, etc.."}
                    onChange={(e)=>{
                        if(step ==1){
                            searchModal(e)
                        }else if(step ==2){
                            searchBrandModal(e)
                        }else if(step == 7){
                            searchRto(e)
                        }
                    }}
                />
            }


            <Row style={styles.gridContainer}>
                {
                    data?data.map(item => (
                       
                    <button  type="button" onClick={() => {cityPage == "active" ? onSelect(item.match(regex)[1],item.match(regex)[2]) : onSelect(item)}} style={styles.brandBox}>

                        {   
                            cityPage == "active"
                             ?
                             <>
                             <h4 style={styles.rtoCode}>{item.match(regex)[1]}</h4>
                             <h4 style={styles.brandName}>{item.match(regex)[2].toUpperCase()}</h4>
                             </>
                             :
                            cityList == 1
                            ?
                            <h4 style={styles.brandName}>{item}</h4>            
                            :
                            <>
                            <img style={styles.brandLogo} alt='brandLogo' src={item.image} />
                            <h5 style={styles.brandName}>{item.name}</h5>
                            </>
                        }
                        {/* {item.logo?( */}
                        {/* ):null}
                        {item.brand?(
                        ):null}
                    */}
                    {/* {item.model?(
                    ):null} */}
                
                    </button>
                    
                )):
                   null
            }
            </Row>
            <Row style={styles.gridContainer}>
                {
                    variantsList?variantsList.map(item => (
                        <Col onClick={() => onSelect(item)} style={styles.brandBox}>
                        <h5 style={styles.brandName}>{item.variantName}</h5>
            
                    </Col>
                )):
                   null
            }
            </Row>
            <Row style={styles.gridContainer}>
                {
                    modals?modals.map(item => (
                    <Col onClick={() => onSelect(item)} style={styles.brandBox}>
                        <h5 style={styles.brandName}>{item.modelName}</h5>
                    </Col>
                )):
                   null
            }
            </Row>
            <Row style={styles.gridContainer}>
                {
                    cubicType?cubicType.map(item => (
                    <Col onClick={() => {onSelect(item)}} style={styles.brandBox}>
                        <h3 style={styles.brandName}>{item}</h3>
                    </Col>
                )):
                   null
            }
            </Row>
            {/* <Row style={styles.gridContainer}>
                {
                    cityListState?cityListState.map(item => (
                    <Col onClick={() => {onSelect(item)}} style={styles.brandBox}>
                        <h4 style={styles.brandName}>{item}</h4>
                    </Col>
                )):
                   null
            }
            </Row> */}

            

            {step == 3?(
                <>
                    {/* <InputComponent value={engineNo} placeholder={'Please enter engine no.'} title='Engine no.' onChange={setEngineNoState} /> */}
                    <InputContainer>
                        <FloatingTitle>{'Engine no.'}</FloatingTitle>
                        <HDiv className="m-0">
                            <input
                                maxLength={12}
                                placeholder="Please enter engine no."
                                onChange={(e)=>{
                                    const cleanValue = (e.target.value).replace(/[^a-zA-Z0-9]/g, '');  setEngineNoState(cleanValue);
                                    setError({});
                                }}
                                value={engineNo}
                            />
                            {error.hasOwnProperty('engineNo') ? (
                                <ErrorPopup errors={error.engineNo}/>
                            ) : null}
                        </HDiv>
                    </InputContainer>
                </>
            ):null}
            {step == 3?(
                <>
                    {/* <InputComponent value={chassisNo} placeholder={'Please enter chassis no.'} title='Chassis no.' onChange={setChassisState} /> */}
                    <InputContainer>
                        <FloatingTitle>{'Chassis no.'}</FloatingTitle>
                        <HDiv className="m-0">
                            <input
                                maxLength={17}
                                placeholder="Please enter chassis no."
                                onChange={(e)=>{
                                    const cleanValue = (e.target.value).replace(/[^a-zA-Z0-9]/g, ''); setChassisState(cleanValue);
                                    setError({});
                                }}
                                value={chassisNo}
                            />
                            {error.hasOwnProperty('chassisNo') ? (
                                <ErrorPopup errors={error.chassisNo}/>
                            ) : null
                        }
                        </HDiv>
                    </InputContainer>
                </>
            ):null}

            {step == 3?(
                <>
                {/* <InputComponent value={colour} placeholder={'Please enter color.'} title='Color' onChange={setColorState}/> */}
                <InputContainer>
                        <FloatingTitle>{'Color'}</FloatingTitle>
                        <HDiv className="m-0">
                            <input
                                maxLength={10}
                                placeholder="Please enter color."
                                onChange={(e)=>{
                                    setColorState(e.target.value);
                                    setError({});
                                }}
                                value={colour}
                            />
                            {/* {error.hasOwnProperty('chassisNo') ? (
                                <ErrorPopup errors={error.chassisNo}/>
                            ) : null} */}
                        </HDiv>
                    </InputContainer>
                </>
            ):null}

            {fuelType?(
                <>
                {/* <InputComponent title='Fuel Type' dropdown={fuelType} onChange={setFuelValue} value={dropDownDefault}/> */}
                    <InputContainer>
                        <FloatingTitle>{'Fuel Type.'}</FloatingTitle>
                        <SelectDropdown onChange={(e) => {setFuelValue(e.target.value);setError({})}}>
                            <option value={-1} disabled selected>{'Please select fuel type'}</option>
                            {
                                fuelType.map(item => (
                                    <>
                                    {
                                        dropDownDefault == item ?
                                        <option selected value={item}>{item}</option>
                                        :
                                        <option value={item}>{item}</option>
                                    }
                                    </>
                                ))
                            }
                        </SelectDropdown>
                        {error.hasOwnProperty('fuelType') ? (
                            <ErrorPopup errors={error.fuelType}/>
                        ) : null}
                    </InputContainer>
                </>
            ):null}

            {step == 4 && (
                <>
                    <InputContainer>
                        <FloatingTitle>{'Manufacture Date.'}</FloatingTitle>
                            <input
                                max={max?moment().format('YYYY-MM-DD'):null}
                                onChange={(e)=>{
                                    setManufacture(e.target.value);
                                    setRegistration('');
                                    setError({});
                                }}
                                value={manufacture?manufacture:''}
                                type={'date'}
                            />
                        {error.hasOwnProperty('manufacture') ? (
                            <ErrorPopup errors={error.manufacture}/>
                        ) : null}
                    </InputContainer>
                </>
            )} 

            {step == 4 && (
                <InputContainer>
                    <FloatingTitle>{'Registration Date.'}</FloatingTitle>
                        <input
                            min={manufacture}
                            max={moment().format('YYYY-MM-DD')}
                            onChange={(e)=>{
                                setRegistration(e.target.value);
                                setError({});
                            }}
                            value={registration?registration:''}
                            type={'date'}
                        />
                    
                    {error.hasOwnProperty('registration') ? (
                        <ErrorPopup errors={error.registration}/>
                    ) : null}
                </InputContainer>
            )}

            {modalData&&triggerModal?(
                <RtoModalContainer>

                    <CloseBtn onClick={() => handleModalClose()} src={CloseButton} />
                    {
                        modalLoading ?
                        <Row style={{}}>
                        <Oval
                        height={25}
                        width={25}
                        color="red"
                        wrapperStyle={{}}
                        wrapperClass=""
                        visible={true}
                        ariaLabel='oval-loading'
                        secondaryColor="red"
                        strokeWidth={2}
                        strokeWidthSecondary={2}
                    />
                        </Row>
                        :
                        <>
                        
                       {modalData?.map(item => (
                        <>
                            <Tab onClick={() =>handleModalSelect(item.code)}>{item}</Tab>
                        </>
                       ))} 
                       </>
                    }
                </RtoModalContainer>
            ):null}
            
        </OuterRow>
    )
}