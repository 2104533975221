import { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { CardHeader } from "../../../components/card-header/CardHeader"
import Footer from "../../../components/Footer/FooterNew"
import { BgDiv, BottomTitle, CardMainContainer, ConfirmBtn, DivBorRight, DivNoBorder, HDiv, MainContainer, SubCard, SubCardTitle } from "./styles"

export const CarForm4 = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [data, setData] = useState({
        emergencyCover:false, 
        engineProtector:false,
        pac:false, 
        zeroDep:false,
        returnToInvoice:false,
        hydrostatic:false,
        consumables:false,
        lossOfKey:false,
        hospitalCash:false,
        tyre:false,
        lossOfPersonalBelongings:false,
        conveyance:false,
        ncb:false,
        roadSideAssist:false
    })
    const [additionalCoverData, setAdditionalCoverData] = useState({
        electricalAccessory:false,
        nonElectricalAccessory:false,
        isBiFuelKit:false,
        paForDriver:false,
        paForUnnamed:false,
        legalLiabilityEmployee:false,
        legalLiabilityDriver:false,
        fibreTank:false
    })
    const [discountData, setDiscountData] = useState({
        voluntaryExcess:false,
        antiTheft:false,
        aaiMember:false,
        modifiedforHandicap:false,
        tppdRestricted:false
    })

    const SubCardComp = ({policyFeatures}) => {

        if(policyFeatures==='addons') {
            
            return(
                <SubCard>
                    <SubCardTitle>Addon Cover</SubCardTitle>
                    <DivBorRight>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.emergencyCover} 
                                checked={data.emergencyCover==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, emergencyCover:data.emergencyCover==false?true:false}))}
                            />
                            <p>Emergency Cover</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.zeroDep} 
                                checked={data.zeroDep==true?true:false}
                                onChange={(e) => setData(prev => ({...prev, zeroDep:data.zeroDep==false?true:false}))} 
                            />
                            <p>Zero Depreciation Cover</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.consumables} 
                                checked={data.consumables==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, consumables:data.consumables==false?true:false}))}
                            />
                            <p>Consumables</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.tyre} 
                                checked={data.tyre==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, tyre:data.tyre==false?true:false}))}
                            />
                            <p>Tyre Cover</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.ncb} 
                                checked={data.ncb==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, ncb:data.ncb==false?true:false}))}
                            />
                            <p>NCB Protection</p>
                        </HDiv>
                    </DivBorRight>

                    <DivBorRight className="center-border-div">
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.engineProtector} 
                                checked={data.engineProtector==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, engineProtector:data.engineProtector==false?true:false}))}
                            />
                            <p>Engine Protector</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.returnToInvoice} 
                                checked={data.returnToInvoice==true?true:false}
                                onChange={(e) => setData(prev => ({...prev, returnToInvoice:data.returnToInvoice==false?true:false}))} 
                            />
                            <p>Return to Invoice</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.lossOfKey} 
                                checked={data.lossOfKey==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, lossOfKey:data.lossOfKey==false?true:false}))}
                            />
                            <p>Loss of Key</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.lossOfPersonalBelongings} 
                                checked={data.lossOfPersonalBelongings==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, lossOfPersonalBelongings:data.lossOfPersonalBelongings==false?true:false}))}
                            />
                            <p>Loss of Personal Belonging</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.roadSideAssist} 
                                checked={data.roadSideAssist==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, roadSideAssist:data.roadSideAssist==false?true:false}))}
                            />
                            <p>Road Side Assistance</p>
                        </HDiv>
                    </DivBorRight>
                    <DivNoBorder className="center-border-div">
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.pac} 
                                checked={data.pac==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, pac:data.pac==false?true:false}))}
                            />
                            <p>Passanger Assist Cover</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.hydrostatic} 
                                checked={data.hydrostatic==true?true:false}
                                onChange={(e) => setData(prev => ({...prev, hydrostatic:data.hydrostatic==false?true:false}))} 
                            />
                            <p>Hydrostatic Lock Cover</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.hospitalCash} 
                                checked={data.hospitalCash==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, hospitalCash:data.hospitalCash==false?true:false}))}
                            />
                            <p>Hospital Cash Cover</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={data.conveyance} 
                                checked={data.conveyance==true?true:false} 
                                onChange={(e) => setData(prev => ({...prev, conveyance:data.conveyance==false?true:false}))}
                            />
                            <p>Conveyance Benefit</p>
                        </HDiv>
                        <HDiv>
                            <p> </p>
                        </HDiv>
                    </DivNoBorder>

                </SubCard>
            )
        }

        if(policyFeatures==='additional-cover') {
            return(
                <SubCard>
                    <SubCardTitle>Additional Cover</SubCardTitle>
                    <DivBorRight>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.electricalAccessory} 
                                checked={additionalCoverData.electricalAccessory==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, electricalAccessory:additionalCoverData.electricalAccessory==false?true:false}))}
                            />
                            <p>Electrical Accessory?</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.nonElectricalAccessory} 
                                checked={additionalCoverData.nonElectricalAccessory==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, nonElectricalAccessory:additionalCoverData.nonElectricalAccessory==false?true:false}))}
                            />
                            <p>Non Electrical Accessory</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.isBiFuelKit} 
                                checked={additionalCoverData.isBiFuelKit==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, isBiFuelKit:additionalCoverData.isBiFuelKit==false?true:false}))}
                            />
                            <p>Is Bi-Fuel Kit?</p>
                        </HDiv>
                    </DivBorRight>
                    <DivBorRight className="center-border-div">
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.paForDriver} 
                                checked={additionalCoverData.paForDriver==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, paForDriver:additionalCoverData.paForDriver==false?true:false}))}
                            />
                            <p>PA Cover for Paid Driver?</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.paForUnnamed} 
                                checked={additionalCoverData.paForUnnamed==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, paForUnnamed:additionalCoverData.paForUnnamed==false?true:false}))}
                            />
                            <p>PC Cover for Unnamed Persons?</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.legalLiabilityEmployee} 
                                checked={additionalCoverData.legalLiabilityEmployee==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, legalLiabilityEmployee:additionalCoverData.legalLiabilityEmployee==false?true:false}))}
                            />
                            <p>Legal Liability of employee?</p>
                        </HDiv>
                    </DivBorRight>
                    <DivNoBorder className="center-border-div">
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.legalLiabilityDriver} 
                                checked={additionalCoverData.legalLiabilityDriver==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, legalLiabilityDriver:additionalCoverData.legalLiabilityDriver==false?true:false}))}
                            />
                            <p>Legal Liability to Paid Driver?</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={additionalCoverData.fibreTank} 
                                checked={additionalCoverData.fibreTank==true?true:false} 
                                onChange={(e) => setAdditionalCoverData(prev => ({...prev, fibreTank:additionalCoverData.fibreTank==false?true:false}))}
                            />
                            <p>Fibre-Glass Fuel Tank?</p>
                        </HDiv>
                        <HDiv>
                            <p> </p>
                        </HDiv>
                    </DivNoBorder>
                </SubCard>
            )
        }

        if(policyFeatures==='discount') {
            return(
                <SubCard>
                    <SubCardTitle>Discount</SubCardTitle>
                    <DivBorRight>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={discountData.voluntaryExcess} 
                                checked={discountData.voluntaryExcess==true?true:false} 
                                onChange={(e) => setDiscountData(prev => ({...prev, voluntaryExcess:discountData.voluntaryExcess==false?true:false}))}
                            />
                            <p>Voluntary Excess?</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={discountData.antiTheft} 
                                checked={discountData.antiTheft==true?true:false} 
                                onChange={(e) => setDiscountData(prev => ({...prev, antiTheft:discountData.antiTheft==false?true:false}))}
                            />
                            <p>Anti-Theft Device?</p>
                        </HDiv>
                    </DivBorRight>
                    <DivBorRight className="center-border-div">
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={discountData.aaiMember} 
                                checked={discountData.aaiMember==true?true:false} 
                                onChange={(e) => setDiscountData(prev => ({...prev, aaiMember:discountData.aaiMember==false?true:false}))}
                            />
                            <p>Member of AAI?</p>
                        </HDiv>
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={discountData.modifiedforHandicap} 
                                checked={discountData.modifiedforHandicap==true?true:false} 
                                onChange={(e) => setDiscountData(prev => ({...prev, modifiedforHandicap:discountData.modifiedforHandicap==false?true:false}))}
                            />
                            <p>Vehicle Modified for Handicap?</p>
                        </HDiv>
                    </DivBorRight>
                    <DivNoBorder className="center-border-div">
                        <HDiv>
                            <input 
                                type={'checkbox'}
                                value={discountData.tppdRestricted} 
                                checked={discountData.tppdRestricted==true?true:false} 
                                onChange={(e) => setDiscountData(prev => ({...prev, tppdRestricted:discountData.tppdRestricted==false?true:false}))}
                            />
                            <p>TPPD Restricted to 6000?</p>
                        </HDiv>
                        <HDiv>
                            <p> </p>
                        </HDiv>
                    </DivNoBorder>
                </SubCard>
            )
        }

    }

    // console.log({...data, ...additionalCoverData, ...discountData});

    return(
        <>
            <MainContainer>
                <BgDiv />
                
                <CardMainContainer>
                    <CardHeader title='Add Ons' />

                    <BottomTitle>Selected Private Car</BottomTitle>

                    <SubCardComp policyFeatures={'addons'} />
                    
                    <SubCardComp policyFeatures={'additional-cover'} />

                    <SubCardComp policyFeatures={'discount'} />

                </CardMainContainer>

                <Link to='/car/form-5'>
                    <ConfirmBtn>Confirm and Continue</ConfirmBtn>
                </Link>

            </MainContainer>
            <Footer/>
        </>
    )
}