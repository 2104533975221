import React from "react";
import { GrNext, GrPrevious } from 'react-icons/gr'

const Pagination = ({ itemsPerPage, totalItems, currentPage, paginate }) => {
    const pageNumbers = Math.ceil(totalItems / itemsPerPage);
    const MAX_DISPLAY_PAGES = 3; // Number of page links to display at the beginning
    const MAX_PAGES_WITH_DOTS = 3;

    if (pageNumbers === 1) {
        // Hide pagination if only one page
        return null;
    }
    const getPageNumbers = () => {
        if (pageNumbers <= MAX_PAGES_WITH_DOTS) {
            // If total page numbers are less than or equal to MAX_PAGES_WITH_DOTS, show all pages
            return Array.from({ length: pageNumbers }, (_, index) => index + 1);
        }

        // Calculate the range of page numbers to display with dots
        const leftBoundary = Math.min(Math.max(currentPage - Math.floor(MAX_PAGES_WITH_DOTS / 2), 2), pageNumbers - MAX_PAGES_WITH_DOTS );
        return Array.from({ length: MAX_PAGES_WITH_DOTS }, (_, index) => leftBoundary + index);
    };

    return (
        <div className="">
            <ul className="paginate-list">

                <button className="paginate-button" onClick={() => currentPage && currentPage > 1 && paginate(currentPage - 1)} ><GrPrevious className="paginate-icons" /></button>
                <li
                >
                    <button className={`paginate-numbers ${currentPage === 1 ? 'active' : ''}`} onClick={() => paginate(1)} >
                        {1}
                    </button>
                </li>
                {currentPage > MAX_PAGES_WITH_DOTS && (
                    <li className="paginate-dots">
                        <span>...</span>
                    </li>
                )}
                {getPageNumbers().map((number) => (
                    <li
                        key={number}
                    >
                        <button className={`paginate-numbers ${currentPage === number ? 'active' : ''}`} onClick={() => paginate(number)} >
                            {number }
                        </button>
                    </li>
                ))}
                {currentPage + MAX_DISPLAY_PAGES < pageNumbers && (
                    <li className="paginate-dots">
                        <span>...</span>
                    </li>
                )}
                <li
                >
                    <button className={`paginate-numbers ${currentPage === pageNumbers ? 'active' : ''}`} onClick={() => paginate(pageNumbers)} >
                        {pageNumbers}
                    </button>
                </li>
                <button className="paginate-button" onClick={() => currentPage < pageNumbers && paginate(currentPage + 1)}>
                    <span className="paginate-icons">
                        <GrNext />
                    </span>
                </button>
            </ul>
        </div >
    );
};
export default Pagination;