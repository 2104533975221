// src/Modal.js
import React from 'react';
import styled from 'styled-components';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContainer = styled.div`
  background: white;
  padding: 20px;
  border-radius: 5px;
  width: 400px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  position: relative;
`;

const Header = styled.div`
  font-size: 25px;
  margin-bottom: 17px;
  text-align:center;
`;

const Body = styled.div`
font-site: 17px;
  margin-bottom: 20px;
  text-align: center;
`;

const Footer = styled.div`
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Button = styled.button`
  padding: 10px 20px;
  background: #f19825;
  font-size: 16px;
  cursor: pointer;
  margin-left: 10px;
  border:none
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 20px;
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
color: black !important
`;

const PupUp = ({ show, onClose, title, onOk, children }) => {
  if (!show) return null;

  return (
    <Overlay>
      <ModalContainer>
        <CloseButton onClick={onClose}>&times;</CloseButton>
        <Header>{title}</Header>
        <Body>{children}</Body>
        <Footer>
          <Button onClick={onClose}>No</Button>
          <Button onClick={onOk}>Yes</Button>
        </Footer>
      </ModalContainer>
    </Overlay>
  );
};

export default PupUp;
