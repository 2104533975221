import Footer from "../../../components/Footer/FooterNew"
import { Link } from 'react-router-dom'
import { useLocation } from "react-router"
import { BgDiv, BottomDiv, BottomSubDiv, Button, CardMainContainer, Div, DivBorderRight, DivPadding, HDiv, HDivApart, InputData, InputDiv, InputThemeDiv, InputThemeTitle, InputTitle, InsideCardContainer, Logo, MainContainer, MidDiv, MidSubDiv, PrivateCarBtn } from "./styles"
import { CardHeader } from '../../../components/card-header/CardHeader'
import { useEffect } from "react"

export const CarForm8 = () => {

    const location = useLocation()

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const item = location.state.from;
    // console.log(item);

    const InputDivComp = ({ title, val, variant }) => {
        return (
            <InputDiv className="padding-more">
                <InputTitle className={variant==='bg-w'?"bg-white":null}>{title}</InputTitle>
                <InputData>{val}</InputData>
            </InputDiv>
        )
    }

    const ThemeBtn = ({title, linkTo}) => {

        if(linkTo) {
            return(
                <Link to={linkTo} state={{ from: item}} >
                    <Button>{title}</Button>
                </Link>
            )
        } else {
            return(
                <Button>{title}</Button>
            )
        }
    }

    return (
        <>
            <MainContainer>
                <BgDiv />

                <CardMainContainer>
                    <CardHeader title='Preview' />

                    <InsideCardContainer>
                        <DivPadding>
                            <HDivApart>
                                <DivBorderRight>
                                    <Logo src={item.icon} />
                                </DivBorderRight>
                                <Div>
                                    <h4>{item.name}</h4>
                                    <p>Chevrolette Impala (Kesari, 2000cc) - Petrol </p> <span>Private Car - 2015 MP-04 </span>
                                    <p>Quotation No. - CVIMPL6989698989</p>
                                </Div>
                                <Div>
                                    <PrivateCarBtn>Private Car Package Policy</PrivateCarBtn>
                                </Div>
                            </HDivApart>
                        </DivPadding>

                        <MidDiv>
                            <InputThemeDiv>
                                <InputThemeTitle>Premium Details</InputThemeTitle>
                                <HDiv>
                                    <MidSubDiv>
                                        <InputDivComp title='Quotation Number' val={'04-Nov-2016'} />
                                        <InputDivComp title='Premium Year' val={'04-Nov-2016'} />
                                        <InputDivComp title='GST' val={'04-Nov-2016'} />
                                    </MidSubDiv>
                                    <MidSubDiv>
                                        <InputDivComp title='IDV' val={'₹ 115,193'} />
                                        <InputDivComp title='Net Premium' val={'₹ 115,193'} />
                                        <InputDivComp title='Final Premium' val={'₹ 115,193'} />
                                    </MidSubDiv>
                                </HDiv>
                            </InputThemeDiv>
                            <MidSubDiv>
                                <ThemeBtn title="Pay - ₹ 115,193" />
                            </MidSubDiv>
                        </MidDiv>

                        <BottomDiv>

                            <BottomSubDiv>
                                <InputThemeTitle className="bg-white">Policy Holder Detail</InputThemeTitle>
                                <InputDivComp variant='bg-w' title={'Name'} val='Mr. Pratap' />
                                <InputDivComp variant='bg-w' title={'Gender'} val='Male' />
                                <InputDivComp variant='bg-w' title={'Birth Date'} val='25-Oct-1998' />
                                <InputDivComp variant='bg-w' title={'Contact Number'} val='8969696969' />
                                <InputDivComp variant='bg-w' title={'Email Address'} val='example@gmail.com' />
                                <InputDivComp variant='bg-w' title={'Occupation'} val='Brigadier, Army' />
                                <InputDivComp variant='bg-w' title={'Communication Address'} val='41-b, MP Nagar, Bhopal, IN' />
                            </BottomSubDiv>
                            <BottomSubDiv>
                                <InputThemeTitle className="bg-white">Policy Holder Detail</InputThemeTitle>
                                <InputDivComp variant='bg-w' title={'Name'} val='Mr. Pratap' />
                                <InputDivComp variant='bg-w' title={'Gender'} val='Male' />
                                <InputDivComp variant='bg-w' title={'Birth Date'} val='25-Oct-1998' />
                                <InputDivComp variant='bg-w' title={'Contact Number'} val='8969696969' />
                                <InputDivComp variant='bg-w' title={'Email Address'} val='example@gmail.com' />
                                <InputDivComp variant='bg-w' title={'Occupation'} val='Brigadier, Army' />
                                <InputDivComp variant='bg-w' title={'Communication Address'} val='41-b, MP Nagar, Bhopal, IN' />
                            </BottomSubDiv>
                            <BottomSubDiv>
                                <InputThemeTitle className="bg-white">Nominee Details</InputThemeTitle>
                                <InputDivComp variant='bg-w' title={'Name'} val='Mr. Raghwendra Pratap' />
                                <InputDivComp variant='bg-w' title={'Relation'} val='Father' />
                                <InputDivComp variant='bg-w' title={'Gender'} val='Male' />
                                <InputDivComp variant='bg-w' title={'Birth Date'} val='25-Oct-1978' />
                            </BottomSubDiv>
                            <BottomSubDiv>
                                <InputThemeTitle className="bg-white">Nominee Details</InputThemeTitle>
                                <InputDivComp variant='bg-w' title={'Name'} val='Ms. Rama Pratap' />
                                <InputDivComp variant='bg-w' title={'Relation'} val='Mother' />
                                <InputDivComp variant='bg-w' title={'Gender'} val='Female' />
                                <InputDivComp variant='bg-w' title={'Birth Date'} val='25-Oct-1982' />
                            </BottomSubDiv>

                        </BottomDiv>

                    </InsideCardContainer>

                    <ThemeBtn linkTo='/car/form-9' title={`Pay - ₹${3369}`} />

                </CardMainContainer>

            </MainContainer>
            <Footer />
        </>
    )
}