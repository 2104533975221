import { MainContainer } from "../../../../assets/common-styles/common-styles"
import Footer from "../../../../components/Footer/FooterNew"
import { CardContainer, Separator } from "./styles"
import PrevArrowImg from '../../../../assets/commons/prev-arrow.svg'
import BrandImg from '../../../../assets/car-insurance/form-5/national.png'
import VideoImg from '../../../../assets/car-insurance/payments/video.svg'
import { CarDetailsComponent } from "../../../../components/car-details-component/CarDetailsComponent"
import { InputComponent } from "../../../../components/input-component/InputComponent"
import { useEffect, useState } from "react"
import { useNavigate ,useLocation} from "react-router-dom"
import { register,resendOTP, verifyUser } from "../../../../Redux/AuthRedux";
import { useSelector, useDispatch } from "react-redux";

export const CarPayment1 = () => {
    const { state:{item} } = useLocation();
    const [timer, setTimer] = useState(30);
    const [OTPState, setOTPState] = useState();
    const [mobileNumberState, setMobileNumberState] = useState('');
    const name = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const [form, setForm] = useState('reg')
    const navigate = useNavigate()

    const handleChange = e => {
        console.log(e);
    }

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    useEffect(() => {
        if(name.isOptSend == true){
            setOTPState(name.otp)
            setForm('otp')
            setTimer(30)
            return
        }
        // if(name.auth.isOptSend == true){
        //     setOTPState(name.auth.otp)
        //     setForm('otp')
        //     setTimer(30)
        //     return
        // }
        // if(name.auth.error){
        //     alert(name.auth.errorMessage)
        //     return
        // }
        // else if(name.auth.isVerified){
        //     setOtpSuccess(true)
        // }

    }, [name])

    const submitFun = () => {
        if(mobileNumberState.length != 10) {
            alert('Please enter a valid mobile number.')
            return
        }
        else{
            var obj = {
                phone: mobileNumberState,
                countryCode: "+91"
            }
            dispatch(register(obj));
        }
    }


    return (
      <>
        <MainContainer>
          <CardContainer>
            <div className="flex">
              <img className="arrow" src={PrevArrowImg} />
              <p className="back-to-quotes">Back to Quotes</p>
            </div>

            <div className="flex apart cards-container">
              <div className="flex-1 mr-half">
                <CarDetailsComponent
                  totalSteps={3}
                  step={1}
                  title="Register"
                  yellowBorder={true}
                  noSearch={true}
                  widthAuto={true}
                />

                {form === 'reg' ? (
                  <p className="bold">
                    Welcome to{' '}
                    <span className="simpli">
                      Simpli<span className="insure">Insure</span>
                    </span>
                    . Please enter your credentials to gain the best rates of
                    insurances in market.
                  </p>
                ) : form === 'otp' ? (
                  <p className="bold">
                    Enter the valid OTP shared to{' '}
                    <span className="simpli">XXXXXXX976</span>
                  </p>
                ) : null}

                {form === 'reg' ? (
                  <>
                    <InputComponent
                      title={'Full Name'}
                      placeholder="Enter your full name"
                      onChange={handleChange}
                    />
                    <InputComponent
                      title="Mobile Number"
                      type="number"
                      onChange={(e) => setMobileNumberState(e)}
                    />
                    <p className="bold">
                      By continuing, you agree to the{' '}
                      <span className="tnc">Terms of Use</span> and{' '}
                      <span className="tnc">Privacy Policy</span> of{' '}
                      <span className="simpli">Simpli</span>
                      <span className="insure">Insure</span>
                    </p>
                  </>
                ) : form === 'otp' ? (
                  <>
                    <InputComponent
                      title="Enter OTP"
                      type="otp"
                      onChange={handleChange}
                    />
                    <div className="flex apart mb-1">
                      <p className="change-no">Change Number</p>
                      <p className="resend">Resend code</p>
                    </div>
                  </>
                ) : null}

                {form === 'reg' ? (
                  <button
                    onClick={() => submitFun()}
                    id="reg"
                    className="continue"
                  >
                    Continue
                  </button>
                ) : form === 'otp' ? (
                  <button
                    id="otp"
                    onClick={() => {
                      // navigate('/car-insurance/payment/3',{state: item})
                      var enquiryId = JSON.parse(
                        localStorage.getItem('enquiryId')
                      );
                      navigate(
                        '/car-insurance/payment/3?enquiryId=' +
                          enquiryId.enquiryId,
                        { state: item }
                      );
                    }}
                    className="continue"
                  >
                    Continue
                  </button>
                ) : null}
              </div>

              <div className="flex-1 ml-half mt-1">
                <div className="cards mb-1">
                  <div className="flex column align-center">
                    <img
                      src={item.sluginfo.logo}
                      alt="brand-img"
                      className="brand-img"
                    />
                    <p className="brand-name">{item.sluginfo.name}</p>
                    <p className="basics w-90">{item.sluginfo.description}</p>
                    <Separator />

                    <div className="flex apart w-100">
                      <p className="total">Total Payable Amount :</p>
                      <p className="amount">+ {item?.price}</p>
                    </div>
                  </div>
                </div>

                <div className="cards mb-1">
                  <div className="flex apart res-apart res-center">
                    <div className="res-margin">
                      <p className="head">Vehicle Number</p>
                      <p className="data">KA 06 HY 3178</p>
                    </div>
                    <div className="res-margin">
                      <p className="head">Location</p>
                      <p className="data">Bengaluru, Karnataka</p>
                    </div>
                    <div className="res-margin">
                      <p className="head">Policy Expiry</p>
                      <p className="data">03 Aug, 2022</p>
                    </div>
                    <div className="res-margin">
                      <p className="head">Year</p>
                      <p className="data">2021</p>
                    </div>
                  </div>
                </div>

                <div className="cards mb-1">
                  <div className="flex apart">
                    <div className="video-icon-container">
                      <img src={VideoImg} className="video-img" />
                    </div>

                    <div>
                      <p className="vid-inspection">
                        For the OD and Comprehensive Policy Self / Video
                        Inspection May Be Required, which can be followed after
                        payment
                      </p>
                      {/* <div className="flex apart mt-half res-apart">
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long established fact</p>
                                            </div>
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long establ</p>
                                            </div>
                                            <div className="flex align-center">
                                                <div className="orange-dot" />
                                                <p className="vid-tips">It is a long established fact thi</p>
                                            </div>
                                        </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </CardContainer>
        </MainContainer>
        <Footer />
      </>
    );
}