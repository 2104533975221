import React, { useState } from 'react';
import Select from 'react-select';

const insuranceTypes = [
  { title: 'Health Insurance' },
  { title: 'Self Health Insurance Plan' },
  { title: 'Family Floater Health Insurance Plan' },
  { title: 'Life Insurance' },
  { title: 'Term Life Insurance' },
  { title: 'Life Investment Related Cover' },
  { title: 'Motor Insurance' },
  { title: 'Private Car Insurance Plan' },
  { title: 'Two Wheeler Insurance Plan' },
  { title: 'Property Insurance' },
  { title: 'Home Insurance' },
  { title: 'Corporate Insurance' },
  { title: 'Employee & Group Welfare' },
  { title: 'Group Term Insurance' },
  { title: 'Mediclaim' },
  { title: 'Director’s and Officers Liability Insurance' },
  { title: 'Group Personal Accident (GPA)' },
  { title: 'Workers Compensation Insurance' },
  { title: 'Public Liability Insurance' },
  { title: 'Professional Indemnity Insurance' },
  { title: 'Product Liability Insurance' },
  { title: 'Commercial General Liability Insurance' },
  { title: 'Small Industrial Insurance' },
  { title: 'Asset Protection Insurance' },
  { title: 'Burglary Insurance' },
  { title: 'Standard Fire and Special Perils Insurance' },
  { title: 'Marine Cargo Insurance' },
  { title: 'Contractor’s All Risk Insurance' },
  { title: 'Engineers All Risk Insurance' },
  { title: 'Machinery Breakdown Insurance' },
  { title: 'Electronic Equipment Insurance' },
  { title: 'Data & Financial Security Insurance' },
  { title: 'Bankers Indemnity Insurance' },
  { title: 'Fidelity Guarantee Insurance' },
  { title: 'Money Insurance' },
  { title: 'Cyber Attack Insurance' },
];

// Convert the array into a format react-select can use
const insuranceOptions = insuranceTypes.map((insurance) => ({
  value: insurance.title,
  label: insurance.title,
}));

const MyComponent = ({ setSegment }) => {
  const handleChange = (selectedOptions) => {
    setSegment(selectedOptions.map((option) => option.value));
  };

  // Custom styles for react-select
  const customStyles = {
    control: (provided) => ({
      ...provided,
      fontSize: '16px', // Adjust the font size for the selected options
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: '16px', // Adjust the font size for the dropdown options
    }),
  };

  return (
    <div>
      <Select
        isMulti
        options={insuranceOptions}
        className="select4"
        onChange={handleChange}
        placeholder="Select Segment"
        styles={customStyles} // Apply custom styles
      />
    </div>
  );
};

export default MyComponent;
