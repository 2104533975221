import { useEffect, useState } from 'react';
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent"
import { Layout, MainContainer, styles } from "./styles"
import { useNavigate,useLocation } from "react-router-dom";
// import { SelectModel } from '../../../Redux/CarsRedux';
import { SelectCarModalList,updateCombinedModel } from '../../../Redux/CarsRedux';
import { useSelector, useDispatch } from "react-redux";

export const SelectModel = (route) => {

    const location = useLocation();
    const dispatch = useDispatch();
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const carsList = useSelector((state) => state.carsRedux);
    const [modalList, setModalList] = useState([]);
    const [modelData,setModelData]=useState(carsList.modalList);
    useEffect(() => {
        setModalList([])
        if(combinedRedux.hasOwnProperty('brandId')) {
            dispatch(SelectCarModalList({makeId:combinedRedux.brandId}));
        }
        else{
            setTimeout(() => {
                getLocalStorage()
            }, 1);
        }
    }, [])
useEffect(()=>{
  setModelData(carsList.modalList)
},[carsList.modalList])

    const getLocalStorage = async() => {
        var carInsuranceDetails =  await (localStorage.getItem('canInsuranceDetails'));
        if(carInsuranceDetails){
            carInsuranceDetails = JSON.parse(carInsuranceDetails);
            if(carInsuranceDetails.hasOwnProperty('brandId')) {
                dispatch(SelectCarModalList({makeId:carInsuranceDetails.brandId}));
                dispatch(updateCombinedModel(carInsuranceDetails));
            }
        }
        else{
            navigate('/');
            return
        }
    };

    

    function searchBrandModal(e){
        var obj = {
            name:e.target.value
        };
        if(e.target.value=="")
        setModelData(carsList.modalList)
    else
         setModelData(()=>carsList.modalList.filter(val=>val.modelName.toLowerCase().includes(e.target.value.toLowerCase())))

        // dispatch(SelectCarModalList(obj));
    }


    const navigate = useNavigate()

    const handleSelect = val => {
        console.log("valueee",val)
        dispatch(updateCombinedModel({modelId:val._id,model:val.modelName})).then(()=>{

            navigate('/brand-new-model/car-details/2')
        })
    }

    return(
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent step={2} modals={modelData} title='Select the Model' onSelect={handleSelect} searchBrandModal={searchBrandModal}/>
                </Layout>
            </MainContainer>
        </>
    )
}