import styled from "styled-components"

export const PopupContainer = styled.div`
    border-radius: 10px;
    padding: 1rem 2rem;
    border: 1px solid #B7B7B7;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 220;
    /* top: 200px; */
    top: 58%;
    background-color: #fff;
    padding-top: 1.7rem;
    padding-bottom: 1.8rem;
    width: 66%;

    @media screen and (max-width: 1146px) {
        width: 80%;
    }
    @media screen and (max-width: 902px) {
        width: 85%;
    }
    @media screen and (max-width: 850px) {
        width: 90%;
    }

    @media screen and (max-width: 804px) {
        top: 70%;
    
        .res-flex {
            flex-direction: column;
        }
        .res-mb-1 {
            margin-bottom: .6rem !important;
        }

    }

    @media screen and (max-width: 465px) {
        top: 90%;
    }

    p {
        margin: 0;
    }

    .flex {
        display: flex;
    }
    .apart {
        justify-content: space-between;
    }

    .top {

    }

    .brand-img {
        border-radius: 50%;
        width: 50px;
        object-fit: contain;
        height: 50px;
        margin-right: .4rem;
    }
    .basics {
        color: #F6931E;
    }
    .data {
        color: #605F5E;
        font-weight: 700;
        font-size: 14px;
    }
    .brand-name {
        font-size: 15px;
    }
    .price-btn {
        background-color: #F6931E;
        border-radius: 10px;
        padding: 0.4rem;
        border: none;
        cursor: pointer;
    }

    .cards {
        padding: 1rem;
        box-shadow: 0px 0px 27px -11px rgba(0,0,0,0.4);
        border-radius: 10px;
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .head {
        font-size: 17px;
        font-weight: 800;
        color: #2B003F;
    }
    .sub-head {
        color: #605F5E;
        opacity: .6;
        font-weight: 800;
        text-decoration-line: underline;
    }
    .discount {
        color: #46B39A;
    }
    .total {
        font-size: 16px;
        color:#000;
        font-weight: 800;
    }
    .total-amount {
        font-size: 17px;
        color:#000;
        font-weight: 800;
    }
    .remove {
        color: #F6931E;
        font-weight: 700;
        margin-left: .3rem;
    }
    .align-center {
        align-items: center;
    }
    .tick-icon {
        margin-right: .35rem;
    }
    .mb-1 {
        margin-bottom: .5rem;
    }
    .info {
        margin-right: .35rem;
    }
    .mb-half {
        margin-bottom: .5rem;
    }
    .learn-more {
        color: #2B003F;
        text-decoration-line: underline;
    }

`
export const CloseIcon = styled.img`
    position: absolute;
    top: 10px;
    right:10px;
    cursor: pointer;
`
export const BrandIcon = styled.img`
`
export const Separator = styled.hr`
    opacity: .5;
    margin: .5rem auto;
`