import styled from "styled-components";

export const Content = styled.div`
    width: 62%;
    margin: auto;
    color: #7A7A7A;
    letter-spacing: .05ch;
    margin-bottom: 2rem;
    h1, h2, h3, p {
        color: #7A7A7A;
    }

    p {
        margin: 0;
        font-size: 1rem;
    }

    .mt-2 {
        margin-top: 2rem;
    }
    .mb-3 {
        margin-bottom: 3rem ;
    }
    .text-center {
        text-align: center;

        p {
            text-align: justify;
        }
    }
    .p-1 {
        padding: 1rem;
    }

    .caps {
        text-transform: uppercase;
    }

    @media screen and (max-width: 800px) {
        margin-top: 3rem;
    }
    @media screen and (max-width: 550px) {
        width:80%;
    }

`
export const Heading = styled.h1`
    font-weight: 900;
    color: #212121 !important;
`
export const Heading2 = styled.h2`
    font-size: 1.95rem;

`