import OurPartners  from '../../pages/linkPages/OurPartners/OurPartners';
import {Products} from '../../pages/products/Products';
import {Products as IndividualInsurance} from '../../pages/products/Products';
import PropertyInsurance from '../../pages/linkPages/property-insurance/PropertyInsurance';
import AssetProtectionInsurance from '../../pages/linkPages/corporate-insurance/asset-insurance/asset';
import DataAndFincinalInsurance from '../../pages/linkPages/corporate-insurance/DFS/dfs'
import EmployeeWelfare from '../../pages/linkPages/corporate-insurance/EGW/EGW';
import MediaCorner from '../../pages/linkPages/media-corner/MediaCorner';
import WorkWithUs from '../../pages/linkPages/workWithUs/workwithus';
import ContactUs from '../../pages/contact_v2/contactPage'
import { AboutUs } from '../../pages/footer-pages/about-us/new-about-us'
import BlogData from '../../pages/brand-main-page/main-brand-page'
import { Corporate } from '../../pages/corporates/Corporate'





export const menuItems = [
    {
      title: 'Home',
      path: '/',
    },
    {
      title: 'About Us',
      path: '/About-Simpli-Insure',
      component: AboutUs
    },
    {
      title : 'Our Partners',
      path : '/Our-Partners',
      component: OurPartners
    },
    {
      title: 'Products',
      path: '/products',
      component: Products,
      submenu: [
        {
          title: 'Individual Insurance',
          path: '/product/Individual-Insurance-Products',
          component: IndividualInsurance,
          submenu: [
            { title: 'Health Insurance', 
            path: '/product/Individual-Insurance-Products/Health-Insurance',
            submenu: [
              { title: 'Self Health Insurance Plan', path: '/product/Individual-Insurance-Products/Health-Insurance/Self-Health-InsurancePlan' },
              { title: 'Family Floater Health Insurance Plan', path: '/product/Individual-Insurance-Products/Health-Insurance/Family-Floater-Health-Insurance-Plan' },
            ],
          },
            { title: 'Life Insurance',
             path: '/product/Individual-Insurance-Products/Life-Insurance',
             submenu: [
              { title: 'Term Life Insurance', path: '/product/Individual-Insurance-Products/Life-Insurance/Term-Life-Insurance' },
              { title: 'Life Investment Related Cover', path: '/product/Individual-Insurance-Products/Life-Insurance/Life-Investment-Related-Cover' },
            ],
             },
            { title: 'Motor Insurance',
             path: '/product/Individual-Insurance-Products/Motor-Insurance',
           
              submenu: [
                { title: 'Private Car Insurance Plan', path: '/product/Individual-Insurance-Products/Motor-Insurance/Private-Car-Insurance-Plan'},
                { title: 'Two Wheeler Insurance Plan', path: '/product/Individual-Insurance-Products/Motor-Insurance/Two-Wheelers-Insurance-Plan' },
              ],
             },
             {
              title: 'Property Insurance',
              path: '/product/Individual-Insurance-Products/Property-Insurance',
              // component: PropertyInsurance,
              submenu: [
                { title: 'Home Insurance', path: '/product/Individual-Insurance-Products/Property-Insurance/home-insurance'},
              ],
             }
          ],
        },
        {
          title: 'Corporate Insurance',
          path: '/product/corporate-insurance',
          component: Corporate,
          submenu: [
                         {
              title: 'Employee & Group Welfare',
              path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare',
              // component: EmployeeWelfare,
              submenu: [
                { title: 'Group Term Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Group-Term-Insurance' },
                {title: 'Mediclaim', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Mediclaim' },
                { title : 'Director’s and Officers Liability Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Directors-and-Officers-Liability-Insurance',
                 },
                
                // { title: 'Group Mediclaim Insurance(GMC)', path: '/Corporate-Insurance-Products/Group-and-Employee-Welfare/Group-Mediclaim-Insurance' },
                { title: 'Group Personal Accident(GPA)', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Group-Personal-Accident' },
                { title:'Workers Compensation Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Workers-Compensation-Insurance' },
                { title: 'Public Liability Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Public-Liability-Insurance' },
                { title: 'Professional Indemnity Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Professional-Indemnity-Insurance' },
                { title: 'Product Liability Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Product-Liability-Insurance' },
                { title: 'Commercial General Liability Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Commercial-General-Liability-Insurance' },
                { title: 'Small Industrial Insurance', path: '/product/Corporate-Insurance-Products/Group-and-Employee-Welfare/Small-Industrial-Insurance' },
              ]
             },
            { title: 'Asset Protection Insurance',
             path: '/product/Corporate-Insurance-Products/Asset-Insurance',
              // component: AssetProtectionInsurance,
             submenu: [
              { title: 'Burglary Insurance', path: '/product/Corporate-Insurance-Products/Asset-Insurance/burglary-insurance'} ,
                { title: 'Standard Fire and Special Perils Insurance', path: '/product/Corporate-Insurance-Products/Asset-Insurance/Standard-Fire-and-Special-Perils-Insurance' },
                { title: 'Marine Cargo Insurance', path: '/product/Corporate-Insurance-Products/Asset-Insurance/Marine-Cargo-Insurance' },
                { title: 'Contractor’s All Risk Insurance', path: '/product/Corporate-Insurance-Products/Asset-Insurance/Contractors-All-Risk-Insurance' },
                { title: 'Engineers All Risk Insurance' , path: '/product/Corporate-Insurance-Products/Asset-Insurance/Engineers-All-Risk-Insurance'
              
              },
                { title: 'Machinery Breakdown Insurance', path: '/product/Corporate-Insurance-Products/Asset-Insurance/Machinery-Breakdown-Insurance' },
                { title: 'Electronic Equipment Insurance', path: '/product/Corporate-Insurance-Products/Asset-Insurance/Electronic-Equipment-Insurance' },
              //   { title: 'Contractor Plant and Machinery Insurance', path: '/Corporate-Insurance-Products/Asset-Insurance/Contractor-Plant-and-Machinery-Insurance'
              //  },
             ]
             },
             {
              title: 'Data & Financial Security Insurance',
              path: '/product/Corporate-Insurance-Products/Cyber-and-Data-Security',
              // component: DataAndFincinalInsurance,
                submenu: [
                { title: 'Bankers Indemnity Insurance', path: '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Bankers-Indemnity-Insurance' },
                { title: 'Fidelity Guarantee Insurance', path: '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Fidelity-Guarantee-Insurance' },
                { title: 'Money Insurance', path: '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Money-Insurance' },
                { title: 'Cyber Attack Insurance', path: '/product/Corporate-Insurance-Products/Cyber-and-Data-Security/Cyber-Attack-Insurance'
                },
              ]
             },
          ],
        },
      ],
    },
    {
      title: 'Learn',
      path: '/blog',
      component: BlogData,
    },
    {
      title: 'Work With Us',
      path: '/Work-With-Us',
      component: WorkWithUs,
    },
    {
      title: 'Contact Us',
      path: '/contact-us',
      component: ContactUs,
    }
  ];