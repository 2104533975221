import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiGetCall, apiPostCall, apiGETCall1, apiPutCall } from '../utilities/site-apis';

const initialState = {
  isFetching: false,
  success: false,
  error: false,
  posData: {},
  posList: [],
  posApproved: [],
  rmData: []

}

export const updateFetchedPosInfo = createAsyncThunk(
  "pos/updateFetchedPosInfo",
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
);
export const updateAddress = createAsyncThunk(
  "pos/updateAddress",
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
);

export const posListData = createAsyncThunk(
  "pos/getPosList",
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
)

export const updateBasic = createAsyncThunk(
  'pos/basic',
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
)

export const updateLanguage = createAsyncThunk(
  'pos/updateLanguage',
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
)

export const updateSegment = createAsyncThunk(
  'pos/basic',
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
)

export const updateDocunents = createAsyncThunk(
  "pos/updateDocument",
  async (params, { rejectWithValue }) => {
    console.log("params is -> ", params);
    const response = await apiPutCall(`pospInfo/` + params.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
);

export const updateIrda = createAsyncThunk(
  "pos/updateIrda",
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
);

export const posSaveData = createAsyncThunk(
  'pos/dataSave',
  async (params, { rejectWithValue }) => {
    console.log("params are ----> ", params)
    return params;
  }
)

export const updateStatus = createAsyncThunk(
  'pos/updateStatus',
  async (params, { rejectWithValue }) => {
    const response = await apiPutCall(`pospInfo/approve/` + params.id, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    console.log('response', response.data)
    return response.data
  }
)
export const updateRMStatus = createAsyncThunk(
  'rm/updateStatus',
  async (params, { rejectWithValue }) => {
    const response = await apiPutCall(`rmInfo/approve/` + params.id, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    console.log('response', response.data)
    return response.data
  }
)

export const PosApprovedData = createAsyncThunk(
  "pospInfo/find",
  async (params, { rejectWithValue }) => {
    console.log('called')
    const response = await apiGETCall1(`/pospInfo/find`, params)

    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data;
  }
);
export const RMApprovedData = createAsyncThunk(
  "rmInfo/find",
  async (params, { rejectWithValue }) => {
    console.log('called')
    const response = await apiGETCall1(`/rmInfo/find`, params)

    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data;
  }
);

export const counterSlice = createSlice({
  name: 'pos',
  initialState,
  extraReducers: {
    [updateAddress.fulfilled]: (state, action) => {
      state.posData = action.payload;
      state.error = false;
    },
    [posListData.fulfilled]: (state, action) => {
      state.posList = action.payload;
      state.error = false;
    },
    [updateBasic.fulfilled]: (state, action) => {
      state.isFetching = false
      state.posData = { ...state.posData, ...action.payload };
    },
    [updateLanguage.fulfilled]: (state, action) => {
      state.isFetching = false
      state.posData = { ...state.posData, ...action.payload };
      // console.log("user daata is ", action.payload);
    },
    [updateSegment.fulfilled]: (state, action) => {
      state.posData = { ...state.posData, ...action.payload };
      state.error = false;
    },
    [updateDocunents.pending]: (state, action) => {
      state.isFetching = true
    },
    [updateDocunents.rejected]: (state, action) => {
      state.isFetching = false
    },
    [updateDocunents.fulfilled]: (state, action) => {
      state.posData = { ...state.posData, ...action.payload };
      state.error = false;
    },
    [updateIrda.fulfilled]: (state, action) => {
      state.posData = { ...state.posData, ...action.payload };
      state.error = false;
    },
    [posSaveData.fulfilled]: (state, action) => {
      state.posData = [...state.posData, { ...action.payload }];
      state.success = true;
      state.error = false;
      state.isFetching = false;
    },
    [updateStatus.pending]: (state, action) => {
      state.isFetching = true
    },
    [updateStatus.rejected]: (state, action) => {
      state.isFetching = false
    },
    [updateStatus.fulfilled]: (state, action) => {
      state.posData = { ...state.posData, ...action.payload };
      state.error = false;
    },
    [updateRMStatus.pending]: (state, action) => {
      state.isFetching = true
    },
    [updateRMStatus.rejected]: (state, action) => {
      state.isFetching = false
    },
    [updateRMStatus.fulfilled]: (state, action) => {
      state.error = false;
    },
    [PosApprovedData.pending]: (state, action) => {
      state.isFetching = true
    },
    [PosApprovedData.rejected]: (state, action) => {
      state.isFetching = false
    },
    [PosApprovedData.fulfilled]: (state, action) => {
      state.posApproved = action.payload.data;
      state.error = false;
    },
    [RMApprovedData.pending]: (state, action) => {
      state.isFetching = true
    },
    [RMApprovedData.rejected]: (state, action) => {
      state.isFetching = false
    },
    [RMApprovedData.fulfilled]: (state, action) => {
      state.rmData = action.payload.data;
      state.error = false;
    },
    [updateFetchedPosInfo.fulfilled]: (state, action) => {
      state.posData = action.payload;
      state.error = false;
    },
  }

})

export const { setPosData } = counterSlice.actions
export default counterSlice.reducer