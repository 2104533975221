import { CardContainer, CardMainDiv, CloseBtn, ContinueBtn, Title } from "./styles"
import CloseImg from '../../assets/brand-new-car/close-btn.svg'
import { InputComponent } from "../input-component/InputComponent"
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { updateCombinedModel, motorEnquiryAPI, PrevInsurerData } from '../../Redux/CarsRedux';
import { useNavigate } from "react-router-dom";
const moment = require('moment');

export const SkeletonCard = ({ title, prevPolicy, continueBtn, onContinue, max }) => {
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const prevInsurerRedux = useSelector((state) => state.carsRedux.prevInsurerList);
    const [mfgDate, setMfgDate] = useState(combinedRedux.mfgYear ? combinedRedux.mfgYear : '');
    const [vehicleOwnedBy, setVehicleOwnedBy] = useState('Individual');
    const [RegistrationDate, setRegistrationDate] = useState(combinedRedux.regDate ? combinedRedux.regDate : '');
    const [policyType, setPolicyType] = useState('privateCarComprehensive');
    // const [getRrevPolicyType, setGetPrevPolicyType] = useState('');
    const [prevInsurerData, setPrevInsurerData] = useState();
    // const [maxDate, setMaxDate] = (null)
    const [expiryDate, setExpiryDate] = useState(combinedRedux?.insurance?.insurancePolicyExpiryDate ? combinedRedux?.insurance?.insurancePolicyExpiryDate : '')
    const [NCB, setNCB] = useState(0);
    // const [NCB, setNCB] = useState(combinedRedux?.insurance?.ncb);
    const [InsurerName, setInsurerName] = useState(combinedRedux?.insurance?.insurancePolicyName ? combinedRedux?.insurance?.insurancePolicyName : '');
    const [InsurerPolicyNumber, setInsurerPolicyName] = useState(combinedRedux?.insurance?.insurancePolicyNumber ? combinedRedux?.insurance?.insurancePolicyNumber : '');
    const [TPStartDate, setTPStartDate] = useState(combinedRedux.regDate ? combinedRedux.regDate : '');
    const [TPEndDate, setTPEndDate] = useState(combinedRedux.regDate ? moment(combinedRedux.regDate).add(3,"y").subtract(1,"days") : '');
    const [TPInsurerName, setTPInsurerName] = useState(combinedRedux?.insurance?.insurancePolicyName ? combinedRedux?.insurance?.insurancePolicyName : '');
    const [TPPolicyNumber, setTPPolicyName] = useState(combinedRedux?.insurance?.insurancePolicyNumber ? combinedRedux?.insurance?.insurancePolicyNumber : '');
    // const max_date = new Date(+new Date("")+minsec)
    // setMaxDate(moment("02/02/2023").format('YYYY-MM-DD'))
    const dispatch = useDispatch();
    const onContinueFun = () => {
        if (expiryDate > moment().add(2, "M").format('YYYY-MM-DD')) {
            alert("As the expiry date of current policy is beyond 60 days, as per regulatory requirements we cannot issue policy.")
            return;
        }
        // alert(JSON.stringify({mfgDate:mfgDate,vehicleOwnedBy:vehicleOwnedBy,RegistrationDate:RegistrationDate,expiryDate:expiryDate,ncb:NCB,InsurerName:InsurerName,addOns:[]}))
        var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
        // alert(policyType)
        var obj = {
            "insurancePolicyName": InsurerName,
            "insurancePolicyNumber": InsurerPolicyNumber,
            "insurancePolicyExpiryDate": expiryDate,
            "puccNumber": "",
            "puccValidUpto": null,
            'insurancePolicyType': policyType,
            "isClaimMadeinLastYear": false,
            "ownerChanged": false,
            "isPolicyExpired": false,
            "tpStartDate": TPStartDate,
            "tpEndDate": TPEndDate,
            "tpInsurerName": TPInsurerName,
            "tpPolicyNumber": TPPolicyNumber
        }
        dispatch(motorEnquiryAPI({ enquiryId: enquiryId, mfgYear: mfgDate, vehicleOwnedBy: vehicleOwnedBy, regDate: RegistrationDate, expiryDate: expiryDate, ncb: NCB, InsurerName: InsurerName, insurance: obj })).then(() => {
            onContinue();
        })
    }

    const navigate = useNavigate();
    // useEffect(() => {
    //   if(combinedRedux?.insurance?.insurancePolicyName){
    //     setInsurerName(combinedRedux?.insurance?.insurancePolicyName)
    //   }
    //   else{
    //     navigate('/')
    //   }
    // }, [])

    const prevNameArray = prevInsurerRedux.map((element) => {
        return element.name
    })

    useEffect(() => {
        if (combinedRedux?.hasOwnProperty('vehicleOwnedBy')) {
            if (combinedRedux?.hasOwnProperty('vehicleOwnedBy')) {
                setVehicleOwnedBy(combinedRedux?.vehicleOwnedBy);
            }
            if (combinedRedux?.newPolicyData.hasOwnProperty('policyType')) {
                setPolicyType(combinedRedux?.insurance?.insurancePolicyType);
            }
            if (combinedRedux.hasOwnProperty('ncb')) {
                setNCB(combinedRedux.ncb);
            }
        }
        // else{
        //     setTimeout(() => {
        //         getLocalStorage()
        //     }, 1);
        // }
    }, [combinedRedux])

    useEffect(() => {
        dispatch(PrevInsurerData({}))
    }, [combinedRedux])
    console.log("prevInsurerRedux are ", prevInsurerRedux);
    return (
        <CardContainer>
            <CardMainDiv>
                <CloseBtn src={CloseImg} onClick={() => navigate(-1)} />
                <Title>{title}</Title>
                {prevPolicy ? (
                    <>
                        <div className="grid-2">
                            <InputComponent max={moment().format('YYYY-MM-DD')} type='date' onChange={(e) => { setMfgDate(e) }} title={'Manufacturing Date'} value={moment(mfgDate).format('YYYY-MM-DD')} />
                            <InputComponent onChange={(e) => { setVehicleOwnedBy(e) }} title={'Vehicle Owned By *'} dropdown={['Individual', 'Commercial']}
                                //  dropdown={'Individual'}
                                value={vehicleOwnedBy} />
                            <InputComponent max={moment().format('YYYY-MM-DD')} type='date' onChange={(e) => { setRegistrationDate(e) }} title={'Registration Date'} value={moment(RegistrationDate).format('YYYY-MM-DD')} />
                            <InputComponent value={'privateCarComprehensive'} onChange={(e) => { setPolicyType(e) }} title={'Policy Type *'}

                                dropdown={['privateCarComprehensive', 'privateCarOD', 'privateCarTP', 'privateCarODTP']}
                            // dropdown={['privateCarComprehensive']}
                            />
                            <InputComponent max={moment().add(2, "M").format('YYYY-MM-DD')} isExpiry={true} type='date' onChange={(e) => { setExpiryDate(e) }} title={'Expiry Date'} value={expiryDate ? moment(expiryDate).format('YYYY-MM-DD') : ''} />
                            {/* <InputComponent onChange={(e) =>{setNCB(e)}} title={'Previous NCB *'} value={NCB} /> */}
                            <InputComponent onChange={(e) => { setNCB(e) }} title={'Previous NCB *'} value={NCB} dropdown={
                                [
                                    '0',
                                    '20',
                                    '25',
                                    '35',
                                    '45',
                                    '50'
                                ]
                            } />
                            <InputComponent onChange={(e) => { setInsurerName(e) }} title={'Insurer Name'} value={InsurerName} dropdown={prevNameArray} />
                            <InputComponent onChange={(e) => { setInsurerPolicyName(e) }} title={'Insurer Policy Number'} value={InsurerPolicyNumber} />
                            
                           {combinedRedux?.newPolicyData?.policyType=="privateCarOD" &&  <InputComponent isExpiry={true} type='date' onChange={(e) => { setTPStartDate(e) }} title={'TP Start Date'} value={moment(TPStartDate).format('YYYY-MM-DD')} />}
                           {combinedRedux?.newPolicyData?.policyType=="privateCarOD" &&  <InputComponent isExpiry={true} max={moment(TPStartDate).add(3,'y').format("YYYY-MM-DD")} type='date' onChange={(e) => { setTPEndDate(e) }} title={'TP Expiry Date'} value={moment(TPEndDate).format('YYYY-MM-DD')} />}
                           {combinedRedux?.newPolicyData?.policyType=="privateCarOD" &&  <InputComponent onChange={(e) => { setTPInsurerName(e) }} title={'TP Insurer Name'} value={TPInsurerName} dropdown={prevNameArray} />}
                           {combinedRedux?.newPolicyData?.policyType=="privateCarOD" &&  <InputComponent onChange={(e) => { setTPPolicyName(e) }} title={'TP Policy Number'} value={TPPolicyNumber} />}

                        </div>

                    </>
                ) : null}
                {continueBtn ? (
                    <ContinueBtn onClick={() => onContinueFun()}>Continue</ContinueBtn>
                ) : null}
            </CardMainDiv>
        </CardContainer>
    )
}