import { CardHeader } from "../../../components/card-header/CardHeader"
import Footer from "../../../components/Footer/FooterNew"
import { BgDiv, BottomDiv, BottomSubDiv, CardMainContainer, ConfirmBtn, DatePlaceholder, Div, DivBorderRight, DivPadding, HDiv, HDivApart, Input, InputData, InputDiv, InputSelect, InputTitle, InsideCardContainer, Logo, MainContainer, MidDiv, MidSubDiv, PrivateCarBtn } from "./styles"
import { Link, useLocation } from 'react-router-dom'
import { useEffect, useState } from "react"

export const CarForm7 = () => {

    const location = useLocation()
    const { from } = location.state;

    useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

    const [data, setData] = useState({
        fName: '',
        mName: '',
        lName: '',
        gender: '',
        married: '',
        dob: '',
        email: '',
        mobNo: '',
        alternateNo: '',
        addr1: '',
        addr2: '',
        addr3: '',
        state: '',
        city: '',
        pin: '',
        nomineeName: '',
        nomDob: '',
        relation: '',
        regAddrSame: false
    })

    const [dateSelected, setDateSelected] = useState({
        dob: 0,
        nomDob:0
    })
    // console.log(location.state.from);

    const OwnerDetailsComp = ({ item }) => {

        const InputDivComp = ({ title, val }) => {
            return (
                <InputDiv className="padding-more">
                    <InputTitle>{title}</InputTitle>
                    <InputData>{val}</InputData>
                </InputDiv>
            )
        }



        return (
            <InsideCardContainer>
                <DivPadding>
                    <HDivApart>
                        <DivBorderRight>
                            <Logo src={item.icon} />
                        </DivBorderRight>
                        <Div>
                            <h4>{item.name}</h4>
                            <p>Chevrolette Impala (Kesari, 2000cc) - Petrol </p> <span>Private Car - 2015 MP-04 </span>
                            <p>Quotation No. - CVIMPL6989698989</p>
                        </Div>
                        <Div>
                            <PrivateCarBtn>Private Car Package Policy</PrivateCarBtn>
                        </Div>
                    </HDivApart>
                </DivPadding>
                <MidDiv>
                    <MidSubDiv>
                        <p>Net Premium : ₹ {item.price}</p>
                        <p>GST : ₹ 239</p>
                        <p className="colored">Total Premium : ₹ 2525</p>
                    </MidSubDiv>
                    <MidSubDiv>
                        <InputDivComp title='Policy Start Date' val={'04-Nov-2016'} />
                        <InputDivComp title='IDV' val={'₹ 115,193'} />
                    </MidSubDiv>
                    <MidSubDiv>
                        <InputDivComp title='Policy Start Date' val={'04-Nov-2016'} />
                        <InputDivComp title='IDV' val={'₹ 115,193'} />
                    </MidSubDiv>
                </MidDiv>
            </InsideCardContainer>
        )
    }

    const handleChange = (e) => {

        if (e.target.name === 'dob') {
            setDateSelected(prev => ({ ...prev, dob: dateSelected.dob + 1 }))
        }
        if (e.target.name === 'nomDob') {
            setDateSelected(prev => ({ ...prev, nomDob: dateSelected.nomDob + 1 }))
        }

        setData(prev => ({
            ...prev,
            [e.target.name]: e.target.value
        }))
    }

    console.log(location.state.from);

    return (
        <>
            <MainContainer>
                <BgDiv />
                <CardMainContainer>
                    <CardHeader title='Owner Details' />

                    <OwnerDetailsComp item={location.state.from} />

                    <BottomDiv>
                        <BottomSubDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">First Name</InputTitle>
                                <Input
                                    name='fName'
                                    value={data.fName}
                                    onChange={handleChange}
                                    placeholder='Enter your first name'
                                />
                            </InputDiv>
                            <InputDiv>
                                <InputTitle className="bg-white">Middle Name</InputTitle>
                                <Input
                                    name='mName'
                                    value={data.mName}
                                    onChange={handleChange}
                                    placeholder='Enter your middle name'
                                />
                            </InputDiv>
                            <InputDiv>
                                <InputTitle className="bg-white">Last Name</InputTitle>
                                <Input
                                    name='lName'
                                    value={data.lName}
                                    onChange={handleChange}
                                    placeholder='Enter your last name'
                                />
                            </InputDiv>
                            <InputDiv>
                                <InputTitle className="bg-white">Select Gender</InputTitle>
                                <InputSelect onChange={handleChange} name="gender">
                                    <option value="Male">Male</option>
                                    <option value="Female">Female</option>
                                    <option value="Other">Other</option>
                                </InputSelect>
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Marital Status</InputTitle>
                                <InputSelect onChange={handleChange} name="married">
                                    <option value="Married">Married</option>
                                    <option value="Single">Single</option>
                                    <option value="Divorced">Divorced</option>
                                </InputSelect>
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Date of Birth</InputTitle>
                                {!(dateSelected.dob > 0) ? (
                                    <DatePlaceholder>Select your birth date DD-MM-YY</DatePlaceholder>
                                ) : null}
                                <Input
                                    name='dob'
                                    value={data.dob}
                                    onChange={handleChange}
                                    type='date'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">E-Mail</InputTitle>
                                <Input
                                    name='email'
                                    type={'email'}
                                    value={data.email}
                                    onChange={handleChange}
                                    placeholder='Enter your email'
                                />
                            </InputDiv>
                            <InputDiv>
                                <InputTitle className="bg-white">Mobile Number</InputTitle>
                                <Input
                                    name='mobNo'
                                    type={'number'}
                                    value={data.mobNo}
                                    onChange={handleChange}
                                    placeholder='Enter your Mobile number'
                                />
                            </InputDiv>
                            <InputDiv>
                                <InputTitle className="bg-white">Alternate Contact Number</InputTitle>
                                <Input
                                    name='alternateNo'
                                    type={'number'}
                                    value={data.alternateNo}
                                    onChange={handleChange}
                                    placeholder='Enter your alternate contact number'
                                />
                            </InputDiv>

                        </BottomSubDiv>

                        <BottomSubDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Address 1</InputTitle>
                                <Input
                                    name='addr1'
                                    value={data.addr1}
                                    onChange={handleChange}
                                    placeholder='Enter your address'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Address 2</InputTitle>
                                <Input
                                    name='addr2'
                                    value={data.addr2}
                                    onChange={handleChange}
                                    placeholder='Enter your address'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Address 3</InputTitle>
                                <Input
                                    name='addr3'
                                    value={data.addr3}
                                    onChange={handleChange}
                                    placeholder='Enter your address'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Select State</InputTitle>
                                <InputSelect onChange={handleChange} name="state">
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Odisha">Odisha</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Telangana">Telangana</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="West Bengal">West Bengal</option>
                                </InputSelect>
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Select City</InputTitle>
                                <InputSelect onChange={handleChange} name="state">
                                    <option value="Andhra Pradesh">Andhra Pradesh</option>
                                    <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                    <option value="Assam">Assam</option>
                                    <option value="Bihar">Bihar</option>
                                    <option value="Chhattisgarh">Chhattisgarh</option>
                                    <option value="Goa">Goa</option>
                                    <option value="Gujarat">Gujarat</option>
                                    <option value="Haryana">Haryana</option>
                                    <option value="Himachal Pradesh">Himachal Pradesh</option>
                                    <option value="Jharkhand">Jharkhand</option>
                                    <option value="Karnataka">Karnataka</option>
                                    <option value="Kerala">Kerala</option>
                                    <option value="Madhya Pradesh">Madhya Pradesh</option>
                                    <option value="Maharashtra">Maharashtra</option>
                                    <option value="Manipur">Manipur</option>
                                    <option value="Meghalaya">Meghalaya</option>
                                    <option value="Mizoram">Mizoram</option>
                                    <option value="Nagaland">Nagaland</option>
                                    <option value="Odisha">Odisha</option>
                                    <option value="Punjab">Punjab</option>
                                    <option value="Rajasthan">Rajasthan</option>
                                    <option value="Sikkim">Sikkim</option>
                                    <option value="Tamil Nadu">Tamil Nadu</option>
                                    <option value="Telangana">Telangana</option>
                                    <option value="Tripura">Tripura</option>
                                    <option value="Uttar Pradesh">Uttar Pradesh</option>
                                    <option value="Uttarakhand">Uttarakhand</option>
                                    <option value="West Bengal">West Bengal</option>
                                </InputSelect>
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Pincode</InputTitle>
                                <Input
                                    name='pin'
                                    type={'number'}
                                    value={data.pin}
                                    onChange={handleChange}
                                    placeholder='Enter your pincode'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Nominee Full Name</InputTitle>
                                <Input
                                    name='nomineeName'
                                    value={data.nomineeName}
                                    onChange={handleChange}
                                    placeholder='Enter your nominee full name'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Date of Birth</InputTitle>
                                {!(dateSelected.nomDob > 0) ? (
                                    <DatePlaceholder>Select your birth date DD-MM-YY</DatePlaceholder>
                                ) : null}
                                <Input
                                    name='nomDob'
                                    value={data.nomDob}
                                    onChange={handleChange}
                                    type='date'
                                />
                            </InputDiv>

                            <InputDiv>
                                <InputTitle className="bg-white">Select Relation</InputTitle>
                                <InputSelect onChange={handleChange} name="relation">
                                    <option value="Spouse">Spouse</option>
                                    <option value="Neighbour">Neighbour</option>
                                    <option value="Other">Other</option>
                                </InputSelect>
                            </InputDiv>

                        </BottomSubDiv>
                        
                        <HDiv className="bottom-radio">
                            <p className="reg-addr-prompt">Registration Address same as Above </p>
                            <HDiv>
                                <Input name="regAddrSame" type='radio' value='Yes' onChange={handleChange} />
                                <p>Yes</p>
                            </HDiv>
                            <HDiv>
                                <Input name='regAddrSame' type='radio' value='No' onChange={handleChange} />
                                <p>No</p>
                            </HDiv>
                        </HDiv>

                    </BottomDiv>
                    
                    <Link to='/car/form-8' 
                        state={{ from: location.state.from}}
                    >
                        <ConfirmBtn>Confirm and Continue</ConfirmBtn>
                    </Link>

                </CardMainContainer>
            </MainContainer>
            <Footer />
        </>
    )
}