import axios from 'axios';
import Config from "../../utilities/Config";

export const fetchDrop = ({ makeName = '', modelName = '', fuelType = '', cc = '' }, callBack) => {
    const query = new URLSearchParams({ makeName, modelName, fuelType, cc });
    const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/filterData?${query}`;
    // const endpoint = `${process.env.REACT_APP_API_URL}/vehicleInsurance/filterData?${query}`;

    axios.get(endpoint)
        .then(response => {
            if (response.status === 200) {
                callBack(response.data);
            } else {
            }
        })
        .catch(error => {
            // reject(`Error fetching data: ${error.message}`);
        });
};

export const fetchData = async ({ page, limit, searchBrand, searchModel, searchCc, searchFuel, searchVariant }) => {
    try {
        const query = new URLSearchParams({ page, limit, searchBrand, searchModel, searchCc, searchFuel, searchVariant });
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/masterMapData?${query}`;
        // const endpoint = `${process.env.REACT_APP_API_URL}/vehicleInsurance/masterMapData?${query}`;
        const response = await axios.get(endpoint)
        if (response.status === 200) {
            const data = response.data;
            return { data: data.data, len: data.length };
        } else {
            throw new Error('Request failed with status: ' + response.status);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

export const fetchMapCount = async ({ slug }) => {
    try {
        const query = new URLSearchParams({ slug });
        // const endpoint = `${process.env.REACT_APP_API_URL}/vehicleInsurance/mappingDataCount?${query}`;
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/mappingDataCount?${query}`;
        const response = await axios.get(endpoint)
        if (response.status === 200) {
            const data = response.data;
            return { count: data.count };
        } else {
            throw new Error('Request failed with status: ' + response.status);
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};