import { useNavigate } from 'react-router-dom';
import { uploadFile } from '../../components/POS/uploadFile';
import './policy-upload.css';
import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Modal from '../../components/policyModel/PolicyModel';

import {
  apiGetCall,
  apiPutCall,
  apiPostCall1,
} from '../../utilities/site-apis';

const PolicyUploadAdmin = ({ isModalOpen, setIsModalOpen, selectedItem, policyEnquiryId }) => {
  // const { policyId, insuranceType } = useParams();
  const [url, setUrl] = useState({
    quotationURL: '',
  });
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { policyData, isFetching, error } = useSelector(
    (state) => state.policy
  );

  const FILE_SIZE_LIMIT = 10 * 1024 * 1024;

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.size > FILE_SIZE_LIMIT) {
      setErrorMessage('File size should be less than 10 MB');
      setSelectedFile(null);
    } else {
      setSelectedFile(file);
      setErrorMessage('');
    }
  };

  const uploadFileHandler = async () => {
    if (!selectedFile) {
      setErrorMessage('Please select a file to upload');
      return;
    }

    setUploading(true);
    setErrorMessage('');
    try {
      const uploadResult = await uploadFile(selectedFile);
      setUploading(false);

      if (uploadResult?.data && uploadResult.data.length > 0) {
        const uploadedFileUrl = uploadResult.data[0]?.url;
        setUploadSuccess(`File uploaded successfully: ${uploadedFileUrl}`);
        setUrl((prev) => ({
          ...prev,
          quotationURL: uploadedFileUrl,
        }));

        // Add the uploaded document to the list of uploaded documents
        setUploadedDocuments((prevDocs) => [
          ...prevDocs,
          {
            fileName: selectedFile.name,
            url: uploadedFileUrl,
            issuedBy: 'Admin', // Placeholder for issued by
            issueDate: new Date().toLocaleString(), // Current date and time
          },
        ]);
      } else {
        setErrorMessage('File upload failed: Invalid response structure');
      }

      setSelectedFile(null);
    } catch (error) {
      setUploading(false);
      setErrorMessage(`Error uploading file: ${error.message}`);
    }
  };

  const clickHandle = async () => {
    let obj = {
      quotationURL: url.quotationURL,
    };

    if (selectedItem === 'healthPolicy') {
      obj = {
        'payment_details.policy_path': url.quotationURL,
      };
    }

    if (!url.quotationURL) {
      alert('Please Upload Your Documents to Proceed');
    } else {
      try {
        const params = { enquiryId: policyEnquiryId, ...obj };
        let response;
        if (selectedItem === 'lifePolicy') {
          response = await apiPostCall1(`/life/enquiry`, params);
        } else if (selectedItem === 'healthPolicy') {
          response = await apiPostCall1(`/health/enquiry`, params);
        } else if (selectedItem === 'policy') {
          response = await apiPostCall1(`/motor/enquiry`, params);
        }

        if (response.data.status === 'Success') {
          alert('Policy documents uploaded successfully');
          setUrl({ quotationURL: '' });
          navigate('/dashboard/agent');
        }
      } catch (error) {
        console.error('Error updating policy:', error);
      }
    }
  };

  if (isFetching) return <p>Loading policy data...</p>;
  if (error) return <p>Error fetching policy data</p>;

  return (
    <>
      {/* Modal for the form */}
      <Modal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <div className="upload-container">
          <h3 className="policyUploadHeading">Document Upload</h3>
          <div className="file-upload">
            <label>Select a file (max 10MB):</label>
            <input type="file" onChange={handleFileChange} />
            {errorMessage && <p className="error-message">{errorMessage}</p>}
          </div>
          <button
            type="button"
            className="policyUploadBtn"
            onClick={uploadFileHandler}
            disabled={uploading || !selectedFile}
          >
            {uploading ? 'Uploading...' : 'Upload'}
          </button>
          {uploadSuccess && <p className="success-message">{uploadSuccess}</p>}
          <button
            type="submit"
            className="policyUploadBtn"
            onClick={clickHandle}
            disabled={!url.quotationURL}
          >
            Submit
          </button>
        </div>

        {/* Separate section for document cards */}
        <div className="document-cards-section">
          {uploadedDocuments.length > 0 ? (
            <div className="document-table-card">
              <h3>List of Document Files in this Policy</h3>
              <div className="document-table-container">
                <table className="document-table">
                  <thead>
                    <tr>
                      <th>Document Name</th>
                      <th>Issue Date</th>
                      <th>Issued By</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {uploadedDocuments.map((doc, index) => (
                      <tr key={index}>
                        <td>{doc.fileName}</td>
                        <td>{doc.issueDate}</td>
                        <td>{doc.issuedBy}</td>
                        <td>
                          <a
                            href={doc.url}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            View
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          ) : (
            <div className="document-table-card">
              <div className="document-table-container">
                <p>No documents uploaded yet.</p>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};

export default PolicyUploadAdmin;
