import styled from "styled-components";

export const MainContainer = styled.div`
  padding-top: 3rem;
  h1 {
    text-align: center;
  }
`;

export const CarCover = styled.img`
  height: 329px;
  width: 349px;
  border-radius: 0px;
`;

export const TwoWheelerCover = styled.img`
  height: 329px;
  width: 349px;
  border-radius: 0px;
`;

export const SelfHealthCover = styled.img`
  height: 329px;
  width: 349px;
  border-radius: 0px;
`;

export const FamilyFloaterHealthCover = styled.img`
  height: 329px;
  width: 349px;
  border-radius: 0px;
`;

export const LifeTermCover = styled.img`
  height: 329px;
  width: 349px;
  border-radius: 0px;
`;

export const LifeInvestmentCover = styled.img`
  height: 329px;
  width: 349px;
  border-radius: 0px;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem;

  @media (min-width: 768px) {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
  }
`;

export const Section = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 2rem;
  width: 100%;
  font-family: 'Inter';
  color: #7A7A7A;
  line-height: 26px;

  &:nth-child(odd) {
    flex-direction: column;
  }

  @media (min-width: 768px) {
    flex-direction: row;
    justify-content: space-around;
    align-items: center;

    &:nth-child(odd) {
      flex-direction: row-reverse;
    }
  }
`;


export const Content = styled.div`
  padding: 1rem;
  width: 100%;

  @media (min-width: 768px) {
    width: 40%;
  }
`;

export const Image = styled.div`
  margin: 2px 10px;

  @media (min-width: 768px) {
    margin: 0;
  }
`;

export const Heading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 45px;
  margin-top: 100px;
  width: 100%;
  color: #f6931e;

  @media (min-width: 768px) {
    padding-left: 7.5%;
    font-size: 30px;
    line-height: 54px;
    margin-top: 60px;
  }
`;

export const SubHeading = styled.div`
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 1.3rem;
  line-height: 37px;
  color: #7a7a7a;

  @media (min-width: 768px) {
    font-size: 1.775rem;
    line-height: 45px;
  }
`;
