import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { resetPassword } from "../../Redux/AuthRedux";
import './reset.css';

const PasswordPage = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [confirmPasswordError, setConfirmPasswordError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const validatePassword = (password) => {
    const minLength = 6;
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    if (password.length < minLength) {
      return `Password must be at least ${minLength} characters long.`;
    }
    if (!hasUpperCase) {
      return "Password must contain at least one uppercase letter.";
    }
    if (!hasLowerCase) {
      return "Password must contain at least one lowercase letter.";
    }
    if (!hasNumber) {
      return "Password must contain at least one digit.";
    }
    if (!hasSpecialChar) {
      return "Password must contain at least one special character.";
    }
    return null;
  };

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    const error = validatePassword(newPassword);
    setPasswordError(error);
  };

  const handleConfirmPasswordChange = (e) => {
    const newConfirmPassword = e.target.value;
    setConfirmPassword(newConfirmPassword);
    if (newConfirmPassword !== password) {
      setConfirmPasswordError("Passwords do not match");
    } else {
      setConfirmPasswordError("");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const userId = JSON.parse(localStorage.getItem('resetPasswordUserId'));

    const passwordValidationError = validatePassword(password);
    if (passwordValidationError) {
      setPasswordError(passwordValidationError);
      return;
    }

    if (password !== confirmPassword) {
      setConfirmPasswordError("Passwords do not match");
      return;
    }

    const obj = {
      userId: userId,
      password: password,
    };

    const response = await dispatch(resetPassword(obj));

    if (response.payload.status === "Success") {
      alert("Password reset successfully");
      localStorage.removeItem('resetPasswordUserId');
      localStorage.removeItem('resetPasswordUserData');
      navigate("/reset-password/success");
    }
  };

  return (
    <div className="reset-app-container">
      <div className="reset-page-container">
        <h2 className="page-title">Reset Password</h2>
        <h3 className="page-subtitle">Set New Password</h3>
        <form onSubmit={handleSubmit} className="form-container">
          <div>
            <input
              type="password"
              className={`input-field ${passwordError ? '' : 'input_margin'}`}
              placeholder="New Password"
              required
              value={password}
              onChange={handlePasswordChange}
            />
            {passwordError && <p className="error-message">{passwordError}</p>}
          </div>
          <div className="input-group">
            <input
              type="password"
              className={`input-field ${confirmPasswordError ? '' : 'input_margin'}`}
              placeholder="Confirm Password"
              required
              value={confirmPassword}
              onChange={handleConfirmPasswordChange}
            />
            {confirmPasswordError && <p className="error-message">{confirmPasswordError}</p>}
          </div>
          
          <div className={`button-group ${confirmPasswordError ? '' : 'btn_margin'}`}>
            <button type="button" className="btn-secondary" onClick={() => navigate(-1)}>Back</button>
            <button type="submit" className="btn-primary">Next</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default PasswordPage;
