import React from 'react';
import './hero.css';
import { useNavigate } from 'react-router-dom';

const Hero = ({data, icon, image, heading, subHeading, btn }) => {
  const navigate = useNavigate();
  return (
    <div className="banner">
      <img src={image} alt="banner" />
      <div className="banner-overlay"></div>
      <div className="BannerContent">
        <div className="contentLeft">
          <h1 className='contentHeading'>{heading}</h1>
          <p className='contentPara'>{subHeading}</p>
          {
            btn === true && <button className='contentBtn' onClick={() => navigate(data)}>Learn More</button>
          }
        </div>
        <div className="contentRight">
          {icon && <div className="contentImg">{icon}</div>}
        </div>
      </div>
    </div>
  );
};

export default Hero;
