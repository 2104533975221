            let sumAssured  = {
                '10 Cr': 100000000,
                '9 Cr': 90000000,
                '8 Cr': 80000000,
                '7 Cr': 70000000,
                '6 Cr': 60000000,
                '5 Cr': 50000000,
                '4 Cr': 40000000,
                '3 Cr': 30000000,
                '2 Cr': 20000000,
                '1 Cr': 10000000,
                '95 lac': 9500000,
                '90 lac': 9000000,
                '85 lac': 8500000,
                '80 lac': 8000000,
                '75 lac': 7500000,
                '70 lac': 7000000,
                '65 lac': 6500000,
                '60 lac': 6000000,
                '55 lac': 5500000,
                '50 lac': 5000000,
                '45 lac': 4500000,
                '40 lac': 4000000,
                '35 lac': 3500000,
                '30 lac': 3000000,
                '25 lac': 2500000,
                '20 lac': 2000000,
                '15 lac': 1500000,
                '10 lac': 1000000
            }

    export default sumAssured;