import React from 'react'
import './Insurance.css'
import car from '../../assets/caricon.png'
import bike from '../../assets/bikeicon.png'
import heart from '../../assets/heart.png'
import family from '../../assets/family.png'
import { AiOutlineRight } from 'react-icons/ai'

const Insurance = () => {
    return (
      <div className="container">
        <div className="menuWrapper">
          <div className="four common">
            <img src={family} alt="family" />
            <p>
              life <br /> insurance
            </p>
          </div>
          <div className="three common">
            <img src={heart} alt="three common" />
            <p>
              {' '}
              health <br /> insurance{' '}
            </p>
          </div>

          <div className="one common">
            <img src={bike} alt="one common" />
            <p>
              {' '}
              bike <br /> insurance{' '}
            </p>
          </div>
          <div className="two common">
            <img src={car} alt="two common" />
            <p>
              {' '}
              car <br /> insurance{' '}
            </p>
          </div>
        </div>

        <div className="insuranceBody">
          <div className="heading">
            <h2>Know more about life insurance</h2>
          </div>
          <div className="subContent1">
            <h3>Term Life Insurance</h3>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Term Life insurance provides coverage for a fixed period of time
              at a fixed premium rate.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              In case of untimely death of the life insured during the policy
              term , the nominee of the life insured gets the Total
              Payout/Benefit. The benefit can be paid out as a lump sum payout
              or a combination of Lump sum & Monthly payout or only as a Monthly
              payout.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Therefore Term insurance plans are said to be pure protection
              plans which ensure financial stability of the dependants in case
              of untimely death of the life insured.
            </p>
          </div>

          <div className="subContent2">
            <h3>Benefits of Term Life Insurance </h3>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Death Benefit: In the unfortunate event of death of life insured
              during policy term, the nominee shall receive the Total Payout as
              a Lump sum amount or a combination of Lump sum & Monthly amount.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Lump sum amount to take care of immediate financial liabilities.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Monthly income to sustain the family lifestyle.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Tax Benefit: The premiums paid for Term Life Insurance are Tax
              free under section 80(C) upto an amount of Rs 1,50,000.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Rider Benefits: Riders are an important addition to the basic plan
              offering & provide an option to customize the coverage to the life
              insured.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Accidental Death Benefit rider offers an additional sum assured
              over the base plan offering in case death occurs due to an
              accident.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Accidental Disability rider offers an immediate lump sum payment
              on occurrence of any disability due to an accident.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Critical Illness rider offers an additional sum assured over the
              base plan offering if the life insured is diagnosed with one of
              the critical illnesses mentioned in the rider.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Waiver of Premium rider offers the waiver of all policy premiums
              in case the life insured is diagnosed permanent disability or
              critical illness.
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Option to increase Death benefit: Certain plans have offerings
              where the life insured can increase the life cover at key stages
              in life like marriage , birth of child.
            </p>
          </div>

          <div className="subContent3">
            <h3>Key Terms when comparing Term Life insurance plans </h3>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Total Payout of each plan
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Premium amount paid for desired Total Payout
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Policy term offered
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              High claim settlement ratio
            </p>
            <p>
              {' '}
              <AiOutlineRight className="arrow" />
              Riders offered with the plan
            </p>
          </div>
        </div>
      </div>
    );
}

export default Insurance