import { CloseIcon, PopupContainer, Separator } from "./styles";
import CloseImg from '../../assets/brand-new-car/close-btn.svg'
import TickImg from '../../assets/car-insurance/view-details/green-tick.svg'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UpArrowImg from '../../assets/car-insurance/view-details/up-arrow.svg'
import InfoImg from '../../assets/car-insurance/view-details/info.svg';
import { useEffect } from 'react';
import { motorEnquiryAPI, updateCombinedModel, GODIGITMotorQuote, resetMotorQuoteRedux, KotakMotorQuote, HDFCMotorUpdateQuote, RELIANCEMotorUpdateQuote } from "../../Redux/CarsRedux";
import { useSelector, useDispatch } from "react-redux";
import './gridCss.css';
import { Modal } from 'antd'
import handleAlert from '../hanleAlert';
export const ViewDetailsPopup = ({ item, addons, setAddons, setDetailsInfo, CallQuoteAPIs, setUpdateCover }) => {

    const dispatch = useDispatch();
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate();
    const [GST, setGST] = useState(0);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    var { enquiryId } = JSON.parse(localStorage.getItem('enquiryId'));
    const motorQuote = useSelector((state) => state.carsRedux.motorQuote);
    const [callApi, setCallApi] = useState(false);
    const [motorQuoteState, setMotorQuoteState] = useState([]);
    const [logo, setLogo] = useState("");
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [addonPrice, setAddonPrice] = useState("")
    const [tpPrice, setTpPrice] = useState("")
    const [odPrice, setOdPrice] = useState("")
    const [discount, setDiscount] = useState(0);
    const [ncbDiscount, setNcbDiscount] = useState("")
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [electricIDV, setElectricIDV] = useState('');
    const [nonElectrical, setNonElectrical] = useState('');
    const [biFuelSI, setBiFuelSI] = useState("");
    const [driverCoverSI, setDriverCoverSi] = useState('');
    const [paCoverSi, setPaCoverSi] = useState('');
    const [voluntarySI, setVoluntarySi] = useState('');
    const [rtiSi, setRtiSi] = useState('');
    const [unNamedPASI, setUnNamedPASI] = useState('');
    const [addOnLoading, setAddOnLoading] = useState(false);

    const handleCPA = (addOnsName) => {
        console.log("item1", addOnsName)
        if (name == "HDFC Ergo" && addOnsName == "Personal Accident Cover") {
            console.log("item", item.name)
            handleAlert("Error", "Owner has no valid driving license or Having  CPA in another policy")
        }
    }

    const updateQuote = (company, payload) => {
        if (company == "RELIANCE General") {
            dispatch(RELIANCEMotorUpdateQuote(payload)).then((callback) => { console.log("result", callback); setAddons(callback.payload.data.addons); setAddOnLoading(false) });
        } else if (company == "HDFC Ergo") {
            dispatch(HDFCMotorUpdateQuote(payload)).then((callback) => { console.log("result", callback); setAddons(callback.payload.data.addons); setAddOnLoading(false) });
        }
    }
    const applyManual = function (slug, name) {
        console.log("val>>>>>", slug, name)
        if (name == "RELIANCE General") {
            let obj = { ...combinedRedux };

            if (slug == "electricalAccessories") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(electricIDV) };
                    else return val;
                })
            }
            else if (slug == "nonElectricalAccessories") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(nonElectrical) };
                    else return val;
                })
            }
            else if (slug == "paidDriverCover") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(driverCoverSI) };
                    else return val;
                })
            }
            else if (slug == "voluntaryDeductible") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(voluntarySI) };
                    else return val;
                })
            }
            else if (slug == "unnamedPassenger") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(unNamedPASI) };
                    else return val;
                })
            }
            setAddOnLoading(true);
            dispatch(motorEnquiryAPI({ addOns: obj, enquiryId: combinedRedux.enquiryId })).then((res) => {
                updateQuote(name, res.payload.data)
            })
        }
        else if (name == "HDFC Ergo") {
            let obj = { ...combinedRedux };
            if (slug == "electricalAccessories") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(electricIDV) };
                    else return val;
                })
            }
            else if (slug == "nonElectricalAccessories") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(nonElectrical) };
                    else return val;
                })
            }
            else if (slug == "lpgCngCheckCover") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: parseInt(biFuelSI) };
                    else return val;
                })
            }
            else if (slug == "returnToInvoice") {
                obj = obj.addOns.map(val => {
                    if (val.slug == slug)
                        return { ...val, siValue: rtiSi };
                    else return val;
                })
            }
            setAddOnLoading(true)
            dispatch(motorEnquiryAPI({ addOns: obj, enquiryId: combinedRedux.enquiryId })).then((res) => {
                updateQuote(name, res.payload.data)
            })
        }
        else return
        // dispatch(HDFCMotorUpdateQuote({ ...combinedRedux, ElecticalAccessoryIDV: parseInt(electricIDV) })).then((callback) => { console.log("result", callback); setAddons(callback.payload.data.addons) });
    }
    useEffect(() => {
         // console.log(m);
            if (motorQuote.length >= 2) {
                let data = [...motorQuote]
                  data.sort((a, b) => a.price - b.price);
           
            setMotorQuoteState(data);
            var cal = ((18 / 100) * data[item]?.price)
            cal = Math.round(cal)
            // setGST(cal)
            setLogo(data[item].sluginfo.logo)
            setName(data[item].sluginfo.name)
            setDesc(data[item].sluginfo.description)
            setGST(data[item]?.gstAmount)
            let prices = 0
            data[item].addons.map((elem) => {
                if (elem.price != undefined) {
                    prices += Number(elem?.price)
                }
                // console.log("elements are", elem?.price);
            });
            setAddonPrice(prices);
            setTpPrice(data[item].tpPrice)
            setOdPrice(data[item]?.odPrice)
            setDiscount(data[item].discount)
            setNcbDiscount(data[item]?.ncbDiscount);
            setNonElectrical(data[item]?.addons?.filter(val => val.slug == "nonElectricalAccessories")[0]?.siValue);
            setElectricIDV(data[item]?.addons?.filter(val => val.slug == "electricalAccessories")[0]?.siValue)
            setBiFuelSI(data[item]?.addons?.filter(val => val.slug == "lpgCngCheckCover")[0]?.siValue)
            dispatch(updateCombinedModel({ price: data[item]?.price, quickQuote: data[item] }));
        }
        else {
            let data = [...motorQuote]
            setMotorQuoteState(data);
            var cal = ((18 / 100) * data[item]?.price)
            cal = Math.round(cal)
            // setGST(cal)
            console.log("data is ---->", motorQuote);
            setLogo(data[item].sluginfo.logo)
            setName(data[item].sluginfo.name)
            setDesc(data[item].sluginfo.description)
            let price = 0
            data[item].addons.map((elem) => {
                if (elem.price != undefined) {
                    price += Number(elem?.price)
                }
                // console.log("elements are", elem?.price);
            });
            setAddonPrice(price);
            setTpPrice(data[item].tpPrice)
            setOdPrice(data[item]?.odPrice)
            setGST(data[item]?.gstAmount)
            setDiscount(data[item].discount)
            setNcbDiscount(data[item]?.ncbDiscount);
            setNonElectrical(data[item]?.addons?.filter(val => val.slug == "nonElectricalAccessories")[0]?.siValue);
            setElectricIDV(data[item]?.addons?.filter(val => val.slug == "electricalAccessories")[0]?.siValue)
            setBiFuelSI(data[item]?.addons?.filter(val => val.slug == "lpgCngCheckCover")[0]?.siValue)
            dispatch(updateCombinedModel({ price: data[item]?.price, quickQuote: data[item] }));
        }
        // console.log("elements are", data[item].addons);



    }, [motorQuote])


    // add ons handles
    const addOnsHandler = (e, slug) => {
        if (slug == "electricalAccessories")
            setElectricIDV(e.target.value);
        else if (slug == "nonElectricalAccessories") {
            setNonElectrical(e.target.value)
        }
        else if (slug == "nonElectricalAccessories") {
            setNonElectrical(e.target.value)
        }
        else if (slug == "lpgCngCheckCover") {
            setBiFuelSI(e.target.value)
        }
        else if (slug == "paidDriverCover") {
            setDriverCoverSi(e.target.value)
        }
        else if (slug == "personalAccident") {
            setPaCoverSi(e.target.value)
        }
        else if (slug == "voluntaryDeductible") {
            setVoluntarySi(e.target.value)
        }
        else if (slug == "returnToInvoice") {
            setRtiSi(e.target.value)
        }
        else if (slug == "unnamedPassenger") {
            setUnNamedPASI(e.target.value)
        }
    }

    const updateAddons = (addOnsName) => {
        console.log("item1", addOnsName, name, addOnsName)
        if (name == "HDFC Ergo" && addOnsName.name == "Personal Accident Cover") {
            console.log("item", item.name)
            handleAlert("Error", "Owner has no valid driving license or Having  CPA in another policy")
        }
        return
        // console.log("item are", item);
        // console.log("addons are", addons);

        // var objCheck =  addons.find((items)=>items.slug === item.slug);
        // addons.find((items)=>items.slug === item.slug);
        let check = []
        console.log("payload are", combinedRedux);
        for (let obj of addons) {
            if (obj.slug == item.slug) {
                check.push(obj);
            }
        }
        // console.log("check addons are", check);

        if (check) {
            const newArr = addons.filter(object => {
                console.log("object are", object);
                return object.slug !== item.slug;
            });
            console.log("newArr are ----->", newArr);
            setAddons(newArr);
            let obj2 = {
                ...combinedRedux,
                addons: newArr
            }
            // console.log("obj2 ->>>", obj2);
            // dispatch(GODIGITMotorQuote(obj2));
            dispatch(KotakMotorQuote(obj2));
            // dispatch(HDFCMotorQuote(obj2));
            // dispatch(ICICIMotorQuote(obj2))
            dispatch(motorEnquiryAPI({ addOns: newArr, enquiryId: combinedRedux.enquiryId }));
            setCallApi(true);
            setUpdateCover(true);
        }
    }

    useEffect(() => {
        if (callApi) {
            dispatch(resetMotorQuoteRedux('')).then((res) => {
                CallQuoteAPIs('new');
                setCallApi(false);
            });
            // setUpdateCover(false);
        }
    }, [combinedRedux?.addOns])




    return (
        <PopupContainer>
            <CloseIcon src={CloseImg} onClick={() => {
                // navigate('/car-insurance/main?enquiryId='+enquiryId)}
                setDetailsInfo(-1);
            }} />

            <div className="flex apart top res-flex">
                <div className="flex">
                    <img className="brand-img" src={logo} />
                    <div>
                        <p className="brand-name data">{name}</p>
                        <p className="basics">{desc}</p>

                        {/* <p className="basics">Cover Value (IDV) : ₹ 4,45,555 | Tenure : 1 year | Policy Start Date : 24 Jul 2022</p> */}
                    </div>
                </div>
                <button
                    //             onClick={()=>
                    //                 {
                    //                     // navigate((localStorage.getItem('Authorization')?'/car-insurance/payment/3':'/car-insurance/payment/1'),{ state: { item:item }})
                    //                     if(localStorage.getItem('Authorization')){
                    //                         var enquiryId = (localStorage.getItem('enquiryId'));
                    // var {enquiryId} = JSON.parse(localStorage.getItem('enquiryId'));
                    //                         // dispatch(motorEnquiryAPI({price:totalPrice}));  remove comment
                    //                         // navigate('/car-insurance/payment/3?enquiryId='+enquiryId,{state: item}  );  remove comment
                    //                         return;
                    //                         // navigate('/car-insurance/payment/3',{ state:item })
                    //                     }
                    //                     else{
                    //                         navigate('/signin-signup',{ state: { item:item } })
                    //                         // navigate('/car-insurance/payment/1',{ state: { item:item } })
                    //                     }
                    //                 }
                    //             }
                    onClick={() => {

                        if (localStorage.getItem('Authorization')) {
                            var enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
                            // dispatch(updateCombinedModel({price:item?.price,quickQuote:item}));
                            // setTimeout(() => {
                            // navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item,totalPrice:item?.price}  );
                            navigate('/car-insurance/payment/3?enquiryId=' + enquiryId.enquiryId, { state: motorQuoteState[item], totalPrice: motorQuoteState[item]?.price });
                            // }, 1);
                            // var enquiryId = JSON.parse(localStorage.getItem('enquiryId'));
                            // dispatch(motorEnquiryAPI({price:totalPrice,quickQuote:item?.responseData}));
                            // navigate('/car-insurance/payment/3?enquiryId='+enquiryId.enquiryId,{state: item}  );
                            // return;
                        }
                        else {
                            navigate('/signin-signup', { state: { item: item } })
                        }
                    }
                    }
                    className="price-btn">{addOnLoading ? "Loading..." : `Buy now @ ${motorQuoteState[item]?.price}`}    </button>
            </div>

            <div className="cards mt-1">
                <div className="flex apart">
                    <p className="head">Premium breakup :</p>
                    <img
                        src={UpArrowImg}
                    />
                </div>
                <p className="sub-head">Basic Covers :</p>
                <div className="flex apart">
                    <p className="data">Basic Own Damage</p>
                    <p className="data">₹ {parseFloat(odPrice).toFixed(2)}</p>
                </div>
                <div className="flex apart">
                    <p className="data">Basic Third Party Premium</p>
                    <p className="data">₹ {parseFloat(tpPrice).toFixed(2)}</p>
                </div>
                <Separator />
                <p className="sub-head">Add-ons Covers :</p>
                <div className="flex apart">
                    <p className="data">Total Premium Addons</p>
                    <p className="data">₹ {parseFloat(addonPrice).toFixed(2)}</p>
                </div>
                <Separator />
                <p className="sub-head">Basic Covers :</p>
                {/* <div className="flex apart">
                    <p className="data">OD Discount</p>
                    <p className="data discount">- ₹ {parseFloat(discount).toFixed(2)}</p>
                </div> */}
                <div className="flex apart">
                    <p className="data">NCB Discount :</p>
                    <p className="data discount">-₹ {ncbDiscount}</p>
                </div>
                <div className="flex apart">
                    <p className="data">18% GST</p>
                    <p className="data">₹ {parseFloat(GST).toFixed(2)}</p>
                </div>
                <Separator />
                <div className="flex apart">
                    <p className="total">Total Payable Amount :</p>
                    <p className="total-amount"> {addOnLoading ? "Loading..." : `₹ ${motorQuoteState[item]?.price}`}</p>
                </div>
            </div>

            <div className="cards mt-1">
                <p className="head mb-half">Add Ons Cover :</p>
                <div className='container2'>
                    {
                        addons && addons?.length > 0 && addons?.map((item1, index) => (
                            <>
                                {
                                    item1.status &&
                                    <>
                                        {/* <div className="flex apart res-flex"> */}
                                        {/* <div> */}
                                        <div>
                                            <div className="flex align-center mb-1 res-mb-1">
                                                <img
                                                    src={TickImg}
                                                    className='tick-icon'
                                                    onClick={() => { handleCPA(item1.name) }}
                                                />
                                                <p className="data">{item1.name} {item1.price && '( ₹' + item1.price + ' )'} <span className="remove" onClick={() => { updateAddons(item1) }}></span>

                                                </p>

                                            </div>
                                            {
                                                name == "HDFC Ergo" && (item1.name == "Non Electrical Accessories Cover" || item1.name == "Electrical Accessories Cover" || item1.name == "LPG CNG Check") ?
                                                    <div style={{ marginLeft: "1rem" }}>

                                                        <input type="text"
                                                            id="adhbInput"
                                                            value={`${item1.slug == "electricalAccessories" ? electricIDV : item1.slug == "nonElectricalAccessories" ? nonElectrical : item1.slug == "lpgCngCheckCover" ? biFuelSI : ""}`}
                                                            placeholder="Electrical Accessories Cover"
                                                            className="addonsInput"
                                                            onChange={e => addOnsHandler(e, item1.slug)}
                                                        />
                                                        <button className="addonsButton" onClick={() => applyManual(item1.slug, name)}>Add Benefit</button>
                                                    </div>
                                                    : (name == "HDFC Ergo" && item1.name == "Return To Invoice" && item1?.siList?.length) > 0 ?
                                                        <div style={{ marginLeft: "1rem" }}>

                                                            <select
                                                                id="adhbInput"
                                                                className="addonsInput"
                                                                onChange={e => addOnsHandler(e, item1.slug)}
                                                            >
                                                                {item1.siList.map(value => <option value = {value.amount}>{value.name}</option>)}
                                                            </select>
                                                            <button className="addonsButton" onClick={() => applyManual(item1.slug, name)}>Add Benefit</button>
                                                        </div>
                                                        : name == "RELIANCE General" && item1?.siList?.length > 1 ?
                                                            <div style={{ marginLeft: "1rem" }}>

                                                                <select
                                                                    id="adhbInput"
                                                                    className="addonsInput"
                                                                    onChange={e => addOnsHandler(e, item1.slug)}
                                                                >
                                                                    {item1.siList.map(value => <option>{value.Amount}</option>)}
                                                                </select>
                                                                <button className="addonsButton" onClick={() => applyManual(item1.slug, name)}>Add Benefit</button>
                                                            </div>
                                                            : name == "RELIANCE General" && item1.siList?.length == 0 && (item1.slug == "electricalAccessories" || item1.slug == "nonElectricalAccessories") ?
                                                                <div style={{ marginLeft: "1rem" }}>

                                                                    <input type="text"
                                                                        id="adhbInput"
                                                                        value={item1.slug == "electricalAccessories" ? electricIDV : item1.slug == "nonElectricalAccessories" ? nonElectrical : null}
                                                                        placeholder="Enter value"
                                                                        className="addonsInput"
                                                                        onChange={e => addOnsHandler(e, item1.slug)}
                                                                    />
                                                                    <button className="addonsButton" onClick={(e) => applyManual(item1.slug, name)}>Add Benefit</button>
                                                                </div> : null
                                            }
                                        </div>

                                        {/* </div> */}
                                        {/* <div>
                                    <div className="flex align-center mb-1 res-mb-1">
                                        <img
                                            src={TickImg}
                                            className='tick-icon'
                                        />
                                        <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                                    </div>
                                    <div className="flex align-center mb-1 res-mb-1">
                                        <img
                                            src={TickImg}
                                            className='tick-icon'
                                        />
                                        <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                                    </div>
                                    <div className="flex align-center mb-1 res-mb-1">
                                        <img
                                            src={TickImg}
                                            className='tick-icon'
                                        />
                                        <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                                    </div>
                                    <div className="flex align-center mb-1">
                                        <img
                                            src={TickImg}
                                            className='tick-icon'
                                        />
                                        <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                                    </div>
                                </div> */}
                                        {/* </div> */}
                                        {/* <div>
                                <div className="flex align-center mb-1 res-mb-1">
                                    <img
                                        src={TickImg}
                                        className='tick-icon'
                                    />
                                    <p className="data">{item.name} {item.price &&  '( ₹'+item.price+' )' } <span className="remove" onClick={()=>updateAddons(item)}>(Remove)</span></p>
                                </div>
                            </div>
                            <div>
                                <div className="flex align-center mb-1 res-mb-1">
                                    <img
                                        src={TickImg}
                                        className='tick-icon'
                                    />
                                    <p className="data">{item.name} {item.price &&  '( ₹'+item.price+' )' } <span className="remove" onClick={()=>updateAddons(item)}>(Remove)</span></p>
                                </div>
                            </div> */}
                                    </>
                                }
                            </>
                        ))
                    }
                </div>

                {/* <div className="flex apart res-flex">
                    <div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                    </div>
                    <div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                        <div className="flex align-center mb-1 res-mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                        <div className="flex align-center mb-1">
                            <img
                                src={TickImg}
                                className='tick-icon'
                            />
                            <p className="data">Zero Depreciation Cover ( ₹3,136 ) <span className="remove">(Remove)</span></p>
                        </div>
                    </div>
                </div> */}

            </div>

            {/* <div className="cards mt-1">
                <div className="flex align-center">
                    <img 
                        src={InfoImg} 
                        alt="" 
                        className="info" 
                    />
                    <p className="data">Lorem Ipsum is simply dummy text of the printing and provisio typesetting Lorem Ipsum text of the printings simply. <span className="learn-more">Learn More</span></p>
                </div>
            </div> */}

        </PopupContainer>
    )
}