import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import './blog.css';
import FooterNew from './../../components/Footer/FooterNew';
import LoadingSpinner from '../../components/loading/loading';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { BlocksRenderer } from '@strapi/blocks-react-renderer';

const BlogDetail = () => {
  const [blog, setBlog] = useState(null);
  const [loading, setLoading] = useState(true); // State to track loading status
  const { slug } = useParams();

  // for the placeholder name
  const getFirstLetter = (str) => {
    return str ? str[0].toUpperCase() : '';
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setLoading(true); // Set loading to true when fetching data starts
    axios.get(`https://uat-backend.simpliinsure.in/cms/api/blogs?filters[slug][$eq]=${slug}&populate=SubBlogs.img,blogBanner,SEO,SEO.sharedImage, SEO.sharedImage.media,authorImage`)
      .then(response => {
        setBlog(response?.data?.data[0]);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
        setLoading(false); // Set loading to false in case of error
      });
  }, [slug]);

  if (loading) {
    return <LoadingSpinner />;
  }

  const seo = blog?.attributes?.SEO || {};
  const metaTitle = seo.metaTitle || '';
  const metaDescription = seo.metaDescription || '';
  const keywords = seo.keywords || '';
  const sharedImageUrl = seo.sharedImage?.media?.data?.attributes?.url || '';

  return (
    <>
    <Helmet>
      <title>Blog | {metaTitle}</title>
      <meta name="title" content={metaTitle} />
      <meta name="description" content={metaDescription} />
      <meta name="keywords" content={keywords} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:image" content={sharedImageUrl} />
    </Helmet>
      
      <div className="blog_detail_container">
        <p className="blog_detail_heading">{blog?.attributes?.BlogTitle}</p>
        <p className="blog_detail_category">{blog?.attributes?.category}</p> 
        <div className='author_section'>
          <img
            src={blog?.attributes?.authorImage?.data?.attributes?.url ? blog?.attributes?.authorImage?.data?.attributes?.url :  `https://placehold.co/60x60?text=${getFirstLetter(blog?.attributes?.author)}`}
            alt="author"
            className="blog_detail_author_image"
          />

          <p className="blog_detail_author_name">Written By {blog?.attributes?.author} • {moment(blog?.attributes?.publishedAt).format('DD MMMM YYYY, hh:mm A')} </p>
        </div>
        <img src={`${blog?.attributes?.blogBanner?.data?.attributes.url}` || "https://placehold.co/1000x400"} alt="data" className="blog_detail_image" />
        <div className='inner-container'>
      {blog?.attributes?.SubBlogs && blog.attributes.SubBlogs.map((subBlog, index) => (
          <div key={index}>
            {
              subBlog.title !== null && <h3 className="blog_detail_subheading">{subBlog.title || ""}</h3>
            }
            {
              subBlog.description1 !== null && <p className="blog_detail_description">{subBlog.description1 || ""}</p>
            }
            {
              subBlog.description2 !== null && <p className="blog_detail_description">{subBlog.description2 || ""}</p>
            }
            {
              subBlog.description3 !== null && <p className="blog_detail_description">{subBlog.description3 || ""}</p>
            }
            {
              subBlog?.sub_blog_list &&
            <BlocksRenderer
              content={subBlog?.sub_blog_list || []}
              blocks={{
                'list': ({ children }) => <ul className="list-disc-cms">{children}</ul>,
                'list-item': ({ children }) => <li className="list-disc-li">{children}</li>,
              }}
            />
            }
            {
              subBlog.img?.data && <img src={`${subBlog.img.data[0].attributes.url}`} alt={subBlog.title} className='blog_img_sec_1' />
            }
          </div>
        ))}
        </div>


      </div>
      <FooterNew />
    </>
  );
};

export default BlogDetail;
