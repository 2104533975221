import { ContactBtn, ContinueBtn, FloatingTitle, FormHeading, HDiv, HelpIcon, InputContainer, LeftDiv, LeftSubDiv, MainContainer, RightDiv, ErrArrow, ErrorContainer, ErrorIcon, ErrorMsgContainer } from "./styles";
import HelpImg from '../../assets/contact-us/help.svg';
import 'antd/dist/antd.css';
import handleAlert from './../hanleAlert';
import ErrorIconImg from '../../assets/brand-new-car/exclamation.svg'
import SuccessIconImg from '../../assets/commons/success.svg'
import SubmittedGif from '../../assets/commons/submitted.gif'


import { useEffect, useState } from "react";

// error popup
const ErrorPopup = ({ error, isValid }) => {
  return (
    <ErrorContainer>
      {!isValid && <ErrorMsgContainer>{error}</ErrorMsgContainer>}
      {isValid ? <ErrorIcon src={SuccessIconImg} /> : <ErrorIcon src={ErrorIconImg} />}
    </ErrorContainer>
  );
};




export const Contact = ({ handleModal }) => {
 const [formError, setFormError] = useState(''); // Form-level error state
  const [data, setData] = useState({
    number: '',
    name: '',
    email: ''
  });

  const [errors, setErrors] = useState({
    number: '',
    name: '',
    email: ''
  });
  const [activeInput, setActiveInput] = useState('');
  const [isValid, setIsValid] = useState(false);


    const handleOnChange = (e) => {
    const { name, value } = e.target;
    setData((prevData) => ({ ...prevData, [name]: value }));
    setActiveInput(name);

    // Validate each input field while typing
    let isFormValid = true;

    // Validate each input field while typing
    if (name === 'name') {
      if (!value) {
        setErrors((prevErrors) => ({ ...prevErrors, name: 'Please enter your full name' }));
      } else if (!/^[a-zA-Z\s]+$/.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, name: 'Please enter a valid name' }));
        isFormValid = false;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
      }
    }

    if (name === 'number') {
      if (!value) {
        setErrors((prevErrors) => ({ ...prevErrors, number: 'Please enter your mobile number' }));
      } else if (!/^\d{10}$/.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, number: 'Please enter a valid 10-digit mobile number' }));
        isFormValid = false;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, number: '' }));
      }
    }

    if (name === 'email') {
      if (!value) {
        setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter your email id' }));
      } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(value)) {
        setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address' }));
        isFormValid = false;
      } else {
        setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
      }
    }
    setIsValid(isFormValid);

  //   if (value.trim() !== '') {
  //   setTyping(true);
  // } else {
  //   setTyping(false);
  // }
  };

  const handleData = () => {
    let isValid = true;

    // Validate mobile number
    if (!data.number) {
      setErrors((prevErrors) => ({ ...prevErrors, number: 'Please enter your mobile number' }));
      isValid = false;
    } else if (!/^\d{10}$/.test(data.number)) {
      setErrors((prevErrors) => ({ ...prevErrors, number: 'Please enter a valid 10-digit mobile number' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, number: '' }));
    }

    // Validate name
    if (!data.name) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Please enter your full name' }));
      isValid = false;
    } else if (!/^[a-zA-Z\s]+$/.test(data.name)) {
      setErrors((prevErrors) => ({ ...prevErrors, name: 'Please enter a valid name' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, name: '' }));
    }

    // Validate email
    if (!data.email) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter your email id' }));
      isValid = false;
    } else if (!/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/.test(data.email)) {
      setErrors((prevErrors) => ({ ...prevErrors, email: 'Please enter a valid email address' }));
      isValid = false;
    } else {
      setErrors((prevErrors) => ({ ...prevErrors, email: '' }));
    }

    if (isValid) {
      handleAlert("Thank You", "Thanks for reaching out. Our team will contact you soon");
      handleModal();

      // Reset data
      setData({
        number: '',
        name: '',
        email: ''
      });
    } else {
      setFormError('Please fill in the required fields correctly.');
    }
    
  };

  

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <MainContainer>
        <LeftDiv>
          <LeftSubDiv>
            <>
              <InputContainer>
                <FloatingTitle>Mobile Number</FloatingTitle>
                <HDiv className="m-0">
                  <h4>+91-</h4>
                  <input
                    className={errors.number && activeInput === 'number' ? 'error' : ''}
                    maxLength={10}
                    placeholder="Enter Number"
                    value={data.number}
                    name = "number"
                    onChange={handleOnChange}
                  />
                   {errors.hasOwnProperty('number') && activeInput === 'number' ? (
                      <ErrorPopup error={errors.number} isValid={isValid} />
                    ) : null}
                </HDiv>
              </InputContainer>
              {/* {errors.number && <p className="error-message" style={{color:"red"}}>{errors.number}</p>} */}

              <InputContainer>
                <FloatingTitle>Full Name</FloatingTitle>
                <HDiv className="m-0">
                  <input
                    className={errors.name && activeInput === 'name' ? 'error' : ''}
                    maxLength={40}
                    placeholder="Enter Full Name"
                    value={data.name}
                    name="name"
                    onChange={handleOnChange}
                  />
                   {errors.hasOwnProperty('name') && activeInput === 'name' ? (
                          <ErrorPopup error={errors.name} isValid={isValid}/>
                    ) : null}
                </HDiv>
              </InputContainer>
              {/* {errors.name && <p className="error-message" style={{color:"red"}}>{errors.name}</p>}   */}
              

              <InputContainer className="email">
                <FloatingTitle>Email ID</FloatingTitle>
                <>
                  <input
                    className={errors.name && activeInput === 'email' ? 'email' : ''}
                    placeholder="Enter Email"
                    type="email"
                    value={data.email}
                    name="email"
                    onChange={handleOnChange}
                  />
                  {errors.hasOwnProperty('email')  && activeInput === 'email' ? (
                          <ErrorPopup error={errors.email} isValid={isValid} />
                    ) : null}
                </>
              </InputContainer>
              {/* {errors.email && <p className="error-message" style={{color:"red"}}>{errors.email}</p>} */}
            </>
            <ContactBtn onClick={handleData}>Submit</ContactBtn>
          </LeftSubDiv>
        </LeftDiv>

        <RightDiv>
          <HelpIcon src={HelpImg} />
          <h2 className="mt-1">Feel free to send any queries</h2>
        </RightDiv>
      </MainContainer>
    </>
  );
};
