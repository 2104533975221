import styled from "styled-components";

export const MainContainer = styled.div`
    padding-top: 3rem;
    /* padding: 1rem; */

    @media screen and (max-width: 980px) {
        padding-top: 5rem;
    }

`
export const BgDiv = styled.div`
    width: 100%;
    height: 35vh;
    position: absolute;
    background: linear-gradient(to left, #41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }
`
export const CardMainContainer = styled.div`
    border-radius: 1rem;
    padding: 1rem 2.5rem;
    background-color: #fff;
    width: 95%;
    margin: auto;
    margin: 1rem auto;
    margin-bottom: 3rem;



`

export const HDiv = styled.div`
    display: flex;
    align-items: center;

    h2 {
        margin: auto 1rem;
    }

`
export const HDivApart = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: 800px) {
        flex-direction: column;
    }

`

export const DetailsMainContainer = styled.div`
    margin: 1rem auto 1rem auto;

    .main-details-container {
        margin-top: 1.5rem;
    }

    .authenticity {
        margin-top: .6rem;
        input {
            margin-right: .2rem;
        }
        p {
            color:#ff4800;  
            margin-top: 1px;
            font-size: .8rem;
            user-select: none;
        }
    }
    a {
        text-decoration-line: none;
    }

`
export const CarType = styled.h3`
    color: #ff4800;
`
export const EditBtn = styled.button`
    border: 1px solid #ff4800;
    padding: .6rem 2rem;
    color: #ff4800;
    font-weight: 900;
    background-color: #fff;
    cursor: pointer;
    border-radius: .5rem;

    @media screen and (max-width: 800px) {
        margin-top: 1rem;
    }

`
export const LeftDiv = styled.div`
    padding: 1.5rem;
    padding-top: 1.8rem;
    position: relative;
    border:1px solid #b7b7b7;
    flex:1;
    margin-right: .5rem;
    border-radius:.5rem;

    p {
        color: #605f5e;
        margin: .5rem auto;
        font-size: .85rem;
        span {
            text-transform: uppercase;
        }
    }
    h2 {
        font-weight: 800;
        font-size: 1.1rem !important;
        span {
            color: #ff4800;
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        margin: auto;
        margin-bottom: .5rem;
    }

`
export const RightDiv = styled.div`
    padding: 1.5rem;
    padding-top: 1.8rem;
    border:1px solid #b7b7b7;
    flex:1;
    margin-left: .5rem;
    border-radius:.5rem;

    p {
        color: #605f5e;
        margin: .5rem auto ;
        font-size:.85rem;
        span {
            text-transform: uppercase;
        }
    }

    @media screen and (max-width: 800px) {
        width: 100%;
        margin: auto;
        margin-top: .5rem;
    }
`
export const FloatingP = styled.p`
    position: absolute;
    background-color: #fff;
    color: #000 !important;
    padding: .1rem .4rem;
    font-size: .95rem !important;
    top: -16px;

`
export const ConfirmBtn = styled.button`
    background: linear-gradient(to left, #ed4168, #ffb25a);
    padding: .8rem 1.8rem;
    border: none;
    border-radius: 1.8rem;
    outline: none;
    color: #fff;
    font-size: 1.25rem;
    display: flex;
    margin: auto;
    margin-top: 2rem;
    cursor: pointer;
    
`