import styled from "styled-components"

export const MainContainer = styled.div`
    padding-top: 3rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

    a {
        text-decoration-line: none;
    }

`
export const BgDiv = styled.div`
    width: 100%;
    height: 35vh;
    position: absolute;
    background: linear-gradient(to left, #41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }
`
export const CardMainContainer = styled.div`
    border-radius: 1rem;
    padding: 1rem 2.5rem;
    background-color: #fff;
    width: 95%;
    margin: 1rem auto;
    margin-bottom: 3rem;

    a {
        text-decoration-line: none;
    }

    @media screen and (max-width:800px) {
        padding: 1rem;
    }

`
export const InsideCardContainer = styled.div`
    border: 1px solid #b7b7b7;
    border-radius: .6rem;
    /* padding-bottom: 1.8rem; */
    /* border-bottom: 0; */

    
    @media screen and (max-width: 840px) {

        .left-right-container {
            display: flex;
            flex-direction: column;
        }
        
    }
`
export const HDivApart = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    .f-1, .pl-2 {
        flex: 1;
        padding-left: 2rem;
    }

    @media screen and (max-width: 910px) {
        flex-direction: column;
        text-align: center;   

        .pl-2 {
            padding: 0;
        }

    }


`
export const Logo = styled.img`

`
export const DivBorderRight = styled.div`
    padding: 1rem;
    padding-right: 4rem;
    border-right: 1px solid #b7b7b7;

    @media screen and (max-width: 910px) {
        padding:1rem;
        border: none;
    }

`
export const DivPadding = styled.div`
    padding: 1rem !important;
    border-bottom: 1px solid #b7b7b7;
`
export const Div = styled.div`
    h4 {
        margin-bottom: .5rem;
    }
    p {
        font-size: .8rem;
    }
    span {
        font-size: .8rem;
        color:#ff4c00;
    }

    @media screen and (max-width: 910px) {
        margin:1rem;
    }

`
export const PrivateCarBtn = styled.div`
    padding: 1rem;
    font-size: .82rem;
    border: 1px solid #ff4c00;
    color:#ff4c00;
    border-radius: .4rem;
`
export const HDiv = styled.div`
    display: flex;
    justify-content: space-between;
    
    .align-c {
        align-items: center;
        cursor: pointer;
    }

    .mr {
        margin-top: 0;
        margin-right: .4rem;
    }
    .ml {
        margin-top: 0;
        margin-left: .4rem;
    }

`
export const LeftDiv = styled.div`
    background-color: #f6f6f6;
    padding: 1rem;
    flex: 1;
`

export const InputDiv = styled.div`
    position: relative;
    padding: .5rem 1rem;
    border: 1px solid #b7b7b7;
    border-radius: .3rem;
    margin: 1.5rem auto;
    position: relative;
    flex: 1;

    .bg-white {
        background-color: #fff;
    }

    input {
        width: 100%;
        padding: .4rem;
        border: none;
        padding-left: 0;
        outline: none;
        background-color: #fff;
    }

`
export const InputTitle = styled.p`
    position: absolute;
    padding: 0.4rem .6rem;
    background-color: #f6f6f6;
    top: -.9rem;
    left: 0.25rem;
`
export const InputData = styled.p`
    color:#605f5e;
`

export const InputThemeDiv = styled.div`
    position: relative;
    padding: .5rem 1rem;
    border: 1.75px solid #ff4c00;
    border-radius: .6rem;
    margin: 1.5rem auto;
    position: relative;
    /* flex:1.5; */
    .bg-white {
        background-color: #fff;
    }

    @media screen and (max-width: 800px) {
        width: 100%;
    }

`
export const InputThemeTitle = styled.div`
    position: absolute;
    color: #ff4c00;
    padding: 0.4rem .6rem;
    background-color: #f6f6f6;
    top: -.9rem;
    font-weight: 800;
`
export const BottomSubDiv = styled.div`
    padding: 1rem;
    border-radius: .3rem;
    border: 1.2px solid #b7b7b7;
    position: relative;
    margin: 1rem;
    margin-top: 3rem;
    padding-bottom: 2rem;
    .bg-white {
        background-color: #fff;
    }
    .padding-more {
        padding: .9rem;
    }

    .padding-t {
        padding-top: 1rem;
    }

`
export const RightDiv = styled.div`
    flex: 1;
    padding: 1rem;
    /* margin: 1rem; */
    /* margin-top: 3rem; */

    .mt-2 {
        margin-top: 2rem;
    }

`
export const VpayIcon = styled.img`
    width: 3rem;
    margin-right: 1rem;
`
export const UpiIcon = styled.img`
    width: 2rem;
    margin-right:2rem ;
`
export const CreditCardIcon = styled.img`
    width:2rem;
    margin:.7rem auto ;
    margin-right: 2rem;
`
export const DebitCardIcon = styled.img`
    width:2rem;
    margin:.7rem auto ;
    margin-right: 2rem;
`
export const NetBankingIcon = styled.img`
    width:2rem;
    margin-right: 2rem;
`
export const Divider = styled.div`
    width: 100%;
    height: 1px;
    background-color: #b7b7b7;
    opacity: .4;
    margin: 1rem auto;
`
export const Input = styled.input`
    
`
export const Button = styled.button`
    border: none;
    border-radius: 1.5rem;
    padding: .8rem 2.8rem;
    color: #fff;
    font-size: 1.2rem;
    background: linear-gradient(to left, #ed4168, #ffb25a);
    display: flex;
    margin: auto;
    margin-bottom: 2.7rem;
    cursor: pointer;
    margin-top: 2rem;
`