import styled from "styled-components";

export const Container = styled.div`
    background: linear-gradient(#FFE1BB, #41015F);
    padding: 20px;
    padding-top: 150px;
`

export const QuoteCardsContainer = styled.div`
    background-color: #fff;
    padding: 25px;
    padding-top: 35px;
    padding-bottom: 80px;
    border-radius: 20px 20px 0 0;
`
export const HDiv = styled.div`
    display: flex;
    align-items: center;
    .btns {
        margin: auto;
    }
`
export const BackIcon = styled.img`
    margin: auto 10px;
`
export const Heading = styled.h3`
    font-family: Arial, Helvetica, sans-serif;
`
export const Line = styled.div`
    width: 100px;
    height: 2px;
    background-color: red;
    margin-left: 20px;

    @media screen and (max-width: 660px) {
        display: none;
    }

`
export const View = styled.img`
    border: 1px solid #b7b7b7;
    border-radius: 5px;
    padding: 10px;
`
export const SelectedView = styled.img`
    border: 1px solid #ff4c00;
    border-radius: 5px;
    padding: 10px;
    margin-left: 8px;
`
export const HDivApart = styled.div`
    display: flex;
    justify-content: space-between;
`
export const GridContainer = styled.div`
    display: grid;
    gap:30px;
    margin-top: 30px;
    /* grid-template-columns: auto auto auto; */
    grid-template-columns: repeat(3,1fr);
    overflow-x: auto;

    @media (max-width:1200px) {
        grid-template-columns: repeat(2,1fr);
    }
    @media (max-width: 870px) {
        grid-template-columns: repeat(1,1fr);
    }
`




export const PaginationContainer = styled.div`
    padding: 10px 20px;
    border: 1px solid #dfdfdf;
    width: 20%;
    margin: 30px auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media screen and (max-width: 1190px) {
        width: 40%;
    }
    @media screen and (max-width: 660px) {
        width: 70%;
    }
    @media screen and (max-width: 420px) {
        width: 100%;
    }
`
export const LeftArrow = styled.img`
    margin-right: 5px;

`
export const RightArrow = styled.img`
    margin-left: 5px;

`
export const ActivePage = styled.p`
    padding:  10px;
    background-color: #ff4800;
    color: #fff;
    font-family: Arial, Helvetica, sans-serif;
`
export const Pages = styled.p`
    padding:  10px;
    font-family: Arial, Helvetica, sans-serif;
`
export const ConfirmBtn = styled.button`
    padding: 12px;
    width: 20%;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top: 22px;
    background: linear-gradient(#ed4168, #ffb25a);
    border: none;
    border-radius: 20px;
    color: #fff;
    font-weight: 700;
    &:hover {
        cursor: pointer;
    }
    @media screen and (max-width:800px) {
        width: 40%;
    }
`