// insuranceCompanies.js

const insuranceCompanies = [
  {
    label: 'Bajaj Allianz General Insurance Company Limited',
    value: 'BajajAllianzGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Bharti AXA General Insurance Company Limited',
    value: 'BhartiAXAGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Care Health Insurance Limited',
    value: 'CareHealthInsuranceLimited',
  },
  {
    label: 'Cholamandalam MS General Insurance Company Limited',
    value: 'CholamandalamMSGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Edelweiss General Insurance Company Limited',
    value: 'EdelweissGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Future Generali India Insurance Company Limited',
    value: 'FutureGeneraliIndiaInsuranceCompanyLimited',
  },
  {
    label: 'Go Digit General Insurance Limited',
    value: 'GoDigitGeneralInsuranceLimited',
  },
  {
    label: 'HDFC ERGO General Insurance Company Limited',
    value: 'HDFCERGOGeneralInsuranceCompanyLimited',
  },
  {
    label: 'ICICI Lombard General Insurance Company Limited',
    value: 'ICICILombardGeneralInsuranceCompanyLimited',
  },
  {
    label: 'IFFCO Tokio General Insurance Company Limited',
    value: 'IFFCOTokioGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Kotak Mahindra General Insurance Company Limited',
    value: 'KotakMahindraGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Liberty General Insurance Limited',
    value: 'LibertyGeneralInsuranceLimited',
  },
  {
    label: 'Magma HDI General Insurance Company Limited',
    value: 'MagmaHDIGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Manipal Cigna Health Insurance Company Limited',
    value: 'ManipalCignaHealthInsuranceCompanyLimited',
  },
  {
    label: 'National Insurance Company Limited',
    value: 'NationalInsuranceCompanyLimited',
  },
  {
    label: 'Niva Bupa Health Insurance Company Limited',
    value: 'NivaBupaHealthInsuranceCompanyLimited',
  },
  {
    label: 'Raheja QBE General Insurance Company Limited',
    value: 'RahejaQBEGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Reliance General Insurance Company Limited',
    value: 'RelianceGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Royal Sundaram General Insurance Company Limited',
    value: 'RoyalSundaramGeneralInsuranceCompanyLimited',
  },
  {
    label: 'SBI General Insurance Company Limited',
    value: 'SBIGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Shriram General Insurance Company Limited',
    value: 'ShriramGeneralInsuranceCompanyLimited',
  },
  {
    label: 'Star Health and Allied Insurance Company Limited',
    value: 'StarHealthAndAlliedInsuranceCompanyLimited',
  },
  {
    label: 'Tata AIG General Insurance Company Limited',
    value: 'TataAIGGeneralInsuranceCompanyLimited',
  },
  {
    label: 'The New India Assurance Company Limited',
    value: 'TheNewIndiaAssuranceCompanyLimited',
  },
  {
    label: 'The Oriental Insurance Company Limited',
    value: 'TheOrientalInsuranceCompanyLimited',
  },
  {
    label: 'United India Insurance Company Limited',
    value: 'UnitedIndiaInsuranceCompanyLimited',
  },
];

export default insuranceCompanies;
