import React from 'react';
import "../components/label.css"
import {FcInfo} from 'react-icons/fc';
import { Tooltip } from 'antd';

const Dropdown = ({ options, onChange, className, value, label, more, others, cta }) => {
  return (
    <>
    {
      more === 'Income' || more === "Lump-Sum" || more === 'payout'  ?      
      <Tooltip
      title={
        more === 'Income'
          ? 'Security benefit will be given on a yearly basis'
          : more === 'Lump-Sum'
          ? 'Your maturity benefit on your death benefit will be given as a one-off payment.'
          : 'This option is only allowed for Lump-Sum-Income or Income on ICICI IPS.'
      }
      color="#f3961e"
    > 

  <label className='labelUpper'>
    {label} {more === 'Income' || more === "Lump-Sum" || more === "payout" ? <FcInfo className='infoIcon' /> : null}
  </label>
</Tooltip> : <label className='labelUpper'> {label} </label>
    }

    
<select onChange={e=>onChange(e)} className={className} value={value}>
  {options.map((option,index) => (
    <option key={option} value={option} className={
        (cta === 'cta' && index === 1) || option.label === 'Whole Life'
          ? 'button-option'
          : ''
      }>
      {option === 'Whole Life' ? option : (others === 'coverTillAge' ? `${option} Years` : option)}
    </option>
  ))}
</select>
 </>
  );
};

export default Dropdown;
