import { BgDiv, BottomDiv, CarDetailsCard, CarGiftIcon, CarIcon, CarInput, ErrorContainer, ErrorMsgContainer, ErrArrow, ErrorIcon, CarTypeIcon, DetailsInputContainer, Div, Divider, ForwardIcon, HDiv, ImgCard, ImgPContainer, InsureBtn, Left, LeftDiv, MainDiv, Right, RightDiv, TitleText } from "./styles"
import CarIconImg from '../../assets/car-blue.svg'
import tag from '../../assets/tag.png'
import medal from '../../assets/medal.png'
import book from '../../assets/book.png'
import SuvIconImg from '../../assets/car-insurance/orange-suv.png'
import CarGiftImg from '../../assets/car-insurance/car-gift.png'
import ForwardImg from '../../assets/car-insurance/forward.png'
import Footer from "../../components/Footer/FooterNew"
import { Link, useNavigate, useLocation } from "react-router-dom"
import { useEffect, useState, useRef } from "react"
import { getCarInfo, resetRedux, updateCombinedModel } from "../../Redux/CarsRedux";
import { useSelector, useDispatch, } from "react-redux";
import { Oval } from 'react-loader-spinner'
import ErrorIconImg from '../../../src/assets/brand-new-car/exclamation.svg';
import SliderCaptcha from '@slider-captcha/react';


export const CarInsurance = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate()
    const carStates = useSelector((state) => state.carsRedux);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const [error, setError] = useState('')
    const [clicked, setClicked] = useState(false)
    const [isValid, setIsValid] = useState(false);
    const [showMessage, setShowMessage] = useState(true);
    const [carNumber, setCarNumber] = useState('');
    const [isUcBrowser, setIsUcBrowser] = useState(false);

    const { state } = useLocation();
    const carRef = useRef();

    useEffect(() => {
        if (combinedRedux) {
            if (combinedRedux?.carNo) {
                setCarNumber(combinedRedux.carNo);
            }
            else if (combinedRedux?.number) {
                setCarNumber(combinedRedux.number);
            }
        }

        // window.scrollTo(0, 0);
        // localStorage.removeItem('canInsuranceDetails')
        // return () => {
        //   }
        carRef.current.focus()
    }, [])

    // useEffect(() => {
    //     if (error) {
    //         const confVal = window.confirm('Record Not Found. Do you want enter details manually?')
    //         if (confVal) {
    //             var obj = {
    //                 number: carNumber
    //             }
    //             dispatch(updateCombinedModel(obj)).then(() => {
    //                 navigate('/brand-new-model/brand-model')
    //             })
    //         }
    //     }
    // }, [error])

    useEffect(() => {
        if (carNumber.length === 0) {
            setError('')
        }
    }, [carNumber])

    function validateRegNo() {
        let e = carNumber;
        let t, o = e.split("-");
        if (o && o.length > 2 && o[o.length - 1].length < 4 && !isNaN(o[o.length - 1])) {
            if (o[o.length - 1].length === 3) {
                o[o.length - 1] = `0${o[o.length - 1]}`;
            }
            if (o[o.length - 1].length === 2) {
                o[o.length - 1] = `00${o[o.length - 1]}`;
            }
            e = o.join("-");
        }
        let a = e.split("-");
        var n = true;
        let regex = ("BH" === e.slice(3, 5)
            ? /^[2-9]{1}[0-9]{1}BH[\d]{4}[A-Z]{1,2}$/
            : t = a[a.length - 1].length < 4
                ? /^[A-Z]{2}[0-9]{1,3}[A-Z]{0,3}[0-9]{5}$/
                : /^[A-Z]{2}[0-9]{1,3}[A-Z]{0,3}[0-9]{4}$/);
        if (!regex.test(e.replace(/-/g, "").toUpperCase())) {
            // setTimeout(() => {
            //     loggigOnPageBrokenOrError("Registrationno_error", e);
            // }, 3000);
            // setError(true);
            n = false;
            return n;
        } 
        return n;
    }

    const insureNow = () => {
        setShowMessage(true)
        // if (carNumber.length < 1 || !isValid) {
        //     setError('Please enter car number.')
        //     return;
        // }
        const isValidNumber = validateRegNo()
        if(!isValidNumber){
            setError('Please enter car number.')
            return;
        }


        if (!carStates.isFetching || carStates.combinedRedux._id) {
            var obj = {
                regNo: carNumber.replace(/-/g, "").trim()
            }
            console.log(obj);
            setClicked(true)
            const data = dispatch(getCarInfo(obj));
            // console.log("🚀 ~ file: CarInsurance.js:81 ~ insureNow ~ data:", data)
        }
        // setError('')
    };

    function extractRTOandState(regNumber) {
        const regex = /^([A-Z]{2})(\d{2}).*/;
        const match = regNumber.match(regex);
        if (match) {
          return {
            stateCode: match[1],
            RTOCode: match[2]
          };
        } else {
          return null; // Invalid format
        }
      }
      
      

    const manageFun = () => {
        if (carStates.carInfoSuccess == true && !carStates.error) {
            console.log("🚀 ~ file: CarInsurance.js:91 ~ manageFun ~ true:", true)
            navigate('/brand-new-model/car-form-preview')
            return
        }
        const vehicleData = extractRTOandState(carNumber.replace(/-/g, "").trim())
        if (carStates.error && carStates.isFetching === false) {
            setError(carStates.errorMessage);
            const obj = {
                number: carNumber.replace(/-/g, "").trim(),
                type:"old",
                rto:vehicleData?.RTOCode,
                stateCode:vehicleData?.stateCode
            }
            dispatch(updateCombinedModel(obj)).then(() => {
                navigate('/brand-new-model/brand-model')
            })
        }
    };

    useEffect(() => {
        if (clicked) {
            console.log("🚀 ~ file: CarInsurance.js:98 ~ useEffect ~ clicked:", clicked)
            manageFun();
        }
    }, [carStates])

    function verifiedCallback(token) {
        console.log('Captcha token: ' + token);
    }

    const handleCarNumberChange = (e) => {
        console.log("here",e)
        const keyCode = e.keyCode || e.which;
        // console.log("here",keyCode, e)
        if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 65 && keyCode <= 90) || (keyCode >= 96 && keyCode <= 105)) {
            // console.log("here",0.5)
            // document.getElementById("regNoTextBox").classList.add("text-center");
            // document.getElementById("car_number_label").style.display = "block";
            if (navigator.userAgent.match(/UCBrowser/)) {
                setIsUcBrowser(true);
                return false;
            }
            if (keyCode === 32 || keyCode === 8) {
                return false;
            }
            // console.log("here",e.target.value,e)
            // if (navigator.userAgent.indexOf("MSIE") !== -1 || !!document.documentMode) {
                const value =e.target.value;
                let newValue = value;
                console.log("here0",newValue)
                if (value.length >= 3) {
                    newValue = formatReg(value);
                }
                if (value.length >= 5) {
                    newValue = formatReg(value);
                }
                if (value.length >= 7) {
                    newValue = formatReg(value);
                }
                console.log("here",newValue)
                setCarNumber(newValue.toUpperCase());
        }
    };

    function formatReg(e) {
        console.log("val",e);
        let t = e;
        t = t.replace(/-/g, "");
        var a = [, , , , ];
        a[0] = t.slice(0, 2);
    a[1] = "";
    a[2] = "";
    a[3] = "";
    try {
        if (isNaN(t.slice(2, 4)) && "B" != t.slice(2, 3)) {
            isNaN(t.slice(2, 3)) || (a[1] = t.slice(2, 3));
            isNaN(t.slice(3, 4)) && (a[2] = t.slice(3, 4));
        } else {
            a[1] = t.slice(2, 4);
        }
        if (isNaN(t.slice(4, 8))) {
            a[2] = a[2] + t.slice(4, 5);
            if (isNaN(t.slice(5, 6)) && isNaN(t.slice(6, 7))) {
                a[2] = a[2] + t.slice(5, 7);
            } else {
                isNaN(t.slice(5, 6)) ? a[2] = a[2] + t.slice(5, 6) : a[3] = a[3] + t.slice(5, 6);
                isNaN(t.slice(6, 7)) || (a[3] = a[3] + t.slice(6, 7));
            }
            isNaN(t.slice(7, 11)) || (a[3] = a[3] + t.slice(7, 11));
        } else if ("BH" == t.slice(2, 4)) {
            a[3] = t.slice(4, 8);
            a[4] = t.slice(8, 10);
        } else {
            a[3] = a[3] + t.slice(4, 8);
        }
    } catch (e) {}
    return a.join("-").replace(/ /g, "").replace(/--/g, "-").replace(/-+$/, "").replace(/[^- 0-9 a-z A-z]/g, "");
    }
    

    const ErrorPopup = ({ isMessage = true }) => {
        return (
            <>
                <ErrorContainer>
                    {isMessage && (
                        <>
                            <ErrorMsgContainer>{error}</ErrorMsgContainer>
                            <ErrArrow />
                        </>
                    )
                    }
                    <ErrorIcon src={ErrorIconImg} />
                </ErrorContainer>
            </>
        )
    }


    return (
      <>
        <MainDiv>
          <BgDiv />
          <HDiv>
            <LeftDiv>
              <CarIcon src={CarIconImg} />
            </LeftDiv>
            <RightDiv>
              <TitleText>
                Let's find you <br />
                the best car insurance
              </TitleText>
              <HDiv>
                <ImgCard>
                  <ImgPContainer>
                    <img src={tag} alt="Instant" />
                    <p>Instant Policy</p>
                  </ImgPContainer>
                  <ImgPContainer>
                    <img src={medal} alt="medal" />
                    <p>Best Prices</p>
                  </ImgPContainer>
                  <ImgPContainer>
                    <img src={book} alt="book" />
                    <p>No Paperwork</p>
                  </ImgPContainer>
                </ImgCard>
              </HDiv>
            </RightDiv>
          </HDiv>

          {/* <SliderCaptcha
      create="https://example.com/captcha/create"
      verify="https://example.com/captcha/verify"
      callback={verifiedCallback}
    /> */}

          <CarDetailsCard>
            <DetailsInputContainer>
              <CarTypeIcon src={SuvIconImg} />
              <CarInput
                ref={carRef}
                tabIndex={0}
                placeholder="Enter Car no."
                onChange={(e) => setCarNumber(e.target.value.toUpperCase())}
                onKeyDown={(e) => {
                  // setTyping(true)
                  handleCarNumberChange(e);
                  // setCarNumber(e.target.value.toUpperCase())
                }}
                // onKeyDown={(e) => { e.key === 'Enter' && insureNow() }}
                value={carNumber}
                maxLength={13}
              />
              {error && <ErrorPopup isMessage={showMessage} />}
              {/* <Link to='/brand-new-model/car-form-preview'> */}
              <InsureBtn onClick={insureNow}>
                {carStates.isFetching && !carStates.combinedRedux._id ? (
                  <Oval
                    height={25}
                    width={85}
                    color="#fff"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                    ariaLabel="oval-loading"
                    secondaryColor="#fff"
                    strokeWidth={2}
                    strokeWidthSecondary={2}
                  />
                ) : (
                  'Insure now'
                )}
              </InsureBtn>
              {/* </Link> */}
            </DetailsInputContainer>

            <BottomDiv>
              {/* <Left>
                            <ForwardIcon src={ForwardImg} />
                            <p>Proceed without card number</p>
                        </Left>
                        <Divider /> */}
              <Right>
                <CarGiftIcon src={CarGiftImg} />
                <p>
                  Brand new car{' '}
                  <a onClick={() => navigate('/brand-new-model/brand-model')}>
                    Click here
                  </a>
                </p>
              </Right>
            </BottomDiv>
          </CarDetailsCard>
        </MainDiv>
        <Footer />
      </>
    );
}