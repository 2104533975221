  exports.getAgeOptions = (selectedDate) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;

    const selectedYear = selectedDate.getFullYear();
    const selectedMonth = selectedDate.getMonth() + 1; 

    let age = currentYear - selectedYear;

    // Check if the current month is before the selected month, and if so, decrease the calculated age by 1
    if (currentMonth < selectedMonth) {
      age--;
    }

    const options = Array.from({ length: 100 - age - 4 }, (_, index) => age + 5 + index);

    return options;
  };



