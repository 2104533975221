import React, { useState } from 'react';
import dropdown from '../../../../utilities/stateData';
import { useDispatch } from 'react-redux';
import { ProductLead } from '../../../../Redux/AuthRedux';
import './form.css';

const InsuranceForm = ({ product }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');

  const [formData, setFormData] = useState({
    name: '',
    phone: '',
    email: '',
    state: '',
    productInterested: '',
    isSocialMedia: false,
  });

const handleChange = (e) => {
  const { name, value, type, checked } = e.target;

  let updatedValue = value;
  if (name === 'name') {
    updatedValue = value.replace(/[^A-Za-z\s]/g, '');
  } else if (name === 'phone') {
    updatedValue = value.replace(/\D/g, '').slice(0, 10);
  } else if (name === 'email') {
    if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      // Invalid email format
      setError('Please enter a valid email address');
    }
  } else if (name === 'state') {
    updatedValue = value.replace(/[^A-Za-z\s]/g, '');
  } else if (name === 'productInterested') {
    // No specific validation for message field in this example
    // You can add validation rules as needed
  } else if (name === 'isSocialMedia') {
    // No specific validation for message field in this example
    // You can add validation rules as needed
  }

  setFormData({
    ...formData,
    [name]: type === 'checkbox' ? checked : updatedValue,
  });
  // Clear error message when user starts typing
  setError('');
  setSubmitted(false);
};


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Simple form validation
    if (!formData.name || !formData.phone || !formData.email || !formData.state || !formData.productInterested || !formData.isSocialMedia) {
      setError('Please fill in all fields');
      return;
    }

    setLoading(true);

    try {
      // Simulating form submission delay
      await new Promise((resolve) => setTimeout(resolve, 2000));
      
      // Dispatching the contact lead action
      dispatch(ProductLead(formData));
      setSubmitted(true);
    } catch (error) {
      setError('Failed to submit the form. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="form-container">
      <h2>Find the Perfect Insurance Plan for You in Minutes - Start Here</h2>
      <form onSubmit={handleSubmit}>
        <label className='form-label'>
          Enter Your Name:
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className={`form-data ${error && !formData.name ? 'error' : ''}`}
          />
          {/* {error && !formData.name && <span className="error-message">Please enter your name</span>} */}
        </label>
        
        <label className='form-label'>
          Enter Your Phone Number:
          <input
            type="text"
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            className={`form-data ${error && !formData.phone ? 'error' : ''}`}
          />
          {/* {error && !formData.phone && <span className="error-message">Please enter your phone number</span>} */}
        </label>
        
        <label className='form-label'>
          Enter Your Email ID:
          <input
            type="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className={`form-data ${error && !formData.email ? 'error' : ''}`}
          />
          {/* {error && !formData.email && <span className="error-message">Please enter your email</span>}*/}
        </label> 
        
        <label className='form-label'>
          Select Your State:
          <select name="state" value={formData.state} onChange={handleChange} className={`form-select ${error && !formData.state ? 'error' : ''}`}>
            <option value="">Select</option>
            {Object.keys(dropdown).map((key) => (
              <option key={key} value={key}>{dropdown[key]}</option>
            ))}
          </select>
          {/* {error && !formData.state && <span className="error-message">Please select your state</span>} */}
        </label>
        
        <label className='form-label'>
            Interested In:
            <select
              name="productInterested"
              value={formData.productInterested}
              onChange={handleChange}
              className={`form-select ${error && !formData.productInterested ? 'error' : ''}`}
            >
              <option value="">Select</option>
              <option value={`Buy ${product} Policy`}>Buy {product} Policy</option>
              <option value={`Renew ${product} Policy`}>Renew {product} Policy</option>
            </select>
            {/* {error && !formData.productInterested && <span className="error-message">Please select an insurance type</span>} */}
          </label>

        
        <label className='form-label'>
          <input
            type="checkbox"
            name="isSocialMedia"
            checked={formData.isSocialMedia}
            onChange={handleChange}
          />
          By ticking this box, I acknowledge that I have read and agreed to
          the terms, conditions, and privacy policy. I authorize
          SimpliInsurance.com and its authorized partners to contact me via
          phone call, SMS, WhatsApp, and email.
        </label>
        
        <button type="submit" className='health-form-btn' disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
      </form>
      {error && !formData.isSocialMedia && <span className="error-message">Please agree to the terms and conditions</span>}
      {submitted && <div className="success-message">Form submitted successfully!</div>}
      {error && <div className="error-message">{error}</div>}
    </div>
  );
};

export default InsuranceForm;
