import styled from "styled-components";

export const Layout = styled.div`
    border: .5px solid #B7B7B7;
    margin: 2rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
`