import styled from "styled-components";

export const ContinueBtn = styled.button`
    background-color: #F6931E;
    padding: .6rem;
    width: 150px;
    border: none;
    border-radius: 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    display: flex;
    align-self: center;
    justify-content: center;
    margin: auto;
`
export const Layout = styled.div`
    border: .5px solid #B7B7B7;
    margin: 2rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
`