import styled from "styled-components";

export const MainContainer = styled.div`
   
    display: flex;
    padding: 1rem 1rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

    @media screen and (max-width: 980px) {
        flex-direction: column-reverse;
        
    }

    a {
        text-decoration-line: none;
    }

    .bold {
        font-weight: 600;
        color:#605F5E;
    }
`

export const LeftDiv = styled.div`
    flex: 1;
    display: flex;
    justify-content:center;    
    /* height: 75vh; */
 


    @media screen and (max-width:980px) {
        margin: auto;
    }

`

export const LeftSubDiv = styled.div`
    padding-top: 2rem;
    /* background-color: pink; */
    width: 80%;

    .terms {
        font-weight: 400;
        font-size: .9rem;
        /* margin-top: 3rem; */
        .tnc, .privacy {
            color: #605F5E;
            font-weight: 700;
            text-decoration-line: underline;
        }
        .simpli {
            color:#FD9620;
            font-weight: 900;
        }
        .insure {
            color:#321046;
            font-weight: 900;
        }

    }

    .diff-method {
        span {
            color: #321046;
            text-decoration-line: underline;
        }
        margin-top: 1.75rem;
    }

    .welcome {
        line-height: 1.75;
    }
    
    .email {
        margin-bottom: 0;
    }
    .pass {
        margin-top: 1rem;
    }

    .signup-head {
        margin: auto;
        h2 {
            margin-bottom: 0;
        }
        p {
            margin-bottom: 0;
        }
    }

    @media screen and (max-width: 980px) {
        width: 90%;
        text-align: center;
        margin-top: 1rem;

        .signup-head {
            flex-direction: column;
        }


    }

`
export const FormHeading = styled.h2`
    color: #202020;
    font-size: 1.7rem;

    @media screen and (max-width: 980px) {
        margin-bottom: 1.5rem;
    }

`
export const InputContainer = styled.div`
    position: relative;
    padding: .85rem;
    margin: 1rem auto;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    input {
        width: 100%;
        padding-top: .2rem;
        padding-bottom: .2rem;
        outline: none;
        border: none;
        font-size: 1.2rem;
        margin-left: .2rem;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .m-0 {
        margin-top: 0;
        h4 {
            margin: auto;
        }
    }
    .m-0-0 {
        margin: 0;
    }

    .horizontal {
        display: flex;
        align-items: center;
        input {
            width: auto;
            accent-color: #F6931E;
        }
        label {
            margin-left: .5rem;
        }

        .apart+.apart {
            margin-left: 1rem;
        }

    }
    
`
export const FloatingTitle = styled.p`
    position: absolute;
    top: -10px;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #fff;
    font-weight: 800;
`
export const HDiv = styled.div`
    display: flex;
    align-self:center;
    align-items: center;
    margin-top: 1.6rem;
`
export const ContactBtn = styled.button`
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    padding: .8rem 2.5rem;
    font-size: 1.2rem;
    border-radius: 4rem;
    border: none;
    color: #fff;
    font-weight: 900;
    cursor: pointer;
    margin-top: 1.5rem;
    margin-bottom: 2rem;

    &:hover {
        box-shadow: 1px 1px 6px gold;
    }

`

export const RightDiv = styled.div`
    flex: 1;
    text-align: center;
    /* height: 75vh; */
    position: relative;
    padding-bottom: 2rem;

    .mt-1 {
        margin-top: 1rem;
    }

`
export const HelpIcon = styled.img`
  margin-top: 5rem;
  width: 100px;


  @media screen and (max-width: 768px) {
    width: 90px;
  }
`;

export const ErrorContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    right: 15px;
    top: -28px;
    font-size: .8rem;
    align-items: flex-end;
    margin-top: .7rem;

`
export const ErrorMsgContainer = styled.div`
    padding: .5rem 1rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    border-bottom-right-radius: 0;
    color: #fff;
    font-weight: 900;
    margin-right: .5rem;
`
export const ErrorIcon = styled.img`
  width: 20px;
  margin-top: .4rem
`;
export const ErrArrow = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid #F6931E; 
    border-left: 10px solid transparent;
    margin-right: .5rem;
`




