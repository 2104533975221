import { CardHeader } from "../../../components/card-header/CardHeader"
import Footer from "../../../components/Footer/FooterNew"
import {Link, useLocation} from 'react-router-dom'
import { BgDiv, BottomSubDiv, Button, CardMainContainer, CreditCardIcon, DebitCardIcon, Div, DivBorderRight, Divider, DivPadding, HDiv, HDivApart, Input, InputData, InputDiv, InputThemeDiv, InputThemeTitle, InputTitle, InsideCardContainer, LeftDiv, Logo, MainContainer, NetBankingIcon, PrivateCarBtn, RightDiv, UpiIcon, VpayIcon } from "./styles"
import Vpay from '../../../assets/car-insurance/form-9/v-pay.png'
import Upi from '../../../assets/car-insurance/form-9/upi.png'
import Card1 from '../../../assets/car-insurance/form-9/card.png'
import Card2 from '../../../assets/car-insurance/form-9/card-2.png'
import NetBanking from '../../../assets/car-insurance/form-9/mobile.png'
import { useEffect, useState } from "react"

export const CarForm9 = () => {

    const location = useLocation()
    const item = location.state.from;

    const [paymentMode, setPaymentMode] = useState('')
    const [data, setData] = useState({
        cardNo:'',
        cardHolderName:'',
        expiry:'',
        cvv:''
    })
    
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const InputDivComp = ({ title, val, variant, type, inputType }) => {
        return (
            <InputDiv className="padding-more">
                <InputTitle className={variant==='bg-w'?"bg-white":null}>{title}</InputTitle>
                <InputData>{val}</InputData>
            </InputDiv>
        )
    }

    const handleChange = (e) => {
        setPaymentMode(e.target.value)
    }

    return(
        <>
            <MainContainer>
                <BgDiv />
                <CardMainContainer>

                    <CardHeader title='Payment Method' />

                    <InsideCardContainer>

                        <DivPadding>
                            <HDivApart>
                                <DivBorderRight>
                                    <Logo src={item.icon} />
                                </DivBorderRight>
                                <Div className="f-1 pl-2">
                                    <h4>{item.name}</h4>
                                    <p>Chevrolette Impala (Kesari, 2000cc) - Petrol </p> <span>Private Car - 2015 MP-04 </span>
                                    <p>Quotation No. - CVIMPL6989698989</p>
                                </Div>
                                <Div>
                                    <PrivateCarBtn>Private Car Package Policy</PrivateCarBtn>
                                </Div>
                            </HDivApart>
                        </DivPadding>

                        <HDiv className="left-right-container">
                            <LeftDiv>
                                <BottomSubDiv>
                                    <InputThemeTitle>Policy Holder Detail</InputThemeTitle>
                                    <InputDivComp title={'Name'} val='Mr. Pratap' />
                                    <InputDivComp title={'Gender'} val='Male' />
                                    <InputDivComp title={'Birth Date'} val='25-Oct-1998' />
                                    <InputDivComp title={'Contact Number'} val='8969696969' />
                                    <InputDivComp title={'Email Address'} val='example@gmail.com' />
                                    <InputDivComp title={'Occupation'} val='Brigadier, Army' />
                                    <InputDivComp title={'Communication Address'} val='41-b, MP Nagar, Bhopal, IN' />
                                </BottomSubDiv>
                                <BottomSubDiv>
                                    <InputThemeTitle>Policy Holder Detail</InputThemeTitle>
                                    <InputDivComp title={'Name'} val='Mr. Pratap' />
                                    <InputDivComp title={'Gender'} val='Male' />
                                    <InputDivComp title={'Birth Date'} val='25-Oct-1998' />
                                    <InputDivComp title={'Contact Number'} val='8969696969' />
                                    <InputDivComp title={'Email Address'} val='example@gmail.com' />
                                    <InputDivComp title={'Occupation'} val='Brigadier, Army' />
                                    <InputDivComp title={'Communication Address'} val='41-b, MP Nagar, Bhopal, IN' />
                                </BottomSubDiv>
                            </LeftDiv>

                            <RightDiv>
                                <BottomSubDiv>
                                    <InputThemeTitle className="bg-white">Choose a Payment Method</InputThemeTitle>

                                    <HDiv className="padding-t">
                                        <HDiv className="align-c" onClick={() => setPaymentMode('v-pay')}>
                                            <VpayIcon src={Vpay} />
                                            <p>V-Pay</p>
                                        </HDiv>
                                        <input type='radio' value={'v-pay'} checked={paymentMode==='v-pay'?true:false} onChange={handleChange} />
                                    </HDiv>
                                    <Divider />
                                    <HDiv>
                                        <HDiv className="align-c" onClick={() => setPaymentMode('upi')}>
                                            <UpiIcon src={Upi} />
                                            <p>UPI</p>
                                        </HDiv>
                                        <input type='radio' value={'upi'} checked={paymentMode==='upi'?true:false} onChange={handleChange} />
                                    </HDiv>
                                    <Divider />
                                    <HDiv>
                                        <HDiv className="align-c" onClick={() => setPaymentMode('c-card')}>
                                            <CreditCardIcon src={Card1} />
                                            <p>Credit Card</p>
                                        </HDiv>
                                        <input type='radio' value={'c-card'} checked={paymentMode==='c-card'?true:false} onChange={handleChange} />
                                    </HDiv>
                                    <Divider />
                                    <HDiv>
                                        <HDiv className="align-c" onClick={() => setPaymentMode('d-card')}>
                                            <DebitCardIcon src={Card2} />
                                            <p>Debit Card</p>
                                        </HDiv>
                                        <input type='radio' value={'d-card'} checked={paymentMode==='d-card'?true:false} onChange={handleChange} />
                                    </HDiv>
                                    <Divider />
                                    <HDiv>
                                        <HDiv className="align-c" onClick={() => setPaymentMode('n-banking')}>
                                            <NetBankingIcon src={NetBanking} />
                                            <p>Net Banking</p>
                                        </HDiv>
                                        <input type='radio' value={'n-banking'} checked={paymentMode==='n-banking'?true:false} onChange={handleChange} />
                                    </HDiv>
                                </BottomSubDiv>

                                <InputThemeDiv className="mt-2">
                                    <InputThemeTitle className="bg-white">{'Credit Card'} Details</InputThemeTitle>
                                    
                                    <InputDiv className="padding-more">
                                        <InputTitle className='bg-white'>Credit Card Number</InputTitle>
                                        <input 
                                            type='number' 
                                            placeholder="Enter Credit Card Number"
                                            value={data.cardNo} 
                                            onChange={e => setData(prev => ({...prev, cardNo:e.target.value}))} 
                                        />
                                    </InputDiv>
                                    <InputDiv className="padding-more">
                                        <InputTitle className='bg-white'>Card Holder Name</InputTitle>
                                        <input 
                                            placeholder="Enter Card Holder Name"
                                            value={data.cardHolderName} 
                                            onChange={e => setData(prev => ({...prev, cardHolderName:e.target.value}))} 
                                        />
                                    </InputDiv>
                                    <HDiv>
                                        <InputDiv className="padding-more mr">
                                            <InputTitle className='bg-white'>Expiry Date</InputTitle>
                                            <input 
                                                placeholder="DD/MM/YYYY"
                                                type={'date'}
                                                value={data.expiry} 
                                                onChange={e => setData(prev => ({...prev, expiry:e.target.value}))} 
                                            />
                                        </InputDiv>
                                        <InputDiv className="padding-more ml">
                                            <InputTitle className='bg-white'>CVV</InputTitle>
                                            <input 
                                                placeholder="Enter CVV No."
                                                value={data.cvv} 
                                                onChange={e => setData(prev => ({...prev, cvv:e.target.value}))} 
                                            />
                                        </InputDiv>
                                    </HDiv>

                                    <Link to='/car/form-10' state={{ from: item}}>
                                        <Button>Pay - ₹ {'115,1'}</Button>
                                    </Link>

                                </InputThemeDiv>

                            </RightDiv>

                        </HDiv>

                    </InsideCardContainer>

                </CardMainContainer>
            </MainContainer>
            <Footer />
        </>
    )
}