// import React, { useState } from "react";
// import "./coBrokerSection.css";

// const CoBrokerSection = () => {
//   const [isChecked, setIsChecked] = useState(false);
//   const [formData, setFormData] = useState({
//     coBrokerName: "",
//     coBrokerBranchCode: "",
//     coBrokerShare: "",
//   });

//   const [coBrokers, setCoBrokers] = useState([]);

//   const handleCheckboxChange = (e) => {
//     setIsChecked(e.target.checked);
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     setFormData({
//       ...formData,
//       [name]: value,
//     });
//   };

//   const handleAddCoBroker = () => {
//     setCoBrokers([
//       ...coBrokers,
//       { ...formData, sno: coBrokers.length + 1 },
//     ]);

//     // Reset form data
//     setFormData({
//       coBrokerName: "",
//       coBrokerBranchCode: "",
//       coBrokerShare: "",
//     });
//   };

//   return (
//     <div className="co-broker-section">
//       <div className="checkbox-container">
//         <label htmlFor="coBrokerCheckbox">
//           <input
//             type="checkbox"
//             id="coBrokerCheckbox"
//             checked={isChecked}
//             onChange={handleCheckboxChange}
//           />
//           Co-Broker
//         </label>
//       </div>

//       {isChecked && (
//         <div className="co-broker-form">
//           <div className="co-broker-grid">
//             <div className="form-group required">
//               <label htmlFor="coBrokerName">Co-Broker Name</label>
//               <select
//                 id="coBrokerName"
//                 name="coBrokerName"
//                 value={formData.coBrokerName}
//                 onChange={handleChange}
//               >
//                 <option value="">--Select--</option>
//                 <option value="CoBroker1">CoBroker 1</option>
//                 <option value="CoBroker2">CoBroker 2</option>
//               </select>
//             </div>

//             <div className="form-group required">
//               <label htmlFor="coBrokerBranchCode">Co-Broker Branch Code</label>
//               <select
//                 id="coBrokerBranchCode"
//                 name="coBrokerBranchCode"
//                 value={formData.coBrokerBranchCode}
//                 onChange={handleChange}
//               >
//                 <option value="">--Select--</option>
//                 <option value="BranchCode1">Branch Code 1</option>
//                 <option value="BranchCode2">Branch Code 2</option>
//               </select>
//             </div>

//             <div className="form-group required">
//               <label htmlFor="coBrokerShare">Co-Broker Share</label>
//               <input
//                 type="text"
//                 id="coBrokerShare"
//                 name="coBrokerShare"
//                 value={formData.coBrokerShare}
//                 onChange={handleChange}
//               />
//             </div>

//             <div className="form-group">
//               <button type="button" onClick={handleAddCoBroker} className="add-btn">
//                 Add
//               </button>
//             </div>
//           </div>

//           <div className="table-container">
//             <table>
//               <thead>
//                 <tr>
//                   <th>Action</th>
//                   <th>Sno</th>
//                   <th>CoBrokerName</th>
//                   <th>CoBrokerBranch</th>
//                   <th>CoBrokerShare</th>
//                 </tr>
//               </thead>
//               <tbody>
//                 {coBrokers.map((broker, index) => (
//                   <tr key={index}>
//                     <td>
//                       <button
//                         onClick={() =>
//                           setCoBrokers(coBrokers.filter((_, i) => i !== index))
//                         }
//                       >
//                         Remove
//                       </button>
//                     </td>
//                     <td>{broker.sno}</td>
//                     <td>{broker.coBrokerName}</td>
//                     <td>{broker.coBrokerBranchCode}</td>
//                     <td>{broker.coBrokerShare}</td>
//                   </tr>
//                 ))}
//               </tbody>
//             </table>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default CoBrokerSection;


import React from 'react';
import './coBrokerSection.css';

const CoBrokerSection = ({
  formData,
  handleChange,
  handleAddCoBroker,
  handleRemoveCoBroker,
  coBrokers,
  handleCheckboxChange,
  isChecked,
}) => {
  return (
    <div className="co-broker-section">
      <div className="checkbox-container">
        <label htmlFor="coBrokerCheckbox" className="coBrokerLabel">
          <input
            type="checkbox"
            id="coBrokerCheckbox"
            className="coBrokerCheckbox"
            checked={isChecked}
            onChange={handleCheckboxChange}
          />
          Co-Broker
        </label>
      </div>

      {isChecked && (
        <div className="co-broker-form">
          <div className="co-broker-grid">
            <div className="form-group required">
              <label htmlFor="coBrokerName" className="coBrokerLabel">
                Co-Broker Name
              </label>
              <select
                id="coBrokerName"
                name="coBrokerName"
                className="coBrokerSelect"
                value={formData.coBrokerName}
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                <option value="self">Self</option>
              </select>
            </div>

            <div className="form-group required">
              <label htmlFor="coBrokerBranchCode" className="coBrokerLabel">
                Co-Broker Branch Code
              </label>
              <select
                id="coBrokerBranchCode"
                name="coBrokerBranchCode"
                className="coBrokerSelect"
                value={formData.coBrokerBranchCode}
                onChange={handleChange}
              >
                <option value="">--Select--</option>
                <option value="NA">NA</option>
              </select>
            </div>

            <div className="form-group required">
              <label htmlFor="coBrokerShare" className="coBrokerLabel">
                Co-Broker Share
              </label>
              <input
                type="text"
                id="coBrokerShare"
                name="coBrokerShare"
                className="coBrokerInput"
                value={formData.coBrokerShare}
                onChange={handleChange}
              />
            </div>

            <div className="form-group">
              <button
                type="button"
                onClick={handleAddCoBroker}
                className="add-btn coBrokerButton"
              >
                Add
              </button>
            </div>
          </div>

          <div className="table-container coBrokerTableContainer">
            <table className="coBrokerTable">
              <thead>
                <tr>
                  <th className="coBrokerTableHeader">Action</th>
                  <th className="coBrokerTableHeader">Sno</th>
                  <th className="coBrokerTableHeader">CoBrokerName</th>
                  <th className="coBrokerTableHeader">CoBrokerBranch</th>
                  <th className="coBrokerTableHeader">CoBrokerShare</th>
                </tr>
              </thead>
              <tbody>
                {coBrokers.map((broker, index) => (
                  <tr key={index}>
                    <td className="coBrokerTableData">
                      <button
                        onClick={() => handleRemoveCoBroker(index)}
                        className="remove-btn coBrokerButton"
                      >
                        Remove
                      </button>
                    </td>
                    <td className="coBrokerTableData">{broker.sno}</td>
                    <td className="coBrokerTableData">{broker.coBrokerName}</td>
                    <td className="coBrokerTableData">
                      {broker.coBrokerBranchCode}
                    </td>
                    <td className="coBrokerTableData">
                      {broker.coBrokerShare}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
};

export default CoBrokerSection;
