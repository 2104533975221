import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCall, apiPostCall,apiPostCall1,apiGETCall1 } from '../utilities/site-apis';

const initialState = {
    quotationsList: [],
    lifeQuotationsList: [],
    policyList:[],
    posUser:[],
    notifications:[],
    isFetching:false,
    error: null,
    statics:null,
    errorMessage:'',
}

export const GetNotifications = createAsyncThunk(
  'dashboard/getNotifications',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/notification/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    else{
      return response.data
    }
  }
)

export const GetQuotations = createAsyncThunk(
  'dashboard/getQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/motor/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const GetMarineQuotations = createAsyncThunk(
  'dashboard/getMarineQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/marine/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const GetBikeQuotations = createAsyncThunk(
  'dashboard/getBikeQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/twowheeler/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const GetOtherQuotations = createAsyncThunk(
  'dashboard/getMOtherQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/other/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const GetHealthQuotations = createAsyncThunk(
  'dashboard/getHealthQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/health/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)
export const getStatics = createAsyncThunk(
  'dashboard/statics',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/lead/enquiry/statics/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    else{
      return response.data
    }
  }
)
export const getPosUser = createAsyncThunk(
  'pos/users',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/lead/enquiry/posUser/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    else{
      return response.data
    }
  }
)


export const GetPolicies = createAsyncThunk(
  'dashboard/getPolicies',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/motor/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const motorEnquiryAPI = createAsyncThunk(
  "lead/enquiry",
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    const response = await apiPostCall1(`/lead/enquiry`, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
);

export const GetCommonQuotations = createAsyncThunk(
  'dashboard/getCommonQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/lead/enquiry/quote/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const GetLifeQuotations = createAsyncThunk(
  'dashboard/getLifeQuotations',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    
    const response = await apiGETCall1(`/life/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    if(params.hasOwnProperty('isQuotation')){
      var obj2 = {...response.data, ...{
        isQuotation:1
      }};
      // console.log("🚀 ~ file: DashboardRedux.jsx ~ line 27 ~ obj2", obj2)
      return obj2;
    }
    else{
      return response.data
    }
  }
)

export const updateProfile = createAsyncThunk(
  'dashboard/updateProfile',
  async (params, { rejectWithValue }) => {
    var Authorization = localStorage.getItem('userDetails');
    Authorization = JSON.parse(Authorization);
    const response = await apiGETCall1(`/motor/enquiry/list/`+Authorization.userId, params)
    if (response.data.status === 'error') {
      return rejectWithValue(response.data)
    }
    return response.data
  }
)

export const counterSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setLoginRedirectUrl: (state, action) => {
      state.loginRedirectUrl = action.payload
    },
    setIsSignUp: (state, action) => {
      state.isSignUp = action.payload
    },
    logout: (state, action) => {
      state.user = null
      state.token = null
    },
  },
  extraReducers: {

    [GetNotifications.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetNotifications.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetNotifications.fulfilled]: (state, action) => {
      state.notifications = action.payload.data
      state.isFetching = false
    },

    [GetQuotations.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetQuotations.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetQuotations.fulfilled]: (state, action) => {
      state.quotationsList = action.payload.data
      state.isFetching = false
    },
    [GetMarineQuotations.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetMarineQuotations.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetMarineQuotations.fulfilled]: (state, action) => {
      state.quotationsList = action.payload.data
      state.isFetching = false
    },
    [GetBikeQuotations.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetBikeQuotations.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetBikeQuotations.fulfilled]: (state, action) => {
      state.quotationsList = action.payload.data
      state.isFetching = false
    },
    [GetOtherQuotations.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetOtherQuotations.rejected]: (state, action) => {
      state.isFetching = false
      state.error = true
      state.errorMessage = action.payload.message
  },
  [GetOtherQuotations.fulfilled]: (state, action) => {
    state.quotationsList = action.payload.data
    state.isFetching = false
  },
  [GetHealthQuotations.pending]: (state, action) => {
    state.isFetching = true;
    state.error = false;
  },
    [GetHealthQuotations.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetHealthQuotations.fulfilled]: (state, action) => {
      state.quotationsList = action.payload.data
      state.isFetching = false
    },
    [getPosUser.pending]: (state, action) =>{
      state.isFetching = true;
      state.error = false;
    },
    [getPosUser.rejected]: (state, action) =>{
      state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [getPosUser.fulfilled]: (state, action) =>{
      state.isFetching = false
        state.error = false
        state.posUser = action.payload.data
    },
    [getStatics.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [getStatics.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [getStatics.fulfilled]: (state, action) => {
      state.statics = action.payload.data
      state.isFetching = false
    },
    [GetPolicies.pending]: (state, action) => {
      state.policyList = true;
      state.error = false;
    },
    [GetPolicies.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetPolicies.fulfilled]: (state, action) => {
      state.policyList = action.payload.data
      state.isFetching = false
    },
    [GetCommonQuotations.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetCommonQuotations.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetCommonQuotations.fulfilled]: (state, action) => {
      state.quotationsList = action.payload.data
      state.isFetching = false
    },
    [motorEnquiryAPI.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [motorEnquiryAPI.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [motorEnquiryAPI.fulfilled]: (state, action) => {
      state.isFetching = false
    },
    [GetLifeQuotations.pending]: (state, action) => {
      state.isFetching = true;
      state.error = false;
    },
    [GetLifeQuotations.rejected]: (state, action) => {
        state.isFetching = false
        state.error = true
        state.errorMessage = action.payload.message
    },
    [GetLifeQuotations.fulfilled]: (state, action) => {
      state.quotationsList = action.payload.data
      state.isFetching = false
    }
  }

})

export const { logout, setLoginRedirectUrl, setIsSignUp } = counterSlice.actions
export default counterSlice.reducer