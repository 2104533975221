import React, {useEffect} from 'react';
import InsuranceForm from './health-form/form';
import EligibilityCriteria from './eligibility/eligibility';
import './health.css';
// import InsuranceForm from './health-form/form';
// import InsuranceSteps from './buy-step/buy';
// import TableSection from './overview-table/overview';
// import HealthBenifits from './benifits-buy/benifits';
// import EligibilityCriteria from './eligibility/eligibility';
import FooterNew from './../../../components/Footer/FooterNew';
// import StepsToBuyHealthInsurance from './eligibility/step-but-right-health';
import BuyInsuranceSteps from './explore-others/explore';
// import Partners from './partners/partners';
import healthImage from './../../../assets/banner/6.png';
import { useParams } from 'react-router';
import data from '../../../assets/step3.data.json'
import { useNavigate } from 'react-router-dom';
import Hero from '../../../components/Hero_Banner/hero';
import product_new_banner from '../../../assets/product-new-banner.png';
import { FaShieldAlt } from 'react-icons/fa';
import Quick from '../../../components/new_quick_links/quick';



const LifeInsurance = () => {
  const navigate = useNavigate()
  const params = useParams()
      useEffect(() => {
        window.scrollTo(0, 0)
    }, [])
    
  return (
    <div>
    <Hero image={ product_new_banner} subHeading={data[params.third]?.image?.para} heading={data[params.third].title} btn = {true} icon = {< FaShieldAlt size={100}  />} />
    <Quick heading={data[params.third].title}/>
    {/* <div className='health-insurance-banner'>
    <img src={healthImage} alt='health-insurance'  className='health-banner-image'/>
     </div> */}
    <div className='health-container'>
      <h1 className='health-container-heading'>{data[params.third].title}</h1>
      <div className='health-part-one'>
        <div className='health-column'>
          <p>{data[params.third].details}</p>


<ul>
  {data[params.third]["details-data"].map((item,_)=>{
    return <li key={_} className='' >
      <div className="">
                <p>{(Object.entries(item)[0][0])}</p>
              </div>
              <div className="">
                <p>{Object.entries(item)[0][1]}</p>
              </div>
    </li>
  })}
</ul>
          <div className="table-container">
           {data[params.third]["details-meta-data"].map((item, index) => {
            return  <div className="table-row" key={index}>
              <div className="table-cell">
                <p>{(Object.entries(item)[0][0])}</p>
              </div>
              <div className="table-cell">
                <p>{Object.entries(item)[0][1]}</p>
              </div>
            </div>
           })}
            
          </div>




          <div className='health-btn-container'>
          <button className='health-button' onClick={() => navigate("/contact-us")}>Enquiry Now</button>
            {/* <button className='health-button'>DOWNLOAD BROCHURE</button>
            <button className='health-button'>SPEAK TO AN EXPERT</button>
            <button className='health-button'>BUY NOW</button> */}
          </div>
        </div>
        <div className='health-column'>
        <InsuranceForm  product = {params.third} />
        </div>
      </div>

      {/* <div className='health-part-two'>
            <p>HOW TO BUY HEALTH INSURANCE POLICY</p>
            <div>
            <InsuranceSteps />
            </div>
      </div> */}

      {/* <div className='health-part-three'>
      <p>{data[params.third].title.toUpperCase()} PLAN OVERVIEW</p>
      <TableSection  data={data[params.third].plan_overview}/>
      </div> */}

      {/* <div className='health-part-four'>
      <p>BENEFITS OF BUYING {data[params.third].title}</p>
      <HealthBenifits stepsData={data[params.third].benefits_buying} />
      </div> */}

      {/* <div className='health-part-five'>
      <p>ELIGIBILITY CRITERIA FOR {data[params.third].title}</p>
      <EligibilityCriteria data={data[params.third]["eligibility_criteria"]} />
      </div> */}

      {/* <div className='health-part-six'>
      <p>STEPS TO BUY THE RIGHT HEALTH INSURANCE POLICY</p>
      <StepsToBuyHealthInsurance />
      </div> */}
      {/* <div className='health-part-seven'>
      <p>HEALTH INSURANCE COMPANIES</p>
      <Partners />
      </div> */}

      <div className='health-part-seven'>
      <p>EXPLORE OTHER INSURANCE</p>
      <BuyInsuranceSteps />
      </div>


    </div>
    <FooterNew />
    </div>
  );
};

export default LifeInsurance;
