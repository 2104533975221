import { InputComponent } from "../../../components/input-component/InputComponent"
import { updateFirstPageData, paymentRedirect, paymentRedirectGPP, paymentRedirectGift, paymentRedirectSJB, paymentRedirectROP, paymentRedirectTATA, paymentRedirectMRS, lifeIciciIpsEbiPdf, lifeIciciSjbEbiPdf, lifeIciciRopEbiPdf, lifeTataSISPdf,lifeTataMrsSISPdf, paymentRedirectHDFC, HdfcLifePlusQuote, paymentRedirectHDFCPlus, HdfcLifeQuote, lifeEnquiryAPI } from '../../../Redux/LifeRedux';
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import "./skeleton.css"
import { pincodeDataHandler } from "./pincode-handler";
import { validationHandler } from "./handlerValidation";
import { ErrorPop } from "./errorHandler";
const moment = require('moment');


export const SkeletonCardMobile = ({title, item}) => {
    // const ebiAllowedSlugs = ['life-icici', 'life-icici-sjb', 'life-icici-rop' ]

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [ formdata, setFormdata ] = useState({});
    const combinedRedux = useSelector((state) => state.life.combinedRedux);
    const [error, setError] = useState('')

    const [firstName, setFirstName] = useState(combinedRedux?.userDetail?.firstName ? combinedRedux?.userDetail?.firstName : '');
    const [middleName, setMiddleName] = useState(combinedRedux?.userDetail?.middleName ? combinedRedux?.userDetail?.middleName : '');
    const [lastName, setLastName] = useState(combinedRedux?.userDetail?.lastName ? combinedRedux?.userDetail?.lastName : '');
    const [gender, setGender] = useState(combinedRedux?.userDetail?.gender);
    const [dob, setDob] = useState(moment(combinedRedux?.userDetail?.dob).format('DD-MM-YYYY'));
    const [smoking, setSmoking] = useState(combinedRedux?.userDetail?.isTobbaco === true ? 'yes' : 'no');
    const [annualIncome, setAnnualIncome] = useState(combinedRedux?.userDetail?.annualIncome);

    const [email, setEmail] = useState(combinedRedux?.userDetail?.email ? combinedRedux?.userDetail?.email : '');
    const [mobile, setMobile] = useState(combinedRedux?.userDetail?.phone ? combinedRedux?.userDetail?.phone : '');

    const [state, setState] = useState(combinedRedux?.userDetail?.state ? combinedRedux?.userDetail?.state : '');
    const [city, setCity] = useState(combinedRedux?.userDetail?.city ? combinedRedux?.userDetail?.city : '');
    const [pincode, setPincode] = useState(combinedRedux?.userDetail?.pincode ? combinedRedux?.userDetail?.pincode : '');
    const [buyNowClicked, setBuyNowClicked] = useState(false);
    const [cityDropdown, setCityDropdown] = useState([city]);
    const ebiAllowedSlugs = ['life-icici', 'life-icici-sjb', 'life-icici-rop' ]

    const queryParameter = new URLSearchParams(window.location.search);
    const enquiryIds = queryParameter.get("enquiryId");

    // populate data using pincode
    const pincodeHandler = (e) => {
        pincodeDataHandler(e, setPincode, setState, setCityDropdown, setCity);
    };

    // for user data
    const userData = JSON.parse(localStorage.getItem('userDetails'))

    // for error handling
          const handleValidation = () => {
            return validationHandler({
                firstName,
                middleName,
                lastName,
                email,
                mobile,
                state,
                city,
                pincode,
                setError
            });
        };


    // for the error alert 
    const ErrorPopups = ErrorPop(error);

    //Error handling End
        const obj = {
            enquiryId: combinedRedux?.enquiryId ? combinedRedux?.enquiryId : enquiryIds,
            planType: combinedRedux?.planType,
            userDetail : {
                firstName: firstName,
                middleName: middleName,
                lastName: lastName,
                gender: gender,
                dob: combinedRedux?.userDetail?.dob,
                isTobbaco: smoking,
                annualIncome: annualIncome,
                email: email,
                phone: mobile,
                state: state,
                city: city,
                pincode: pincode
            },
              interestedPolicy : {
                slug: item?.slug,
                companySlugName: item?.sluginfo?.name,
                slugImage: item?.sluginfo?.logo,
                policyTerm: item?.policyTerm ? item?.policyTerm : null,
                sumAssured: item?.sumAssured ? item?.sumAssured : null,
                premiumPaymentTerm: item?.premiumPaymentTerm ? item?.premiumPaymentTerm : null,
                paymentFrequency: item?.mode ? item?.mode : null,
                premium: item?.totalPayableAmount ? item?.totalPayableAmount : null,
                appNumber: (item.slug === "life-hdfc" || item.slug === "life-hdfc-plus") && item.hdfc_appnum

            }
        }


    // EBI PDF
    const handleEbiPdf = () => {
        const validationError = handleValidation();
        if (validationError) {
            alert(validationError); // Show alert with the error message
            return;
        }

        const ebiPdfMethods = {
        'life-icici': lifeIciciIpsEbiPdf,
        'life-icici-sjb': lifeIciciSjbEbiPdf,
        'life-icici-rop': lifeIciciRopEbiPdf,
    };
    dispatch(updateFirstPageData(obj)).then((res) => {
    if (res?.payload) {
        const selectedEBI = ebiPdfMethods[item.slug];

    if (selectedEBI) {
    // based on the slug it dispatch
        dispatch(selectedEBI({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
            if (res?.payload?.data?.EBIPdfDigital) {
                window.open(res?.payload?.data?.EBIPdfDigital, '_blank')
                window.location.reload()
                // window.location.href = res?.payload?.data?.EBIPdfDigital;
            }
        });
    }
}
});
}

// handle pdf for tata product
const handleTataPdf = () => {
            
    const validationError = handleValidation();
        if (validationError) {
            alert(validationError); // Show alert with the error message
            return;
        }

     const sisPdfMethods = {
        'tata-life-srp': lifeTataSISPdf,
        'tata-mrss': lifeTataMrsSISPdf,
    };

    dispatch(updateFirstPageData(obj)).then((res) => {
    if (res?.payload) {
        const selectedEBI = sisPdfMethods[item.slug]
    if (selectedEBI) {
    // based on the slug it dispatch
        dispatch(selectedEBI({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
    // Open the PDF in a new tab or window
         window.open(res?.payload?.data, '_blank');
         window.location.reload()
        });
    }
  };
});
}

    
    const handleContinue = (e) => {
        localStorage.setItem('redirectAfterLoginURL', window.location.href);
        // for get auth token
        const isAuthTokenExist = localStorage.getItem('Authorization')
        if (!isAuthTokenExist || isAuthTokenExist === 'null') {
            navigate('/signin-signup')
            return;
        }

    setBuyNowClicked(true);

      const validationError = handleValidation();
        if (validationError) {
            alert(validationError); // Show alert with the error message
            return;
        }


        dispatch(updateFirstPageData(obj)).then((res) => {
            if (res?.payload) {

        // For TATA AIA
        if (item.slug === 'tata-life-srp') {
            dispatch(paymentRedirectTATA({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
                const form = document.createElement('form');
                form.method = 'POST';
                form.target = '_top';
                form.action = process.env.REACT_APP_TATA_URL;
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = "REQUEST";
                input.value = res.payload.data
                form.append(input);
                document.body.appendChild(form);
                form.submit();
            }    
         );
        }

        // FOR TATA MRS
           if (item.slug === 'tata-mrss') {
            dispatch(paymentRedirectMRS({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
                const form = document.createElement('form');
                form.method = 'POST';
                form.target = '_top';
                form.action = process.env.REACT_APP_TATA_URL;
                const input = document.createElement('input');
                input.type = 'hidden';
                input.name = "REQUEST";
                input.value = res.payload.data
                form.append(input);
                document.body.appendChild(form);
                form.submit();
            }    
         );
        }

          if (item.slug === 'life-hdfc') {
         dispatch(lifeEnquiryAPI({ enquiryId: enquiryIds })).then((res) => {
         dispatch(HdfcLifeQuote(res.payload.data)).then((res) => {
            dispatch(paymentRedirectHDFC({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
                    if (res?.payload?.data) {
                        window.location.href = res?.payload?.data;
                    }
                }
                );
            });
         })
        } else if (item.slug === 'life-hdfc-plus') {
            dispatch(lifeEnquiryAPI({ enquiryId: enquiryIds })).then((res) => {
            dispatch(HdfcLifePlusQuote(res.payload.data)).then((res) => {
            dispatch(paymentRedirectHDFCPlus({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
                    if (res?.payload?.data) {
                        window.location.href = res?.payload?.data;
                    }
                }
                );
            });
            })
        }


        const paymentMethods = {
        'life-icici': paymentRedirect,
        'life-icici-GPP': paymentRedirectGPP,
        'life-icici-gift': paymentRedirectGift,
        'life-icici-sjb': paymentRedirectSJB,
        'life-icici-rop': paymentRedirectROP,
    };
    // payment method is an object which have slug as key and function as value
    const selectedPaymentMethod = paymentMethods[item.slug];
    // if (selectedPaymentMethod)  have "life-icici" in slug then it will go to "paymentRedirect" function in lifeRedux.js file

    if (selectedPaymentMethod) {
    // based on the slug it dispatch
        dispatch(selectedPaymentMethod({ enquiryId: combinedRedux?.enquiryId })).then((res) => {
             const mainData = res?.payload?.data;
            dispatch(updateFirstPageData({enquiryId: combinedRedux?.enquiryId, "interestedPolicy.appNumber":mainData?.transID})).then((res) => {
                    if (mainData?.URL) {
                            window.location.href = mainData?.URL;
                    }
            });
        });
    }
            }
        });
    }	



    return (
        <div className="modelData">
                {/* <CloseBtn src={CloseImg} onClick={()=>navigate(-1)}/> */}
                <div className="slugData">
                    <img src={item?.sluginfo.logo} />
                    <p className="slugName">{item.sluginfo.name}</p>
                </div>
                <div className="slugLine-mobile"></div>
                <p className="title">{title}</p>
                <div className="slugLine-mobile"></div>
                    <>
                        <div className="grid-2">
                        <div className = "lineDetails">
                        <p className="details">Customer Details</p>
                        </div>
                        <p style = {{fontWeight:'bold'}}>Basic Information :</p>
                        <div style={{display:'flex', gap:'10px'}}>
                        <InputComponent onChange={(e) => {
                                setFirstName(e);
                            }} placeholder={"First Name"} value={firstName} />
                             {error.hasOwnProperty('education') ? (
                                <ErrorPopups error={error.education} />
                            ) : null}
                        <InputComponent onChange={(e) =>{setMiddleName(e)}} placeholder={"Middle Name"} value={middleName} />
                        </div>
                        <div style={{display:'flex', gap:'10px'}}>
                             <InputComponent onChange={(e) =>{setLastName(e)}} placeholder={"Last Name"} value={lastName} />
                            <InputComponent disabled onChange={(e) =>{setDob(e)}} placeholder={'DOB'} value={dob} type={Date}  />
                        </div>
                        <div style={{display:'flex', gap:'10px'}}>
                        <InputComponent disabled value={gender} onChange={(e) =>{setGender(e)}} placeholder={"Gender"}
                            dropdown={['male','female']}
                             />
                        <InputComponent disabled onChange={(e) =>{setSmoking(e)}} placeholder={'Smoking Status'} value={smoking}
                            dropdown={['yes','no']} 
                             />
                        <InputComponent onChange={(e) =>{setAnnualIncome(e)}} placeholder={'Annual Income'} disabled value={annualIncome} />
                        </div>
                        <p style = {{fontWeight:'bold'}}>Contact Details : </p>
                        <div style={{display:'flex', gap:'10px'}}>
                        <InputComponent onChange={(e) =>{setEmail(e)}} placeholder={'Email'} value={email} />
                        <InputComponent onChange={(e) =>{setMobile(e)}} placeholder={'Mobile'} value={mobile} type={'number'} />   
                        </div>
                   
                        <p style = {{fontWeight:'bold'}}>Address :</p>
                        <div style={{display:'flex', gap:'10px'}}>
                        <InputComponent onChange={(e) =>{pincodeHandler(e)}} placeholder={'Pin Code'} value={pincode} type={'number'} inputType={'pincode'} />
                        <InputComponent onChange={(e) =>{setCity(e)}} placeholder={'City Name'} value={city} dropdown={cityDropdown} disabled={pincode.length !== 6} />
                        <InputComponent value={state} onChange={(e) =>{setState(e)}} placeholder={"State"} disabled={pincode.length !== 6}/>
                        </div>

                        </div>
                    </>


                {ebiAllowedSlugs.includes(item.slug) ? (
                <div className="main-action">
                    <button className="changes" onClick={() => handleContinue()}>Buy Now ₹{item.totalPayableAmount.toLocaleString('en-IN', { maximumFractionDigits: 2 })} </button> 
                      <button className="detailModal__header__btn" onClick={handleEbiPdf}>EBI PDF</button>
                </div>
                ) : (
                    <div className="main-action">
                    <button className="changes" onClick={() => handleContinue()}>Buy Now ₹{parseInt(item.totalPayableAmount).toLocaleString('en-IN', { maximumFractionDigits: 2 })} </button> 
                      <button className="detailModal__header__btn" onClick={handleTataPdf} disabled = {buyNowClicked}>SIS PDF</button>
                </div>
                )}  
            </div>     
    )
}