import styled from "styled-components";


export const SlotContainer = styled.div`
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    padding: .4rem 1rem;
    display: flex;
    width: 90%;
    justify-content: space-between;
    align-items: center;
    margin: .8rem;

    @media screen and (max-width:1100px) {
        display: grid;
        grid-template-columns: repeat(3,1fr);
    }

    @media screen and (max-width:820px) {
        display: grid;
        grid-template-columns: repeat(2,1fr);
    }

    @media screen and (max-width:640px) {
        display: grid;
        grid-template-columns: repeat(1,1fr);
        margin: auto !important;
        padding: 1.5rem;
        margin: .5rem auto !important;
    }

`

export const RowAndDivider = styled.div`
    padding-right: 2rem;
    border-right: 1px solid rgba(151,151,151,0.3);
    margin: .6rem 1rem;
    h5 {
        color:#605F5E;
        opacity: .6;
        font-weight: 800;
        text-decoration-line: underline;
    }
    width: 200px;

    @media screen and (max-width:640px) {
        margin: auto !important;
        border: none;
        text-align: center;
        padding: 0;
        justify-content: center !important;
        text-align: left;
    }

`
export const Row = styled.div`
    padding-right: 2rem;
    margin: .6rem 1rem;
    h5 {
        color:#605F5E;
        opacity: .6;
        font-weight: 800;
        text-decoration-line: underline;
    }
    width: 180px;

    @media screen and (max-width:640px) {
        margin: auto !important;
        border: none;
        text-align: center;
        padding: 0;
        justify-content: center !important;
        text-align: left;
        width: 200px;
    }

`

export const HDiv = styled.div`
    display: flex;
    flex-direction: row;
    p {
        margin: 0;
        text-transform: uppercase;
        font-weight: 800;
    }

    input {
        accent-color: #F6931E;
        color:#fff;
    }

    @media screen and (max-width:640px) {
        /* justify-content: center; */
        ${'' /* text-align: left; */}
    }

`

export const EditBtn = styled.img`
    margin-left: .4rem;
`
export const Terms = styled.label`
    user-select: none;
    text-transform: none !important;
    font-weight: 400 !important;
    color: #605F5E;
    letter-spacing: .07ch;
    margin-left: .5rem !important;
    margin-top: .1rem !important;
`

export const ContinueBtn = styled.button`
    background-color: #F6931E;
    padding: .6rem;
    width: 150px;
    border: none;
    border-radius: 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 3rem;
`
export const ContinueBtnDisable = styled.button`
    background-color: gray;
    padding: .6rem;
    width: 150px;
    border: none;
    border-radius: 1.8rem;
    font-size: 1rem;
    cursor: pointer;
    margin-top: 1rem;
    margin-bottom: 3rem;
`

export const Layout = styled.div`
    border: .5px solid #B7B7B7;
    margin: 2rem;
    background-color: #fff;
    padding: 1rem;
    display: flex;
    align-items: center !important;
    justify-content: center;
`

export const Opacity = styled.div`
    width: 100vw;
    height: ${document.body.scrollHeight}px;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: .6;
    z-index: 200;
`
export const MainContainer = styled.div`
    /* position: relative; */
`

export const PopupContainer = styled.div`
    border-radius: 10px;
    padding: 1rem 2rem;
    border: 1px solid #B7B7B7;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 220;
    top: 200px;
    background-color: #fff;
    padding-top: 1.7rem;
    padding-bottom: 1.8rem;

    .prompt {
        font-weight: 800;
        font-size: 20px;
        color:#605F5E;
    }
    .flex {
        display: flex;
    }
    .apart {
        justify-content: space-between;
    }
    button {
        width: 45%;
        padding: 0.6rem 1rem;
        border-radius: 25px;
        border: 1px solid #B7B7B7;
        background-color: #fff;
        color:#605F5E !important;
        text-transform: uppercase;
        font-size: 16px;
        cursor: pointer;

        &:hover {
            border-color: #F6931E;
        }
    }

`

export const CloseIcon = styled.img`
    position: absolute;
    top: 10px;
    right:10px;
    cursor: pointer;
`


export const InputContainer = styled.div`
    position: relative;
    padding: .6rem;
    margin: 1rem auto;
    border: 1px solid #B7B7B7;
    border-radius: 10px;
    input {
        width: 100%;
         ${'' /* padding-top: .2rem; */}
         ${'' /* padding-bottom: .2rem; */}
        outline: none;
        border: none;
        font-size: 1rem;
        margin-left: .2rem;
        background:none;
        margin-top:.3rem;
    }

    h4 {
        font-size: 1.4rem;
        font-weight: 500;
    }

    .m-0 {
        margin-top: 0;
        h4 {
            margin: auto;
        }
    }
    .m-0-0 {
        margin: 0;
    }

    .horizontal {
        display: flex;
        align-items: center;
        input {
            width: auto;
            accent-color: #F6931E;
        }
        label {
            margin-left: .5rem;
        }

        .apart+.apart {
            margin-left: 1rem;
        }

    }
    
`

export const FloatingTitle = styled.p`
    position: absolute;
    top: -8px;
    padding-left: .5rem;
    padding-right: .5rem;
    background-color: #fff;
    font-weight: 800;
`
export const SelectDropdown = styled.select`
    width: 100%;
    padding-top: .35rem;
    padding-bottom: .35rem;
    // outline: dotted;
    // padding: 0.3rem;
    // border-radius: .6rem;
    font-size: 1rem;
    // border: 1px solid #B7B7B7;
    // text-shadow: 0 0 0 #000;
    border: none;
    background-color: transparent;
    resize: none;
    outline: none;
`