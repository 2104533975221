import styled from "styled-components";
import { styles } from "../../../assets/common-styles/common-styles";

export const MainContainer = styled.div`
    /* padding-top: 3rem; */
    position: relative;
    
    /*@media screen and (max-width: 800px) {
        padding-top: 5rem;
    } */
    

    // antd neutraliser styles

    p, h4 {
        margin: 0 ;
    }

    // general classes

    .flex {
        display: flex;
    }
    .flex-toggler {
        display: none;
    }
    .apart {
        justify-content: space-between;
    }
    .align-center {
        align-items: center;
    }
    .details1{
        width:170px
    }
    .column {
        flex-direction: column;
    }
    .c-g{
        column-gap:23px;
    }
    .c-g4{
        column-gap:3rem
    }
    .j-center {
        justify-content: center;
    }
    .p-1 {
        padding: 1rem;
    }
    .mt-1 {
        margin-top: 1.5rem !important;
    }
    .relative {
        position: relative;
    }
    .mt-half {
        margin-top: .85rem !important;
    }
    .gtc-4 {
        grid-template-columns: repeat(4,1fr);
    }
    .gtc-3 {
        grid-template-columns: repeat(3,1fr);
    }
    .gtc-2 {
        grid-template-columns: repeat(2, 1fr);
    }
    .gtc-1 {
        grid-template-columns: repeat(1,1fr);
    }
    .bg-w {
        background-color: #fff !important;
    }
    .pr-1 {
        padding-right: 10rem !important;
    }
    .mb-1 {
        margin-bottom: 1rem;
    }


    .prev {
        display: block;
    }
    .pric1{
        margin-right:-1rem
    }
    .downloadPdf {
        width: '40px'
    }
    .download {
        border-radius: 8px;
        padding: 0.5rem;
        box-shadow: 0px 0px 18px -6px rgba(0,0,0,0.15);
        align-items: center;
        display: flex;
        flex-direction: column;
        height: 65px;
        background-color: #fff;
    }
    .posName {
        display:flex;
        margin-bottom: .5rem;
    }
    .posName h4{
    font-size: 1rem;
    font-weight: 600;
    margin-left: 1rem !important;
    color: #605F5E;
    font-weight: normal;
    }

    .icons {
        cursor: pointer;
    }

    .buy-policy {
        margin-right: 1rem;
    }

    .download-text {
        color: #333333;
        opacity: .7;
        text-transform: uppercase;
        font-size: .65rem;
        margin-top: .41rem !important;
    }
    .title {
        font-weight: 700;
        color: #333333;
        font-size: 1.5rem;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        margin-left: 2.5rem;
    }
    

    @media screen and (max-width: 980px) {
        .main-container {
            display:flex;
            flex-direction:column;
            {
                .right{
                    .mb-1{
        flex-direction:column;
    }
    
    .search{
        justify-content:space-between;
        ${'' /* min-width:270px; */}
    }
    .add-rm{
        width:7rem;
        height:3rem;
        
    }
                } 
            }
    }
    
        }
    
        @media screen and (max-width: 600px) {
            .right{
                .right-inner{
                  flex-direction:column;
                }
                .align-center .apart{
                   
                    margin-top:2rem;
                }

            }
        }

    .left {
        // flex: 1;
        padding: 1rem;

    }
    .right {
        flex: 3;
        background-color: #F5F5F9;
        /* height: 100vh; */
        padding: 1rem;
        padding-right: 2rem;
    }

    .brand-logo {
        width: 160px;
    }
    .brand-container {
        margin-bottom: .5rem;
    }

    
    .separator {
        background-color: #41015F;
        opacity: .12;
        margin-top: .5rem !important;
        margin-bottom: .5rem !important;
    }
    

.tabs-container {
    display: flex;
    justify-content: space-around;
    border-radius: 18px;
    margin: 1.5rem auto 1.95rem;
    width: 80%;
    background-color: #F5F5F9;
    color: #F6931E;
    padding: 0.5rem;
}
.tabs-container-two {
    display: flex;
    justify-content: space-around;
    border-radius: 18px;
    margin: 1.5rem auto 1.95rem;
    width: 80%;
    background-color: #ffffff;
    color: #F6931E;
    padding: 0.5rem;
}

.tab-item {
    flex: 1;
    padding: 1rem;
    border-radius: 18px;
    text-align: center;
    cursor: pointer;
    transition: background-color 0.3s, color 0.3s;
    margin: 0 0.5rem;
    background-color: #f8f8f8ab;
}

.tab-item:hover {
    background-color: rgba(246, 147, 30, 0.1);
}

.selected-tab {
    background-color: #F6931E;
    color: #fff;
}



    .grid {
        display: grid;
    }

    .tracker-cards-container {
        /* display: grid; */
        grid-template-columns: repeat(5,1fr);
        gap: 1rem;

        /* display: flex; */
        gap: 1rem;
        
        @media screen and (max-width: 1300px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (max-width: 1180px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width: 1111px) {
            grid-template-columns: repeat(2, 1fr);
            /* .tracker-card {
                margin: auto;
            } */
        }
        @media screen and (max-width: 937px) {
            grid-template-columns: repeat(1, 1fr);
            /* .tracker-card {
                margin: auto;
            } */
        }

    }
    .tracker-card {
        border-radius: 8px;
        background-color: #fff;
        padding: 0.8rem;
        p {
            color: #333333;
        }
        min-width: 200px;
        height: 230px;
    }
    

    .right {
        .t-2 {
            margin-top: 2rem;
        }
        .t-3 h4{
            margin-top:2.8rem;
            font-weight:700;
        }
        .det1{
            font-weight:500;
        }
    }
    ul {
        margin: 0;
    }
    .entries-container {
        background-color: #fff;
        ${'' /* border-radius: 8px 8px 0 0 ; */}
        list-style-type: none;
        // text-transform: uppercase !important;
        text-align: left;
        font-weight: 600;
        color: #605F5E;
        ${'' /* column-gap:44px; */}
    }
    .entries-container1 {
        background-color: #fff !important;
        ${'' /* border-radius: 8px 8px 0 0 ; */}
        list-style-type: none;
        // text-transform: uppercase !important;
        text-align: left;
        font-weight: 600;
        color: #605F5E;
        justify-content: space-between;
        ${'' /* column-gap:44px; */}
    }



    .fit_content{
        width: 100%;
        background-color: #ff3300 !important;
        ${'' /* height:100%; */}
        background-color: #fff !important;
        text-align: center;
        .fit_heading{
            text-align: center;
            p{
                padding: 0.7rem;
                font-size: 13px;
            }
        }
        .list_content{
            padding: 0.7rem;
            .profile_view{
                border: none;
                background-color: white;
                cursor: pointer;
            }
        }
        .clickable{
            text-decoration: underline;
            color: blue;
            cursor: pointer;
        }
    }
    .show_option{
        list-style: none;
    }
    .pop_container{
        box-shadow: box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        
    }



    .button_modal{
        background: white;
        border:none;
    }
    .modalPos {
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}
    .modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0,0,0);
  background-color: rgba(0,0,0,0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 70%;
  height: 240vh;
  display: flex;
  align-item: center;
  justify-content: center;
  border-radius: 10px;
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}
.inside_box{
    width: 95%;
    height:223vh;
    margin-top: 70px;
    box-shadow: rgba(149, 157, 165, 0.5) 0px 8px 24px;
    border-radius:10px;
    }
.content_popup{
    width:92%;
    margin:auto;
}
    .popup_head{
        display:flex;
        justify-content: space-between;
        h4{
        font-weight: 600;
        color:orange;
        text-decoration: underline;
    }
    }
    .heading{
            font-weight:800;
            color:#41015f;
            display:flex;
        }
    .div_class{
            box-shadow: box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }

        .button_status{
            border:none;
            background-color: white;
        }
        .profile_div{
            display: flex;
            align-item: center;
            justify-content: center;
        }
.profile_photo{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-top: -50px;
}

.rm_right{
    div{
        display:flex;
        justify-content: space-between;
        h3{
            span{
                font-weight:700;
            }
        }
    }
}

.rm_details{
    display:flex;
    margin-bottom: 30px;
}

.details_front{
    width:50%;
    div{
        h3{
            display:flex;
            margin-top: 10px;
        }
        img{
            width: 21rem;
            height:14rem;
            border-radius:10px;
            display:flex;
        }
    }
}
.details_back{
    width: 50%;
    div{
        h3{
            display:flex;
            margin-top: 10px;
        }
        img{
            width: 21rem;
            height:14rem;
            border-radius:10px;
            display:flex;
        }
    }
}
.table_body{
    overflow-x:auto;
    background-color:white;
}
.back{
    cursor:pointer;
}


.accept_reject{
    margin-top: 10px;
    display: flex;
    align-item: center;
    justify-content: center;
    button{
        width: 190px;
        height: 50px;
        border-radius: 10px;
        font-weight: 600;
        margin-left: 20px;
        cursor: pointer;
        p{
            color: orange;
            background-color: white
        }
    }
    .reject{
        border: 1px solid orange;
        background-color: white;
        p{
            color:orange;
        }
    }
    .approve{
        border: none;
        background-color: orange;
        p{
            color: white;
            background-color:orange;
        }
    }
}


.popUp_line{
    width: 90%;
    margin:auto;
    background: black;
    padding-bottom: 1px;
}









    .rm_container{
        width:700px;
        display: flex;
        align-item: center;
        justify-content: center;
        height: 800px;
    }
    .drop{
        width:122px;
    }
    .css-1xc3v61-indicatorContainer{
        padding:2px !important;
    }
    .css-1jqq78o-placeholder{
        text-wrap:nowrap;
    }

  .pl-1{
    padding-left:10px
  }
  .t-m2 {
        text-align: center ;
        width: 117px !important;
        p {
            padding: .7rem ;
            font-size: 13px;
            word-wrap:break-word;
        }
  }

    .t-l {
        text-align: left ;
        width: 117px !important;
        p {
            padding: .7rem ; 
            font-size: 13px;
            word-wrap:break-word;
        }
        .pos_name{
        
    }
    .price{
        width:170px;
        text-align:center;
    }
    .pric{
     margin-left:1rem
    }
    .c-r{
        text-align:right;
    }
    .t-c{
        text-align:center
    }
    .m-1{
        margin-left:1rem
    }
    }
    .m-1 p{
        text-align:center !important;
        margin-left:.2rem
    }
    .lead{
        display:flex;
        ${'' /* justify-content:center; */}
        align-items:center;
        column-gap:.2rem;
    }
    .total-lead{
        background:rgb(239, 239, 239);
        padding:.4rem;
    }
    .t-m{
        text-align: left ;
        width: 95px !important;
        p {
            padding: .7rem ;
            font-size: 13px;
        }
    }
    .t-e {
        text-align: left !important;
        width: 150px !important;
        p {
            padding: .7rem ;
            font-size: 13px;
        }
        word-wrap: break-word;
    }
    .t-f{
        text-align: left !important;
        width: 130px !important;
        p {
            padding: .5rem ;
            font-size: 13px;
        }
        word-wrap: break-word;
    }
    .data-list {
        /* background-color: rgb(215, 215, 215,.5); */
        list-style-type: none;
        // text-transform: uppercase !important;
        color: #605F5E;

        p {
            text-align: left;
        }
        
    }
    .pk{
        margin-left:-2rem;
    }
    .pol{
        column-gap:30px;
    }
    .expired {
        text-transform: none;
        color: #FF3E1D;
    }
    .dropdown-select-rm{
        padding: 0.2rem;
        border: none;
        outline: none;
        border-radius: 8px;
        width: 126px;
        margin-left:0;
    }
    .dropdown-select-rm{
        padding: 0.2rem;
        border: none;
        outline: none;
        border-radius: 8px;
        width: 126px;
        margin-left:0;
    }
    .dropdown-select-com{
        padding: 0.2rem;
        border: none;
        outline: none;
        border-radius: 8px;
        width: 220px;
        margin-left:17px;
    }
    .ps{
        background:#f1f1f1
    }
    .ps .css-13cymwt-control{
        background:#f1f1f1;
    }
    .fil{
        color:  black !important;
        margin-top: 1rem !important;
        width:87px !important
    }
    .dropdown-select {
        padding: 0.8rem;
        border: none;
        outline: none;
        border-radius: 8px;
        width: 200px;
        font-weight: 600;
        margin-right: 1rem;
        
        option {
            font-weight: 600;
        }
        .active{
            color: green;
        }
        .inactive{
            color: red;
        }
        .pending{
            color: orange;
        }
    }
    .f-w600{
        font-weight: 600;
    }
    .dropdown-select-date{
        padding: 0.4rem;
        border: none;
        outline: none;
        border-radius: 8px;
        width: 150px;
        font-weight: 500;
        margin-right: 1rem;
        background: antiquewhite;
    }
    .clear {
        color: #41015F;
        text-decoration-line: underline;
    }
    .menu-icons {
        width: 25px;
    }
    .menu-icons-container {
        position: absolute;
        right:-15px;
        bottom: 40%;
    }
    .view-details-icon {
        margin-left: .3rem;
    }
    .search-box {
        padding: 0.8rem;
        border-radius: 8px;
        box-shadow: 0px 0px 22px -9px rgba(0,0,0,0.2);
        background-color: #fff;
        height: 64px;
        ${'' /* width: 30vw; */}
    }
    .search-img {
        width: 22px;
        cursor: pointer;
    }
    .search-input {
        border: none;
        outline: none;
        margin-left: 1rem;
    }
    .arrow-down-dash {
        transform: rotate(90deg);
    }
    .arrow-left-dash {
        transform: rotate(180deg);
    }
    .arrow-up-dash {
        transform: rotate(-90deg);
    }
    .faded {
        opacity: .5;
    }

    .title-container {
        margin-left: -2.2rem;
    }
    .title-containers {
        display: none
    }
    .index {
        font-weight: 700;
        color: #666666;
        font-size: 1rem;
        margin: 2rem auto !important;
    }
    .button_addrmpos{
        display:flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: .5rem;
        h3{
        font-weight: 700;
        color: #666666;
        font-size: 1rem;
    }
        button{
            background-color: #41015f;
            border:none;
            width: 13rem;
            height:3rem;
            font-weight: 600;
            border-radius: 0.8rem;
        }
    }

`

export const ProfileMainContainer = styled.div`
    border-radius: 8px;
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    .mr-1 {
        margin-right: 1rem;
    }
    .ml-1 {
        margin-left: 1rem;
    }
    .mt-3 {
        margin-top: 3rem;
    }

    .input-container {
        position: relative;
        border-radius: 8px;
        border: 1px solid #B7B7B7;
        flex:1;
        /* width: 100%; */
        padding-top: 0.3rem;
        padding-bottom: 0.5rem;
        padding-left:.5rem ;
        padding-right: 0.5rem;
    }
    .float-p {
        color: #6F6F6F;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: .2rem;
    }
    .input-box {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
    }
    .pencil-icon {
        width: 23px;
        height: 23px;
        position: absolute;
        right: 12px;
        top: 17px;
        cursor: pointer;
    }

    @media screen and (max-width: 1050px) {
        padding: 0.6rem;
        .flex {
            flex-direction: column;
        }
        .ml-1, .mr-1 {
            margin: 0;
        }
        .input-container {
            margin-bottom: .8rem;
        }
    }

`
export const UserImgContainer = styled.div`
    position: relative;
    margin: auto;
    width: max-content;
    .edit-svg {
        position: absolute;
        bottom: 0;
        right: 5px;
        cursor: pointer;
    }

`
export const SaveBtn = styled.button`
    background-color: ${styles.layout.themeColor};
    border-radius: 9px;
    border: none;
    width: 170px;
    padding: .8rem;
    margin: auto;
    margin-top: 1.75rem;
    cursor: pointer;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: .2ch;
`

export const HDiv = styled.div`
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
    margin: .5rem auto;
    align-self: center;

    div {
        flex:1;
        // margin: 0 .5rem;
    }

    hr {
        /* transform: rotate(90deg); */
        height: 50px;
        margin-top: .3rem;
        opacity: .3;
    }

`

export const PopupContainer = styled.div`
    border-radius: 10px;
    padding: 1rem 2rem;
    border: 1px solid #B7B7B7;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 220;
    /* top: 200px; */
    top: 58%;
    background-color: #fff;
    padding-top: 1.7rem;
    padding-bottom: 1.8rem;
    width: 60%;

    @media screen and (max-width: 1146px) {
        width: 80%;
    }
    @media screen and (max-width: 902px) {
        width: 85%;
    }
    @media screen and (max-width: 850px) {
        width: 90%;
    }

    @media screen and (max-width: 804px) {
        top: 70%;
    
        .res-flex {
            flex-direction: column;
        }
        .res-mb-1 {
            margin-bottom: .6rem !important;
        }

    }

    @media screen and (max-width: 465px) {
        top: 90%;
    }

    p {
        margin: 0;
    }

    .flex {
        display: flex;
    }
    .apart {
        justify-content: space-between;
        
    }
     .beautiful-button {
    background: linear-gradient(45deg, #6a11cb, #2575fc); /* Gradient background */
    color: white; /* Text color */
    border: none; /* Remove default border */
    border-radius: 30px; /* Rounded corners */
    padding: 1px 17px;
    font-size: 15px;
    font-weight: bold; /* Bold text */
    cursor: pointer; /* Pointer cursor on hover */
    transition: all 0.3s ease-in-out; /* Smooth transition for hover effects */
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2); /* Subtle shadow */
}

.beautiful-button:hover {
    background: linear-gradient(45deg, #2575fc, #6a11cb); /* Reverse gradient on hover */
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3); /* Enhanced shadow on hover */
    transform: translateY(-2px); /* Slight lift effect */
}
    .top {

    }

    .brand-img {
        border-radius: 50%;
        width: 50px;
        height: 50px;
        margin-right: .4rem;
    }
    .basics {
        color: #F6931E;
    }
    .data {
        color: #605F5E;
        font-weight: 700;
        font-size: 14px;
    }
    .brand-name {
        font-size: 15px;
    }
    .price-btn {
        background-color: #F6931E;
        border-radius: 10px;
        padding: 0.4rem;
        border: none;
        cursor: pointer;
    }

    .cards {
        padding: 1rem;
        box-shadow: 0px 0px 27px -11px rgba(0,0,0,0.4);
        border-radius: 10px;
    }
    .mt-1 {
        margin-top: 1rem;
    }
    .head {
        font-size: 17px;
        font-weight: 800;
        color: #2B003F;
    }
    .sub-head {
        color: #605F5E;
        opacity: .6;
        font-weight: 800;
        text-decoration-line: underline;
    }
    .discount {
        color: #46B39A;
    }
    .total {
        font-size: 16px;
        color:#000;
        font-weight: 800;
    }
    .total-amount {
        font-size: 17px;
        color:#000;
        font-weight: 800;
    }
    .remove {
        color: #F6931E;
        font-weight: 700;
        margin-left: .3rem;
    }
    .align-center {
        align-items: center;
    }
    .tick-icon {
        margin-right: .35rem;
    }
    .mb-1 {
        margin-bottom: .5rem;
    }
    .info {
        margin-right: .35rem;
    }
    .mb-half {
        margin-bottom: .5rem;
    }
    .learn-more {
        color: #2B003F;
        text-decoration-line: underline;
    }

`
export const CloseIcon = styled.img`
    position: absolute;
    top: 10px;
    right:10px;
    cursor: pointer;
`
export const BrandIcon = styled.img`
`
export const Separator = styled.hr`
    opacity: .5;
    margin: .5rem auto;
`

export const Opacity = styled.div`
    width: 100vw;
    height: ${window.innerHeight + 250}px;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: .6;
    z-index: 200;
`;


export const ErrorContainer = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    // margin-left:22rem;
    // margin-top: -2rem;
    // left: 33rem;
    right: 1.5rem;
    top: -1.5rem;
    // top: -28px;
    // bottom: 1rem;
    z-index:1;
    // font-size: .8rem;
    align-items: flex-end;

`

export const ErrorMsgContainer = styled.div`
    padding: .5rem 1rem;
    /* background: linear-gradient(to left, #ED4168, #FFB25A); */
    background-color: #F6931E;
    border-radius: .6rem;
    // border-bottom-right-radius: 0;
    color: #fff;
    font-weight: 900;
`

export const ErrArrow = styled.div`
    width: 0; 
    height: 0; 
    border-top: 10px solid #F6931E; 
    border-left: 10px solid transparent;
    margin-right: .5rem;
`

export const ErrorIcon = styled.img`
    width: 20px;
    margin-top: -.2rem;
`

