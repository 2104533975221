import styled from "styled-components";

export const MainContainer = styled.div`
    height: 100vh;
    width: 100vw;
    /* position: relative; */
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgb(0,0,0,.7);
    display: flex;
    align-items: center;
    justify-content: center;
`

export const FormContainer = styled.form`
    padding: 2rem;
    border-radius: 10px;
    background-color: #fff;
    position: relative;
    display: flex;
    min-width: 400px;
    flex-direction: column;
    .close-btn {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 18px;
        cursor: pointer;
    }

    .title {
        color: #605F5E;
        text-align: center;
        font-size: 18px;
        /* font-family: "Proxima Nova Bold"; */
        font-family: Arial, Helvetica, sans-serif;
    }
    .info {
        font-size: 14px;
        color: #1B3557;
        margin: .8rem auto;
    }

    .mt-3 {
        margin-top: 3rem;
    }

    .input-container {
        position: relative;
        border-radius: 8px;
        border: 1px solid #B7B7B7;
        flex:1;
        /* width: 100%; */
        padding-top: 0.3rem;
        padding-bottom: 0.5rem;
        padding-left:.5rem ;
        padding-right: 0.5rem;
        margin-bottom: 1rem;
    }
    .float-p {
        color: #6F6F6F;
        font-size: 12px;
        text-transform: uppercase;
        margin-bottom: .2rem;
    }
    .input-box {
        width: 100%;
        background-color: transparent;
        border: none;
        outline: none;
    }
    .pencil-icon {
        width: 23px;
        height: 23px;
        position: absolute;
        right: 12px;
        top: 17px;
        cursor: pointer;
    }

    @media screen and (max-width: 1050px) {
        padding: 0.6rem;
        .flex {
            flex-direction: column;
        }
        .ml-1, .mr-1 {
            margin: 0;
        }
        .input-container {
            margin-bottom: .8rem;
        }
    }


`
export const SaveBtn = styled.button`
    background-color: #E4E4E4;
    border-radius: 9px;
    border: none;
    width: 170px;
    padding: .8rem;
    margin: auto;
    margin-top: 1.75rem;
    cursor: pointer;
    font-size: 17px;
    letter-spacing: .2ch;
    color: rgb(0,0,0,.4) !important;
`