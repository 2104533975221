import React, { useEffect } from 'react'
import axios from 'axios'
// import { URI } from './constant'
import { useState } from 'react'
import './loader.css'
import Popup from './Popup'
import Config from "../../utilities/Config";
import Element from 'antd/lib/skeleton/Element'

const header= { headers: {'Authorization': `Bearer ${(localStorage.getItem('Authorization'))}`} }

export const CustSelect = ({ value, index, className = ``, slug, setDependancy }) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const fetchDrop = (value) => {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams({ makeName: '', modelName: '', fuelType: '', cc: '', slug: slug });
            const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/filterData?${query}`;

            axios.get(endpoint)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                        // callBack(response.data);
                    } else {
                        reject(`Error fetching data: ${response.status}`);
                    }
                })
                .catch(error => {
                    reject(`Error fetching data: ${error.message}`);
                });
        });
    };
    const handleClick = () => {
        fetchDrop(value).then((response) => {
            setData(response.data)
            setLoader(false)
        })
    }
    const handleMapping = async (e) => {
        let query;
        if (e.target.value === "select value")
         query = new URLSearchParams({ id: index, value: "", field: 'MakeName', slug: slug });
        else
         query = new URLSearchParams({ id: index, value: e.target.value, field: 'MakeName', slug: slug });
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/mappingData?${query}`;
        const response = await axios.put(endpoint,"",header)
        if (response.status === 200) {
            setDependancy()
            return response.msg
        } else {
            console.error(`Error fetching data: ${response.status}`)
        }
    }
    useEffect(() => {
        // setData([])
    }, [value])

    return (
        <select className={`${className}`}
            // disabled={(value.makeName || value.modelName || value.fuelType || value.cc || value.variantName)?true:false}
            onChange={handleMapping}
            onClick={handleClick}
        >
            {data && data.length > 0 ? (
                <>
                    {value.makeName ? <><option>{value.makeName}</option><option className='divider' disabled></option></> : <option>select value</option>}
                    <option >select value</option>
                    {loader ? <option>loading ...</option> : data.map((e) =>
                        <option key={e}>{e}</option>
                    )}
                </>
            ) : (<>
                {value.makeName ? <><option>{value.makeName}</option><option className='divider' disabled></option></> : <option>select value</option>}
            </>
            )}
        </select >
    )
}
export const CustModelSelect = ({ value, index, className = ``, slug, setDependancy }) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const fetchDrop = (value) => {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams({ makeName: value.makeName, slug: slug });
            const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/filterData?${query}`;

            axios.get(endpoint)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                        // callBack(response.data);
                    } else {
                        reject(`Error fetching data: ${response.status}`);
                    }
                })
                .catch(error => {
                    reject(`Error fetching data: ${error.message}`);
                });
        });
    };
    const handleClick = () => {
        if (!value.makeName) return null
        fetchDrop(value).then((response) => {
            setLoader(false)
            setData(response.data)
        })
    }
    const handleMapping = async (e) => {
        let query;
        if (e.target.value === "select value") 
         query = new URLSearchParams({ id: index, value: "", field: 'ModelNameV2', slug: slug });
        else
         query = new URLSearchParams({ id: index, value: e.target.value, field: 'ModelNameV2', slug: slug });
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/mappingData?${query}`;
        const response = await axios.put(endpoint,"",header)
        if (response.status === 200) {
            setDependancy()
            return response.msg
        } else {
            console.error(`Error fetching data: ${response.status}`)
        }
    }
    useEffect(() => {
        // setData([])
    }, [value])

    return (
        <select className={`${className}`}
            // disabled={!value.makeName ? true : false}
            onChange={handleMapping}
            onClick={handleClick}
        >
            {data && data.length > 0 ? (
                <>
                    {value.modelName ? <><option>{value.modelName}</option><option className='divider' disabled></option></> : <option>select value</option>}
                    <option >select value</option>
                    {loader ? <option>loading ...</option> : data.map((e) =>
                        <option key={e}>{e}</option>
                    )}
                </>
            ) : (<>
                {value.modelName ? <><option>{value.modelName}</option><option className='divider' disabled></option></> : <option>select value</option>}
            </>
            )}
        </select >
    )
}
export const CustFuelSelect = ({ value, index, className = ``, slug, setDependancy }) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const fetchDrop = (value) => {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams({ makeName: value.makeName, modelName: value.modelName, slug: slug });
            const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/filterData?${query}`;

            axios.get(endpoint)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                        // callBack(response.data);
                    } else {
                        reject(`Error fetching data: ${response.status}`);
                    }
                })
                .catch(error => {
                    reject(`Error fetching data: ${error.message}`);
                });
        });
    };
    const handleClick = () => {
        if (!value.modelName) return null
        fetchDrop(value).then((response) => {
            setLoader(false)
            setData(response.data)
        })
    }
    const handleMapping = async (e) => {
        let query;
        if (e.target.value === "select value") 
        query = new URLSearchParams({ id: index, value: "", field: 'FuelTypeId', slug: slug });
    else
         query = new URLSearchParams({ id: index, value: e.target.value, field: 'FuelTypeId', slug: slug });
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/mappingData?${query}`;
        const response = await axios.put(endpoint,"",header)
        if (response.status === 200) {
            setDependancy()
            return response.msg
        } else {
            console.error(`Error fetching data: ${response.status}`)
        }
    }
    useEffect(() => {
        // setData([])
    }, [value])

    return (
        <select className={`${className}`}
            // disabled={!value.modelName ? true : false}
            onChange={handleMapping}
            onClick={handleClick}
        >
            {value.fuelType && <><option>{value.fuelType}</option><option className='divider' disabled></option></>}
            {data && data.length > 0 ? (
                <>
                    {value.fuelType ? <><option>{value.fuelType}</option><option className='divider' disabled></option></> : <option>select value</option>}
                    <option >select value</option>
                    {loader ? <option>loading ...</option> : data.map((e) =>
                        <option key={e}>{e}</option>
                    )}
                </>
            ) : (<>
                {value.fuelType ? <><option>{value.fuelType}</option><option className='divider' disabled></option></> : <option>select value</option>}
            </>
            )}
        </select >
    )
}
export const CustCcSelect = ({ value, index, className = ``, slug, setDependancy }) => {
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const fetchDrop = (value) => {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams({ makeName: value.makeName, modelName: value.modelName, fuelType: value.fuelType, slug: slug });
            const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/filterData?${query}`;

            axios.get(endpoint)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                        // callBack(response.data);
                    } else {
                        reject(`Error fetching data: ${response.status}`);
                    }
                })
                .catch(error => {
                    reject(`Error fetching data: ${error.message}`);
                });
        });
    };
    const handleClick = () => {
        if (!value.fuelType) return null
        fetchDrop(value).then((response) => {
            setLoader(false)
            setData(response.data)
        })
    }
    const handleMapping = async (e) => {
        let query;
        if (e.target.value === "select value")
        query = new URLSearchParams({ id: index, value: "", field: 'cc', slug: slug });
         query = new URLSearchParams({ id: index, value: e.target.value, field: 'cc', slug: slug });
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/mappingData?${query}`;
        const response = await axios.put(endpoint,"",header)
        if (response.status === 200) {
            setDependancy()
            return response.msg
        } else {
            console.error(`Error fetching data: ${response.status}`)
        }
    }
    useEffect(() => {
        // setData([])
    }, [value])

    return (
        <select className={`${className}`}
            // disabled={!value.fuelType ? true : false}
            onChange={handleMapping}
            onClick={handleClick}
        >
            {data && data.length > 0 ? (
                <>
                    {value.cc ? <><option>{value.cc}</option><option className='divider' disabled></option></> : <option>select value</option>}
                    <option >select value</option>
                    {loader ? <option>loading ...</option> : data.map((e) =>
                        <option key={e}>{e}</option>
                    )}
                </>
            ) : (<>
                {value.cc ? <><option>{value.cc}</option><option className='divider' disabled></option></> : <option>select value</option>}
            </>
            )}
        </select >
    )
}
export const CustVariantSelect = ({ value, index, className = ``, slug, setDependancy }) => {
    const [isOpen, setIsOpen] = useState(false)
    const [temp, setTemp] = useState()
    const [loader, setLoader] = useState(true)
    const [data, setData] = useState([])
    const fetchDrop = (value) => {
        return new Promise((resolve, reject) => {
            const query = new URLSearchParams({ makeName: value.makeName, modelName: value.modelName, fuelType: value.fuelType, cc: value.cc, slug: slug });
            const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/filterData?${query}`;
            axios.get(endpoint)
                .then(response => {
                    if (response.status === 200) {
                        resolve(response.data);
                        // callBack(response.data);
                    } else {
                        reject(`Error fetching data: ${response.status}`);
                    }
                })
                .catch(error => {
                    reject(`Error fetching data: ${error.message}`);
                });
        });
    };

    const handleClick = () => {
        if (!value.cc) return null
        fetchDrop(value).then((response) => {
            setLoader(false)
            setData(response.data)
        })
    }
    const handlePopup = (e) => {
        if (e.target.value !== "select value") {
            setIsOpen(true)
            setTemp(e.target.value)
            return;
        }
        setIsOpen(true)
            setTemp("")
    }
    const handlePopupResponse = (temp) => {
        handleMapping(temp)
        setIsOpen(false)
    }
    const handleMapping = async (value) => {
        const query = new URLSearchParams({ id: index, value: value, field: 'VariantName', slug: slug });
        const endpoint = `${Config.apiBaseUrl}/vehicleInsurance/mappingData?${query}`;
        console.log(endpoint)
        const response = await axios.put(endpoint,"",header)
        if (response.status === 200) {
            setDependancy()
            return response.msg
        } else {
            console.error(`Error fetching data: ${response.status}`)
        }
    }
    useEffect(() => {
        // setData([])
    }, [value])

    return (
        <>
            {isOpen && <Popup content={
                <>
                    <p>Do you want to map this variant: {temp} in Master Data</p>
                    <button className='handleMap-button' onClick={() => handlePopupResponse(temp)}>Yes</button>
                </>
            }
                handleClose={e => setIsOpen(false)}
            />
            }
            <select className={`${className}`}
                // disabled={!value.cc ? true : false}
                onChange={handlePopup}
                onClick={handleClick}
            >
                {data && data.length > 0 ? (
                    <>
                        {value.variantName ? <><option>{value.variantName}</option><option className='divider' disabled></option></> : <option>select value</option>}
                        <option >select value</option>
                        {loader ? <option>loading ...</option> : data.map((e) =>
                            <option key={e}>{e}</option>
                        )}
                    </>
                ) : (<>
                    {value.variantName ? <><option>{value.variantName}</option><option className='divider' disabled></option></> : <option>select value</option>}
                </>
                )}
            </select >
        </>

    )
}

