import styled from "styled-components";

export const MainContainer = styled.div`
    padding-top: 3rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }

    a {
        text-decoration-line: none;
    }

`
export const BgDiv = styled.div`
    width: 100%;
    height: 35vh;
    position: absolute;
    background: linear-gradient(to left, #41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }
`
export const CardMainContainer = styled.div`
    border-radius: 1rem;
    padding: 1rem 2.5rem;
    background-color: #fff;
    width: 95%;
    margin: 1rem auto;
    margin-bottom: 3rem;

    a {
        text-decoration-line: none;
    }

`
export const GridDiv = styled.div`
    display: grid;
    grid-template-columns: repeat(3,1fr);
    gap: 1rem;

    @media screen and (max-width: 900px) {
        grid-template-columns: repeat(2,1fr);
    }

    @media screen and (max-width: 600px) {
        grid-template-columns: repeat(1,1fr);
    }

`
export const ConfirmBtn = styled.button`
    border: none;
    border-radius: 1.5rem;
    padding: .8rem 2.8rem;
    color: #fff;
    font-size: 1.2rem;
    background: linear-gradient(to left, #ed4168, #ffb25a);
    display: flex;
    margin: auto;
    margin-bottom: 2.7rem;
    cursor: pointer;
`
export const MutedConfirmBtn = styled.button`
    border: none;
    border-radius: 1.5rem;
    padding: .8rem 2.8rem;
    color: #fff;
    font-size: 1.2rem;
    background: linear-gradient(to left, #ed4168, #ffb25a);
    display: flex;
    margin: auto;
    margin-bottom: 2.7rem;
    cursor: not-allowed;
    position: relative;

    span {
        position: absolute;
        background-color: #F5F5DC;
        padding: .4rem 1rem;
        font-size: 1rem;
        color:#000;
        right: -12rem;
        top: .35rem;
        border: 1px solid #000;
    }

    @media screen and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        span {
            right: 2.8rem;
            top: 3.2rem;
        }

    }

`