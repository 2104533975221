import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { register } from "../../Redux/AuthRedux";
import './reset.css';

const MobileNumberPage = ({ onNext }) => {
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [mobileNumberError, setMobileNumberError] = useState('');

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleMobileNumberChange = (e) => {
    const newMobileNumber = e.target.value;

    if (/^\d*$/.test(newMobileNumber) && newMobileNumber.length <= 10) {
      setMobileNumber(newMobileNumber);
      setMobileNumberError('');
    } else {
      setMobileNumberError('Mobile number must be numeric and exactly 10 digits long.');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (mobileNumber.length !== 10) {
      setMobileNumberError('Mobile number must be exactly 10 digits long.');
      return;
    }

    const obj = {
      phone: mobileNumber,
      countryCode: countryCode
    };

    const response = await dispatch(register(obj));

    if (response.payload.status === "Success") {
      localStorage.setItem('resetPasswordUserData', JSON.stringify(obj));
      navigate("/reset-password/otp");
    } else {
      alert(response.payload.message);
    }
  };

  return (
    <div className="reset-app-container">
      <div className="reset-page-container">
        <h2 className="page-title">Reset Password</h2>
        <h3 className="page-subtitle">Enter Mobile Number</h3>
        <form onSubmit={handleSubmit} className="form-container">
          <div className="input-group">
            <input
              type="text"
              className={`input-field ${mobileNumberError ? '' : 'input_margin'}`}
              placeholder="Mobile Number"
              required
              value={mobileNumber}
              onChange={handleMobileNumberChange}
            />
            {mobileNumberError && <p className="error-message">{mobileNumberError}</p>}
          </div>
          <div className="button-group">
            <button type="submit" className="btn-primary" style={{marginLeft:"35px"}}>Next</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default MobileNumberPage;
