import React, { useEffect } from "react";
import Footer from "../../components/Footer/FooterNew";
import { CarCover, TwoWheelerCover, SelfHealthCover, FamilyFloaterHealthCover, LifeTermCover, LifeInvestmentCover,Container,Content,Image,Section,Heading,SubHeading } from "./styles";
import imgOne from "../../assets/corporate/18420.svg";
import imgTwo from "../../assets/corporate/18422.svg";
import imgThree from "../../assets/corporate/18426.svg";
import imgFour from "../../assets/corporate/g10.svg";
import imgFive from "../../assets/corporate/18430.svg";
import imgSix from "../../assets/corporate/18433.svg";
import { useNavigate } from "react-router-dom";
import Hero from "../../components/Hero_Banner/hero";
import contactBanner from "../../assets/contact-banner.png";
import { MdConnectWithoutContact } from "react-icons/md";
import Quick from "../../components/new_quick_links/quick";


export const Corporate = () => {
    const navigate = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
    <Hero image={contactBanner} heading={'Corporate Insurance'} subHeading={"Safeguarding Your Business Interests and Operations"} btn={false} icon={<MdConnectWithoutContact size={100} />}/>
    <Quick heading = {'Corporate Insurance'} />
    <Container>
    <Section>
        <Content>
        <SubHeading><p>Bankers Indemnity Insurance</p></SubHeading>
                        <ul>
                            <li>It covers on site risks along with damages to ATM.</li>
                            <li>It covers transit risks and registered and postal sending.</li>
                            <li>It covers forgery, alteration, dishonest appraisers and hypothecated goods.</li>
                            <li>It covers risks of Janta Agents / Chhoti Bachat Yojna Agents / Pygmie Collectors.</li>
                            <li>It covers fire and other special perils, all AOG perils and terrorism.</li>
                        </ul>
          <SubHeading><p>What we offer</p></SubHeading>
                    <ul>    
                        <li>All Banks and NBFC must take this policy.</li>
                        <li>We will assist you choose suitable & optimum cover meeting your requirements.</li>
                        <li>We will assist in all claims arising during the policy period.</li>
                    </ul>
        </Content>
        <Image >
        <CarCover src={imgOne} />
        </Image>
    </Section>
    <Section>
        <Content>
        <SubHeading><p>Cyber Attack Insurance</p></SubHeading>
                  <ul>
                        <li>It covers loss of E- Communication and Damage to E-Reputation.</li>
                        <li>It covers loss of E-Vandalism and Business Interruption due to cyber attack.</li>
                        <li>It covers loss of client data in online fraud.</li>
                        <li>It covers Un-Authorized online transactions and Identity theft in online fraud.</li>
                        <li>It cover email spoofing and E Vandalism (like defacing website).</li>
                  </ul>
                  <SubHeading><p>What we offer</p></SubHeading>
                   <ul>     
                        <li>All companies must take this policy as currently all companies are susceptible to online frauds.</li>
                        <li>We will assist to choose best policy suiting to your requirements.</li>
                        <li>If any claim arises, we will assist in the entire claim process, till its settlement.</li>
                    </ul>
        </Content>
        <Image >
        <TwoWheelerCover src={imgTwo} />
        </Image>
    </Section>
    <Section>
        <Content>
        <SubHeading><p>Standard Fire and Special Perils Insurance</p></SubHeading>
          <ul>
                        <li>It covers fire and other special perils like lightning, explosion, implosion and others.</li>
                        <li>It covers all AOG perils like earthquake, flood, cyclone, landslide, land subsidence, etc.</li>
                        <li>It covers Riot, strike and malicious damage and terrorism cover.</li>
                        <li>It covers damages due to Aircraft crash along with other types of impact damages.</li>
                        <li>It covers damages due to over flowing of water tank and leakage from automatic sprinkler system</li>
          </ul>
          <SubHeading><p>What we offer</p></SubHeading>
                <ul>     
                    <li> It cover fire, all AOG perils, terrorism, riot – strike, malicious damages and others.</li>
                    <li> We will assist in choosing covers, Sum Insured and get most optimum cover.</li>
                    <li> We will also extend out best claim related services.</li>
                </ul>
        </Content>
        <Image >
        <SelfHealthCover src={imgThree} />
        </Image>
    </Section>
    <Section>
        <Content>
        <SubHeading><p>Director’s and Officers Liability Insurance</p></SubHeading>
                    <ul>
                        <li> It provides cover for claims made against Directors & Officers for their alleged breach of duty.</li>
                        <li> It also covers legal fees, settlement cost and financial losses.</li>
                        <li> It also covers losses due to lack of corporate governance, and allegations of mis-management.</li>
                        <li> It covers breach of fiduciary duty and failure to comply with regulators.</li>
                        <li> It also pays for negligence, allegations of mismanagement on behalf of board.</li>
                    </ul>
                     <SubHeading><p>What we offer</p></SubHeading>
                <ul>             
                    <li> It covers all legal liability raised against company directors and senior officials.</li>
                    <li> We will recommend the most optimum cover.</li>
                    <li> If a claim arises we will provide claims services including co-ordination with Insurance company for early claim settlement.</li>
                </ul>
        </Content>
        <Image >
        <FamilyFloaterHealthCover src={imgFour} />
        </Image>
    </Section>
    <Section>
        <Content>
        <SubHeading><p>Group Mediclaim Insurance (GMC)</p></SubHeading>
                  <ul>
                    <li>It cover accidental hospitalization and covers pre existing diseases too.</li>
                    <li>It cover covid treatment and maternity coverage too.</li>
                    <li>It covers day treatment and pre & post hospitalization expenses.</li>
                    <li>It covers ambulance charges and also cover ICU and room charges.</li>
                    <li>It can also cover OPD expenses along with baby from day 1.</li>
                  </ul>
                    <SubHeading><p>What we offer</p></SubHeading>
                        <ul>
                        <li>This insurance covers health treatment related expenses.</li>
                        <li>We will help design a health insurance program suiting all your requirements.</li>
                        <li>In case of any claim we shall get either the cashless facility or early claim settlement.</li>
                        </ul>
        </Content>
        <Image >
        <LifeTermCover src={imgFive} />
        </Image>
    </Section>
    <Section>
        <Content>
        <SubHeading><p>Group Personal Accident Insurance (GPA)</p></SubHeading>
        <ul>
          <li>It covers accidental Death or total permanent disability.</li>
          <li>It covers death or disability due to terrorism activity.</li>
          <li>It covers ambulance charges.</li>
          <li>It covers expense for carriage of dead body.</li>
          <li>It also covers death by snake or insect bite or animal bite.</li>
        </ul>
        <SubHeading><p>What we offer</p></SubHeading>
        <ul>
          <li>This policy will compensate your employees in case of accidental death or permanent disability.</li>
          <li>We will assist you to get the cover meeting your requirement and optimum policy.</li>
          <li>For all claims arising during policy period we will make efforts for early claim settlement.</li>
        </ul>

        </Content>
        <Image >
        <LifeInvestmentCover src={imgSix} />
        </Image>
    </Section>
      {/* <div>
        <p>Personal Insurance covers</p>
        <div style={{ display: "flex" }}>
          <div>
            <p>Private Car cover</p>
            <p>
              Private Car cover, also known as personal auto insurance, provides
              financial protection for privately-owned vehicles used for personal
              purposes. It includes essential coverages such as third-party
              liability, which pays for damages to others in accidents you cause.
              Own damage coverage protects your vehicle from accidents, theft,
              vandalism, and natural disasters. Personal accident coverage
              compensates for injuries or death of you and your passengers.
              Medical expenses coverage covers treatment costs for
              accident-related injuries. Optional add-ons may include roadside
              assistance and coverage for legal expenses or personal belongings.
              Premium costs vary based on factors like vehicle type, driver's age
              and history, location, and desired coverage. Carefully review policy
              terms, conditions, and exclusions to understand your specific
              coverage. Consult an insurance professional or refer to your policy
              documents for accurate information.{" "}
              <a href="">Click here to go car insurance</a>
            </p>
          </div>
          <div style={{ float: "right" }}>
            <CarCover src={CarCoverImg} />
          </div>
        </div>
      </div> */}
      {/* <div>
        <div>
          <p>Two Wheeler cover</p>
          <p>
            Two Wheeler cover, or motorcycle insurance, provides financial
            protection for motorbikes and scooters. It includes third-party
            liability for damage or injury caused to others, own damage coverage
            for repairs or replacement due to accidents or theft, and personal
            accident coverage for rider injury or death. Optional coverages may
            include accessories, roadside assistance, and medical expenses.
            Premium costs depend on factors like vehicle type, rider's history,
            and desired coverage. Comply with local regulations and review
            policy terms for accurate information. Two Wheeler cover ensures
            peace of mind and financial security while riding your two-wheeler.{" "}
            <a>Click here to go two wheeler insurance</a>
          </p>
        </div>
        <div>
          <TwoWheelerCover src={TwoWheelerCoverImg} />
        </div>
      </div> */}
      {/* <div>
        <div>
          <p>Self Health cover</p>
          <p>
            Self Health cover, also known as individual health insurance or
            personal health insurance, provides financial protection for
            individuals' medical expenses and healthcare costs. It typically
            includes coverage for medical expenses, emergency care, specialist
            consultations, and preventive care. The specific coverage and
            benefits vary depending on the insurance provider and plan chosen.
            Premium costs depend on factors like age, pre-existing conditions,
            coverage limits, and optional add-ons. It's important to review
            policy documents to understand coverage details, exclusions,
            deductibles, and co-payments associated with Self Health cover.
            Waiting periods and limitations may apply to certain treatments, and
            pre-existing conditions may have specific coverage considerations.
            Consult with an insurance professional or refer to policy documents
            for accurate and specific information about your Self Health cover.{" "}
            <a href=""> click here to go to health insurance</a>
          </p>
        </div>
        <div>
          <SelfHealthCover src={SelfHealthCoverImg} />
        </div>
      </div> */}
      {/* <div>
        <div>
          <p>Family floater health cover</p>
          <p>
            Family floater health cover is an insurance policy that provides
            shared health coverage for an entire family under a single plan. It
            typically includes coverage for the whole family, with a shared sum
            insured that can be utilized by any family member. The policy covers
            medical expenses, hospitalization, pre and post-hospitalization
            expenses, and may include maternity and new born coverage. The
            premium cost depends on factors like the number of family members,
            their ages, pre-existing conditions, and desired coverage limits.
            Reviewing policy terms is important to understand coverage details,
            exclusions, waiting periods, deductibles, and co-payments.
            Consulting with an insurance professional will help you choose a
            suitable family floater health cover that meets your family's
            healthcare needs.
          </p>
        </div>
        <div>
          <FamilyFloaterHealthCover src={FamilyFloaterHealthCoverImg} />
        </div>
      </div> */}
      {/* <div>
        <div>
          <p>Life term cover</p>
          <p>
            Life term cover, also known as term life insurance, is a straightforward and affordable type of life insurance policy. It provides a death benefit to beneficiaries if the policyholder passes away during a specified term. Key features include a fixed term of coverage, affordable premiums, and the option to convert to a permanent policy. It aims to provide financial protection for loved ones, replace lost income, and cover debts or future expenses. Reviewing policy terms, exclusions, and premium payment schedule is important. Consulting with an insurance professional helps in selecting the right life term cover policy based on individual needs and goals.
          </p>
        </div>
        <div>
          <LifeTermCover src={LifeTermCoverImg} />
        </div>
      </div> */}
        {/* <div>
            <div>
            <p>Life investment-related cover</p>
            <p>
                Life investment-related cover combines life insurance protection with an investment component. It offers a death benefit to beneficiaries and allows policyholders to invest a portion of their premiums in various investment funds or portfolios. Key features include life insurance coverage, investment opportunities, flexibility in fund selection, and potential cash value accumulation. Policyholders can customize their investment strategy and may have access to the cash value or surrender the policy. It is important to review the policy terms, including fees and charges, and consult with a financial advisor to understand the suitability and potential risks associated with these policies based on individual financial goals. Availability and specific features may vary depending on the insurance company and jurisdiction.
            </p>
            </div>
            <div>
            <LifeInvestmentCover src={LifeInvestmentCoverImg} />
            </div>
        </div> */}
      </Container>
      <Footer />
    </div>
  );
};
