import { configureStore } from '@reduxjs/toolkit';
import { combineReducers } from 'redux';
// import { loadState } from "../utility/browser-storage";

import AuthRedux from './AuthRedux';
import InsuranceRedux from './InsuranceRedux';
import CarsRedux from './CarsRedux';
import DashboardRedux from './DashboardRedux';
import PosRedux from "./posRedux";
import LifeRedux from "./LifeRedux";
import PolicyRedux from "./policyRedux"

const reducers = combineReducers({
  auth: AuthRedux,
  insurance: InsuranceRedux,
  carsRedux:CarsRedux,
  dashboardRedux:DashboardRedux,
  posRedux: PosRedux,
  life: LifeRedux,
  policy: PolicyRedux
});

export const store = configureStore({
  devTools: true,
  reducer: reducers,
//   preloadedState: loadState(),
});