import styled from "styled-components";

export const MainContainer = styled.div`
    padding-top: 3rem;

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }
    
    a {
        text-decoration-line: none;
    }

`
export const BgDiv = styled.div`
    width: 100%;
    height: 35vh;
    position: absolute;
    background: linear-gradient(to left, #41025f, #ffe1bb);
    z-index: -1;

    @media screen and (max-width: 1040px) {
        height: 120vh;
    }
`
export const CardMainContainer = styled.div`
    border-radius: 1rem;
    padding: 1rem 2.5rem;
    background-color: #fff;
    width: 95%;
    margin: 1rem auto;
    margin-bottom: 3rem;

    a {
        text-decoration-line: none;
    }

`
export const BottomTitle = styled.h4`
    color: #ff4c00;
    margin: 1.25rem auto;
    margin-top: 0;

    @media screen and (max-width: 800px) {
        text-align: center;
        margin: auto auto 2.5rem auto;
    }

`
export const SubCard = styled.div`
    padding: 1rem 1.5rem;
    border-radius: .3rem;
    border: 1px solid #b7b7b7;
    position:relative;
    display: grid;
    grid-template-columns: repeat(3,1fr);
    margin: 1.8rem auto;
    margin-bottom: .2rem;

    .center-border-div {
        margin-left: 2rem;
    }

    @media screen and (max-width: 950px) {
        grid-template-columns: repeat(1, 1fr);
        
        .center-border-div {
            margin: 0;
        }

    }


`
export const SubCardTitle = styled.p`
    color:#000000;
    position: absolute;
    padding: .2rem .8rem;
    background-color: #fff;
    top: -.6rem;
    left: 1rem;
`
export const DivBorRight = styled.div`
    padding: 1rem;
    padding-left: .4rem;
    border-right: 1px solid rgb(183, 183, 183,.5);

    @media screen and (max-width: 950px) {
        border-right: 0;
        padding-left: .3rem;
        border-bottom: 1px solid rgb(183, 183, 183,.5);
    }

`
export const DivNoBorder = styled.div`
    padding: 1rem;

    @media screen and (max-width: 950px) {
        padding-bottom: 0 ;
        padding-left: .3rem;
    }

`

export const HDiv = styled.div`
    display: flex;
    align-items: center;

    margin: 1rem auto;
    
    input {
        margin-right: .6rem;
        accent-color: #ff4c00;
    }

    p {
        color:#707070;
    }

`
export const ConfirmBtn = styled.button`
    border: none;
    border-radius: 1.5rem;
    padding: .8rem 2.8rem;
    background: linear-gradient(to left, #ed4168, #ffb25a);
    color: #fff;
    display: flex;
    margin: auto;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 3rem;
    cursor: pointer;
`