import Skeleton from "react-loading-skeleton"
import 'react-loading-skeleton/dist/skeleton.css'
import { useNavigate } from "react-router-dom"
import { MainContainer } from "../../../assets/common-styles/common-styles"
import { SkeletonCard } from "../../../components/skeleton-card/SkeletonCard"
import { SkeletonComponent } from "../../../components/skeleton-component/SkeletonComponent"
import { ContinueBtn, Opacity, SkeletonContainer } from "./styles"


export const PrevInsuranceDetails = () => {

    const navigate = useNavigate()
    
    const handleContinue = () => {
        navigate('/intro-screen')
    }

    return(
        <>
            <MainContainer style={{display:'flex', justifyContent:'center'}}>
                <SkeletonContainer>
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                    <SkeletonComponent />
                </SkeletonContainer>
                <Opacity />
                <SkeletonCard continueBtn={true} onContinue={handleContinue} title={'Enter Previous Policy Details'} prevPolicy={true} />
            </MainContainer>
        </>
    )
}