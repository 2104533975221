import { useEffect, useState } from "react";
import OtpInput from "react-otp-input";
import { Link, useLocation } from "react-router-dom";
import { CarForm9 } from "../form-9/Form9"
import { Button, inputStyles, MainContainer, OpacityDiv, OtpContainer } from "./styles";

export const CarForm10 = () => {

    const location = useLocation()
    const item = location.state.from;
    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    const [otp, setOtp] = useState('')

    const handleChange = (val) => setOtp(val)

    return(
        <>
            <MainContainer>
                <CarForm9 />
                <OpacityDiv />
                <OtpContainer>
                    <p>Enter 4 digit verification code (OTP)</p>
                    <OtpInput
                        value={otp}
                        onChange={handleChange}
                        numInputs={4}
                        separator={<span>-</span>}
                        containerStyle={{margin:'1rem auto'}}
                        inputStyle={inputStyles}
                    />
                    <Link to='/car/form-11' state={{from: item}}>
                        <Button>Confirm Code</Button>
                    </Link>
                </OtpContainer>
            </MainContainer>
        </>
    )
}