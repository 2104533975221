import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import LoadingSpinner from '../../components/loading/loading';
import './blog.css';

const BlogCard = () => {
  const [blogs, setBlogs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    axios.get(`https://uat-backend.simpliinsure.in/cms/api/blogs?populate=SubBlogs.img,blogBanner,SEO,SEO.sharedImage,insurace_type`)
      .then(response => {
        const allBlogs = response?.data?.data || [];
        const shuffledBlogs = allBlogs.sort(() => 0.5 - Math.random()); // Shuffle the blogs
        const selectedBlogs = shuffledBlogs.slice(0, 4); // Select only 4 random blogs
        setBlogs(selectedBlogs);
        setLoading(false);
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <>
    <div className="main_blog_section">
      {blogs.map(blog => (
        <div className="card_blog" key={blog.id}>
          <div className="blog_item">
            <div className="blog_image">
              {blog?.attributes?.blogBanner?.data?.attributes?.url && (
                <img src={`${blog?.attributes?.blogBanner?.data?.attributes.url}` || "https://placehold.co/1000x400"} alt={blog.attributes?.category} className='blog_img' />
              )}
              <span className="blog_category">{blog?.attributes?.category || ''}</span>
            </div>
            <div className="blog_content">
              <h2 className='blog_heading'>{blog?.attributes?.BlogTitle || ''}</h2>
              <p className='para_blog'>{blog?.attributes?.subheading || ''}</p>
              <div className="blog_buttons">
                <Link className="blog_read-more-btn blog_button" to={`/blog/${blog?.attributes?.slug}`}>Read More</Link>
                <a className='blog_quote-btn blog_button' target="_blank" rel="noopener noreferrer" href={blog?.attributes?.insurace_type?.redirectionLink || '/'}>Get Quote</a>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
    </>
  );
};

export default BlogCard;
