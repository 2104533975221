import styled from 'styled-components';

export const Container = styled.div`
  padding: 20px;
  background-color: #f9f9f9;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  width:100%;
  .custom{
    margin-left: 1.5rem;
    margin-top:-7px;
    width: 150px;;
  }
`;

export const Title = styled.h2`
  margin-bottom: 10px;
`;

export const Label = styled.label`
  display: block;
  margin-bottom: 5px;
`;

export const Input = styled.input`
  width: 50%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
`;

export const TextArea = styled.textarea`
  width: 70%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
`;
export const Dropdown = styled.select`
  width: 30%%;
  padding: 5px;
  margin-bottom: 10px;
  border: 1px solid #ddd;
  border-radius: 3px;
  margin-left:7px;
`;

export const Button = styled.button`
  padding: 5px 10px;
  background-color: #F6931E;
  color: white;
  border: none;
  border-radius: 3px;
  cursor: pointer;
`;
