import styled from "styled-components";

export const MainContainer = styled.div`
    width: 85%;
    margin: auto;
    color:#000 !important;
    margin-top: 2.5rem !important;

    p {
        margin: 0;
    }

    .h-div-apart {
        display: flex;
        justify-content: space-between;
        margin-top: 1rem;
        .h-div {
            display: flex;
            align-items: center;
        }

    }

    .grid {
        display: grid;
        gap: .4rem;
    }
    
    .gtc-4 {
        grid-template-columns: repeat(4,1fr);

        @media screen and (max-width: 1480px) {
            grid-template-columns: repeat(3,1fr);
        }
        
        @media screen and (max-width: 1120px) {
            grid-template-columns: repeat(2,1fr);
        }

        @media screen and (max-width: 800px) {
            grid-template-columns: repeat(1,1fr);
           
        }

    }
    .mt-1 {
        margin-top: 1rem;
    }

    @media screen and (max-width: 800px) {
        padding-top: 5rem;
    }
`

export const SkeletonContainer = styled.div`
    
    width: 80%;
    margin: auto;
`

export const ListIcon = styled.img`
    margin-left: .75rem;
    cursor: pointer;
`
export const GridInactiveIcon = styled.img`
    margin-left: .75rem;
    cursor: pointer;
`
export const ListInactiveIcon = styled.img`
    margin-left: .75rem;
    cursor: pointer;
`
export const GridActiveIcon = styled.img`
    margin-left: .75rem;
    cursor: pointer;
`
export const Opacity = styled.div`
    width: 100vw;
    height: ${localStorage.getItem('iHeight')}px;
    position: absolute;
    top: 0;
    background-color: #000;
    opacity: .6;
    z-index: 200;
`