import { CloseIcon, PopupContainer, Separator } from "../styles";
import CloseImg from '../../../../assets/brand-new-car/close-btn.svg'
import TickImg from '../../../../assets/car-insurance/view-details/green-tick.svg'
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import UpArrowImg from '../../../../assets/car-insurance/view-details/up-arrow.svg'
import InfoImg from '../../../../assets/car-insurance/view-details/info.svg';
import { useEffect } from 'react';
// import { motorEnquiryAPI,updateCombinedModel,GODIGITMotorQuote,resetMotorQuoteRedux,KotakMotorQuote } from "../../Redux/CarsRedux";
import { useSelector, useDispatch } from "react-redux";
import './gridCss.css';
const moment = require('moment');
 
const getFullName = (first, middle, last) =>{
    if(first && middle && last)
    return `${first} ${middle} ${last}`
   if(first && last)
   return `${first} ${last}`
return first || "";
}


const ShowLifeData = (data,user) => {
 
    return (
        <>
            <div className="flex apart top res-flex">
                {/* <div className="flex">
                    <img className="brand-img" src={data?.quickQuote?.sluginfo?.logo} />  
                    <div>
                        <p className="brand-name data">{data?.quickQuote?.sluginfo?.name}</p>
                        <p className="basics"> Plan Name : {data?.interestedPolicy?.slug?.toUpperCase()} | Payment : {data?.interestedPolicy?.paymentStatus}</p>

                    </div>
                </div> */}
              
            </div>

            <div className="cards mt-1">
                <div className="flex apart">
                    <p className="head">Customer Details :</p>
                </div>
                <div className="flex apart">
                <p className="data1">Customer ID : {data?.customerID || "SIM-00089"}</p>
                    <p className="data1">Name : {getFullName(data?.userDetail?.firstName,data?.userDetail?.middleName, data?.userDetail?.lastName) || data?.user?.fullName}</p>
                    <p className="data1">Email : {data?.userDetail?.email || data?.user?.email}</p>
                    <p className="data1">Mobile No : {data?.userDetail?.phone ||data?.user?.phone}</p>
                </div>
                {/* <div className="flex apart">
                    <p className="data1">Basic Third Party Premium</p>
                    <p className="data1">₹ 0</p>
                </div> */}
                <Separator />
                <div className="flex apart">
                    <p className="head">Basic Information :</p>
                </div>
                <div className="flex apart">
                    <p className="data1">Sum Assured : {data?.sumAssured || 'N/A'}</p>
                    <p className="data1">Plan Type : {data?.planType || null}</p>
                    {/* <p className="data1">Mobile No : {data?.user?.phone}</p> */}
                </div>
                <div className="flex apart">
                    <p className="data1">Cover Till Age  : {data?.coverTillAge + "Years"}</p>
                    <p className="data1">Payment option : {data?.PremiumPaymentOption}</p>
                </div>
                <div className="flex apart">
                    <p>Premium Payment Term : ₹ {data?.interestedPolicy?.premiumPaymentTerm}</p>
                    <p className="data1">Payment Term: {data?.interestedPolicy?.policyTerm}</p>
                </div>
                <div className="flex apart">
                    <p>Price : {data?.interestedPolicy?.premium}</p>
                    <p className="data1">Mode Of Payment : {data?.modeOfPayment}</p>
                </div>
                
                <div className="flex apart">
                    {/* <p>Employee Name : ₹ {'N/A'}</p> */}
                    {/* <p className="data1">RM Name : {'N/A'}</p> */}
                </div>
                <div className="flex apart">
                <p>POS Name : {data?.user?.roles?.includes("pos") ? data?.user?.fullName : user?.roles?.includes("pos") ? user?.fullName : ""}</p>
                    {/* <a className="data1">File : <span className="head">Download Details</span></a> */}
                </div>
            </div>
        </>
    )
}
//  health pop up
const ShowHealthData = (data) =>{
    return (
        <>
            <div className="flex apart top res-flex">
                {/* <div className="flex">
                    <img className="brand-img" src={data?.quickQuote?.sluginfo?.logo} />  
                    <div>
                        <p className="brand-name data">{data?.quickQuote?.sluginfo?.name}</p>
                        <p className="basics"> Plan Name : {data?.interestedPolicy?.slug?.toUpperCase()} | Payment : {data?.interestedPolicy?.paymentStatus}</p>

                    </div>
                </div> */}
              
            </div>

            <div className="cards mt-1">
                <div className="flex apart">
                    <p className="head">Customer Details :</p>
                </div>
                <div className="flex apart">
                <p className="data1">Customer ID : {data?.customerID || "SIM-000073"}</p>
                    <p className="data1">Name : {getFullName(data?.proposal_details?.first_name,data?.proposal_details?.last_name) ||null}</p>
                    <p className="data1">Email : {data?.proposal_details?.Email}</p>
                    <p className="data1">Mobile No : {data?.proposal_details?.MobileNo}</p>
                </div>
                {/* <div className="flex apart">
                    <p className="data1">Basic Third Party Premium</p>
                    <p className="data1">₹ 0</p>
                </div> */}
                <Separator />
                <div className="flex apart">
                    <p className="head">Basic Information :</p>
                </div>
                <div className="flex apart">
                    <p className="data1">Sum Assured : {data?.plan_details?.plan_SI || 'N/A'}</p>
                    <p className="data1">Plan Name : {data?.plan_details?.plan_name || null}</p>
                    {/* <p className="data1">Mobile No : {data?.user?.phone}</p> */}
                </div>
                <div className="flex apart">
                    <p className="data1">Tenure  : {data?.plan_details?.tenure}</p>
                    <p className="data1">Insurer : {data?.plan_details?.ic_name}</p>
                </div>
                <div className="flex apart">
                    <p>Address : {data?.proposal_details?.address1}</p>
                    <p className="data1">Mode Of Payment : {"Online"}</p>
                </div>
                
                <div className="flex apart">
                    {/* <p>Employee Name : ₹ {'N/A'}</p> */}
                    {/* <p className="data1">RM Name : {'N/A'}</p> */}
                </div>
                <div className="flex apart">
                    <p>POS Name : {data?.user?.roles?.includes("pos") ? data?.user?.fullName : ""}</p>
                    {/* <a className="data1">File : <span className="head">Download Details</span></a> */}
                </div>
            </div>
        </>
    )
}



export const ViewDetailsPopup = ({data,setPopUpData, type, user}) => {
    console.log("🚀 ~ ViewDetailsPopup ~ data:", data,"type",type)
    
    
    const dispatch = useDispatch();
    const [totalPrice, setTotalPrice] = useState(0);
    const navigate = useNavigate()
    const [GST, setGST] = useState(0);
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    // var {enquiryId} = JSON.parse(localStorage?.getItem('enquiryId'));
    const motorQuote = useSelector((state) => state.carsRedux.motorQuote);
    const [callApi, setCallApi] = useState(false);
    const [motorQuoteState, setMotorQuoteState] = useState([]);
    // useEffect(() => {
        
    // },[motorQuote[item]])

    useEffect(() => {
        // if(motorQuote.length > 0) {
        //     var data = [...motorQuote].sort((a, b) => (a.price) < (b.price) ? 1 : -1)
        //     setMotorQuoteState(data)

        //     var cal = ((18/ 100) * data[item]?.price)
        //     cal = Math.round(cal)
        //     setGST(cal)
        //     setTotalPrice(parseInt(data[item]?.price) - parseInt(cal))
        //     dispatch(updateCombinedModel({price:data[item]?.price,quickQuote:data[item]}));
            
        // }
      }, [motorQuote])


    const updateAddons = (item) => {
        // var objCheck = addons.find((items)=>items.slug === item.slug);
        // if(objCheck){
        //     const newArr = addons.filter(object => {
        //         return object.slug !== item.slug;
        //     });
        //     setAddons(newArr);
        //     dispatch(motorEnquiryAPI({addOns:newArr,enquiryId:combinedRedux.enquiryId}));
        //     setCallApi(true);
        //     setUpdateCover(true);
        // }
    }
    
    useEffect(() => {
        // if(callApi){
        //     dispatch(resetMotorQuoteRedux('')).then((res)=>{
        //         CallQuoteAPIs('new');
        //         setCallApi(false);
        //     });
        //     // setUpdateCover(false);
        // }
    }, [combinedRedux?.addOns])
    
    


    return(
        <PopupContainer>
            <CloseIcon src={CloseImg} onClick={() =>{
                // navigate('/car-insurance/main?enquiryId='+enquiryId)}
                setPopUpData({});
            }}/>
            {type == "life" ? ShowLifeData(data,user) :
                type == "health" ? ShowHealthData(data) :
                <>
            <div className="flex apart top res-flex">
                <div className="flex">
                    <img className="brand-img" src={data?.quickQuote?.sluginfo?.logo} />  
                    <div>
                        <p className="brand-name data">{data?.quickQuote?.sluginfo?.name}</p>
                        <p className="basics">Policy Number :{data?.quotationNumber} : Plan Name : {data?.quickQuote?.sluginfo?.name} | Status : {data?.status  == 'paymentPending' ? 'Pending Payment' : data?.status == 'paymentCompleted'?'Payment Completed':data?.status}</p>

                        {/* <p className="basics">Cover Value (IDV) : ₹ 4,45,555 | Tenure : 1 year | Policy Start Date : 24 Jul 2022</p> */}
                    </div>
                </div>
                <button class="beautiful-button" onClick={()=>navigate(`/car-insurance/payment/3?enquiryId=${data?.enquiryId}`)}>Buy Now</button>
            </div>

            <div className="cards mt-1">
                <div className="flex apart">
                    <p className="head">Customer Details :</p>
                </div>
                    <div className="flex apart">
                    <p className="data1">Customer ID : {data?.customerID}</p>
                    <p className="data1">Name : {data?.ownerDetails?.fullName || user?.fullName}</p>
                    <p className="data1">Email : {data?.ownerDetails?.email || user?.email}</p>
                    <p className="data1">Mobile No : {data?.ownerDetails?.mobileNo || user?.phone}</p>
                </div>
                {/* <div className="flex apart">
                    <p className="data1">Basic Third Party Premium</p>
                    <p className="data1">₹ 0</p>
                </div> */}
                <Separator />
                <div className="flex apart">
                    <p className="head">Basic Information :</p>
                </div>
                <div className="flex apart">
                    <p className="data1">Vehicle Registration Number : {data?.number ? data?.number : 'N/A'}</p>
                    <p className="data1">Policy Type : {data?.newPolicyData?.policyType == 'privateCarComprehensive'? 'Comprehensive':data?.newPolicyData?.policyType == 'privateCarOD'?'Standalone OD Policy':data?.newPolicyData?.policyType == 'privateCarTP'?'Standalone TP Policy':null}</p>
                    {/* <p className="data1">Mobile No : {data?.user?.phone}</p> */}
                </div>
                <div className="flex apart">
                    <p className="data1">Policy From Date : {moment(data?.insurance?.insurancePolicyExpiryDate).format('DD-MM-YYYY')}</p>
                    <p className="data1">Vehicle Type : {data?.vehicleType}</p>
                </div>
                <div className="flex apart">
                    <p>Policy To Date : {moment(data?.insurance?.insurancePolicyExpiryDate).add(1,"y").subtract(1,"days").format('DD-MM-YYYY')}</p>
                    <p className="data1">Fuel Type : {data?.fuelType}</p>
                </div>
                <div className="flex apart">
                    <p>Premium Amount : ₹ {data?.quickQuote?.price || data?.price}</p>
                    <p className="data1">No Claim Bonus: {data?.quickQuote?.ncbDiscount || 'N/A'}</p>
                </div>
                <div className="flex apart">
                    {/* <p>Employee Name : ₹ {'N/A'}</p> */}
                    {/* <p className="data1">RM Name : {'N/A'}</p> */}
                </div>
                <div className="flex apart">
                    <p>POS Name : {data?.user?.roles?.includes("pos") ? data?.user?.fullName : user?.roles?.includes("pos") ? user?.fullName : ""}</p>
                    {/* <a className="data1">File : <span className="head">Download Details</span></a> */}
                </div>
            </div>
            </>
            }
        </PopupContainer>
    )
}