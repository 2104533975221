export function validation(value, key) {
    if (!value) return { status: false, message: `Please enter ${key}` }
    switch (key) {
        case 'pan':
            console.log(value, /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value))
            return { status: /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/.test(value), message: 'Thank you' }
        case 'name':
            return { status: /^[a-zA-Z\s]*$/.test(value), message: 'Thank you' }
        case 'age':
            const today = new Date();
            const chooseDate = new Date(value)
            const age = today.getFullYear() - chooseDate.getFullYear()
            if (chooseDate >= today) {
                return { status: false, message: "Enter Your Date Of Birth" }
            }
            else if (age < 18) {
                return { status: false, message: "You Must be 18+ to proceed" }
            }
            else if (age > 70) {
                return { status: false, message: "Your age must be less than 70 Years to proceed" };
            } else {
                return { status: true, message: 'Thank you' }
            }
        case 'mob':
            if (value.length !== 10) return { status: false, message: `Please enter ${key}` }
            return { status: /^[6-9][0-9]*$/.test(value), message: 'Thank you' }
        case 'aadhar':
            if (value.length !== 12) return { status: false, message: `Please enter ${key}` }
            return { status: /^[0-9][0-9]*$/.test(value), message: 'Thank you' }
        case 'hNo':
            return { status: /^$|^[a-zA-Z0-9][a-zA-Z0-9\-_ ]*$/.test(value), message: 'Thank you' }
        case 'alpha':
            return { status: /^[a-zA-Z0-9\s]*$/.test(value), message: 'Thank you' }
        case 'email':
            return {
              status: /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value.toLowerCase()),
              message: 'Thank you',
            };
            case 'password':
            return { status: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value), message: 'Please enter a valid password' }
            case 'currentPassword':
            return { status: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/.test(value), message: 'Please enter a valid password' }
        case 'pinCode':
            if (value.length !== 6) return { status: false, message: `Please enter ${key}` }
            return { status: /^[0-9]*$/.test(value), message: 'Thank you' }
        default:
            break;
    }
}