import React, {useState, useEffect} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { lifeEnquiryAPI, updateFirstPageData} from "../../Redux/LifeRedux";
import vector from '../../assets/Vector.png'
import './filter.css'
import Dropdown from '../life-dropdown';
import CustomInputBox from '../selectTextInput';
import { getAgeOptions } from '../../utilities/dobToCoverTillAge';
import sumAssuredData from '../../utilities/sumAssuredTemp';
import formatSumAssured from '../../utilities/calculateSum';
import { calculateAge } from '../../utilities/calculateAge';
import AlertLumpsumModal from './../input-alert-lumpsum';

export const FilterBar = ({ CallQuoteAPIs, isCheckedMenu, handleCheckboxChange, slugData}) => {

  // State for redux and dropdown
  const combinedRedux = useSelector((state) => state.life.combinedRedux);
  const Age = calculateAge(combinedRedux?.userDetail?.dob)

    // section for policy term
    const policyData = combinedRedux.coverTillAge - Age
    const policyTermData = combinedRedux.policyTerm ? combinedRedux.policyTerm : policyData

    const annualInc = combinedRedux?.userDetail?.annualIncome
    const mainData = combinedRedux?.userDetail?.dob
    const dispatch = useDispatch()
    const [ppt, setPpt] = useState(combinedRedux.premiumPaymentTerm ? combinedRedux.premiumPaymentTerm : policyTermData)
    const [modeOfPayment, setModeOfPayment] = useState('')
    const [sumAssured, setSumAssured] = useState('')
    const [claimSettlementRatio, setClaimSettlementRatio] = useState('')
    let [coverTillAge, setCoverTillAge] = useState([])
    const [deathBenefit, setDeathBenefit] = useState('')
    const [deathTooltip , setDeathTooltip] = useState('Lump-Sum')
    const [payoutTooltip , setPayoutTooltip] = useState('payout')
    const [lumpSumPersent, setLumpSumPersent] = useState('')
    const [payoutTermState, setPayoutTermState] = useState('')
    // State variable to track if the alert has been shown
    const [alertShown, setAlertShown] = useState(false);
    
    const [lifeFilter, setLifeFilter] = useState({
        policyTerm: '',
        premiumPaymentTerm: '',
        modeOfPayment: '',
        sumAssured: '',
        coverTillAge: '',
        DeathBenefitOption: '',
        claimSettlementRatio: '',
        PremiumPaymentOption:'',
        LumpsumPercentage: '',
        PayoutTerm : '',
    })
    // const tataSlug = ['tata-mrs', 'tata-life-srp']

    // dropdown options data
    const premiumPaymentTermOptions = ["Select", ...Array.from({ length: 26 }, (_, index) => (index + 1).toString())];
    const payModeOptions = ['Select','Yearly', 'Half-Yearly', 'Quarterly', 'Monthly'];	
    const deathBenefits = ['Select','Lump-Sum', 'Income', 'Increasing Income', 'Lump-Sum-Income'];
    const premiumPaymentOptions = ['Select','Regular Pay', 'Limited Pay', 'Single Pay'];
    const payoutTermData = ['Select','10', '20', '30'];
    const payoutAllowedOptions = ['Income', 'Lump-Sum-Income'];

    // for add cover till age


// useEffect(() => {
//   if (mainData) {
//     const options = getAgeOptions(new Date(mainData));
//     const updatedOptions = ['Select', 'Whole Life', ...options];
//     setCoverTillAge(updatedOptions);

//     // Check if the selected value is "Whole Life"
//     if (lifeFilter.coverTillAge === 'Whole Life') {
//       // Set the value to 100
//       setLifeFilter(prevFilter => ({
//         ...prevFilter,
//         coverTillAge: 99,
//       }));
//     }
//   }
// }, [mainData, lifeFilter.coverTillAge]);


    // for update premiumPaymentOptions automatically
  useEffect(() => {
    if (
      lifeFilter.premiumPaymentTerm !== '' ||
      lifeFilter.PremiumPaymentOption !== ''
    ) {
      // Dispatch the update action with the updated values
      dispatch(
        updateFirstPageData({
          premiumPaymentTerm: lifeFilter.premiumPaymentTerm,
          PremiumPaymentOption: lifeFilter.PremiumPaymentOption,
          enquiryId: combinedRedux.enquiryId,
        })
      )
        .then(() => {
          dispatch(lifeEnquiryAPI({ enquiryId: combinedRedux.enquiryId }));
          CallQuoteAPIs();
        })
        .catch((error) => {
          console.error('Error updating database:', error);
        });
    }
  }, [lifeFilter.premiumPaymentTerm, lifeFilter.PremiumPaymentOption]);

    // for updating Cover Till Age
 useEffect(() => {
  if (mainData) {
    const options = getAgeOptions(new Date(mainData));
    const updatedOptions = ['Select', 'Whole Life', ...options];
    setCoverTillAge(updatedOptions);

    // Check if the selected value is "Whole Life"
    if (lifeFilter.coverTillAge === 'Whole Life') {
      // Set the value to 100
      setLifeFilter(prevFilter => ({
        ...prevFilter,
        coverTillAge: 99,
      }));
    } else if (lifeFilter.coverTillAge !== '') { // Add an else condition here
      dispatch(
        updateFirstPageData({
          coverTillAge: lifeFilter.coverTillAge,
          premiumPaymentTerm: policyTermData,
          enquiryId: combinedRedux.enquiryId,
        })
      )
        .then(() => {
          dispatch(lifeEnquiryAPI({ enquiryId: combinedRedux.enquiryId }));
          CallQuoteAPIs();
        })
        .catch((error) => {
          console.error("Error updating coverTillAge:", error);
        });
    }
  }
}, [lifeFilter.coverTillAge, mainData]);

        

    // for  updating Sum Assured
    useEffect(() => {
        if(lifeFilter.sumAssured != "") {

            dispatch(updateFirstPageData({sumAssured: lifeFilter.sumAssured, enquiryId: combinedRedux.enquiryId})).then(()=>{
                dispatch(lifeEnquiryAPI({enquiryId: combinedRedux.enquiryId}))
                CallQuoteAPIs()
            }).catch((error) => {
                console.error("Error updating sumAssured:", error);
            });
            }
     }, [lifeFilter.sumAssured]);

     // for updating Mode of payment
     useEffect(() => {
        if(lifeFilter.modeOfPayment != "") {
            dispatch(updateFirstPageData({modeOfPayment: lifeFilter.modeOfPayment, enquiryId: combinedRedux.enquiryId})).then(()=>{
                dispatch(lifeEnquiryAPI({enquiryId: combinedRedux.enquiryId}))
                CallQuoteAPIs()
            }).catch((error) => {
                console.error("Error updating modeOfPayment:", error);
            });
        }
        }, [lifeFilter.modeOfPayment]);

        // for DeathBenefitOption
     useEffect(() => {
        if(lifeFilter.DeathBenefitOption != "") {
            dispatch(updateFirstPageData({DeathBenefitOption: lifeFilter.DeathBenefitOption, enquiryId: combinedRedux.enquiryId})).then(()=>{
                dispatch(lifeEnquiryAPI({enquiryId: combinedRedux.enquiryId}))
                CallQuoteAPIs()
            }).catch((error) => {
                console.error("Error updating DeathBenefitOption:", error);
            });
        }
        if (lifeFilter.DeathBenefitOption === "Income") {
            setDeathTooltip("Income");
        } else if (lifeFilter.DeathBenefitOption === "Lump-Sum") {
            setDeathTooltip("Lump-Sum");
        }
        }, [lifeFilter.DeathBenefitOption]);

        // useEffect (() => {
        //     if(lifeFilter.LumpsumPercentage != "") {
        //         dispatch(updateFirstPageData({LumpsumPercentage: lifeFilter.LumpsumPercentage, enquiryId: combinedRedux.enquiryId})).then(()=>{
        //             dispatch(lifeEnquiryAPI({enquiryId: combinedRedux.enquiryId}))
        //             CallQuoteAPIs()
        //         }).catch((error) => {
        //             console.error("Error updating LumpsumPercentage:", error);
        //         });
        //     }
        // }, [lifeFilter.LumpsumPercentage]);

          useEffect (() => {
            if(lifeFilter.PayoutTerm != "") {
                dispatch(updateFirstPageData({PayoutTerm: lifeFilter.PayoutTerm, enquiryId: combinedRedux.enquiryId})).then(()=>{
                    dispatch(lifeEnquiryAPI({enquiryId: combinedRedux.enquiryId}))
                    CallQuoteAPIs()
                }).catch((error) => {
                    console.error("Error updating payoutTerm:", error);
                });
            }
        }, [lifeFilter.PayoutTerm]);

        // const handleLumpSumPersent = (value) => {
        //     if (value > 100) {
        //         setLumpSumPersent(100);
        //         setLifeFilter({ ...lifeFilter, LumpsumPercentage: 100 });
        //     } else if(value != "") {
        //         setLumpSumPersent(value);
        //         setLifeFilter({ ...lifeFilter, LumpsumPercentage: value });
        //     }
        // }


    // for handle ppt change with condition PPt should not be greater than policy term
        const handlePpt = (value) => {
            if (value !== '') {
            let policyTerm = combinedRedux.policyTerm ? combinedRedux.policyTerm : policyTermData;
            if (Number(value) >= policyTerm) {
                setPpt(policyTerm);
                setLifeFilter({ ...lifeFilter, premiumPaymentTerm: policyTerm, PremiumPaymentOption: 'Regular Pay' });
            } else if (Number(value) === 1) {
                setPpt(value);
                setLifeFilter({ ...lifeFilter, premiumPaymentTerm: value, PremiumPaymentOption: 'Single Pay' });
            } else {
                setPpt(value);
                setLifeFilter({ ...lifeFilter, premiumPaymentTerm: value, PremiumPaymentOption: 'Limited Pay' });
            }
            }
        };

        const handleSumAssured = (value) => {

         if (value > 1000000000) {
            setSumAssured(1000000000);
            setLifeFilter({ ...lifeFilter, sumAssured: 1000000000 });
         } else if(value != "") {
                setSumAssured(value);
                setLifeFilter({ ...lifeFilter, sumAssured: value });
            }
        }


        // sum assured calculation
        // let data = [];
        // const sumAssuredOptions = [
        // 'Select',
        // ...Array.from({ length: 10 }, (_, index) => {
        //     const croreValue = (10 - index) * 10000000;
        //     data.push({ label: `${10 - index} Cr`, value: croreValue }); // Store croreValue in data array
        //     return `${10 - index} Cr`;
        // }),
        // ...Array.from({ length: 18 }, (_, index) => {
        //     const lacValue = (95 - index * 5) * 100000;
        //     data.push({ label: `${95 - index * 5} lac`, value: lacValue }); // Store lakhValue in data array
        //     return `${95 - index * 5} lac`;
        // }),
        // ];

        // // filter sum assured data based on annual income
        // const filteredData = data.filter((item) => item.value <= (annualInc * 20));

    return (
        <div className='filter'>
        <label htmlFor="dropdown" className='labIcon'>Filters <span><img style={{paddingLeft:'2px'}} src={vector} alt='ram' /></span></label>
        <div className='dataDown'>

        <Dropdown options={coverTillAge} label={'Cover Till Age'} className="custom-dropdown" onChange={(e) => { setLifeFilter({...lifeFilter,coverTillAge:e.target.value}) }} value={combinedRedux.coverTillAge} others = {'coverTillAge'} cta = {'cta'}/>
        
        <Dropdown options={premiumPaymentOptions} label={'Payment Option'} className="custom-dropdown" value={combinedRedux.PremiumPaymentOption} onChange={(e) => { setLifeFilter({...lifeFilter,PremiumPaymentOption:e.target.value}) }}/>

            <CustomInputBox
                onChange= { handlePpt}
                onBlur = {handlePpt}
                label={'PPT'}
                placeholder={'Enter PPT'}
                value={ppt ? ppt : policyTermData}
            />


        <Dropdown options={payModeOptions} label={'Mode Of Payment'}  onChange={(e) => {
            if (e.target.value !== 'Select') {
            setModeOfPayment(e.target.value);
            setLifeFilter({ ...lifeFilter, modeOfPayment: e.target.value });
            }
        }} className="custom-dropdown" value={combinedRedux.modeOfPayment}/>

        {/* <Dropdown
        options={filteredData.map((option) => option.label)}
        label={'Sum Assured'}
        onChange={(e) => {
            if (e.target.value !== 'Select') {
            setSumAssured(e.target.value);
            setLifeFilter({ ...lifeFilter, sumAssured: e.target.value });
            }
        }}
        className="custom-dropdown"
        value={Object.keys(sumAssuredData).find((key) => sumAssuredData[key] === combinedRedux.sumAssured)}
        /> */}
        <CustomInputBox label={`SA (${formatSumAssured(combinedRedux.sumAssured)})`} placeholder={'Enter Sum Assured'} sum={true} value={combinedRedux.sumAssured} onChange={handleSumAssured} />

        <Dropdown options={deathBenefits} label={'Death Benefit Payout'} onChange={(e) =>{if(e.target.value!=="death Benefit"){setDeathBenefit(e.target.value);setLifeFilter({...lifeFilter,DeathBenefitOption:e.target.value})}}} className="custom-dropdown" value={combinedRedux.DeathBenefitOption} more = {deathTooltip} />

        {/* {
          combinedRedux.DeathBenefitOption === 'Lump-Sum-Income' ? (
            <CustomInputBox
                onChange= { handleLumpSumPersent}
                onBlur = {handleLumpSumPersent}
                label={'Lumpsum Percent'}
                placeholder={'Enter'}
                value={lumpSumPersent ? lumpSumPersent : combinedRedux.LumpsumPercentage}
            />
          ) : null
        } */}

        {
          payoutAllowedOptions.includes(combinedRedux.DeathBenefitOption) ? (
          <Dropdown options={payoutTermData} label={'Payout Term'} onChange={(e) => { setLifeFilter({...lifeFilter,PayoutTerm:e.target.value}) }} className="custom-dropdown" value={combinedRedux.PayoutTerm} more = {payoutTooltip} />
          ) : null
          
        }

        </div>
        {/* 2nd Side of the filter */}
        <div>
    {/* <div className="checkdiv">
        <input
        type="checkbox"
        id="apiCheckbox"
        className="check"
        checked={isCheckedMenu}
        onChange={handleCheckboxChange}
        style={{ backgroundColor: isCheckedMenu ? 'yellow' : 'initial' }}
      />
        <label for="apiCheckbox" className="checkText">Insurance Plans with Premium Return</label>
      </div> */}
        </div>
      </div>  
    );
}

