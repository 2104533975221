import BrandLogo from '../../../../assets/get-quotes/hdfc-logo.png'

export const cardData = [
    {   
        id:1,
        logo:BrandLogo,
        companyName:'HDFC life insurance',
        maxLimit:'99Yrs',
        features:{
            a:'Waiver of premium cover',
            b:'100% payout on terminal illness',
            c:'Cover against 34 critical illness'
        },
        price: '₹ 3863'
    },
    {
        id:2,
        logo:BrandLogo,
        companyName:'HDFC life insurance',
        maxLimit:'99Yrs',
        features:{
            a:'Waiver of premium cover',
            b:'100% payout on terminal illness',
            c:'Cover against 34 critical illness'
        },
        price: '₹ 3863'
    },
    {
        id:3,
        logo:BrandLogo,
        companyName:'HDFC life insurance',
        maxLimit:'99Yrs',
        features:{
            a:'Waiver of premium cover',
            b:'100% payout on terminal illness',
            c:'Cover against 34 critical illness'
        },
        price: '₹ 3863'
    },
    {
        id:4,
        logo:BrandLogo,
        companyName:'HDFC life insurance',
        maxLimit:'99Yrs',
        features:{
            a:'Waiver of premium cover',
            b:'100% payout on terminal illness',
            c:'Cover against 34 critical illness'
        },
        price: '₹ 3863'
    },
    {
        id:5,
        logo:BrandLogo,
        companyName:'HDFC life insurance',
        maxLimit:'99Yrs',
        features:{
            a:'Waiver of premium cover',
            b:'100% payout on terminal illness',
            c:'Cover against 34 critical illness'
        },
        price: '₹ 3863'
    },
    {
        id:6,
        logo:BrandLogo,
        companyName:'HDFC life insurance',
        maxLimit:'99Yrs',
        features:{
            a:'Waiver of premium cover',
            b:'100% payout on terminal illness',
            c:'Cover against 34 critical illness'
        },
        price: '₹ 3863'
    },
]