import React from 'react'
import { BackIcon, CarIcon, CarouselDesc, CarouselDiv, CarouselHeader, ChangeNo, Circle, CircleInactive, ContinueBtn, Description, ErrArrow, ErrorContainer, ErrorIcon, ErrorMsgContainer, FadedContinueBtn, FloatingTitle, FormHeading, HDiv, HDivApart, InputContainer, LeftDiv, LeftSubDiv, LoginPassword, MainContainer, NextIcon, Resend, RightDiv, SubmittedContainer, SubmittedIcon, SuccessIcon } from "./styles"
import ReactElasticCarousel from "react-elastic-carousel"
import BackIconimg from '../../assets/commons/back-arrow-icon.svg'
import { data } from "./carousel-data"
import CarImg from '../../assets/commons/car-svg.svg'

const Carousal = () => {
    const PaginationCircle = ({ onClick, key, active }) => {
        if (active === true) {
            return (
                <Circle
                    key={key}
                    onClick={onClick}
                />
            )
        } else if (active === false) {
            return (
                <CircleInactive
                    key={key}
                    onClick={onClick}
                />
            )
        }
    }
    return (
        <>
            <CarIcon src={CarImg} />
            <ReactElasticCarousel
                showArrows={true}
                renderArrow={({ type, onClick }) => {
                    if (type === 'PREV') {
                        return (
                            <BackIcon onClick={onClick} src={BackIconimg} />
                        )
                    } else {
                        return (
                            <NextIcon onClick={onClick} src={BackIconimg} />
                        )
                    }
                }}
                renderPagination={({ pages, activePage, onClick }) => {
                    return (
                        <HDiv>
                            {pages.map((item) => {
                                const isActivePage = activePage === item;
                                return (
                                    <PaginationCircle
                                        key={item}
                                        onClick={() => onClick(item)}
                                        active={isActivePage}
                                    />
                                )
                            })}
                        </HDiv>
                    )
                }}
            >
                {data.map(item => (
                    <CarouselDiv key={item.id}>
                        <CarouselHeader>{item.title}</CarouselHeader>
                        <CarouselDesc>{item.description}</CarouselDesc>
                    </CarouselDiv>
                ))}
            </ReactElasticCarousel>
        </>

    )
}

export default Carousal