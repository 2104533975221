import { useNavigate } from "react-router-dom";
import { MainContainer, styles } from "../../../assets/common-styles/common-styles";
import { CarDetailsComponent } from "../../../components/car-details-component/CarDetailsComponent";
import { Layout } from "./styles";
import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { updateCombinedModel, modelVariant } from '../../../Redux/CarsRedux';
import { axiosGETCall } from '../../../utilities/utils/apiPost'
import moment from "moment";
import { combineReducers } from "redux";

export const CarDetailsForm4 = () => {

    const navigate = useNavigate()

    const dispatch = useDispatch();
    const combinedRedux = useSelector((state) => state.carsRedux.combinedRedux);
    const variantsList = useSelector((state) => state.carsRedux.variantsList);
    const [VariantList, setVariantList] = useState([]);
    // console.log("variantsList is ->", variantsList);


    useEffect(() => {
        // let mfgDate = moment(combinedRedux.mfgYear).year()
        // console.log("mfg date is",mfgDate)
        // const list = variantsList.filter((obj) => {
        //     if(obj.generationYear <= `${mfgDate}`) {
        //         return obj
        //     }
        // });
        // // console.log("variantsList are ", variantsList.filter(variant =>  console.log(variant.generationYear)));
        setVariantList(variantsList)
    }, [variantsList])

    useEffect(() => {
        if (combinedRedux.hasOwnProperty('cubicCap')) {
            let mfgDate = moment(combinedRedux.mfgYear).year()
            const modelData = combinedRedux;
            console.log("combinedRedux", combinedRedux);
            dispatch(modelVariant({ modelId: modelData.modelId, cubicCap: modelData.cubicCap, fuelType: combinedRedux.fuelType, uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true, mfgYear: `${mfgDate}`, brand: combinedRedux.brand, model: combinedRedux.model }))
        }
        else {
            setTimeout(() => {
                getLocalStorage()
            }, 1);
        }
    }, [combinedRedux])


    const getLocalStorage = async () => {
        var carInsuranceDetails = await (localStorage.getItem('canInsuranceDetails'));
        if (carInsuranceDetails) {
            carInsuranceDetails = JSON.parse(carInsuranceDetails);
            dispatch(updateCombinedModel(carInsuranceDetails));
        }
    };



    const data = [
        {
            id: 1,
            model: 'lorem ipsum'
        },
        {
            id: 2,
            model: 'lorem ipsum'
        },
        {
            id: 3,
            model: 'lorem ipsum'
        },
        {
            id: 4,
            model: 'lorem ipsum'
        },
        {
            id: 5,
            model: 'lorem ipsum'
        },
        {
            id: 6,
            model: 'lorem ipsum'
        },
        {
            id: 7,
            model: 'lorem ipsum'
        },
        {
            id: 8,
            model: 'lorem ipsum'
        },
        {
            id: 9,
            model: 'lorem ipsum'
        },
        {
            id: 10,
            model: 'lorem ipsum'
        },
        {
            id: 11,
            model: 'lorem ipsum'
        },
        {
            id: 12,
            model: 'lorem ipsum'
        },
        {
            id: 13,
            model: 'lorem ipsum'
        },
        {
            id: 14,
            model: 'lorem ipsum'
        },
        {
            id: 15,
            model: 'lorem ipsum'
        },
        {
            id: 16,
            model: 'lorem ipsum'
        },
        {
            id: 17,
            model: 'lorem ipsum'
        },
        {
            id: 18,
            model: 'lorem ipsum'
        },
        {
            id: 19,
            model: 'lorem ipsum'
        },
        {
            id: 20,
            model: 'lorem ipsum'
        },
    ]

    const handleSelect = val => {
        console.log("value........", val)
        if (combinedRedux?.type == "old") {
            axiosGETCall(`/vehicleInsurance/rtoCity/list?code=${combinedRedux?.stateCode}${combinedRedux?.rto}`, (callback => {
                console.log("result rto", callback)
                if (callback?.data?.length != 0) {
                    dispatch(updateCombinedModel({ makerModel: val.name, modelVariant: val.variantName, makeId: val.makeId, uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true,city:callback?.data[0]?.cityName })).then(() => {
                        navigate('/brand-new-model/car-form-preview', { state: { fromForm: true } })
                    });
                }
                else{
                    dispatch(updateCombinedModel({ makerModel: val.name, modelVariant: val.variantName, makeId: val.makeId, uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true })).then(() => {
                        navigate('/brand-new-model/car-details/5', { state: { fromForm: true } })
                    });
                }
            }))
        } else {
            dispatch(updateCombinedModel({ makerModel: val.name, modelVariant: val.variantName, makeId: val.makeId, uniqueFuelData: true, uniqueCubicCapData: true, uniqueSubNameData: true })).then(() => {
                navigate('/brand-new-model/car-details/5')
            });
        }


    }

    return (
        <>
            <MainContainer>
                <Layout>
                    <CarDetailsComponent
                        step={6} noSearch={true}
                        title='Variant'
                        variantsList={VariantList}
                        onSelect={handleSelect}
                    />
                </Layout>
            </MainContainer>
        </>
    )
}